<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiLabelDropdown from '@/core/ui/UiLabelDropdown';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiListContent from '@/core/ui/UiListContent';
import UiLabelEditor from '@/core/ui/UiLabelEditor';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';

// Define props
// const props = defineProps({});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

// Variables
const { allProjects, supportRequestEdit, supportRequestOk } = storeToRefs(useProjectsStore());
const sendForm = () => {
  let submit = true;

  if (!supportRequestEdit.value.typeOfMention) {
    showErrorTypeOfMention.value = true;
    submit = false;
  } else {
    showErrorTypeOfMention.value = false;
  }
  if (!supportRequestEdit.value.projectId) {
    showErrorProject.value = true;
    submit = false;
  } else {
    showErrorProject.value = false;
  }
  if (!supportRequestEdit.value.question && typeQuestion.value) {
    showErrorQuestion.value = true;
    submit = false;
  } else {
    showErrorQuestion.value = false;
  }
  if (!supportRequestEdit.value.description && !typeQuestion.value) {
    showErrorDescription.value = true;
    submit = false;
  } else {
    showErrorDescription.value = false;
  }
  if (!supportRequestEdit.value.reproduce && !typeQuestion.value) {
    showErrorReproduce.value = true;
    submit = false;
  } else {
    showErrorReproduce.value = false;
  }
  if (submit) {
    emit('emitSave');
  }
};

const updatetypeOfMention = (value) => {
  supportRequestEdit.value.typeOfMention = value.type;

  if (value.type) {
    showErrorTypeOfMention.value = false;
  }

  typeQuestion.value = value.type === 'SENDSUPPORTREQUESTQUESTION';
};

const updateProject = (value) => {
  supportRequestEdit.value.projectId = value.id;
  if (value.id) {
    showErrorProject.value = false;
  }
};

watch(() => supportRequestEdit.value.question, async (value) => {
  showErrorQuestion.value = !value;
});

watch(() => supportRequestEdit.value.description, async (value) => {
  showErrorDescription.value = !value;
});

watch(() => supportRequestEdit.value.reproduce, async (value) => {
  showErrorReproduce.value = !value;
});

const cancelForm = () => {
  emit('emitCancel');
};

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const configHelp = {
  form: {
    title: t('Help.ReportProblem'),
    dropdownTitle1: t('Help.TypeProblem'),
    dropdownFor1: 'soortmelding',
    dropdownLabel1: 'name',
    dropdownPlaceholder: t('Help.ChooseType'),
    dropdownItems1: [
      {
        name: t('General.Question'),
        type: 'SENDSUPPORTREQUESTQUESTION'
      },
      {
        name: t('General.Bug'),
        type: 'SENDSUPPORTREQUESTBUG'
      }
    ],
    dropdownTitle2: t('General.Project'),
    dropdownFor2: 'project',
    dropdownLabel2: 'name',
    textareaTitle1: t('General.Question'),
    textareaFor1: 'vraag',
    textareaTitle2: t('General.Description'),
    textareaFor2: 'beschrijving',
    textareaTitle3: t('Help.Steps'),
    textareaFor3: 'stappen',
    saveButton: t('General.Send'),
    cancelButton: t('General.Cancel')
  },
  content: {
    title: t('Help.App.Title'),
    contents: [
      {
        title: t('Help.App.Introduction'),
        hash: 'inleiding',
        content: t('Help.App.Introduction.Content')
      },
      {
        title: t('Help.App.Stakeholders'),
        hash: 'stakeholders',
        content: t('Help.App.Stakeholders.Content')
      },
      {
        title: t('Help.App.Issues'),
        hash: 'issues',
        content: t('Help.App.Stakeholders.Content')
      },
      {
        title: t('Help.App.ConnectIssuesStakeholders'),
        hash: 'koppelenissuesenstakeholders',
        content: t('Help.App.ConnectIssuesStakeholders.Content')
      },
      {
        title: t('Help.App.Reports'),
        hash: 'rapporateges',
        content: t('Help.App.Reports.Content')
      },
      {
        title: t('Help.App.Search'),
        hash: 'zoeken',
        content: t('Help.App.Search.Content')
      },
      {
        title: t('Help.App.Notes'),
        hash: 'notities',
        content: t('Help.App.Notes.Content')
      },
      {
        title: t('Help.App.Media'),
        hash: 'media',
        content: t('Help.App.Media.Content')
      },
      {
        title: t('Help.App.Projectmanagement'),
        hash: 'projectbeheer',
        content: t('Help.App.Projectmanagement.Content')
      },
      {
        title: t('Help.App.Versionmanagement'),
        hash: 'versiebeheer',
        content: t('Help.App.Versionmanagement.Content')
      }
    ]
  }
};
const typeQuestion = ref(false);
const showErrorTypeOfMention = ref(false);
const showErrorProject = ref(false);
const showErrorQuestion = ref(false);
const showErrorDescription = ref(false);
const showErrorReproduce = ref(false);
const errorMessage = ref(t('General.MandatoryField'));

// Functions
</script>

<template>
  <main>
    <p-card v-if="!supportRequestOk"
      class="help-support pt-3 pb-3 pe-1 ps-1">
      <template #content>
        <h3 class="help-support__title mt-0 mb-5">
          {{ configHelp.form.title }}
        </h3>
        <form class="mb-10" >
          <div class="help-support__grid mb-5">
            <ui-label-dropdown
              :label-title="configHelp.form.dropdownTitle1"
              :label-for="configHelp.form.dropdownFor1"
              :placeholder="configHelp.form.dropdownPlaceholder"
              :option-label="configHelp.form.dropdownLabel1"
              :options="configHelp.form.dropdownItems1"
              :showError="showErrorTypeOfMention"
              :error-message="errorMessage"
              @emit-item="updatetypeOfMention">
            </ui-label-dropdown>
            <ui-label-dropdown
              :label-title="configHelp.form.dropdownTitle2"
              :label-for="configHelp.form.dropdownFor2"
              :option-label="configHelp.form.dropdownLabel2"
              :options="allProjects"
              :showError="showErrorProject"
              :error-message="errorMessage"
              @emit-item="updateProject">
            </ui-label-dropdown>
          </div>
          <ui-label-editor v-if="typeQuestion"
            class="help-support__input mb-5"
            :label-title="configHelp.form.textareaTitle1"
            :label-for="configHelp.form.textareaFor1"
            :showError="showErrorQuestion"
            :error-message="errorMessage"
            v-model="supportRequestEdit.question">
          </ui-label-editor>
          <ui-label-editor v-if="!typeQuestion"
            class="help-support__input mb-5"
            :label-title="configHelp.form.textareaTitle2"
            :label-for="configHelp.form.textareaFor2"
            :showError="showErrorDescription"
            :error-message="errorMessage"
            v-model="supportRequestEdit.description">
          </ui-label-editor>
          <ui-label-editor v-if="!typeQuestion"
            class="help-support__input mb-5"
            :label-title="configHelp.form.textareaTitle3"
            :label-for="configHelp.form.textareaFor3"
            :showError="showErrorReproduce"
            :error-message="errorMessage"
            v-model="supportRequestEdit.reproduce">
          </ui-label-editor>
          <div class="d-flex justify-content-end">
            <ui-save-buttons
              :save-button="configHelp.form.saveButton"
              :cancel-button="configHelp.form.cancelButton"
              @emit-save="sendForm()"
              @emit-cancel="cancelForm()">
            </ui-save-buttons>
          </div>
        </form>

      </template>
    </p-card>
    <p-card v-if="supportRequestOk"
      class="help-support pt-3 pb-3 pe-1 ps-1">
      <template #content>
        <p>{{ $t('Help.Thanks') }}</p>
        <p-button
          class="me-2 ui-toggle-buttons__button p-button-secondary"
          xicon="pi pi-download"
          iconPos="left"
          :label="$t('Help.ReportAgain')"
          @click="supportRequestOk = false">
        </p-button>
      </template>
    </p-card>
    <p-card
      class="help-support pt-3 pb-3 pe-1 ps-1 mt-2">
      <template #content>
        <h3 class="help-support__title mt-0 mb-6">
          {{ configHelp.content.title }}
        </h3>
        <ui-list-content
          :list-content="configHelp.content.contents">
        </ui-list-content>
      </template>
    </p-card>
  </main>
</template>

<style lang="scss">
.help-support {
  &__input {
    .p-inputtextarea {
      min-height: calc-rem(93);
      resize: none;
    }
  }

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: calc-rem(16);
  }
}
</style>
