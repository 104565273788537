<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import useMoment from 'moment';
// import Masonry from 'masonry-layout';

// Component imports
import UiContentCard from '@/core/ui/UiContentCard';
import UiReviewCard from '@/core/ui/UiReviewCard';
import UiDocumentHistoryCard from '@/core/ui/UiDocumentHistoryCard';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useRoute } from 'vue-router';

// Define props

// Define emits

// Variables
const { fetchSingleProject } = useProjectsStore();
const { project } = storeToRefs(useProjectsStore());
const { loadingStakeholderIssue, contentCard, issueName, stakeholderName, moderator, author, configConcerns, configImportances } = storeToRefs(useStakeholderIssueStore());
const { fetchStakeholderIssue, updateStakeholderIssueConcern, updateStakeholderIssueImportance } = useStakeholderIssueStore();
const route = useRoute();
const projectId = route.params.projectId;
const issueId = route.params.issueId;
const stakeholderId = route.params.stakeholderId;
const UpdateConcern = (value) => {
  updateStakeholderIssueConcern(projectId, stakeholderId, issueId, value.id);
};
const UpdateImportance = (value) => {
  updateStakeholderIssueImportance(projectId, stakeholderId, issueId, value.id);
};

const configHistory = {
  cardTitle: 'General.DocumentHistory',
  moderator: '',
  moderatorUrl: '/',
  author: '',
  authorUrl: '/',
  dateTime: '',
  subtitleModerator: 'General.LastUpdated',
  subtitleAuthor: 'General.Author',
  moderatorInfo: 'General.By'
};

// Functions
onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  await fetchStakeholderIssue(projectId, stakeholderId, issueId);
});
</script>

<template>
  <div
    class="stakeholder-issue-detail"
    ref="container"
    v-if="!loadingStakeholderIssue">
    <div>
      <ui-content-card
        class="stakeholder-issue-detail__card"
        :content="contentCard">
      </ui-content-card>
    </div>
    <div class="stakeholder-issue-detail__sub-grid">
      <div>
        <ui-review-card
          class="stakeholder-issue-detail__card"
          :card-title="$t(configConcerns.cardTitle)"
          :checkbox-options="configConcerns.checkboxes"
          @update-value="UpdateConcern" @submit-value="''">
        </ui-review-card>
        <ui-review-card
          class="stakeholder-issue-detail__card"
          :card-title="$t(configImportances.cardTitle)"
          :checkbox-options="configImportances.checkboxes"
          @update-value="UpdateImportance" @submit-value="''">
        </ui-review-card>
        <div class="p-card p-component ui-content-card stakeholder-issue-detail__card">
          <div class="p-card-body">
            <div class="p-card-content">
              <div class="pt-4 pb-2 pe-1 ps-1">
                <div class="mb-3">
                  <h3>{{ $t('General.Stakeholder') }}</h3>
                  <router-link
                    class="stakeholder-issue-detail__card-link"
                    :to="'/projects/' + projectId + '/stakeholders/' + stakeholderId">
                    {{ stakeholderName }}
                  </router-link>
                </div>
                <div>
                  <h3>{{ $t('General.Issue') }}</h3>
                  <router-link
                    class="stakeholder-issue-detail__card-link"
                    :to="'/projects/' + projectId + '/issues/' + issueId">
                    {{ issueName }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ui-document-history-card
          v-if="moderator != null && author != null"
          class="stakeholder-issue-detail__card"
          :card-title="$t(configHistory.cardTitle)"
          :moderator="moderator.firstname || moderator.lastname
              ? (moderator.firstname ?? '') +
              ' ' +
              (moderator.lastname ?? '')
              : moderator.userName"
          :moderator-url="'/profile/' + moderator.id"
          :date-time="useMoment(moderator.modDate).format('DD-MM-YYYY HH:mm')"
          :moderator-info="$t(configHistory.moderatorInfo)"
          :author="author.firstname || author.lastname
              ? (author.firstname ?? '') +
              ' ' +
              (author.lastname ?? '')
              : author.userName"
          :author-url="'/profile/' + author.id"
          :subtitle-author="$t(configHistory.subtitleAuthor)"
          :subtitle-moderator="$t(configHistory.subtitleModerator)">
        </ui-document-history-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.stakeholder-issue-detail {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    column-gap: calc-rem(9);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 3fr 2fr;
  }

  @media (min-width: 1530px) {
    grid-template-columns: 4fr 2fr;
  }

  @media (min-width: 1665px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr;
  }

  &__sub-grid {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;

    @media (min-width: 1665px) {
      grid-template-columns: 1fr 1fr;
      column-gap: calc-rem(9);
    }
  }

  &__card {
    margin-bottom: calc-rem(9);

    &-link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
