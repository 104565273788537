<script setup>
import { ref, toRefs } from 'vue';

// Component imports
import PCard from 'primevue/card';
// import PPanel from 'primevue/panel';
import PPanel from '../../../core/ui/panel/PanelCustom';
import PBadge from 'primevue/badge';
import PButton from 'primevue/button';

// Store imports

// Lifecycle hook imports
import { useRoute } from 'vue-router';

// Define props
const props = defineProps({
  card: Object,
  buttonText: Array,
  type: String
});

const route = useRoute();
const projectId = route.params.projectId;

// Variables
const { card } = toRefs(props);
const dummyCollapsedBool = ref(true); // This makes the toggle work...
const cardItems = ref(card); // Disconnect for reactivity
</script>

<template>
  <p-card class="dashboard-list-card">
    <template #header>
      <h2 class="dashboard-list-card__title mt-0 mb-5 pt-4 pe-3 ps-3">
        <router-link :to="'/projects/' + projectId + '/' + props.type">{{ cardItems.cardTitle }}</router-link>
      </h2>
      <h3 class="dashboard-list-card__subtitle mt-0 mb-0 pe-3 ps-3">
        {{ cardItems.cardSubtitle }}
      </h3>
    </template>
    <template #content>
      <p-panel :toggleable="true" :collapsed="true" v-for="item in cardItems.panels" :key="item.id">
        <template #header>
          <div class="d-flex align-items-center">
            <p-badge class="dashboard-list-card__badge me-2"
              :class="item.className + ' collapsed-' + dummyCollapsedBool">
              {{ item.panel.length }}
            </p-badge>
            <span>
              {{ item.panelTitle }}
            </span>
          </div>
        </template>
        <TransitionGroup name="fade" tag="ul" :class="'dashboard-list-card__list m-0 pt-0 pb-3 ps-0'"
          :key="'status' + item.id">
          <li class="dashboard-list-card__list-item align-items-center" v-for="listitem in item.panel.slice(
            0,
            item.isCollapsed ? 4 : item.panel.length
          )" :key="'issue' + listitem.id">
            <router-link class="dashboard-list-card__list-link d-inline-block" :to="listitem.urlTo"
              v-if="listitem.urlTo !== null && listitem.urlTo !== ''">
              {{ listitem.title }}
            </router-link>
          </li>
          <li class="dashboard-list-card__list-item --button pt-4" v-show="item.panel.length > 4"
            :key="'button' + item.id">
            <p-button class="
                dashboard-list-card__button
                w-100
                p-button-secondary
                justify-content-center
              " :class="{ 'is-rotated': !item.isCollapsed }"
              @click="dummyCollapsedBool = !dummyCollapsedBool; item.isCollapsed = !item.isCollapsed">
              <span class="pi pi-chevron-down"></span>
              <span v-if="item.isCollapsed">{{ props.buttonText[0] }}</span>
              <span v-if="!item.isCollapsed">{{ props.buttonText[1] }}</span>
            </p-button>
          </li>
        </TransitionGroup>
      </p-panel>
    </template>
  </p-card>
</template>

<style lang="scss">
.dashboard-list-card {
  width: 100%;

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    color: $primary;

    a {
      text-decoration: none;
      color: #005FE3;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__subtitle {
    padding-bottom: calc-rem(12);
    font-size: calc-rem(12);
    line-height: calc-rem(14);
    color: $secondaryTextColor;
  }

  &__badge {
    width: calc-rem(39);
    height: calc-rem(34);
    font-weight: 600;
    line-height: calc-rem(34);
    border-radius: calc-rem(20);
  }

  &__list {
    list-style: none;

    &-item {
      display: flex;

      &:nth-child(n + 2) {
        border-top: calc-rem(1) solid $separatorLineColor;
      }

      &.--button {
        border-top: none;
      }
    }

    &-link {
      padding-top: calc-rem(12);
      padding-bottom: calc-rem(12);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
      text-decoration: none;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        @extend %button-focus;
      }
    }
  }

  &__button {
    height: calc-rem(40);
    line-height: calc-rem(40);

    .pi {
      margin-right: calc-rem(7);
      transition: transform 300ms ease-out 200ms;
    }

    &.is-rotated {
      .pi {
        transform: rotate(180deg);
      }
    }
  }

  .p-card {
    &-content {
      padding: 0;
    }
  }

  .p-panel {
    border-top: calc-rem(1) solid $separatorLineColor;

    &-toggler {
      .pi-plus {
        &::before {
          content: '\e902';
        }
      }

      .pi-minus {
        &::before {
          content: '\e903';
        }
      }
    }
  }

  .--criticism {
    &.dashboard-list-card__badge {
      background-color: $criticism;
    }
  }

  .--warning {
    &.dashboard-list-card__badge {
      background-color: $warning;
    }
  }

  .--focus {
    &.dashboard-list-card__badge {
      background-color: $focus;
      color: $black;
    }
  }

  .--positive {
    &.dashboard-list-card__badge {
      background-color: $positive;
    }
  }

  .--unclear {
    &.dashboard-list-card__badge {
      background-color: $unclear;
    }
  }
}
</style>
