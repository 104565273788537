<script setup>
// import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Component imports
import DashboardListCard from './components/DashboardListCard';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports

// Variables
const { getProjectIssuesGroupedByStatus, getProjectStakeholdersGroupedByStatus } = storeToRefs(useProjectsStore());
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttonText = [t('Project.Dashboard.CompleteList'), t('Project.Dashboard.InCompleteList')];
// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
</script>

<template>
  <div class="dashboard-list row">
    <div class="col-6">
      <dashboard-list-card
        :card="getProjectIssuesGroupedByStatus"
        :button-text="buttonText"
        type="issues">
      </dashboard-list-card>
    </div>
    <div class="col-6">
      <dashboard-list-card
        :card="getProjectStakeholdersGroupedByStatus"
        :button-text="buttonText"
        type="stakeholders">
      </dashboard-list-card>
    </div>
  </div>
</template>

<style lang="scss">
.dashboard-list {
}
</style>
