<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { storeToRefs } from 'pinia/dist/pinia.esm-browser';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Components imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import IssueEdit from '@/modules/issueedit/IssueEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useIssuesStore } from '@/store/modules/issues';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = t('Issue.EditIssue');
const { issueToAdd, loadingEditIssue } = storeToRefs(useIssuesStore());
const { updateIssue, updateIssueGeoJson, fetchSingleIssueToEdit, deleteIssue } = useIssuesStore();
const { fetchSingleProject, fetchSingleProjectIssues, fetchProjects } = useProjectsStore();
const { project } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
// const store = useIssuesStore();
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const showError = ref(false);
const route = useRoute();
const projectId = route.params.projectId;
const issueId = route.params.issueId;
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  deleteButton: t('General.Delete'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
    // {
    //   label: t('General.Delete'),
    //   command: () => {
    //     confirmDeleteIssue();
    //   }
    // }
  ]
};

const cancel = () => {
  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.issueToAdd = {};
  // });
  router.push({ path: '/projects/' + projectId + '/issues/' + issueId });
};

const save = async () => {
  if (issueToAdd.value.name === undefined || issueToAdd.value.name === '') {
    showError.value = true;
    console.log('error: no name');
  } else {
    showError.value = false;
    await saveIssue(false);
  }
};

const saveNew = async () => {
  if (issueToAdd.value.name === undefined || issueToAdd.value.name === '') {
    showError.value = true;
    console.log('error: no name');
  } else {
    showError.value = false;
    await saveIssue(true);
  }
};

const saveIssue = async (newIssue) => {
  loadingEditIssue.value = true;
  await updateIssue(projectId, issueId, issueToAdd.value);
  await updateIssueGeoJson(projectId, issueId, issueToAdd.value.gis);
  await fetchSingleProjectIssues(projectId);
  isDirtyPage.value = false;

  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.issueToAdd = {};
  // });

  if (newIssue) {
    await router.push({ path: '/projects/' + projectId + '/issues/create' });
  } else {
    await router.push({ path: '/projects/' + projectId + '/issues/' + issueId });
  }
};

const confirmDeleteIssue = () => {
  confirm.require({
    message: t('Issue.DeleteIssue'),
    header: t('General.Confirm'),
    acceptLabel: t('General.Yes'),
    rejectLabel: t('General.No'),
    accept: async () => {
      loadingEditIssue.value = true;
      await deleteIssue(projectId, issueId);
      loadingEditIssue.value = true;
      await fetchSingleProjectIssues(projectId);
      await fetchProjects();
      await router.push({ path: '/projects/' + projectId + '/issues/' });
    },
    reject: () => {
      console.log('reject');
    }
  });
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  await fetchSingleIssueToEdit(project.value, issueId);
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    next();
  }
});
</script>

<template>
  <base-layout class="project-issues-edit-view position-relative">
    <template v-slot:header>
      <view-header
        :title="viewTitle">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <issue-edit
        v-if="!loadingEditIssue"
        :showError="showError"
        :save-button="buttons.saveButton"
        :cancel-button="buttons.cancelButton"
        :delete-button="buttons.deleteButton"
        :buttons-dropdown-items="buttons.buttonsDropdownItems"
        @emit-save="save()"
        @emit-cancel="cancel()"
        @emit-delete="confirmDeleteIssue()">
      </issue-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-issue-edit-view__spinner position-absolute"
    v-if="loadingEditIssue">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-issue-edit-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
