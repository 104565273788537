<script setup>
// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PProgressSpinner from 'primevue/progressspinner';
import PButton from 'primevue/button';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Router import
import router from '@/router';

// Variables
const { loadingIndex } = storeToRefs(useProjectsStore());
const { indexProjects } = useProjectsStore();

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: false
});

const IndexProjects = async () => {
  await indexProjects();
  await router.push({ path: '/' });
};
</script>

<template>
  <base-layout v-if="!loadingIndex">
    <template v-slot:header>
      <view-header :title="Indexeren">
      </view-header>
    </template>
    <template v-slot:main>
      <div>
        <p>LET OP: alle projecten in deze omgeving worden opnieuw geindexeerd.</p>
        <p-button
          :label="'Start indexeren'"
          icon="pi pi-inbox"
          iconPos="left"
          @click="IndexProjects()">
        </p-button>
      </div>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-search-index-view__spinner position-absolute"
    v-if="loadingIndex">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-search-index-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
