<script setup>
// import { onMounted } from 'vue';
// import { storeToRefs } from 'pinia';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';

// Store imports

// Variables

// Functions

</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="ReportIssueView">
      </view-header>
    </template>
    <template v-slot:main></template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.report-issue-view {
}
</style>
