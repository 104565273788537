<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PEditor from 'primevue/editor';
import POverlayPanel from 'primevue/overlaypanel';
import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useIssuesStore } from '@/store/modules/issues';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholders: Object,
  issues: Object,
  projectImportanceMatrix: Object
});

// Define emits

// Variables
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { project } = storeToRefs(useProjectsStore());
const {
  updateStakeholderIssueImportanceFromMatrix,
  updateStakeholderIssueImportanceOpinionFromMatrix,
  addStakeholderIssueImportance,
  removeStakeholderIssue
} = useStakeholderIssueStore();

const {
  updateStakeholderStatusFromMatrix,
  updateStakeholderEmotionFromMatrix,
  updateStakeholderUrgencyFromMatrix
} = useStakeholdersStore();

const {
  updateIssueStatusFromMatrix
} = useIssuesStore();

const {
  updateSingleProjectStakeholders,
  updateSingleProjectIssues
} = useProjectsStore();

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

// const opColor = ref();
const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
const onFocusOverlayPanelInput = () => {
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const onFocusOverlayPanelInputEditor = (stakeholderId, issueId) => {
  const opinion = crossPoint(stakeholderId, issueId).opinion;
  const status = crossPoint(stakeholderId, issueId).issueStakeholderImportanceNr;

  if (!opinion && !status) {
    updateCrossPointImportance(0, stakeholderId, issueId);
  }
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const onBlurOverlayPanelInput = () => {
  focusOverlayPanelInput.value = false;
};
const hideOverlayPanelButtons = () => {
  showOverlayPanelButtons.value = false;
};
// const cancelSelectColor = () => {
//   opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const getImportance = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return { className: '--unclear', name: configMatrix.tooltip.unclear };
    case 4:
      return { className: '--positive', name: configMatrix.tooltip.positive };
    case 3:
      return { className: '--focus', name: configMatrix.tooltip.focus };
    case 2:
      return { className: '--warning', name: configMatrix.tooltip.warning };
    case 1:
      return { className: '--criticism', name: configMatrix.tooltip.criticism };
    default:
      return { className: null, name: null };
  }
};
const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
const scrollRight = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value += 1;
  scrollValue.value -= scrollDistance;

  // Add class to 1st column and enable left button
  if (countClicks.value === 1) {
    disableLeftButton.value = false;
    addShadowClass.value = true;
  }
  // Disable right button on max clicks
  if (countClicks.value === maxClicks) {
    disableRightButton.value = true;
  }
};
const scrollLeft = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value -= 1;
  scrollValue.value += scrollDistance;

  // Disable left button and remove class from 1st column
  if (countClicks.value === 0) {
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }

  // Enable right button
  if (countClicks.value < maxClicks) {
    disableRightButton.value = false;
  }
};
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Cancel')
  },
  tooltip: {
    reset: t('Status.NoStress'),
    criticism: t('Status.Kritiek'),
    warning: t('Status.Waarschuwing'),
    focus: t('Status.Aandacht'),
    positive: t('Status.Positief'),
    unclear: t('Status.Onduidelijk')
  }
};

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});

const emotionOption = ref([
  {
    value: null,
    name: t('General.Unknown')
  },
  {
    value: 0,
    name: t('Emotion.Geen.emotie')
  },
  {
    value: 1,
    name: t('Emotion.Ontkenning')
  },
  {
    value: 2,
    name: t('Emotion.Woede')
  },
  {
    value: 3,
    name: t('Emotion.Onderhandelen')
  },
  {
    value: 4,
    name: t('Emotion.Depressie')
  },
  {
    value: 5,
    name: t('Emotion.Aanvaarding')
  },
  {
    value: 6,
    name: t('Emotion.Low')
  },
  {
    value: 7,
    name: t('Emotion.Average')
  },
  {
    value: 8,
    name: t('Emotion.High')
  }
]);

const urgencyOptions = ref([
  {
    nr: null,
    name: ''
  },
  {
    value: 1,
    name: t('Urgency.Zeer.urgent')
  },
  {
    value: 2,
    name: t('Urgency.Urgent')
  },
  {
    value: 3,
    name: t('Urgency.Redelijk.urgent')
  },
  {
    value: 4,
    name: t('Urgency.Beperkt.urgent')
  },
  {
    value: 5,
    name: t('Urgency.Niet.urgent')
  }
]);

const importanceClassName = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return '--unclear';
    case 4:
      return '--positive';
    case 3:
      return '--focus';
    case 2:
      return '--warning';
    case 1:
      return '--criticism';
  }
};

const importanceText = (importanceNr) => {
  switch (importanceNr) {
    case 0:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Positief');
    case 3:
      return t('Status.Aandacht');
    case 2:
      return t('Status.Waarschuwing');
    case 1:
      return t('Status.Kritiek');
  }
};

const crossPoint = (stakeholderId, issueId) => {
  if (projectImportanceMatrix.value && projectImportanceMatrix.value.jsonModel) {
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderImportanceName = importanceText(crossPoint.issueStakeholderImportanceNr);
      crossPoint.importanceClassName = importanceClassName(crossPoint.issueStakeholderImportanceNr);
      if (!crossPoint.orgValue) {
        crossPoint.orgValue = crossPoint.opinion;
      }
      return crossPoint;
    }
  }
  return {
    issueStakeholderConcernNr: null,
    issueStakeholderConcernName: null,
    issueStakeholderImportanceNr: null,
    issueStakeholderImportanceName: null,
    opinion: null,
    importanceClassName: null
  };
};

watch(() => props.projectImportanceMatrix, (first, second) => {
  // console.info('projectImportanceMatrix', first);
  projectImportanceMatrix.value = first;
});
watch(() => props.stakeholders, (first, second) => {
  // console.info('stakeholders', first);
  stakeholders.value = first;
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

// This collects dynamic refs for all crosspoints
const projectImportanceMatrix = ref(null);
const stakeholders = ref(null);
const importance = ref([]);
const crossPoints = ref([]);
const crosspointTextareas = ref([]);
const stakeholderEmotion = ref([]);
const stakeholderEmotionLabel = ref([]);
const stakeholderUrgency = ref([]);
const stakeholderUrgencyLabel = ref([]);
const toggleImportance = (event, importanceId) => {
  // console.info(importance, importanceId);
  // console.info('importanceId', crossPoints.value[importanceId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectimportancematrix.edit', currentUser.value, props.projectId)) {
    importance.value[importanceId].toggle(event);
  }
};
const toggleCrossPoint = (event, crossPointId) => {
  // console.info(crossPoints, crossPointId);
  // console.info('crossPointId', crossPoints.value[crossPointId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectimportancematrix.edit', currentUser.value, props.projectId)) {
    crossPoints.value[crossPointId].toggle(event);
  }
};
const updateStakeholderStatusValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.statusNr = value;
    document.querySelector('#shimportancemain-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderStatusFromMatrix(project.value, stakeholderId, value);
    updateSingleProjectStakeholders(stakeholderId, value);
    // }
  }
};
const updateIssueStatusValue = (value, categoryIndex, issueId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.statusNr = value;
    document.querySelector('#issue-importance-' + issueId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateIssueStatusFromMatrix(project.value, issueId, value);
    updateSingleProjectIssues(issueId, value);
    // }
  }
};
const updateCrossPointImportance = (value, stakeholderId, issueId) => {
  if (value === -1 || value === null) {
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (!crossPoint || crossPoint === undefined) {
      return;
    }
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    if (confirm(t('Product.ConcernMatrix.AreYouSure'))) {
      crossPoint.issueStakeholderImportanceNr = null;
      crossPoint.importanceClassName = null;
      removeStakeholderIssue(props.projectId, stakeholderId, issueId);
    }
  } else {
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderImportanceNr = value;
      crossPoint.importanceClassName = value;
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderIssueImportanceFromMatrix(props.projectId, stakeholderId, issueId, value);
    } else {
      projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.push({
        stakeholderId: stakeholderId,
        issueId: issueId,
        issueStakeholderImportanceNr: value
      });
      // console.info('SOFT CREATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      addStakeholderIssueImportance(props.projectId, stakeholderId, issueId, value);
    }
  }
};
const updateStakeholderIssueImportanceOpinion = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPoint) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderIssueImportanceOpinionFromMatrix(props.projectId, stakeholderId, issueId, crosspointTextareas.value[crossPointId].value);

    const indexOfObject = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectImportanceMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].orgValue = crosspointTextareas.value[crossPointId].value;
    }
  }
  showOverlayPanelButtons.value = false;
};
const toggleStakeholderEmotion = (event, stakeholderId) => {
  stakeholderEmotion.value[stakeholderId].toggle(event);
};
const updateStakeholderEmotion = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#emotionOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderEmotionFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderEmotion.value[stakeholderId].toggle(event);
};
const toggleStakeholderUrgency = (event, stakeholderId) => {
  stakeholderUrgency.value[stakeholderId].toggle(event);
};
const updateStakeholderUrgency = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#urgencyOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderUrgencyFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderUrgency.value[stakeholderId].toggle(event);
};
</script>

<template>
  <main>
    <p-card
      class="importance-matrix position-relative"
      v-if="stakeholders && props.issues && projectImportanceMatrix">
      <template #content>
        <div class="matrix__grid">
          <div
            class="matrix__grid-item"
            :class="{ '--shade': addShadowClass }">
            <!-- RENDER STAKEHOLDERS -->
            <div
              v-for="stakeholderCategory in stakeholders.panels"
              :key="'stakeholderCategory' + stakeholderCategory.id">
              <h3 class="matrix__stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                <span>
                  {{ stakeholderCategory.panelTitle }}
                </span>
              </h3>
              <ul class="matrix__stakeholders mt-0 mb-0 ps-0">
                <li
                  v-for="stakeholder in stakeholderCategory.panel"
                  :key="'stakeholder' + stakeholder.id"
                  class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center"
                  id="shale_drillo"
                  role="rowheader">
                  <router-link
                    :to="'/projects/' + projectId + '/stakeholders/' + stakeholder.id"
                    :title="stakeholder.title">
                    {{ stakeholder.title }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="matrix__stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center --last-in-cat">
                <span>
                  {{ $t('Issue.Frontend.Status') }}
                </span>
              </h3>
            </div>
          </div>
          <div class="matrix__grid-item overflow-hidden">
            <div
              class="matrix__scroll-window w-100 h-100 position-relative"
              ref="scrollWindow">
              <div
                class="matrix__matrix position-absolute h-100"
                ref="matrix"
                :style="'transform: translateX( ' + scrollValue + 'px)'">
                <div
                  :class="{ '--border-right': !showScrollButtons }"
                  role="table"
                  aria-label="Belangenmatrix">
                  <div
                    class="d-flex"
                    role="row">
                    <!-- RENDER ISSUES TOP ROW -->
                    <template
                      v-for="issueCategory in props.issues.panels"
                      :key="'issueCategory' + issueCategory.id">
                      <div
                        class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category"
                        role="columnheader">
                        <span>
                          {{ issueCategory.panelTitle }}
                        </span>
                      </div>
                      <div
                        v-for="issue in issueCategory.panel"
                        class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end"
                        :key="'issue' + issue.id"
                        role="columnheader">
                        <span>
                          <router-link
                            :to="'/projects/' + projectId + '/issues/' + issue.id"
                            :title="issue.title">
                            {{ issue.title }}
                          </router-link>
                        </span>
                      </div>
                    </template>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category"
                      role="columnheader">
                      <span>
                        {{ $t('ProjectStakeholder.Status.Excel') }}
                      </span>
                    </div>
                    <!-- RENDER LAST COLUMN TOP ROW -->
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('ProjectStakeholder.Emotion.Excel') }}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('Stakeholder.Urgency') }}
                      </span>
                    </div>
                  </div>
                  <!-- RENDER SECOND ROW - FIRST STAKEHOLDERCATEGORY AND SO ON -->
                  <template
                    v-for="crosspointStakeholderCategoryPanel in props.stakeholders.panels"
                    :key="'crosspointStakeholderCategoryPanel' + crosspointStakeholderCategoryPanel.id">
                    <div
                      class="d-flex"
                      role="row">
                      <div
                        class="matrix__td --category"
                        role="cell"
                        v-for="index in props.issues.panels.length + props.issues.itemCount + 1"
                        :key="'crosspointStakeholderCategoryPanel' + index">
                      </div>
                      <div
                        class="matrix__td --strategy --wide"
                        role="cell">
                      </div>
                      <div
                        class="matrix__td --strategy"
                        role="cell">
                      </div>
                    </div>
                    <div
                      v-for="(crosspointStakeholderPanel, index) in crosspointStakeholderCategoryPanel.panel"
                      :key="'crosspointStakeholderPanel' + crosspointStakeholderPanel.id"
                      class="d-flex"
                      role="row">
                      <template
                        v-for="crosspointIssueCategoryPanel in props.issues.panels"
                        :key="'crosspointIssueCategoryPanel' + crosspointIssueCategoryPanel.id">
                        <div
                          :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                          class="matrix__td d-flex justify-content-center align-items-center"
                          role="cell">
                        </div>
                        <div
                          v-for="crosspointIssuePanel in crosspointIssueCategoryPanel.panel"
                          :key="'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id"
                          :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                          class="matrix__td d-flex justify-content-center align-items-center"
                          role="cell"
                          :debugme-title="'crosspoint: ' + crosspointStakeholderPanel.title + '-' + crosspointIssuePanel.title + ' value: ' + crossPoint(
                              crosspointStakeholderPanel.id,
                              crosspointIssuePanel.id
                            ).issueStakeholderImportanceNr +
                            ' - ' +
                            crossPoint(
                              crosspointStakeholderPanel.id,
                              crosspointIssuePanel.id
                            ).issueStakeholderImportanceName
                          ">
                          <p-button class="matrix__button p-0" :class="
                            crossPoint(
                              crosspointStakeholderPanel.id,
                              crosspointIssuePanel.id
                            ).importanceClassName
                          " :label="
  crossPoint(
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  ).issueStakeholderImportanceName
" aria-haspopup="true" :aria-controls="
  'crosspoint-' +
  crosspointStakeholderPanel.id +
  '-' +
  crosspointIssuePanel.id
" v-tooltip.top="
  crossPoint(
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  ).issueStakeholderImportanceName
" @click="
  toggleCrossPoint(
    $event,
    'crosspoint-' +
    crosspointStakeholderPanel.id +
    '-' +
    crosspointIssuePanel.id
  )
">
                          </p-button>
                          <p-overlay-panel class="matrix__overlay-panel p-2"
                            :class="{ '--focused': focusOverlayPanelInput }" :ref="
                              (el) => {
                                crossPoints[
                                  'crosspoint-' +
                                  crosspointStakeholderPanel.id +
                                  '-' +
                                  crosspointIssuePanel.id
                                ] = el;
                              }
                            " :id="
  'crosspoint-' +
  crosspointStakeholderPanel.id +
  '-' +
  crosspointIssuePanel.id
" @hide="hideOverlayPanelButtons()">
                            <div class="
                                matrix__overlay-panel-buttons
                                d-flex
                                align-items-center
                                w-100
                              ">
                              <p-button class="p-button-rounded p-0 text-center --reset" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == null,
                              }" label="Geen spanning" icon="pi pi-times" v-tooltip.top="configMatrix.tooltip.reset" @click="
  updateCrossPointImportance(
    null,
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  )
">
                              </p-button>
                              <p-button class="p-button-rounded p-0 --unclear" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == '--unclear',
                              }" :label="configMatrix.tooltip.unclear" v-tooltip.top="configMatrix.tooltip.unclear"
                                @click="
                                  updateCrossPointImportance(
                                    0,
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  )
                                ">
                              </p-button>
                              <p-button class="p-button-rounded p-0 --positive" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == '--positive',
                              }" :label="configMatrix.tooltip.positive" v-tooltip.top="
  configMatrix.tooltip.positive
" @click="
  updateCrossPointImportance(
    4,
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  )
">
                              </p-button>
                              <p-button class="p-button-rounded p-0 --focus" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == '--focus',
                              }" :label="
  configMatrix.tooltip.focus
" v-tooltip.top="
  configMatrix.tooltip.focus
" @click="
  updateCrossPointImportance(
    3,
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  )
">
                              </p-button>
                              <p-button class="p-button-rounded p-0 --warning" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == '--warning',
                              }" :label="configMatrix.tooltip.warning" v-tooltip.top="
  configMatrix.tooltip.warning
" @click="
  updateCrossPointImportance(
    2,
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  )
">
                              </p-button>
                              <p-button class="p-button-rounded p-0 --criticism" :class="{
                                '--active':
                                  crossPoint(
                                    crosspointStakeholderPanel.id,
                                    crosspointIssuePanel.id
                                  ).importanceClassName == '--criticism',
                              }" :label="
  configMatrix.tooltip.criticism
" v-tooltip.top="
  configMatrix.tooltip.criticism
" @click="
  updateCrossPointImportance(
    1,
    crosspointStakeholderPanel.id,
    crosspointIssuePanel.id
  )
">
                              </p-button>
                            </div>
                            <label class="ui-form-label d-flex align-items-center my-2" for="description"><span class="d-inline-block">{{ $t('IssueStakeholder.Opinion') }}</span></label>
                            <p-editor class="mt-2" :ref="
                                (el) => {
                                  crosspointTextareas[
                                    'crosspoint-' +
                                    crosspointStakeholderPanel.id +
                                    '-' +
                                    crosspointIssuePanel.id
                                  ] = el;
                                }
                              " @click="onFocusOverlayPanelInputEditor(crosspointStakeholderPanel.id, crosspointIssuePanel.id)"
                                      v-model="crossPoint(crosspointStakeholderPanel.id,crosspointIssuePanel.id).opinion">
                              <template v-slot:toolbar>
                                <span class="ql-formats">
                                  <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                  <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                  <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                </span>
                              </template>
                            </p-editor>
                            <textarea class="p-hidden
                              " :placeholder="$t('General.Explanation')" :ref="
                                (el) => {
                                  crosspointTextareas[
                                    'crosspoint-' +
                                    crosspointStakeholderPanel.id +
                                    '-' +
                                    crosspointIssuePanel.id
                                  ] = el;
                                }
                              " @focus="onFocusOverlayPanelInput()" @blur="onBlurOverlayPanelInput()" v-model="crossPoint(
  crosspointStakeholderPanel.id,
  crosspointIssuePanel.id
).opinion"></textarea>
                            <Transition name="fade">
                              <div class="d-flex justify-content-end" v-if="showOverlayPanelButtons">
                                <p-button class="
                                    matrix__overlay-panel-button
                                    p-button-text
                                    me-1
                                  " :label="
                                    configMatrix.overlayPanel.cancelButton
                                  " @mousedown="cancelStakeholderIssueImportanceOpinion(
                                      null,
                                      'crosspoint-' +
                                      crosspointStakeholderPanel.id +
                                      '-' +
                                      crosspointIssuePanel.id,
                                      crosspointStakeholderPanel.id,
                                      crosspointIssuePanel.id
                                    )">
                                </p-button>
                                <p-button class="matrix__overlay-panel-button"
                                  :label="configMatrix.overlayPanel.saveButton" @mousedown="
                                    updateStakeholderIssueImportanceOpinion(
                                      null,
                                      'crosspoint-' +
                                      crosspointStakeholderPanel.id +
                                      '-' +
                                      crosspointIssuePanel.id,
                                      crosspointStakeholderPanel.id,
                                      crosspointIssuePanel.id
                                    )
                                  ">
                                </p-button>
                              </div>
                            </Transition>
                          </p-overlay-panel>
                        </div>
                      </template>
                      <div
                        :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                        class="matrix__td d-flex justify-content-center align-items-center"
                        role="cell">
                        <p-button
                          class="matrix__button p-0"
                          :class="importanceClassName(crosspointStakeholderPanel.statusNr)"
                          :label="importanceText(crosspointStakeholderPanel.statusNr)"
                          aria-haspopup="true"
                          :aria-controls="'shimportance-' + crosspointStakeholderPanel.id"
                          :id="'shimportancemain-' + crosspointStakeholderPanel.id"
                          v-tooltip.top="importanceText(crosspointStakeholderPanel.statusNr)"
                          @click="toggleImportance($event, 'shimportance-' + crosspointStakeholderPanel.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { importance['shimportance-' + crosspointStakeholderPanel.id] = el; }"
                          :id="'shimportance-' + crosspointStakeholderPanel.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--unclear', }" :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateStakeholderStatusValue(5, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--positive',}"
                              :label="configMatrix.tooltip.positive"
                              v-tooltip.top="configMatrix.tooltip.positive"
                              @click="updateStakeholderStatusValue(4, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--focus',}"
                              :label="configMatrix.tooltip.focus"
                              v-tooltip.top="configMatrix.tooltip.focus"
                              @click="updateStakeholderStatusValue(3, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--warning', }"
                              :label="configMatrix.tooltip.warning"
                              v-tooltip.top="configMatrix.tooltip.warning"
                              @click="updateStakeholderStatusValue(2, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--criticism', }"
                              :label="configMatrix.tooltip.criticism"
                              v-tooltip.top="configMatrix.tooltip.criticism"
                              @click="updateStakeholderStatusValue(1, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                          </div>
                        </p-overlay-panel>
                      </div>
                      <!-- emotionOptions -->
                      <div
                        :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                        class="
                          matrix__td
                          d-flex
                          justify-content-center
                          align-items-center
                          --wide
                        " role="cell">
                        <div>
                          <p-button class="matrix__strategy-button p-button-text" :label="
                            crosspointStakeholderPanel.emotion
                              ? emotionOption[crosspointStakeholderPanel.emotion.nr].name
                              : $t('General.Unknown')
                          " :ref="
  (el) => {
    stakeholderEmotionLabel[
      crosspointStakeholderPanel.id
    ] = el;
  }
" :id="
  'emotionOptionLabel' +
  crosspointStakeholderPanel.id
" @click="
  toggleStakeholderEmotion(
    $event,
    crosspointStakeholderPanel.id
  )
">
                          </p-button>
                          <p-overlay-panel class="p-3" :ref="
                            (el) => {
                              stakeholderEmotion[
                                crosspointStakeholderPanel.id
                              ] = el;
                            }
                          ">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li v-for="option in emotionOption" :key="
                                'emotionOption' +
                                crosspointStakeholderPanel.id +
                                option.value
                              " class="d-flex align-items-center">
                                <p-radio-button :name="
                                  'emotionOption' +
                                  crosspointStakeholderPanel.id
                                " :id="
  'emotionOption' +
  crosspointStakeholderPanel.id +
  option.value
" :value="option.value" @click="
  crosspointStakeholderPanel.emotion = {
    nr: option.value,
    name: option.name,
  };
updateStakeholderEmotion(
  option.value,
  option.name,
  crosspointStakeholderPanel.id,
  crosspointStakeholderPanel.emotion
);
">
                                </p-radio-button>
                                <label :for="
                                  'emotionOption' +
                                  crosspointStakeholderPanel.id +
                                  option.value
                                " class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                        </div>
                      </div>
                      <!-- urgencyOptions -->
                      <div
                        :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                        class="
                          matrix__td
                          d-flex
                          justify-content-center
                          align-items-center
                          --wide
                        " role="cell">
                        <div>
                          <p-button class="matrix__strategy-button p-button-text" :label="
                            crosspointStakeholderPanel.urgency
                              ? urgencyOptions[crosspointStakeholderPanel.urgency.nr].name
                              : $t('General.Unknown')
                          " :ref="
  (el) => {
    stakeholderUrgencyLabel[
      crosspointStakeholderPanel.id
    ] = el;
  }
" :id="
  'urgencyOptionLabel' +
  crosspointStakeholderPanel.id
" @click="
  toggleStakeholderUrgency(
    $event,
    crosspointStakeholderPanel.id
  )
">
                          </p-button>
                          <p-overlay-panel class="p-3" :ref="
                            (el) => {
                              stakeholderUrgency[
                                crosspointStakeholderPanel.id
                              ] = el;
                            }
                          ">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li v-for="option in urgencyOptions" :key="
                                'urgencyOptions' +
                                crosspointStakeholderPanel.id +
                                option.value
                              " class="d-flex align-items-center">
                                <p-radio-button :name="
                                  'urgencyOptions' +
                                  crosspointStakeholderPanel.id
                                " :id="
  'urgencyOptions' +
  crosspointStakeholderPanel.id +
  option.value
" :value="option.value" @click="
  crosspointStakeholderPanel.urgency = {
    nr: option.value,
    name: option.name,
  };
updateStakeholderUrgency(
  option.value,
  option.name,
  crosspointStakeholderPanel.id,
  crosspointStakeholderPanel.urgency
);
">
                                </p-radio-button>
                                <label :for="
                                  'urgencyOptions' +
                                  crosspointStakeholderPanel.id +
                                  option.value
                                " class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- RENDER BEOORDELING ISSUE ROW -->
                  <div
                    class="d-flex"
                    role="row">
                    <template
                      v-for="(issueCategory, issueCategoryIndex) in props.issues.panels"
                      :key="'issueImportanceCategory' + issueCategory.id">
                      <div
                        class="matrix__td --category --last-in-cat"
                        role="columnheader">
                        <span>
                        </span>
                      </div>
                      <div
                        v-for="issue in issueCategory.panel"
                        class="matrix__td d-flex justify-content-center align-items-center --category --last-in-cat"
                        :key="'issueImportance' + issue.id"
                        role="columnheader">
                        <p-button
                          class="matrix__button p-0"
                          :class="importanceClassName(issue.statusNr)"
                          :label="importanceText(issue.statusNr)"
                          :id="'issue-importance-' + issue.id"
                          aria-haspopup="true"
                          :aria-controls="'issue-importance-' + issue.id"
                          v-tooltip.top="importanceText(issue.statusNr)"
                          @click="toggleCrossPoint($event, 'issue-importance-' + issue.id)">
                        </p-button>
                        <p-overlay-panel class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }" :ref="
                            (el) => {
                              crossPoints[
                                'issue-importance-' + issue.id
                              ] = el;
                            }
                          "
                          :id="'issue-importance-' + issue.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="
                              matrix__overlay-panel-buttons
                              d-flex
                              align-items-center
                              w-100
                            ">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="
                                updateIssueStatusValue(
                                  0,
                                  issueCategoryIndex,
                                  issue.id
                                )
                              ">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{'--active': importanceClassName(issue.statusNr) == '--positive', }"
                              :label="configMatrix.tooltip.positive"
                              v-tooltip.top="configMatrix.tooltip.positive"
                              @click="
                              updateIssueStatusValue(
                                4,
                                issueCategoryIndex,
                                issue.id
                              )
                              ">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--focus', }"
                              :label="configMatrix.tooltip.focus"
                              v-tooltip.top="configMatrix.tooltip.focus"
                              @click="
                              updateIssueStatusValue(
                                3,
                                issueCategoryIndex,
                                issue.id
                              )
                              ">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--warning', }"
                              :label="configMatrix.tooltip.warning"
                              v-tooltip.top="configMatrix.tooltip.warning"
                              @click="
                              updateIssueStatusValue(
                                2,
                                issueCategoryIndex,
                                issue.id
                              )
                              ">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--criticism', }"
                              :label="configMatrix.tooltip.criticism"
                              v-tooltip.top="configMatrix.tooltip.criticism"
                              @click="
                              updateIssueStatusValue(
                                1,
                                issueCategoryIndex,
                                issue.id
                              )
                              ">
                            </p-button>
                          </div>
                        </p-overlay-panel>
                      </div>
                    </template>
                    <div
                      class="matrix__td --strategy --category --last-in-cat"
                      role="cell">
                    </div>
                    <div
                      class="matrix__td --strategy --category --last-in-cat --wide"
                      role="cell">
                    </div>
                    <div
                      class="matrix__td --strategy --category --last-in-cat"
                      role="cell">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="matrix__grid" title="Legend">
          <div
            class="matrix__stakeholder-cat pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
            {{ $t('General.LegendTension') }}
          </div>
          <div
            class="matrix__td d-flex justify-content-center align-items-center --legend-item --category" role="cell">
          </div>
          <template v-for="item in [1, 2, 3, 4, 0]" :key="'legend-importance-' + item">
            <div
              class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
              {{importanceText(item)}}
            </div>
            <div
              class="matrix__td d-flex justify-content-center align-items-center --legend-item" role="cell" :title="importanceText(item)">
              <p-button class="matrix__button p-0" :class="importanceClassName(item)" :label="importanceText(item)"></p-button>
            </div>
          </template>
        </div>
        <Transition name="fade">
          <ui-scroll-buttons class="importance-matrix__buttons position-fixed" :right-button="disableRightButton"
            :left-button="disableLeftButton" v-if="showScrollButtons" @submit-right="scrollRight()"
            @submit-left="scrollLeft()">
          </ui-scroll-buttons>
        </Transition>
      </template>
    </p-card>
    <p-progress-bar
      class="importance-matrix__progressbar"
      :value="50"
      v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.importance-matrix {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;
  }

  .matrix__th:not(:nth-last-child(2)) {
    position: relative;
    bottom: -4px;
    left: 60px;
    transform: rotate(30deg);
  }
  .matrix__th.--strategy > span {
    transform: rotate(-30deg);
  }
  .matrix__td.--category.--last-in-cat,
  .matrix__stakeholder-cat.--last-in-cat {
    border-bottom: 0.0625rem solid #E2E5E9;
  }
}
</style>
