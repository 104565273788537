<script setup>
import { storeToRefs } from 'pinia';

// Component imports
import PCard from 'primevue/card';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import UiReviewCard from '@/core/ui/UiReviewCard';

// Store imports
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  buttonsDropdownItems: Array,
  showError: Boolean
});

// Variables
const { editStakeholderIssue, configConcerns, configImportances } = storeToRefs(useStakeholderIssueStore());
// const { } = useStakeholderIssueStore();

const configOpinion = {
  labelName: 'IssueStakeholder.DescPosition',
  labelFor: 'opinion'
};
const configBatna = {
  labelName: 'IssueStakeholder.BATNA',
  labelFor: 'batna'
};
const configNarrative = {
  labelName: 'IssueStakeholder.Narrative',
  labelFor: 'narrative'
};
const configNarrativeMotivation = {
  labelName: 'IssueStakeholder.NarrativeMotivation',
  labelFor: 'narrativeMotivation'
};
// JWB: zendesk - ticket 3672
// JWB: JIRA - https://xuntos.atlassian.net/jira/software/projects/SOMSET/boards/12?selectedIssue=SOMSET-800
// const configViewpoint = {
//   labelName: 'IssueStakeholder.Viewpoint',
//   labelFor: 'viewPoint'
// };
const configConclusion = {
  labelName: 'IssueStakeholder.Conclusion',
  labelFor: 'conclusion'
};
const configStrategy = {
  labelName: 'IssueStakeholder.Strategy',
  labelFor: 'strategy'
};
const configConcern = {
  labelName: 'IssueStakeholder.DescIntererst',
  labelFor: 'concern'
};
</script>

<template>
  <div class="issue-edit">
    <p-card
      class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form>
          <ui-review-card
            class="stakeholder-detail__card mb-4"
            :card-title="$t(configConcerns.cardTitle)"
            :checkbox-options="configConcerns.checkboxes"
            v-model="editStakeholderIssue.concernNr">
          </ui-review-card>
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configConcern.labelName)"
            :label-for="configConcern.labelFor"
            :value-editor="editStakeholderIssue.concernText"
            v-model="editStakeholderIssue.concernText">
          </ui-label-editor>
          <ui-review-card
            class="stakeholder-detail__card mb-4"
            :card-title="$t(configImportances.cardTitle)"
            :checkbox-options="configImportances.checkboxes"
            v-model="editStakeholderIssue.importanceNr">
          </ui-review-card>
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configOpinion.labelName)"
            :label-for="configOpinion.labelFor"
            :value-editor="editStakeholderIssue.opinion"
            v-model="editStakeholderIssue.opinion">
          </ui-label-editor>
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configBatna.labelName)"
            :label-for="configBatna.labelFor"
            :value-editor="editStakeholderIssue.batna"
            v-model="editStakeholderIssue.batna">
          </ui-label-editor>
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configNarrative.labelName)"
            :label-for="configNarrative.labelFor"
            :value-editor="editStakeholderIssue.narrative"
            v-model="editStakeholderIssue.narrative">
          </ui-label-editor>
          <!-- SOMSET-721: Must not show up in new version -->
          <ui-label-editor
            v-if="1===2"
            class="issue-edit__textarea mb-6"
            :label-title="$t(configNarrativeMotivation.labelName)"
            :label-for="configNarrativeMotivation.labelFor"
            :value-editor="editStakeholderIssue.narrativeMotivation"
            v-model="editStakeholderIssue.narrativeMotivation">
          </ui-label-editor>
          <!--
            JWB: zendesk - ticket 3672
            JWB: JIRA - https://xuntos.atlassian.net/jira/software/projects/SOMSET/boards/12?selectedIssue=SOMSET-800
            <ui-label-editor
              class="issue-edit__textarea mb-6"
              :label-title="$t(configViewpoint.labelName)"
              :label-for="configViewpoint.labelFor"
              :value-editor="editStakeholderIssue.viewpoint"
              v-model="editStakeholderIssue.viewpoint">
            </ui-label-editor>
          -->
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configConclusion.labelName)"
            :label-for="configConclusion.labelFor"
            :value-editor="editStakeholderIssue.conclusion"
            v-model="editStakeholderIssue.conclusion">
          </ui-label-editor>
          <ui-label-editor
            class="issue-edit__textarea mb-6"
            :label-title="$t(configStrategy.labelName)"
            :label-for="configStrategy.labelFor"
            :value-editor="editStakeholderIssue.strategy"
            v-model="editStakeholderIssue.strategy">
          </ui-label-editor>
        </form>
      </template>
    </p-card>
    <div class="mt-4 d-flex justify-content-end">
      <ui-save-buttons-dropdown
        :items="props.buttonsDropdownItems"
        :save-button="props.saveButton"
        :cancel-button="props.cancelButton"
        @emit-save="submitSave()"
        @emit-cancel="submitCancel()">
      </ui-save-buttons-dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.issue-edit {
  &__showmore {
    .pi {
      transition: transform 200ms ease-out;
    }

    &.is-rotated {
      .pi {
        transform: rotate(180deg);
      }
    }
  }

  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}
</style>
