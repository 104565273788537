<script setup>
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import sanitizeHtml from 'sanitize-html';

// Component imports
import PCard from 'primevue/card';
import PPaginator from 'primevue/paginator';
import PCheckbox from 'primevue/checkbox';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useSearch } from '@/store/modules/search';
import { useUserStore } from '@/store/modules/user';

// Define props
const props = defineProps({
  searchFor: String
});

// Define emits
// const emit = defineEmits([]);

// Variables
const { loadingResults, searchResults, projectsInSearchResults, typesInSearchResults } = storeToRefs(useSearch());
const { currentUser } = storeToRefs(useUserStore());
const { fetchSearchResults } = useSearch();
const route = useRoute();
const resultsOnPage = ref(null);
const resultsAll = ref(null);
const currentPage = ref(0);
const numberOfItemsPerPage = 10;
const selectedProjects = ref([]);
const selectedTypes = ref([]);

// Functions
const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'pre', 'strong',
      // 'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};

const onPage = (event) => {
  currentPage.value = event.page;
  resultsOnPage.value = paginate(resultsAll.value);
};

const updateResultsByProjects = async () => {
  if (selectedProjects.value.length === 0) {
    resultsAll.value = searchResults.value;
    return;
  }

  const filteredResults = [];
  for (let idx = 0; idx < searchResults.value.length; idx++) {
    if (selectedProjects.value.includes(searchResults.value[idx].projectSid)) {
      filteredResults.push(searchResults.value[idx]);
    }
  }

  resultsAll.value = filteredResults;
};

const updateResultsByType = async () => {
  if (selectedTypes.value.length === 0) {
    return;
  }

  const filteredResults = [];
  for (let idx = 0; idx < resultsAll.value.length; idx++) {
    if (selectedTypes.value.includes(resultsAll.value[idx].type)) {
      filteredResults.push(resultsAll.value[idx]);
    }
  }

  resultsAll.value = filteredResults;
};

const updateTypeCount = () => {
  for (let idx = 0; idx < typesInSearchResults.value.length; idx++) {
    typesInSearchResults.value[idx].total = resultsAll.value.filter((obj) => obj.type === typesInSearchResults.value[idx].type).length;
  }
};

const onClickCheckboxProjects = async () => {
  currentPage.value = 0;
  await updateResultsByProjects();
  await updateResultsByType();
  updateTypeCount();

  resultsOnPage.value = paginate(resultsAll.value);
};

const onClickCheckboxTypes = async () => {
  currentPage.value = 0;
  await updateResultsByProjects();
  await updateResultsByType();
  updateTypeCount();

  resultsOnPage.value = paginate(resultsAll.value);
};

const initResults = async () => {
  const project = route.query.project;
  if (project === undefined) {
    resultsAll.value = searchResults.value;
    resultsOnPage.value = paginate(resultsAll.value);
    return;
  }

  selectedProjects.value.push(project);

  const filteredResults = [];
  for (let idx = 0; idx < searchResults.value.length; idx++) {
    if (searchResults.value[idx].projectSid === project) {
      filteredResults.push(searchResults.value[idx]);
    }
  }

  resultsAll.value = filteredResults;

  await updateResultsByType();
  resultsOnPage.value = paginate(resultsAll.value);

  updateTypeCount();
};

const paginate = (list) => {
  const totalPages = Math.ceil(list.length / numberOfItemsPerPage);
  if (currentPage.value >= totalPages) {
    currentPage.value = totalPages - 1;
  }
  const index = currentPage.value * numberOfItemsPerPage;
  return list.slice(index, index + numberOfItemsPerPage);
};

onMounted(async () => {
  if (currentUser !== null) {
    await fetchSearchResults(route.params.projectId, route.query.searchfor, currentUser.language);
  } else {
    await fetchSearchResults(route.params.projectId, route.query.searchfor);
  }
  await initResults();
});

watch(() => props.searchFor, (val) => {
  console.log('search for:' + val);
});
</script>

<template>
  <main>
    <div class="search-results" v-if="!loadingResults">
      <div class="search-results__col" >
        <p-card>
          <template #content>
            <div class="pt-2 pb-2 pe-1 ps-1" v-if="resultsAll && resultsAll.length > 0">
              <h2 class="search-results__title mt-0 mb-5">
                <span v-if="resultsAll.length > 1">
                  {{ resultsAll.length }} {{ $t('General.Results') }}
                </span>
                <span v-else>
                  {{ resultsAll.length }} {{ $t('General.Result') }}
                </span>
              </h2>
              <ul class="search-results__list mt-0 mb-0 ps-0">
                <li
                  class="search-results__list-item mb-4"
                  v-for="item in resultsOnPage"
                  :key="item.id">
                  <h3 class="mt-0 mb-0">
                    <router-link
                      :to="item.url">
                      {{ item.name }}
                    </router-link>
                  </h3>
                  <h4 class="mt-0 mb-1 search-results__type">{{ $t(item.type) }}</h4>
                  <p class="mt-0 mb-0" v-html="sanitizer(item.description)"></p>
                  <div v-if="item.type.toLowerCase() !== 'project'" class="mt-1 mb-0 search-results__project"><strong>{{ $t('General.Project') }}: </strong>
                    <router-link
                      :to="item.projectUrl">
                      {{ item.projectName }}
                    </router-link>
                  </div>
                </li>
              </ul>
              <p-paginator
                v-if="resultsAll.length > numberOfItemsPerPage"
                @page="onPage($event)"
                :rows="numberOfItemsPerPage"
                :totalRecords="resultsAll.length" >
              </p-paginator>
            </div>
            <div class="pt-2 pb-2 pe-1 ps-1" v-else>
              {{ $t('General.NoSearchResults') }}
            </div>
          </template>
        </p-card>
      </div>
      <div class="search-results__col">
        <p-card v-if="typesInSearchResults.length > 0" class="mb-2">
          <template #content>
            <h3 class="search-results__facet">{{ $t('General.Type') }}</h3>
            <div v-for="type of typesInSearchResults" :key="type.type" class="mb-1">
              <p-checkbox
                :inputId="type.type"
                name="projectType"
                :value="type.name"
                v-model="selectedTypes"
                class="me-1"
                @input="onClickCheckboxTypes()">
              </p-checkbox>
              <label :for="type.type">{{ $t(type.name) }}</label>
            </div>
          </template>
        </p-card>
        <p-card v-if="projectsInSearchResults.length > 0">
          <template #content>
            <h3 class="search-results__facet">{{ $t('General.Projects') }}</h3>
            <div v-for="project of projectsInSearchResults" :key="project.projectSid" class="mb-1">
              <p-checkbox
                :inputId="project.projectSid"
                name="projectSid"
                :value="project.projectSid"
                v-model="selectedProjects"
                class="me-1"
                @input="onClickCheckboxProjects()">
              </p-checkbox>
              <label :for="project.projectSid">{{project.name}}</label>
            </div>
          </template>
        </p-card>
      </div>
    </div>
    <div class="search-results" v-else>
      <p-progress-spinner class="mt-10 me-auto ms-auto">
      </p-progress-spinner>
    </div>
  </main>
</template>

<style lang="scss">
.search-results {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: calc-rem(10);

  &__title {
    font-size: calc-rem(22);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02em;
  }

  &__type {
    margin-bottom: 0.375rem;
    font-size: 0.75rem;
    letter-spacing: 0.01em;
    color: #98A4B3;
  }

  &__facet {
    font-size: calc-rem(22);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02em;
  }

  &__project {
    font-size: 0.75rem;
    letter-spacing: 0.01em;
    color: #98A4B3;
  }

  &__list {
    list-style: none;

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      h3 {
        font-size: calc-rem(18);
        font-weight: 600;
        line-height: calc-rem(20);
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
