<script setup>
import { ref } from 'vue';
import useMoment from 'moment';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';
import PProgressSpinner from 'primevue/progressspinner';
// import PCalendar from 'primevue/calendar';
import PCalendar from '../../core/ui/calendar/CalendarCustom';
import PDropdown from 'primevue/dropdown';
import PButton from 'primevue/button';
import EnvironmentMonitor from '@/modules/environmentmonitor/EnvironmentMonitorOld';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

onMounted(async () => {
  await fetchSingleProject(projectId);
  await fetchSingleProjectStakeholders(projectId);
  await fetchSingleProjectExportViewPointTable(projectId);
  getCategories();
  stakeholders();
});

// Variables
const { loadingProject, project, getProjectStakeholdersGroupedByCategory, projectExportViewPointTable, loadingProjectExportViewPointTable } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectStakeholders, fetchSingleProjectExportViewPointTable } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configViewHeader = {
  viewTitle: 'Product.ViewpointTable',
  downloadButton: t('General.Download'),
  syncButton: t('General.Sync'),
  showCalendarIcon: true,
  categories: {
    optionLabel: 'name'
  }
};
const selectedCategory = ref({
  name: t('Product.Matrix.SelectStakeholderCategoryShowAll'),
  value: 'all'
});
const selectedCategoryStakeholders = ref(null);
const syncData = async () => {
  let calendarVal = calendarValue.value === null ? null : useMoment(calendarValue.value).format('YYYY-MM-DD');
  if (calendarVal !== null) {
    if (useMoment(calendarValue.value).format('YYYY-MM-DD') === useMoment(new Date()).format('YYYY-MM-DD')) {
      calendarVal = null;
      calendarValue.value = null;
      downloadOptions.value[0].endpoint = 'environmentmonitor/export/pdf';
      downloadOptions.value[1].endpoint = 'environmentmonitor/export/excel';
    } else {
      downloadOptions.value[0].endpoint = 'environmentmonitor/export/pdf?date=' + calendarVal;
      downloadOptions.value[1].endpoint = 'environmentmonitor/export/excel?date=' + calendarVal;
    }
  }

  await fetchSingleProjectStakeholders(projectId, calendarVal);
  await fetchSingleProjectExportViewPointTable(projectId, calendarVal);
  stakeholders();
};
const calendarValue = ref(null);

const allCategories = ref([]);
const getCategories = () => {
  allCategories.value = [];
  allCategories.value.push({
    name: t('Product.Matrix.SelectStakeholderCategoryShowAll'),
    value: 'all'
  });
  getProjectStakeholdersGroupedByCategory.value.panels.forEach(panel => {
    allCategories.value.push({
      name: panel.panelTitle,
      value: panel.id
    });
  });
};
const stakeholders = () => {
  if (selectedCategory.value.value === 'all') {
    selectedCategoryStakeholders.value = getProjectStakeholdersGroupedByCategory.value;
    return;
  }
  selectedCategoryStakeholders.value = getProjectStakeholdersGroupedByCategory.value;
  selectedCategoryStakeholders.value.panels = getProjectStakeholdersGroupedByCategory.value.panels.filter(x => x.id === selectedCategory.value.value);
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const downloadOptions = ref([
  {
    label: t('General.Frontend.DownloadPDF'),
    endpoint: 'environmentmonitor/export/pdf',
    type: 'pdf',
    filename: project.name + '_Environmentmonitor.pdf'
  },
  {
    label: t('General.Frontend.DownloadExcel'),
    endpoint: 'environmentmonitor/export/excel',
    type: 'xls',
    filename: project.name + '_Environmentmonitor.xls'
  }
]);
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="$t(configViewHeader.viewTitle)">
        <template v-slot:actions>
          <p-button
            class="project-progress-matrix-view__sync-button p-button-secondary me-3"
            :label="configViewHeader.syncButton"
            :icon="loadingProjectExportViewPointTable ? 'pi pi-spin pi-spinner' : 'pi pi-sync'"
            @click="syncData()">
          </p-button>
          <p-calendar
            class="me-4"
            v-model="calendarValue"
            dateFormat="dd-mm-yy"
            :show-icon="configViewHeader.showCalendarIcon"
            icon="pi pi-calendar"
            :minDate="project.AddDate"
            :maxDate="new Date()"
            :showButtonBar="true"
            @dateSelect="syncData()">
          </p-calendar>
          <p-dropdown
            class="project-progress-matrix-view__dropdown me-2"
            :option-label="configViewHeader.categories.optionLabel"
            :options="allCategories"
            v-model="selectedCategory"
            scrollHeight="400px"
            @change="syncData()">
          </p-dropdown>
          <ui-download-buttons-dropdown :project-name="project.name" :downloadOptions="downloadOptions"></ui-download-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <div class="project-environment-monitor-view__wrap position-relative">
        <p-progress-spinner
          class="project-environment-monitor-view__spinner position-absolute"
          v-if="loadingProjectExportViewPointTable">
        </p-progress-spinner>
        <environment-monitor
          :key="$route.fullPath"
          v-if="getProjectStakeholdersGroupedByCategory && projectExportViewPointTable.jsonModel"
          :projectId="projectId"
          :stakeholders="selectedCategoryStakeholders"
          :crosspoints="projectExportViewPointTable.jsonModel.MatrixCrossPoints">
        </environment-monitor>
      </div>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-environment-monitor-view {
  &__download {
    .pi {
      margin-right: calc-rem(7);
    }
  }

  &__wrap {
    min-height: calc-rem(144);
  }

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
