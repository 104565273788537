<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';
import { useI18n } from 'vue-i18n';

// Component imports
import UiContentCard from '@/core/ui/UiContentCard';
import UiReviewCard from '@/core/ui/UiReviewCard';
import UiStakeholdersCard from '@/core/ui/UiStakeholdersCard';
import UiMapCard from '@/core/ui/UiMapCard';
import UiDocumentHistoryCard from '@/core/ui/UiDocumentHistoryCard';
import UiFilesCard from '@/core/ui/UiFilesCard';
import UiNotesCard from '@/core/ui/UiNotesCard';

// Store imports
import { useIssuesStore } from '@/store/modules/issues';
import { useProjectsStore } from '@/store/modules/projects';
import { useMediaStore } from '@/store/modules/media';
import { useNotesStore } from '@/store/modules/notes';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  selectedDate: String
});

// Define emits
// const emit = defineEmits(['update-title']);

// Variables
const { loadingIssueStakeholders, author, moderator, configReview, stakeholderOverview, contentCard, issue, issueVersion } = storeToRefs(useIssuesStore());
const { fetchSingleIssue, updateIssueStatus, updateIssueGeoJson } = useIssuesStore();
const { project } = storeToRefs(useProjectsStore());
const { fetchSingleProjectIssues } = useProjectsStore();
const { allMediaIssue, loadingAllMediaIssue } = storeToRefs(useMediaStore());
const { allNotesIssue, loadingAllNotesIssue } = storeToRefs(useNotesStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());

const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const projectId = route.params.projectId;
const issuesId = route.params.issueId;

const UpdateStatus = async (value) => {
  await updateIssueStatus(projectId, issuesId, value.id);
  await fetchSingleProjectIssues(projectId);
};
const goToSaveGeo = async (value) => {
  await updateIssueGeoJson(projectId, issuesId, JSON.stringify(value));
};
const configMap = {
  cardTitle: t('General.Location'),
  textLabelEdit: t('General.Frontend.EditLocation'),
  textLabelSave: t('General.Frontend.SaveLocation')
};
const configHistory = {
  cardTitle: t('General.DocumentHistory'),
  moderator: '',
  moderatorUrl: '/',
  authorUrl: '/',
  dateTime: '',
  subtitleModerator: t('General.LastUpdated'),
  moderatorInfo: t('General.By')
};
const configFiles = {
  cardTitle: t('General.ProjectMedias')
};
const configNotes = {
  cardTitle: t('General.ProjectNotes')
};

const selectedDate = ref(null);

watch(() => props.selectedDate, async (first, second) => {
  selectedDate.value = first;
  if (selectedDate.value === useMoment().format('YYYY-MM-DD')) {
    selectedDate.value = null;
  }
  await fetchSingleIssue(project.value, issuesId, selectedDate.value);
});
</script>

<template>
  <div class="issue-detail">
    <div>
      <ui-content-card
        class="issue-detail__card"
        :content="contentCard">
      </ui-content-card>
    </div>
    <div class="issue-detail__sub-grid">
      <div>
        <ui-review-card
          class="issue-detail__card"
          :card-title="configReview.cardTitle"
          :checkbox-options="configReview.checkboxes"
          :read-only="!IsActionAllowedInRole('issue.checkbox.status', currentUser, route.params.projectId)"
          @update-value="UpdateStatus"
          @submit-value="''"
          :versionDetails="(props.selectedDate === null ? null : {date: props.selectedDate, value: issueVersion.statusNr, type: 'status' })">
        </ui-review-card>
        <ui-stakeholders-card
          v-if="!loadingIssueStakeholders && stakeholderOverview.tabs.length > 0"
          class="issue-detail__card"
          :card-title="stakeholderOverview.cardTitle"
          card-type="stakeholders"
          :tabs="stakeholderOverview.tabs">
        </ui-stakeholders-card>
      </div>
      <div>
        <ui-map-card
          v-if="project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng && project.gisDefaultZoomLevel"
          class="issue-detail__card"
          :card-title="configMap.cardTitle"
          :text-label-edit="configMap.textLabelEdit"
          :text-label-save="configMap.textLabelSave"
          :gis-default-coordinates-lat="project.gisDefaultCoordinatesLat"
          :gis-default-coordinates-lng="project.gisDefaultCoordinatesLng"
          :gis-default-zoom-level="project.gisDefaultZoomLevel"
          :screenshot-name="issue.name"
          :read-only="!IsActionAllowedInRole('issue.map.edit', currentUser, route.params.projectId)"
          :geo-json="issue.gis"
          @emit-save="goToSaveGeo">
        </ui-map-card>
        <ui-document-history-card
          v-if="moderator != null && author != null"
          class="issue-detail__card"
          :card-title="configHistory.cardTitle"
          :moderator="moderator.firstname || moderator.lastname
              ? (moderator.firstname ?? '') +
              ' ' +
              (moderator.lastname ?? '')
              : moderator.userName"
          :moderator-url="'/profile/' + moderator.id"
          :date-time="useMoment(moderator.modDate).format('DD-MM-YYYY HH:mm')"
          :moderator-info="configHistory.moderatorInfo"
          :author="author.firstname || author.lastname
              ? (author.firstname ?? '') +
              ' ' +
              (author.lastname ?? '')
              : author.userName"
          :author-url="'/profile/' + author.id"
          :subtitle-author="$t('General.Author')"
          :subtitle-moderator="configHistory.subtitleModerator">
        </ui-document-history-card>
        <ui-files-card
          v-if="!loadingAllMediaIssue && allMediaIssue.length > 0"
          class="issue-detail__card"
          :card-title="configFiles.cardTitle"
          :files="allMediaIssue">
        </ui-files-card>
        <ui-notes-card
          v-if="!loadingAllNotesIssue && allNotesIssue.length > 0"
          class="issue-detail__card"
          :card-title="configNotes.cardTitle"
          :notes="allNotesIssue">
        </ui-notes-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.issue-detail {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    column-gap: calc-rem(9);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 3fr 2fr;
  }

  @media (min-width: 1530px) {
    grid-template-columns: 4fr 2fr;
  }

  @media (min-width: 1665px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr;
  }

  &__sub-grid {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;

    @media (min-width: 1665px) {
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: calc-rem(9);
    }
  }

  &__card {
    margin-bottom: calc-rem(9);
  }

  .p-card .p-card-content h2 {
    margin-bottom: calc-rem(10);
  }
}
</style>
