<script setup>
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiFileUpload from '@/core/ui/UiFileUpload';
import UiInputList from '@/core/ui/UiInputList';
import UiCheckboxList from '@/core/ui/UiCheckboxList';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiFieldset from '@/core/ui/UiFieldset';
import UiMapCardProjectEdit from '@/core/ui/UiMapCardProjectEdit';

// Store imports
import { storeToRefs } from 'pinia';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Constants import
import { ProjectConfigConstants } from '@/config/projectsConfigConstants';

// Define props
const props = defineProps({
  showError: Boolean
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configMap = {
  cardTitle: t('General.Location')
};

// Variables
const { projectToEdit, projectPicture, orgProjectPicture, loadingProject } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);
const configName = ProjectConfigConstants.configName;
const configFileUpload = ProjectConfigConstants.configFileUpload;
const configDescription = ProjectConfigConstants.configDescription;
const configStakeholder = ProjectConfigConstants.configStakeholder;
const configGisDefaultCoordinatesLat = ProjectConfigConstants.configGisDefaultCoordinatesLat;
const configGisDefaultCoordinatesLng = ProjectConfigConstants.configGisDefaultCoordinatesLng;
const configGisDefaultZoomLevel = ProjectConfigConstants.configGisDefaultZoomLevel;
const configFieldsetGis = ProjectConfigConstants.configFieldsetGis;
const configFieldsetVisibleFields = ProjectConfigConstants.configFieldsetVisibleFields;
const configFieldsetCustomFields = ProjectConfigConstants.configFieldsetCustomFields;
const configStakeholderFieldNameWithTitle = ProjectConfigConstants.configStakeholderFieldNameWithTitle;
const configIssueFieldNameWithTitle = ProjectConfigConstants.issueFieldNameWithTitle;
const configIssue = ProjectConfigConstants.configIssue;
const configButtons = ProjectConfigConstants.configButtons;
const checkboxesStakeholders = reactive(ProjectConfigConstants.checkboxesStakeholders);
const checkboxesIssues = reactive(ProjectConfigConstants.checkboxesIssues);
const gisLat = ref(projectToEdit.value.gisDefaultCoordinatesLat);
const gisLng = ref(projectToEdit.value.gisDefaultCoordinatesLng);
const gisZoom = ref(projectToEdit.value.gisDefaultZoomLevel);

// Functions
function assignInitialValuesToCheckboxes () {
  // If a user has previoulsy checked some checkboxes, we want those checkboxes to be checked when a user wants to edit a project.
  // Stakeholders
  if (projectToEdit.value.availableStakeholderFields !== undefined && projectToEdit.value.availableStakeholderFields !== null) {
    // Get field from DB, parse from JSON
    const checkedStakeholderFieldsJSON = JSON.parse(projectToEdit.value.availableStakeholderFields);

    // Get FieldNames of the checkboxes that have been checked
    const checkboxesToCheckStakeholderFieldNames = checkedStakeholderFieldsJSON.filter(c => c.Available === true).map(c => c.FieldName);

    // Get titles matching with FieldNames
    const checkboxesToCheckStakeholderTitles = configStakeholderFieldNameWithTitle.filter(c => checkboxesToCheckStakeholderFieldNames.includes(c.FieldName)).map(c => c.title);

    // Set checkbox to checked, if user has checked it
    const checkboxesStakeholdersChecked = checkboxesStakeholders.checkboxes.map(f => checkboxesToCheckStakeholderTitles.indexOf(f.title) >= 0 ? { ...f, checked: true } : { ...f, checked: false });

    checkboxesStakeholders.checkboxes = checkboxesStakeholdersChecked;
  }

  // Issues:
  if (projectToEdit.value.availableIssueFields !== undefined && projectToEdit.value.availableIssueFields !== null) {
    const checkedIssueFieldsJSON = JSON.parse(projectToEdit.value.availableIssueFields);

    const checkboxesToCheckIssueFieldNames = checkedIssueFieldsJSON.filter(c => c.Available === true).map(c => c.FieldName);

    const checkboxesToCheckIssueTitles = configIssueFieldNameWithTitle.filter(c => checkboxesToCheckIssueFieldNames.includes(c.FieldName)).map(c => c.title);

    const checkboxesIssuesChecked = checkboxesIssues.checkboxes.map(f => checkboxesToCheckIssueTitles.indexOf(f.title) >= 0 ? { ...f, checked: true } : { ...f, checked: false });
    checkboxesIssues.checkboxes = checkboxesIssuesChecked;
  }
}

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

const fieldChanged = () => {
  isDirtyPage.value = true;
};

const resetPicture = () => {
  projectPicture.value = orgProjectPicture.value;
};

const resetMap = () => {
  projectToEdit.value.gisDefaultCoordinatesLat = null;
  gisLat.value = null;
  projectToEdit.value.gisDefaultCoordinatesLng = null;
  gisLng.value = null;
  projectToEdit.value.gisDefaultZoomLevel = null;
  gisZoom.value = null;
  fieldChanged();
};

const updateGisLat = async (value) => {
  projectToEdit.value.gisDefaultCoordinatesLat = value;
  gisLat.value = value;
};

const updateGisLng = async (value) => {
  projectToEdit.value.gisDefaultCoordinatesLng = value;
  gisLng.value = value;
};

const updateGisZoom = async (value) => {
  projectToEdit.value.gisDefaultZoomLevel = value;
  gisZoom.value = value;
};

const updateAvailableStakeholderFields = (value) => {
  const result = configStakeholderFieldNameWithTitle.filter(c => value.includes(c.title));
  const items = [];
  for (let i = 0; i < result.length; i++) {
    items.push({
      FieldName: result[i].FieldName,
      Available: true
    });
  }
  isDirtyPage.value = true;
  projectToEdit.value.availableStakeholderFields = JSON.stringify(items);
};

const updateAvailableIssueFields = (value) => {
  const result = configIssueFieldNameWithTitle.filter(c => value.includes(c.title));
  const items = [];
  for (let i = 0; i < result.length; i++) {
    items.push({
      FieldName: result[i].FieldName,
      Available: true
    });
  }
  isDirtyPage.value = true;
  projectToEdit.value.availableIssueFields = JSON.stringify(items);
};

assignInitialValuesToCheckboxes();
</script>

<template>
  <div class="project-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4" v-if="!loadingProject">
      <template #content>
        <form>
          <ui-label-input class="mb-5"
            :label-title="$t(configName.labelName)"
            :label-for="configName.labelFor"
            :showError="props.showError"
            @emit-dirty="fieldChanged"
            v-model="projectToEdit.name">
          </ui-label-input>
          <div class="
                projects-overview__img
                position-relative
                ms-3
                w-25
                overflow-hidden
                mb-5
              ">
            <img v-if=" projectPicture.picture" :src="`data:image/${projectPicture.pictureFileExtension};base64,` + projectPicture.picture" class="img-object-fit w-100 h-100" :alt="projectPicture.description" />
            <img v-if=" projectPicture.length === 1" :src="projectPicture[0].base64Full" class="img-object-fit w-100 h-100" :alt="projectPicture.description" />
          </div>
          <ui-file-upload class="mb-6"
            :label-title="$t(configFileUpload.labelTitle)"
            :label-for="configFileUpload.labelFor"
            :file-types="configFileUpload.fileTypes"
            :file-limit="configFileUpload.fileLimit"
            :file-size="configFileUpload.fileSize"
            :file-url="configFileUpload.fileUrl"
            :more-files="configFileUpload.moreFiles"
            :thumbnail-width="configFileUpload.thumbnailWidth"
            :choose-label="$t(configFileUpload.chooseLabel)"
            @emit-dirty="fieldChanged"
            @emit-remove="resetPicture"
            v-model="projectPicture">
          </ui-file-upload>
          <ui-label-editor
            class="mb-5"
            :label-title="$t(configDescription.labelName)"
            :label-for="configDescription.labelFor"
            :value-editor="projectToEdit.description"
            @emit-dirty="fieldChanged"
            v-model="projectToEdit.description">
          </ui-label-editor>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetCustomFields.labelName)">
            <div class="row">
              <div class="col-6">
                <ui-input-list class="mb-5"
                               :label-title="$t(configStakeholder.labelName)"
                               :label-for="configStakeholder.labelFor"
                               :default-message="$t(configStakeholder.defaultMessage)"
                               :error-message="$t(configStakeholder.errorMessage)"
                               @emit-dirty="fieldChanged"
                               v-model="projectToEdit.customStakeholderFields">
                </ui-input-list>
              </div>
              <div class="col-6">
                <ui-input-list class="mb-5"
                               :label-title="$t(configIssue.labelName)"
                               :label-for="configIssue.labelFor"
                               :default-message="$t(configIssue.defaultMessage)"
                               :error-message="$t(configIssue.errorMessage)"
                               @emit-dirty="fieldChanged"
                               v-model="projectToEdit.customIssueFields">
                </ui-input-list>
              </div>
            </div>
          </ui-fieldset>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetGis.labelName)">
            <p>{{ $t('Project.ClickMap') }}</p>
            <ui-map-card-project-edit
              class="mb-2 w-50"
              :card-title="$t(configMap.cardTitle)"
              :gis-default-coordinates-lat="projectToEdit.gisDefaultCoordinatesLat"
              :gis-default-coordinates-lng="projectToEdit.gisDefaultCoordinatesLng"
              :gis-default-zoom-level="projectToEdit.gisDefaultZoomLevel"
              @emit-lat="updateGisLat"
              @emit-lng="updateGisLng"
              @emit-zoom="updateGisZoom"
              @emit-dirty="fieldChanged">
            </ui-map-card-project-edit>
            <p-button class="mb-5" v-if="projectToEdit.gisDefaultZoomLevel" @click="resetMap">
              {{  $t('Project.DeleteMap') }}
            </p-button>
            <ui-label-input class="mb-5 p-hidden"
              :label-title="$t(configGisDefaultCoordinatesLat.labelName)"
              :label-for="configGisDefaultCoordinatesLat.labelFor"
              @emit-dirty="fieldChanged"
              v-model="gisLat">
            </ui-label-input>
            <ui-label-input class="mb-5 p-hidden"
              :label-title="$t(configGisDefaultCoordinatesLng.labelName)"
              :label-for="configGisDefaultCoordinatesLng.labelFor"
              @emit-dirty="fieldChanged"
              v-model="gisLng">
            </ui-label-input>
            <ui-label-input class="mb-5 p-hidden"
              :label-title="$t(configGisDefaultZoomLevel.labelName)"
              :label-for="configGisDefaultZoomLevel.labelFor"
              @emit-dirty="fieldChanged"
              v-model="gisZoom">
            </ui-label-input>
          </ui-fieldset>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetVisibleFields.labelName)">
            <div class="row">
              <div class="col-6">
                <ui-checkbox-list :checkbox-list="checkboxesStakeholders"
                                  @emit-checkboxList="updateAvailableStakeholderFields">
                </ui-checkbox-list>
              </div>
              <div class="col-6">
                <ui-checkbox-list :checkbox-list="checkboxesIssues" @emit-checkboxList="updateAvailableIssueFields">
                </ui-checkbox-list>
              </div>
            </div>
          </ui-fieldset>
        </form>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4">
      <ui-save-buttons @emit-save="submitSave()" @emit-cancel="submitCancel()" :saveButton="$t(configButtons.saveButton)"
        :cancelButton="$t(configButtons.cancelButton)">
      </ui-save-buttons>
    </div>
  </div>
</template>

<style lang="scss">
.project-edit {}
</style>
