<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia/dist/pinia.esm-browser';
import { useRoute } from 'vue-router';

// Components imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import StakeholderIssueEdit from '@/modules/stakeholderissueedit/StakeholderIssueEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useIssuesStore } from '@/store/modules/issues';
import router from '@/router';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useProjectsStore } from '@/store/modules/projects';

// Variables
// const viewTitle = 'Bewerken';
const viewTitle = '';
const { editStakeholderIssue, loadingEditStakeholderIssue, issueName, stakeholderName } = storeToRefs(useStakeholderIssueStore());
const { fetchEditStakeholderIssue, updateStakeholderIssue } = useStakeholderIssueStore();
const { fetchSingleProject } = useProjectsStore();
const { project } = storeToRefs(useProjectsStore());
const store = useIssuesStore();
const showError = ref(false);
const route = useRoute();
const projectId = route.params.projectId;
const stakeholderId = route.params.stakeholderId;
const issueId = route.params.issueId;
const buttons = {
  saveButton: 'Opslaan',
  cancelButton: 'Annuleren',
  buttonsDropdownItems: [
    {
      label: 'Opslaan',
      command: () => {
        save();
      }
    }
  ]
};

const cancel = () => {
  // Object in store needs to be empty so new projects can be added
  store.$patch((state) => {
    state.editStakeholder = {};
  });
  router.back();
};

const save = async () => {
  saveStakeholderIssue();
};

const saveStakeholderIssue = async () => {
  await updateStakeholderIssue(projectId, stakeholderId, issueId, editStakeholderIssue.value);

  router.push({ path: '/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId });
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  await fetchEditStakeholderIssue(projectId, stakeholderId, issueId);
});

</script>

<template>
  <base-layout class="project-stakeholder-issue-edit-view">
    <template v-slot:header>
      <view-header
        :title="viewTitle + ' ' + issueName + ' | ' + stakeholderName"
        v-if="!loadingEditStakeholderIssue">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <stakeholder-issue-edit
        v-if="!loadingEditStakeholderIssue"
        :showError="showError"
        :save-button="buttons.saveButton"
        :cancel-button="buttons.cancelButton"
        :buttons-dropdown-items="buttons.buttonsDropdownItems"
        @emit-save="save()"
        @emit-cancel="cancel()">
      </stakeholder-issue-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-stakeholder-issue-edit-view__spinner position-absolute"
    v-if="loadingEditStakeholderIssue">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-stakeholder-issue-edit-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
