<script setup>
// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PProgressSpinner from 'primevue/progressspinner';
import PButton from 'primevue/button';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

// Router import
import router from '@/router';

onMounted(async () => {
  await fetchSingleProject(projectId);
});

// Variables
const { loadingProject, project } = storeToRefs(useProjectsStore());
const { fetchSingleProject, archiveSingleProject, fetchProjects } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const archiveProject = async () => {
  loadingProject.value = true;
  await archiveSingleProject(projectId);
  await fetchProjects();
  await router.push({ path: '/' });
};
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="project ? project.name : ''"></view-header>
    </template>
    <template v-slot:main>
      <p>{{ $t('Project.Archive.Confirm') }}</p>
      <p-button
        :label="$t('Project.Archive')"
        icon="pi pi-inbox"
        iconPos="left"
        @click="archiveProject()">
      </p-button>
      <p><em>{{ $t('Project.Archive.PS' )}}</em></p>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-archive-view__spinner position-absolute"
    v-if="loadingProject">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-archive-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
