<script setup>
// import { ref } from 'vue';

// Component imports
import PButton from 'primevue/button';

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  hideSpinner: Boolean
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

// Variables
const submitSave = () => {
  // if (!hideSpinner.value) {
  //   spinner.value = true;
  // }
  emit('emitSave');
};
const submitCancel = () => {
  emit('emitCancel');
};
// const hideSpinner = ref(props.hideSpinner ?? false);
// const spinner = ref(false);
</script>

<template>
  <div class="ui-save-buttons d-flex">
    <p-button
      class="p-button-link me-2"
      :label="props.cancelButton"
      @click="submitCancel()">
    </p-button>
    <p-button
      :label="props.saveButton"
      @click="submitSave()">
    </p-button>
<!--    <p-button v-if="spinner"-->
<!--      icon="pi pi-spin pi-spinner"-->
<!--      :label="props.saveButton">-->
<!--    </p-button>-->
  </div>
</template>

<style lang="scss">
.ui-save-buttons {
}
</style>
