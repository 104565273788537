<script setup>
import { storeToRefs } from 'pinia';
import { useSideBarStore } from '@/store/modules/sidebar';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ProjectsOverview from '@/modules/projectsoverview/ProjectsOverview';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useUserStore } from '@/store/modules/user';

// Variables
const { currentUser } = storeToRefs(useUserStore());

// Functions
useMenuBarStore().$patch({
  showLogo: true
});

useSideBarStore().$patch({
  showSideBar: false
});
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header :title="$t('General.Projects')">
        <template
          v-slot:actions
          v-if="currentUser && currentUser.isAdministrator">
          <p-button
            :label="$t('Project.New')"
            icon="pi pi-plus"
            iconPos="left"
            @click="$router.push('/projects/create')">
          </p-button>
          <p-button
            :label="$t('General.Frontend.Index')"
            icon="pi pi-inbox"
            iconPos="left"
            class="ms-2"
            @click="$router.push('/projects/index')">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <projects-overview></projects-overview>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.projects-view {
}
</style>
