<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import useMoment from 'moment';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PCalendar from 'primevue/calendar';
import PButton from 'primevue/button';
import UiMultiFileUpload from '@/core/ui/UiMultiFileUpload';
import UiFormLabel from '@/core/ui/UiFormLabel';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiLabelMultiSelect from '@/core/ui/UiLabelMultiSelect';
import UiFieldset from '@/core/ui/UiFieldset';

// Store imports
import { useNotesStore } from '@/store/modules/notes';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  showError: Boolean,
  deleteButton: String
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel',
  'emitDelete'
]);

const { IsActionAllowedInRole } = useRolesStore();

// Variables
const { editNote, noteFile, loadingNote } = storeToRefs(useNotesStore());
const { project, getProjectStakeholderByIdName, getProjectIssuesByIdName, loadingProjectStakeholders } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const { currentUser } = storeToRefs(useUserStore());
const isDirtyPage = ref(isDirty);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configName = {
  labelName: t('ProjectNote.Name'),
  labelFor: 'name'
};
const configFileUpload = {
  labelTitle: t('General.ProjectMedia'),
  labelFor: 'file',
  fileTypes: '',
  fileLimit: 10,
  fileSize: 5242880,
  fileUrl: '/',
  moreFiles: false,
  thumbnailWidth: 60,
  auto: true,
  chooseLabel: t('ProjectMedia.Frontend.Select')
};
const configDescription = {
  labelName: t('ProjectNote.Frontend.Description'),
  labelFor: 'description'
};
const configBody = {
  labelName: t('ProjectNote.Body'),
  labelFor: 'body'
};
const configDateNote = {
  labelName: t('ProjectNote.StartDate'),
  labelFor: 'startdate'
};
const configStakeholders = {
  legendTitle: t('General.Stakeholders'),
  labelTitle: t('General.Stakeholders'),
  labelFor: 'stakeholders',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectStakeholders'),
  optionLabel: 'name',
  emptyMessage: t('General.NoStakeholdersAvailable'),
  options: []
};
const configIssues = {
  legendTitle: t('General.Issues'),
  labelTitle: t('General.Issues'),
  labelFor: 'Issues',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectIssues'),
  optionLabel: 'name',
  emptyMessage: t('General.NoIssuesAvailable'),
  options: []
};
const configButtons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel')
};
const submitSave = () => {
  noteFile.value = files;
  emit('emitSave');
};
const submitCancel = () => {
  emit('emitCancel');
};
const submitDelete = () => {
  emit('emitDelete');
};
const fieldChanged = () => {
  isDirtyPage.value = true;
};
const dateFieldChanged = () => {
  isDirtyPage.value = true;
  editNote.value.startDate = useMoment(dateRef.value).format('YYYY-MM-DD');
};

const form = ref();
const formWidth = ref();
const getWidth = () => {
  const elem = form.value;
  const elemWidth = elem.offsetWidth;
  formWidth.value = elemWidth;
};
const files = ref([]);
const dateRef = ref('');

// Functions
onMounted(() => {
  getWidth();

  if (editNote.value.notesBinary && editNote.value.notesBinary.length > 0) {
    for (let idx = 0; idx < editNote.value.notesBinary.length; idx++) {
      const media = {
        id: editNote.value.notesBinary[idx].id,
        lastModified: null,
        lastModifiedDate: null,
        name: editNote.value.notesBinary[idx].binaryFilename,
        type: editNote.value.notesBinary[idx].binaryContentType,
        base64Full: editNote.value.notesBinary[idx].binaryContentType + ';base64,' + editNote.value.notesBinary[idx].binaryContent,
        base64: editNote.value.notesBinary[idx].binaryContent,
        status: 'exists'
      };
      files.value.push(media);
    }
  }

  dateRef.value = useMoment(editNote.value.startDate).format('DD-MM-YYYY');
});

window.addEventListener('resize', () => {
  getWidth();
});
</script>

<template>
  <div class="note-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form ref="form">
          <div class="row">
            <div class="col-12">
              <ui-label-input class="mb-5"
                :label-title="configName.labelName"
                :label-for="configName.labelFor"
                :showError="props.showError"
                :error-message="$t('General.MandatoryField')"
                @emit-dirty="fieldChanged"
                v-model="editNote.name">
              </ui-label-input>
              <div class="ui-label-input mb-5">
                <ui-form-label class="mb-2" :label-title="configDateNote.labelName"
                  :label-for="configDateNote.labelFor">
                  @emit-dirty="fieldChanged"
                </ui-form-label>
                <p-calendar class="me-5" v-model="dateRef" dateFormat="dd-mm-yy" :showIcon="true"
                  icon="pi pi-calendar" @input="fieldChanged" @date-select="dateFieldChanged">
                </p-calendar>
              </div>
              <ui-fieldset
                class="mb-6 --label-multi-select"
                :title="configIssues.legendTitle">
                <ui-label-multi-select
                  :width-value="formWidth"
                  :label-title="configIssues.labelTitle"
                  :label-for="configIssues.labelFor"
                  :show-label="configIssues.showLabel"
                  :show-filter="configIssues.showFilter"
                  :placeholder="configIssues.placeholder"
                  :option-label="configIssues.optionLabel"
                  :empty-message="configIssues.emptyMessage"
                  :select-options="getProjectIssuesByIdName"
                  :selected-options="editNote.issues"
                  @emit-dirty="fieldChanged"
                  v-model="editNote.issues">
                </ui-label-multi-select>
              </ui-fieldset>
              <ui-fieldset
                class="mb-6 --label-multi-select legend"
                :title="configStakeholders.legendTitle">
                <ui-label-multi-select
                  :width-value="formWidth"
                  :label-title="configStakeholders.labelTitle"
                  :label-for="configStakeholders.labelFor"
                  :show-label="configStakeholders.showLabel"
                  :show-filter="configStakeholders.showFilter"
                  :placeholder="configStakeholders.placeholder"
                  :option-label="configStakeholders.optionLabel"
                  :empty-message="configStakeholders.emptyMessage"
                  :select-options="getProjectStakeholderByIdName"
                  :selected-options="editNote.stakeholders"
                  v-model="editNote.stakeholders"
                  @emit-dirty="fieldChanged">
                </ui-label-multi-select>
              </ui-fieldset>
              <ui-label-editor class="mb-5" :label-title="configDescription.labelName"
                :label-for="configDescription.labelFor" :value-editor="editNote.description"
                v-model="editNote.description" @emit-dirty="fieldChanged">
              </ui-label-editor>
              <ui-label-editor class="mb-5" :label-title="configBody.labelName" :label-for="configBody.labelFor"
                :value-editor="editNote.body" v-model="editNote.body" @emit-dirty="fieldChanged">
              </ui-label-editor>
              <ui-multi-file-upload class="mb-6" :label-title="configFileUpload.labelTitle"
                :label-for="configFileUpload.labelFor" :file-types="configFileUpload.fileTypes"
                :file-limit="configFileUpload.fileLimit" :file-size="configFileUpload.fileSize"
                :file-url="configFileUpload.fileUrl" :more-files="configFileUpload.moreFiles"
                :thumbnail-width="configFileUpload.thumbnailWidth" :choose-label="configFileUpload.chooseLabel"
                :auto="configFileUpload.auto"
                v-model="files" @emit-dirty="fieldChanged">
              </ui-multi-file-upload>
            </div>
          </div>
        </form>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4" v-if="!loadingNote && !loadingProjectStakeholders">
      <div class="ui-save-buttons-dropdown d-flex">
        <p-button
          v-if="IsActionAllowedInRole('note.delete', currentUser, project.id)"
          class="p-button-link me-2 red"
          :label="props.deleteButton"
          @click="submitDelete()">
        </p-button>
      </div>
      <ui-save-buttons @emit-save="submitSave()" @emit-cancel="submitCancel()" :saveButton="configButtons.saveButton" :cancelButton="configButtons.cancelButton" :hide-spinner="true">
      </ui-save-buttons>
    </div>
  </div>
</template>

<style lang="scss">
.note {
  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}

.--label-multi-select legend{
  font-size: calc-rem(16)!important;
  font-weight: 600!important;
  line-height: 1rem!important;
  color: #2B323B!important;
  margin: 0!important;
}
</style>
