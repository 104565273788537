<script setup>
import { ref } from 'vue';

// Component import
import PCard from 'primevue/card';
import PSelectButton from 'primevue/selectbutton';
import PButton from 'primevue/button';
import UiCardTitle from './UiCardTitle';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiFormErrorMessage from '@/core/ui/UiFormErrorMessage';

// Define props
const props = defineProps({
  cardTitle: String,
  labelTitle: String,
  labelFor: String,
  selectOptions: Array,
  optionLabel: String,
  multipleSelect: Boolean,
  buttonLabel: String,
  modelValue: Object
});
// Define emits
const emit = defineEmits(['emitClick']);

// Variables
const selectedOption = ref({ nr: 3, name: 'Lezen' });
const username = ref('');
const errorMsgUsername = ref('');
const errorMsgRole = ref('');
const submitClick = () => {
  if (username.value === '') {
    errorMsgUsername.value = 'Vul een gebruiksnaam (e-mailadres) in';
  } else if (!validateEmail(username.value)) {
    errorMsgUsername.value = 'Vul een geldig e-mailadres in';
  } else {
    errorMsgUsername.value = '';
  }
  if (selectedOption.value === undefined || selectedOption.value === '') {
    errorMsgRole.value = 'Selecteer een rol';
  } else {
    errorMsgRole.value = '';
  }
  if (errorMsgUsername.value === '' && errorMsgRole.value === '') {
    errorMsgUsername.value = '';
    errorMsgRole.value = '';

    const result = {
      username: username.value,
      nr: selectedOption.value.nr
    };

    emit('update-value', result);
    emit('update:modelValue', result);
    emit('emitClick');

    username.value = '';
    selectedOption.value = { nr: 3, name: 'Lezen' };
  }
};
const validateEmail = (value) => {
  // return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(value);
};

// Functions
</script>

<template>
  <p-card class="ui-add-member-card">
    <template #content>
      <div class="pt-3 pb-3 pe-3 ps-3">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <ui-label-input class="mb-2" v-model="username" :label-title="props.labelTitle"
          :error-message="errorMsgUsername" :show-error="errorMsgUsername.length > 0" :label-for="props.labelFor">
        </ui-label-input>
        <p-select-button v-model="selectedOption" :options="props.selectOptions" :option-label="props.optionLabel"
          :multiple="props.multipleSelect">
        </p-select-button>
        <ui-form-error-message class="mt-1" v-if="errorMsgRole.length > 0">
          {{ errorMsgRole }}
        </ui-form-error-message>
        <div class="pt-4">
          <p-button :label="props.buttonLabel" icon="pi pi-send" iconPos="left" @click="submitClick()">
          </p-button>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-add-member-card {}
</style>
