<script setup>
// import { ref } from 'vue';
import { onMounted } from 'vue';

// Component imports
import PCard from 'primevue/card';
import UiProjectCard from '@/core/ui/UiProjectCard';

// Store imports
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/modules/user';

// Lifecycle hook imports
import { useRoute } from 'vue-router';

// Variables
const { profile, profilePicture } = storeToRefs(useUserStore());
const { fetchProfile, fetchProfilePicture } = useUserStore();
const route = useRoute();
const profileId = route.params.profileId;
onMounted(async () => {
  await fetchProfile(profileId);
  await fetchProfilePicture(profileId);
});

const configMe = {
  headings: {
    name: 'Profile.Name',
    email: 'Profile.Username',
    company: 'Profile.Company',
    jobTitle: 'Profile.Functionname',
    phone: 'Profile.Phone',
    bio: 'Profile.Biography',
    settings: 'Profile.Settings',
    language: 'Profile.Language',
    twoFactor: 'Profile.Authentication',
    subTitle: 'Profile.JointProjects'
  }
};

// Functions

</script>

<template>
  <main class="me">
    <p-card class="mb-7 pe-4">
      <template #content>
        <div class="me__grid">
          <div class="me__grid-item pt-5 ps-4">
            <div class="row">
              <div class="col-6">
                <h4 class="mt-0">
                  {{ $t(configMe.headings.name) }}
                </h4>
                <p class="mt-0 mb-4">
                  {{
                    profile.firstname || profile.lastname
                      ? (profile.firstname ?? '') +
                        ' ' +
                        (profile.lastname ?? '') +
                        ' (' +
                        profile.userName +
                        ')'
                      : profile.userName
                  }}
                </p>
                <h4 class="mt-0">
                  {{ $t(configMe.headings.company) }}
                </h4>
                <p class="mt-0 mb-4">
                  {{ profile.company ?? '-' }}
                </p>
                <h4 class="mt-0">
                  {{ $t(configMe.headings.jobTitle) }}
                </h4>
                <p class="mt-0 mb-4">
                  {{ profile.functionname ?? '-' }}
                </p>
              </div>
              <div class="col-6">
                <h4 class="mt-0">
                  {{ $t(configMe.headings.email) }}
                </h4>
                <p class="mt-0 mb-4">
                  <a :href="'mailto:' + profile.userName">
                    {{ profile.userName }}
                  </a>
                </p>
                <h4 class="mt-0">
                  {{ $t(configMe.headings.phone) }}
                </h4>
                <p class="mt-0 mb-4">
                  {{ profile.phone ?? '-' }}
                </p>
              </div>
              <div class="col-12">
                <h4 class="mt-0 pe-10">
                  {{ $t(configMe.headings.bio) }}
                </h4>
                <p class="mt-0 mb-7 pe-10" v-html="profile.bio ?? '-'"></p>
              </div>
            </div>
          </div>
          <div class="me__grid-item pt-5 pb-5 ps-4">
            <div class="me__img position-relative mb-2 w-100 overflow-hidden">
              <span
                class="me__img--default position-absolute pi pi-user"
              ></span>
              <img
                v-if="
                  profilePicture.picture !== null &&
                  profilePicture.picture !== undefined &&
                  profilePicture.pictureFileExtension !== ''
                "
                :src="`data:image/jpeg;base64,` + profilePicture.picture"
                class="img-object-fit position-absolute w-100 h-100"
                :alt="profile.firstname + ' ' + profile.lastname"
              />
            </div>
          </div>
        </div>
      </template>
    </p-card>
    <h2 class="mt-0 mb-5">
      {{ $t(configMe.headings.subTitle) }}
    </h2>
    <div class="me__cards-grid">
      <div v-for="item in profile.relatedProjects" :key="item.id">
        <ui-project-card
          :card-title="item.name"
          :card-url="'/projects/' + item.id"
          :total-issues="item.numberOfIssues"
          :total-stakeholders="item.numberOfStakeholders"
          :mod-date="item.modDate"
          :show-updates="false"
        >
        </ui-project-card>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.me {
  &__grid,
  &__cards-grid {
    display: grid;
  }

  &__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      &:nth-child(1) {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
          border-left: calc-rem(1) solid $separatorLineColor;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
          min-height: calc-rem(139);
        }
      }

      h3,
      h4 {
        color: $secondaryTextColor;
      }

      h3 {
        margin-top: calc-rem(3);
        font-size: calc-rem(16);
        font-weight: 600;
        line-height: calc-rem(18);
      }

      h4 {
        margin-bottom: calc-rem(7);
        line-height: calc-rem(16);
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__cards-grid {
    row-gap: calc-rem(12);

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
      column-gap: calc-rem(12);
    }
  }

  &__img {
    aspect-ratio: 308 / 296;
    border-radius: $borderRadius;
    background-color: $mainBg;
    color: $secondaryTextColor;

    &--default {
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: calc-rem(150);
      opacity: 0.2;
      transform: translate(-50%, -50%);
    }

    img {
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .p-card-content {
    padding: 0;
  }
}
</style>
