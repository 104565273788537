import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

export const useStakeholderIssueStore = defineStore({
  id: 'stakeholderissue',
  state: () => ({
    stakeholderissue: {},
    editStakeholderIssue: {},
    stakeholderVersion: {},
    author: null,
    moderator: null,
    issueName: null,
    stakeholderName: null,
    issueId: null,
    stakeholderId: null,
    loadingStakeholderIssue: true,
    loadingEditStakeholderIssue: true,
    contentCard: '',
    features: [
      {
        id: 0,
        name: 'concern',
        labelName: t('IssueStakeholder.Concern'),
        labelFor: 'concernText',
        dropdownPlaceholder: 'Maak een keuze',
        dropdownLabel: 'name',
        options: [
          {
            nr: 1,
            name: t('Status.Extreem.groot.belang')
          },
          {
            nr: 2,
            name: t('Status.Groot.belang')
          },
          {
            nr: 3,
            name: t('Status.Redelijk.belang')
          },
          {
            nr: 4,
            name: t('Status.Nauwelijks.belang')
          },
          {
            nr: 5,
            name: t('Status.Onduidelijk')
          }
        ]
      },
      {
        id: 1,
        name: 'importance',
        labelName: t('General.Tension'),
        labelFor: 'importanceText',
        dropdownPlaceholder: 'Maak een keuze',
        dropdownLabel: 'name',
        options: [
          {
            nr: 0,
            name: t('Status.Onduidelijk')
          },
          {
            nr: 1,
            name: t('Status.Kritiek')
          },
          {
            nr: 2,
            name: t('Status.Waarschuwing')
          },
          {
            nr: 3,
            name: t('Status.Aandacht')
          },
          {
            nr: 4,
            name: t('Status.Positief')
          }
        ]
      }
    ],
    configConcerns: {
      cardTitle: t('IssueStakeholder.Concern'),
      forCheckboxes: 'concerns',
      checkboxes: [
        {
          id: 5,
          labelTitle: t('Status.Onduidelijk'),
          value: 'onduidelijk',
          nameCheckboxes: 'checkboxes',
          className: '--unclear',
          checked: false
        },
        {
          id: 4,
          labelTitle: t('Status.Nauwelijks.belang'),
          value: 'nauwelijksbelang',
          nameCheckboxes: 'checkboxes',
          className: '--hardlyimportance',
          checked: false
        },
        {
          id: 3,
          labelTitle: t('Status.Redelijk.belang'),
          value: 'redelijkbelang',
          nameCheckboxes: 'checkboxes',
          className: '--reasonablyimportance',
          checked: false
        },
        {
          id: 2,
          labelTitle: t('Status.Groot.belang'),
          value: 'grootbelang',
          nameCheckboxes: 'checkboxes',
          className: '--greatimportance',
          checked: false
        },
        {
          id: 1,
          labelTitle: t('Status.Extreem.groot.belang'),
          value: 'extreemgrootbelang',
          nameCheckboxes: 'checkboxes',
          className: '--extremlyhighimportance',
          checked: false
        }
      ]
    },
    configImportances: {
      cardTitle: t('General.Tension'),
      forCheckboxes: 'importances',
      checkboxes: [
        {
          id: 0,
          labelTitle: t('Status.Onduidelijk'),
          value: 'onduidelijk',
          nameCheckboxes: 'checkboxes',
          className: '--unclear',
          checked: false
        },
        {
          id: 4,
          labelTitle: t('Status.Positief'),
          value: 'positief',
          nameCheckboxes: 'checkboxes',
          className: '--positive',
          checked: false
        },
        {
          id: 3,
          labelTitle: t('Status.Aandacht'),
          value: 'aandacht',
          nameCheckboxes: 'checkboxes',
          className: '--focus',
          checked: false
        },
        {
          id: 2,
          labelTitle: t('Status.Waarschuwing'),
          value: 'waarschuwing',
          nameCheckboxes: 'checkboxes',
          className: '--warning',
          checked: false
        },
        {
          id: 1,
          labelTitle: t('Status.Kritiek'),
          value: 'kritiek',
          nameCheckboxes: 'checkboxes',
          className: '--criticism',
          checked: false
        }
      ]
    },
    error: null
  }),
  getters: {
  },
  actions: {
    async fetchStakeholderIssue (projectId, stakeholderId, issueId) {
      this.loadingStakeholderIssue = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId);
        this.stakeholderissue = response.data;

        this.contentCard = '';
        if (this.stakeholderissue.concernNr !== null || this.stakeholderissue.concernText !== null) {
          this.contentCard += '<h3>' + t('IssueStakeholder.Concern') + '</h3>';
          if (this.stakeholderissue.concernNr !== null) {
            const type = this.features.find(x => x.name === 'concern').options.find(y => y.nr === this.stakeholderissue.concernNr);
            this.contentCard += '<p><strong>' + type.name + '</strong></p>';
          }
          if (this.stakeholderissue.concernText !== null) {
            this.contentCard += '<p>' + this.stakeholderissue.concernText + '</p>';
          }
        }
        if (this.stakeholderissue.opinion) {
          this.contentCard += '<h3>' + t('IssueStakeholder.Opinion') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.opinion + '</p>';
        }
        if (this.stakeholderissue.batna) {
          this.contentCard += '<h3>' + t('IssueStakeholder.BATNA') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.batna + '</p>';
        }
        if (this.stakeholderissue.narrative) {
          this.contentCard += '<h3>' + t('IssueStakeholder.Narrative') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.narrative + '</p>';
        }
        if (this.stakeholderissue.narrativeMotivation) {
          this.contentCard += '<h3>' + t('IssueStakeholder.NarrativeMotivation') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.narrativeMotivation + '</p>';
        }
        // JWB: zendesk - ticket 3672
        // JWB: JIRA - https://xuntos.atlassian.net/jira/software/projects/SOMSET/boards/12?selectedIssue=SOMSET-800
        // if (this.stakeholderissue.viewpoint) {
        //   this.contentCard += '<h3>' + t('IssueStakeholder.Viewpoint') + '</h3>';
        //   this.contentCard += '<p>' + this.stakeholderissue.viewpoint + '</p>';
        // }
        if (this.stakeholderissue.conclusion) {
          this.contentCard += '<h3>' + t('IssueStakeholder.Conclusion') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.conclusion + '</p>';
        }
        if (this.stakeholderissue.strategy) {
          this.contentCard += '<h3>' + t('IssueStakeholder.Strategy') + '</h3>';
          this.contentCard += '<p>' + this.stakeholderissue.strategy + '</p>';
        }

        for (const item in this.configConcerns.checkboxes) {
          this.configConcerns.checkboxes[item].checked = this.configConcerns.checkboxes[item].id === this.stakeholderissue.concernNr;
        }

        for (const item in this.configImportances.checkboxes) {
          this.configImportances.checkboxes[item].checked = this.configImportances.checkboxes[item].id === this.stakeholderissue.importanceNr;
        }

        this.author = this.stakeholderissue.addUser ?? null;
        this.moderator = this.stakeholderissue.modUser ?? null;
        this.issueName = this.stakeholderissue.issueName;
        this.issueId = this.stakeholderissue.issueId;
        this.stakeholderName = this.stakeholderissue.projectStakeholderName;
        this.stakeholderId = this.stakeholderissue.stakeholderId;
        this.loadingStakeholderIssue = false;
        await this.fetchStakeholderContacts(projectId, stakeholderId);
      } catch (error) {
        this.loadingStakeholderIssue = false;
        this.error = error;
      }
    },
    async fetchStakeholderIssueMatrix (projectId, stakeholderId, issueId) {
      this.loadingStakeholderIssue = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId);
        this.stakeholderissue = response.data;

        this.contentCard = '';

        // for (const item in this.configConcerns.checkboxes) {
        //   this.configConcerns.checkboxes[item].checked = this.configConcerns.checkboxes[item].id === this.stakeholderissue.concernNr;
        // }
        //
        // for (const item in this.configImportances.checkboxes) {
        //   this.configImportances.checkboxes[item].checked = this.configImportances.checkboxes[item].id === this.stakeholderissue.importanceNr;
        // }

        this.author = this.stakeholderissue.addUser ?? null;
        this.moderator = this.stakeholderissue.modUser ?? null;
        this.issueName = this.stakeholderissue.issueName;
        this.issueId = this.stakeholderissue.issueId;
        this.stakeholderName = this.stakeholderissue.projectStakeholderName;
        this.stakeholderId = this.stakeholderissue.stakeholderId;
        this.loadingStakeholderIssue = false;
        // await this.fetchStakeholderContacts(projectId, stakeholderId);
      } catch (error) {
        this.loadingStakeholderIssue = false;
        this.error = error;
      }
    },
    async fetchEditStakeholderIssue (projectId, stakeholderId, issueId) {
      this.loadingEditStakeholderIssue = true;
      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId);
        const stakeholderissue = response.data;

        this.editStakeholderIssue = {
          viewpoint: stakeholderissue.viewpoint,
          concernNr: stakeholderissue.concernNr,
          concernText: stakeholderissue.concernText,
          strategy: stakeholderissue.strategy,
          opinion: stakeholderissue.opinion,
          batna: stakeholderissue.batna,
          importanceNr: stakeholderissue.importanceNr,
          narrative: stakeholderissue.narrative,
          narrativeMotivation: stakeholderissue.narrativeMotivation,
          conclusion: stakeholderissue.conclusion,
          issueId: stakeholderissue.issueId,
          stakeholderId: stakeholderissue.stakeholderId,
          statusNr: stakeholderissue.statusNr
        };

        this.issueName = stakeholderissue.issueName;
        this.stakeholderName = stakeholderissue.projectStakeholderName;

        for (const item in this.configConcerns.checkboxes) {
          this.configConcerns.checkboxes[item].checked = this.configConcerns.checkboxes[item].id === stakeholderissue.concernNr;
        }

        for (const item in this.configImportances.checkboxes) {
          this.configImportances.checkboxes[item].checked = this.configImportances.checkboxes[item].id === stakeholderissue.importanceNr;
        }

        this.loadingEditStakeholderIssue = false;
      } catch (error) {
        this.loadingEditStakeholderIssue = false;
        this.error = error;
      }
    },
    async updateStakeholderIssue (projectId, stakeholderId, issueId, stakeholderIssue) {
      const submitStakeholderIssue = {
        viewpoint: stakeholderIssue.viewpoint,
        concernNr: stakeholderIssue.concernNr,
        concernText: stakeholderIssue.concernText,
        strategy: stakeholderIssue.strategy,
        opinion: stakeholderIssue.opinion,
        batna: stakeholderIssue.batna,
        importanceNr: stakeholderIssue.importanceNr,
        narrative: stakeholderIssue.narrative,
        narrativeMotivation: stakeholderIssue.narrativeMotivation,
        conclusion: stakeholderIssue.conclusion,
        issueId: stakeholderIssue.issueId,
        stakeholderId: stakeholderIssue.stakeholderId,
        statusNr: stakeholderIssue.statusNr
      };

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId, submitStakeholderIssue);
      } catch (err) {
        console.error(err);

        return false;
      }

      return true;
    },
    async updateStakeholderIssueConcern (projectId, stakeholderId, issueId, concern) {
      this.stakeholderissue.concernNr = concern;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId, this.stakeholderissue)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
        throw error;
      }
    },
    async updateStakeholderIssueConcernDescription (projectId, stakeholderId, issueId, concernText) {
      this.stakeholderissue.concernText = concernText;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId, this.stakeholderissue)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderIssueConcernFromMatrix (projectId, stakeholderId, issueId, concern) {
      // Fetch first, because not all data is available
      await this.fetchStakeholderIssueMatrix(projectId, stakeholderId, issueId);
      await this.updateStakeholderIssueConcern(projectId, stakeholderId, issueId, concern);
    },
    async updateStakeholderIssueConcernDescriptionFromMatrix (projectId, stakeholderId, issueId, concernText) {
      // Fetch first, because not all data is available
      await this.fetchStakeholderIssueMatrix(projectId, stakeholderId, issueId);
      await this.updateStakeholderIssueConcernDescription(projectId, stakeholderId, issueId, concernText);
    },
    async updateStakeholderIssueImportance (projectId, stakeholderId, issueId, importance) {
      this.stakeholderissue.importanceNr = importance;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId, this.stakeholderissue)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
        throw error;
      }
    },
    async updateStakeholderIssueImportanceOpinion (projectId, stakeholderId, issueId, opinionText) {
      this.stakeholderissue.opinion = opinionText;

      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/issues/' + issueId + '/stakeholders/' + stakeholderId, this.stakeholderissue)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },
    async updateStakeholderIssueImportanceFromMatrix (projectId, stakeholderId, issueId, importance) {
      // Fetch first, because not all data is available
      await this.fetchStakeholderIssue(projectId, stakeholderId, issueId);
      await this.updateStakeholderIssueImportance(projectId, stakeholderId, issueId, importance);
    },
    async updateStakeholderIssueImportanceOpinionFromMatrix (projectId, stakeholderId, issueId, concernText) {
      // Fetch first, because not all data is available
      await this.fetchStakeholderIssue(projectId, stakeholderId, issueId);
      await this.updateStakeholderIssueImportanceOpinion(projectId, stakeholderId, issueId, concernText);
    },
    async addStakeholderIssueConcern (projectId, stakeholderId, issueId, concern) {
      const stakeholderIssue = {
        issueId: issueId,
        stakeholderId: stakeholderId,
        concernNr: concern,
        importanceNr: 0 // default, status = onduidelijk
      };
      try {
        await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + issueId, stakeholderIssue);
      } catch (err) {
        console.error('Error Post IssueStakeholder:' + err);
      }
    },
    async addStakeholderIssueImportance (projectId, stakeholderId, issueId, importance) {
      const stakeholderIssue = {
        issueId: issueId,
        stakeholderId: stakeholderId,
        concernNr: 5, // default, status = onduidelijk,
        importanceNr: importance
      };
      try {
        await HttpClient.post('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + issueId, stakeholderIssue);
      } catch (err) {
        console.error('Error Post IssueStakeholder:' + err);
      }
    },
    async removeStakeholderIssue (projectId, stakeholderId, issueId) {
      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId + '/issues/' + issueId);
      } catch (err) {
        console.error('Error delete IssueStakeholder:' + err);
      }
    },
    async updateStakeholderParticipation (projectId, stakeholderId, participation) {
      // Get the latest version of the stakeholder first
      const response = await HttpClient.get('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId);
      const stakeholder = response.data;
      stakeholder.participationNr = participation;
      try {
        await HttpClient.put('api/v1/projects/' + projectId + '/stakeholders/' + stakeholderId, stakeholder)
          .then(() => {
            //
          });
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    }
  }
});
