export default {
  getConcern (concernNr) {
    switch (concernNr) {
      case 5:
        return { className: '--unclear', name: 'Onduidelijk' };
      case 4:
        return { className: '--hardlyimportance', name: 'Nauwelijks belang' };
      case 3:
        return { className: '--reasonablyimportance', name: 'Redelijke belang' };
      case 2:
        return { className: '--greatimportance', name: 'Groot belang' };
      case 1:
        return { className: '--extremlyhighimportance', name: 'Extreem groot belang' };
      default:
        return { className: null, name: null };
    }
  },
  getImportance (importanceNr) {
    switch (importanceNr) {
      case 0:
      case 5:
        return { className: '--unclear', name: 'Onduidelijk' };
      case 4:
        return { className: '--positive', name: 'Positief' };
      case 3:
        return { className: '--focus', name: 'Aandacht' };
      case 2:
        return { className: '--warning', name: 'Waarschuwing' };
      case 1:
        return { className: '--criticism', name: 'Kritiek' };
      default:
        return { className: null, name: null };
    }
  }
};
