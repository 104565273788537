<script setup>
import { watch, ref, toRefs, onMounted } from 'vue';

// Component imports
import PMultiSelect from 'primevue/multiselect';
import UiFormLabel from './UiFormLabel.vue';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  showLabel: Boolean,
  showFilter: Boolean,
  placeholder: String,
  emptyMessage: String,
  optionLabel: String,
  selectOptions: Array,
  selectedOptions: Object,
  modelValue: String,
  widthValue: Number
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables
// const selectedOptions = ref([{ id: 'a9fb7ff5-9406-4027-8b96-2188ad0a35db', className: '--unclear', name: 'Rijksoverheid' }]);
const selectedOptions = ref(props.selectedOptions);
const { selectOptions, widthValue } = toRefs(props);
const onChange = (event) => {
  emit('update:modelValue', event.value);
  emit('emitDirty');
};
const multiSelect = ref();
const setMaxWidth = () => {
  const elem = multiSelect.value;
  // console.log(widthValue.value);
  if (widthValue.value > 0) {
    elem.childNodes[1].style.maxWidth = widthValue.value + 'px';
  }
};

// Functions
watch(
  [widthValue],
  () => {
    setMaxWidth();
  }
);
onMounted(() => {
  setMaxWidth();
});
</script>

<template>
  <div
    ref="multiSelect"
    class="ui-label-multiselect overflow-hidden">
    <ui-form-label
      class="mb-2"
      :for="props.labelFor"
      :class="{ 'sr-only': props.showLabel }">
    </ui-form-label>
    <p-multi-select
      class="w-100"
      :id="props.labelFor"
      :option-label="props.optionLabel"
      :options="selectOptions"
      :filter="props.showFilter"
      :placeholder="props.placeholder"
      :emptyMessage="props.emptyMessage"
      @change="onChange($event)"
      v-model="selectedOptions">
      <template #value="slotProps">
        <div
          class="ui-label-multiselect__label me-1 d-inline-block"
          v-for="option of slotProps.value"
           :key="option.id">
          <div class="d-flex align-items-center">
            <span
              class="ui-label-multiselect__category d-inline-block me-1"
              :class="option.className">
            </span>
            <span class="d-inline-block">
              {{ option.name }}
            </span>
          </div>
        </div>
        <template v-if="!slotProps.value || slotProps.value.length === 0">
          {{ props.placeholder }}
        </template>
      </template>
      <template #option="slotProps">
        <div class="d-flex align-items-center">
          <span
            class="ui-label-multiselect__category d-inline-block me-1"
            :class="slotProps.option.className"></span>
          <span class="ui-label-multiselect__option d-inline-block">
            {{ slotProps.option.name }}
          </span>
        </div>
      </template>
    </p-multi-select>
  </div>
</template>

<style lang="scss">
.ui-label-multiselect {
  &__category {
    width: calc-rem(16);
    height: calc-rem(16);
    border-radius: $borderRadius;

    &.--unclear {
      background-color: $unclear;
    }

    &.--positive {
      background-color: $positive;
    }

    &.--focus {
      background-color: $focus;
    }

    &.--warning {
      background-color: $warning;
    }

    &.--criticism {
      background-color: $criticism;
    }
  }

  &__label {
    padding-top: calc-rem(6);
    padding-right: calc-rem(8);
    padding-bottom: calc-rem(6);
    padding-left: calc-rem(8);
    font-size: calc-rem(12);
    line-height: calc-rem(14);
    border-radius: $borderRadius;
    background-color: $secondary;
  }

  &__option {
    font-size: calc-rem(14);
    line-height: calc-rem(16);
  }

  .p-placeholder {
    margin-left: calc-rem(10);
    padding-top: calc-rem(6);
    padding-bottom: calc-rem(6);
    font-size: calc-rem(14);
    line-height: calc-rem(16);
  }

  .p-multiselect {
    max-width: calc-rem(536);

    &-label {
      padding-top: calc-rem(4);
      padding-right: calc-rem(5);
      padding-bottom: calc-rem(4);
      padding-left: calc-rem(5);
      display: flex;
      align-items: center;

      &-container {
        display: flex;
        height: calc-rem(40);
      }
    }
  }
}
</style>
