<script setup>
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import router from '@/router';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';
import PProgressSpinner from 'primevue/progressspinner';
import StakeholderIssueDetail from '@/modules/stakeholderissuedetail/StakeholderIssueDetail';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';

// Variables
const { loadingStakeholderIssue, issueName, stakeholderName } = storeToRefs(useStakeholderIssueStore());
const editButton = 'Bewerken';
const route = useRoute();
const goToEdit = () => {
  router.push({ path: '/projects/' + route.params.projectId + '/issues/' + route.params.issueId + '/stakeholders/' + route.params.stakeholderId + '/edit' });
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="issueName + ' | ' + stakeholderName"
        v-if="!loadingStakeholderIssue">
        <template v-slot:actions>
          <p-button
            :label="editButton"
            @click="goToEdit()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <stakeholder-issue-detail></stakeholder-issue-detail>
      <p-progress-spinner
        class="mt-10 me-auto ms-auto"
        v-if="loadingStakeholderIssue">
      </p-progress-spinner>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-stakeholder-issue-view {
}
</style>
