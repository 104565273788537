<script setup>
import { storeToRefs } from 'pinia';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

// Component imports
import PProgressSpinner from 'primevue/progressspinner';
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import ProjectStakeholderIssueAnalysis from '@/modules/projectstakeholderissueanalysis/ProjectStakeholderIssueAnalysis';
import UiDownloadButton from '@/core/ui/UiDownloadButton';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Variables
const { loadingProjectStakeholderIssueAnalysis, projectProjectStakeholderIssueAnalysis, projectProjectStakeholderIssueAnalysisFile } = storeToRefs(useProjectsStore());
const { fetchSingleProjectStakeholderIssueAnalysis, updateProjectStakeholderIssueAnalysis } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const cancel = () => {
  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.media = {};
  // });
  // router.back();
  projectProjectStakeholderIssueAnalysisFile.value = {};
};

const save = async () => {
  await updateProjectStakeholderIssueAnalysis(projectId, projectProjectStakeholderIssueAnalysisFile.value);
};

onMounted(async () => {
  await fetchSingleProjectStakeholderIssueAnalysis(projectId);
});

</script>

<template>
  <base-layout class="project-stakeholder-issue-analysis-view position-relative">
    <template v-slot:header>
      <view-header :title="$t('Product.StakeholderIssueAnalysis')">
        <template v-slot:actions>
          <ui-download-button
            v-if="projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisFilename"
            class="p-button"
            label="Download"
            :filename="projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisFilename"
            :type="projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisContentType"
            :endpoint="'stakeholderissueanalysis/download'"
          >
          </ui-download-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <project-stakeholder-issue-analysis
        v-if="!loadingProjectStakeholderIssueAnalysis"
        @emit-save="save()"
        @emit-cancel="cancel()">
      </project-stakeholder-issue-analysis>
      <p-progress-spinner
        class="project-stakeholder-issue-analysis-view__spinner position-absolute"
        v-if="loadingProjectStakeholderIssueAnalysis">
      </p-progress-spinner>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-stakeholder-issue-analysis-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
