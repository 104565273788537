<script setup>
const props = defineProps({
  title: String,
  imgUrl: String
});
</script>

<template>
  <span class="ui-avatar position-relative d-inline-block">
    <img :src="props.imgUrl" class="img-object-fit position-absolute w-100 h-100" :alt="props.title" v-if="props.imgUrl">
  </span>
</template>

<style lang="scss">
.ui-avatar {
  border-radius: $borderRadius;
  overflow: hidden;
  background-color: $shade100;
  background-image: url(../../assets/images/user-secondary-active.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  img {
    top: 0;
    left: 0;
  }
}
</style>
