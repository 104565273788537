<script setup>
import { ref } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { onMounted } from '@vue/runtime-core';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ProjectEdit from '@/modules/projectedit/ProjectEdit';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import PProgressSpinner from 'primevue/progressspinner';
import PConfirmDialog from 'primevue/confirmdialog';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Constants import
import { ProjectConfigConstants } from '@/config/projectsConfigConstants';

// Router import
import router from '@/router';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = 'Project.Edit';
const { loadingProject, projectToEdit, project, projectPicture, allProjects } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectPicture, updateProject, fetchProjects, fetchMultipleProjectsPictures } = useProjectsStore();
const { isDirty } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);
const confirm = useConfirm();
const route = useRoute();
const projectId = route.params.projectId;
const showError = ref(false);

// Model needs initial value, todo: find more elegant way of doing this
projectToEdit.value = project.value;

const configButtons = ProjectConfigConstants.configButtons;

// Functions
const cancel = () => {
  projectToEdit.value = {};
  projectPicture.value = {};
  router.push({ path: '/projects/' + projectId });
};

const save = async () => {
  if (showError.value === false) {
    if (isDirtyPage.value) {
      await updateProject(projectId, projectToEdit.value, projectPicture.value);
      await fetchProjects().then(async () => {
        await allProjects.value.forEach(project => {
          fetchMultipleProjectsPictures(project.id);
        });
      });
      isDirtyPage.value = false;
    }
    await router.push({ path: '/projects/' + projectId });
  }
};

// Functions
useMenuBarStore().$patch({
  showLogo: true,
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  await fetchSingleProject(projectId);
  await fetchSingleProjectPicture(projectId);
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    next();
  }
});

</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="$t(viewTitle)">
        <template v-slot:actions>
          <ui-save-buttons
            @emit-save="save()"
            @emit-cancel="cancel()"
            :saveButton="$t(configButtons.saveButton)"
            :cancelButton="$t(configButtons.cancelButton)">
          </ui-save-buttons>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <project-edit
        :showError="showError"
        v-model="projectToEdit"
        @emit-save="save"
        @emit-cancel="cancel">
      </project-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-edit-view__spinner position-absolute"
    v-if="loadingProject">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-edit-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translateX(-50%,-50%);
  }
}
</style>
