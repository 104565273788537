<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';

import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import NoteEdit from '@/modules/noteedit/NoteEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useNotesStore } from '@/store/modules/notes';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';
import { useMediaStore } from '@/store/modules/media';

// Variables
const { editNote, loadingNote, noteFile } = storeToRefs(useNotesStore());
const { createNewNote, fetchNewNote, fetchNotes } = useNotesStore();
const { updateMediaBinariesNote } = useMediaStore();
const { fetchSingleProjectStakeholders, fetchSingleProjectIssues } = useProjectsStore();
const { loadingProjectStakeholders, projectStakeholders, projectIssues } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const route = useRoute();
const projectId = route.params.projectId;
const showError = ref(false);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
  ]
};
// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const cancel = () => {
  editNote.value = {};
  router.push({ path: '/projects/' + projectId + '/notes/' });
};

const save = async () => {
  console.log('save');
  if (editNote.value.name === '') {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveNote(false);
};

const saveNew = async () => {
  if (editNote.value.name === '') {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveNote(true);
};

const saveNote = async (newNote) => {
  const response = await createNewNote(projectId, editNote.value);
  const noteId = response.id;

  if (noteFile.value.length > 0) {
    await updateMediaBinariesNote(projectId, noteId, noteFile.value);
    noteFile.value = [];
  }

  loadingNote.value = true;
  await fetchNotes(projectId);
  loadingNote.value = false;
  isDirtyPage.value = false;

  if (newNote) {
    await router.push({ path: '/projects/' + projectId + '/notes/create' });
  } else {
    await router.push({ path: '/projects/' + projectId + '/notes/' + response.id });
  }
};

onMounted(async () => {
  loadingNote.value = true;
  loadingProjectStakeholders.value = true;
  await fetchNewNote(projectId);
  if (projectIssues.value.length === 0) {
    await fetchSingleProjectIssues(projectId);
  }
  if (projectStakeholders.value.length === 0) {
    await fetchSingleProjectStakeholders(projectId);
  }
  loadingNote.value = false;
  loadingProjectStakeholders.value = false;
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        loadingNote.value = true;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    loadingNote.value = true;
    next();
  }
});

</script>

<template>
  <base-layout class="project-note-create-view position-relative">
    <template v-slot:header>
      <view-header
        :title="$t('ProjectNote.Frontend.Add2')">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <note-edit
        v-if="!loadingNote && !loadingProjectStakeholders"
        :show-error="showError"
        @emit-save="save()"
        @emit-cancel="cancel()">>
      </note-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-note-create-view__spinner position-absolute"
    v-if="loadingNote || loadingProjectStakeholders">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-note-create-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
