<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import router from '@/router';

// Component imports
import PProgressSpinner from 'primevue/progressspinner';
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import HelpSupport from '@/modules/helpsupport/HelpSupport';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = t('Help.Title');
const { loadingSupportRequest, supportRequestEdit } = storeToRefs(useProjectsStore());
const { fetchSingleSupportRequestNew, addNewSuppporRequest } = useProjectsStore();

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true,
  toggleSideBarClass: true
});

const cancel = () => {
  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.media = {};
  // });
  supportRequestEdit.value = {};
  router.back();
};

const save = async () => {
  addNewSuppporRequest(supportRequestEdit.value);
};

onMounted(async () => {
  await fetchSingleSupportRequestNew();
});
</script>
<template>
  <base-layout class="help-view">
    <template v-slot:header>
      <view-header
        :title="viewTitle">
      </view-header>
    </template>
    <template v-slot:main>
      <help-support v-if="!loadingSupportRequest"
        @emit-save="save()"
        @emit-cancel="cancel()">
      </help-support>
      <p-progress-spinner
        class="help-view__spinner position-absolute"
        v-if="loadingSupportRequest">
      </p-progress-spinner>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.help-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
