<script setup>
import { ref, watch } from 'vue';

// Components imports
import PInputtext from 'primevue/inputtext';
import PButton from 'primevue/button';
import UiFormLabel from '@/core/ui/UiFormLabel';

// Define emits
// const emit = defineEmits(['emitMessages']);
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  defaultMessage: String,
  errorMessage: String,
  modelValue: [],
  refresh: Boolean
});

// Variables
const messages = ref(props.modelValue);
if (props.modelValue === undefined) {
  messages.value = [];
}

const message = ref('');
const disableButton = ref(true);
const addMessage = () => {
  messages.value.push(message.value);
  message.value = '';
  disableAddButton();
  emit('update:modelValue', messages.value);
  emit('emitDirty');
};
const showDefaultMessage = () => {
  if (messages.value.length === 0) {
    return true;
  }
};
const unableAddButton = () => {
  if (message.value.length > 0) {
    disableButton.value = false;
  }
};
const disableAddButton = () => {
  if (message.value.length < 1) {
    disableButton.value = true;
  }
};
const removeItem = (index) => {
  messages.value.splice(index, 1);
  emit('update:modelValue', messages.value);
  emit('emitDirty');
};

// Watchers
// When user hits 'Save'-button, the model needs to be refreshed so a new project can be made.
watch(() => props.refresh, (val) => {
  if (val === true) {
    messages.value = [];
  }
});
</script>

<template>
  <div class="ui-input-list">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor">
    </ui-form-label>
    <div class="d-flex">
      <p-inputtext
        class="ui-input-list__input me-2 w-100"
        v-model="message"
        @keyup="unableAddButton()"
        @keyup.delete="disableAddButton()">
      </p-inputtext>
      <p-button
        class="ui-input-list__button p-button-secondary"
        :disabled="disableButton"
        :aria-disabled="disableButton"
        label="Voeg toe"
        icon="pi pi-plus"
        iconPos="center"
        @click="addMessage()">
      </p-button>
    </div>
    <div class="ui-input-list__messages mt-2 mb-0">
      <div
        class="ui-input-list__message --default pt-4 pb-4 pe-3 ps-3"
        v-if="showDefaultMessage()">
        {{ props.defaultMessage }}
      </div>
      <div v-if="!showDefaultMessage()">
        <TransitionGroup
          name="fade"
          tag="ul">
          <li
            class="ui-input-list__message position-relative pt-3 pb-3 pe-3 ps-3 d-flex justify-content-between align-items-center"
            v-for="(item, index) in messages"
            :key="item.id">
            <span>
              {{ item }}
            </span>
            <p-button
              class="ui-input-list__button p-button-text"
              label="'Verwijder item'"
              icon="pi pi-plus-circle"
              iconPos="center"
              @click="removeItem(index)">
            </p-button>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-input-list {
  &__input {
    height: calc-rem(40);
  }

  &__button {
    padding-right: calc-rem(11);
    padding-left: calc-rem(11);

    &.p-button-text {
      color: $textColor;

      &:enabled:hover {
        background-color: $separatorLineColor;
        color: $textColor;
      }

      .pi {
        font-size: calc-rem(14);
        transform: rotate(45deg);
      }
    }

    .p-button-label {
      @extend %sr-only;
    }
  }

  &__messages {
    padding-top: calc-rem(3);
    padding-bottom: calc-rem(3);
    border-radius: calc-rem(5);
    background-color: $mainBg;

    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
    }

    .p-message {
      line-height: calc-rem(16);
      background-color: transparent;

      &-icon {
        display: none;
      }
    }
  }

  &__message {
    font-size: calc-rem(14);
    line-height: calc-rem(16);

    &:nth-child(n+2) {
      &::before {
        position: absolute;
        top: 0;
        left: calc-rem(15);
        width: calc(100% - 30px);
        height: calc-rem(1);
        content: '';
        background-color: $separatorLineColor;
      }
    }

    &.--default {
      color: $secondaryTextColor;
    }
  }
}
</style>
