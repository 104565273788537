<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import router from '@/router';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import DashboardList from '@/modules/dashboardlist/DashboardList';
import DashboardMap from '@/modules/dashboardmap/DashboardMap';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiToggleButtons from '@/core/ui/UiToggleButtons';
import PProgressspinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Variables
const { loadingProject, project, projectStakeholders, projectIssues } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectIssues, fetchSingleProjectStakeholders } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;

const mapButton = 'General.Map';
const listButton = 'General.List';
const reportButton = 'General.MakeReport';
const showDashboardList = ref(true);
const showDashboardMap = ref(false);

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const goto = (value) => {
  router.push({ path: value });
};

const showMap = () => {
  showDashboardList.value = false;
  setTimeout(() => {
    showDashboardMap.value = true;
  }, 200);
};

const showList = () => {
  showDashboardMap.value = false;
  setTimeout(() => {
    showDashboardList.value = true;
  }, 200);
};

const doSomething = (text) => {
  console.log(text);
};

onMounted(async () => {
  await fetchSingleProject(projectId);
  if (route.query.showmap) {
    showMap();
  }
  if (projectIssues.value.length === 0) {
    await fetchSingleProjectIssues(projectId);
  }
  if (projectStakeholders.value.length === 0) {
    await fetchSingleProjectStakeholders(projectId);
  }
});
</script>

<template>
  <base-layout v-if="!loadingProject" :key="'dashboard-map-' + project.id">
    <template v-slot:header>
      <view-header :title="project ? project.name : ''">
        <template v-slot:actions>
          <ui-toggle-buttons
            v-if="project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng && project.gisDefaultZoomLevel"
            :left-button="$t(mapButton)"
            :left-button-state="showDashboardList"
            :right-button="$t(listButton)"
            :right-button-state="showDashboardMap"
            :only-button="$t(reportButton)"
            :show-only-button="false"
            @emit-left="showMap()"
            @emit-right="showList()"
            @emit-primary="doSomething('report')">
          </ui-toggle-buttons>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <Transition name="fade">
        <dashboard-list v-if="showDashboardList"></dashboard-list>
      </Transition>
      <Transition name="fade">
        <dashboard-map
          v-if="showDashboardMap"
          @emit-goto="goto">
        </dashboard-map>
      </Transition>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progressspinner
    class="project-view__spinner position-absolute"
    v-if="loadingProject">
  </p-progressspinner>
</template>

<style lang="scss">
.project-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $primary;
  }
  66% {
    stroke: $primary;
  }
  80%,
  90% {
    stroke: $primary;
  }
}
</style>
