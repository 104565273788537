import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';

export const useSearch = defineStore({
  id: 'search',
  state: () => ({
    searchResults: [],
    projectsInSearchResults: [],
    typesInSearchResults: [],
    loadingResults: true,
    error: null
  }),
  getters: {
  },
  actions: {
    async fetchSearchResults (projectId, searchFor) {
      this.loadingResults = true;

      try {
        const response = await HttpClient.get('api/v1/searchindex/search?searchfor=' + searchFor);
        this.searchResults = response.data;
        this.projectsInSearchResults = [];
        this.typesInSearchResults = [];

        for (let idx = 0; idx < this.searchResults.length; idx++) {
          if (!this.projectsInSearchResults.find(x => x.projectSid === this.searchResults[idx].projectSid)) {
            this.projectsInSearchResults.push({
              projectSid: this.searchResults[idx].projectSid,
              name: this.searchResults[idx].projectName
            });
          }

          const typeInResults = this.typesInSearchResults.find(x => x.type === this.searchResults[idx].type.replaceAll(' ', ''));
          if (!typeInResults) {
            this.typesInSearchResults.push({
              type: this.searchResults[idx].type.replaceAll(' ', ''),
              name: this.searchResults[idx].type,
              total: 1
            });
          } else {
            typeInResults.total++;
          }
        }

        this.projectsInSearchResults.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        this.loadingResults = false;
      } catch (err) {
        this.loadingResults = false;
        console.error(err);
        this.error = err;
      }
    }
  }
});
