<script setup>
// import { ref } from 'vue';
import { useRoute } from 'vue-router';

// Component imports
import NotesOverviewNote from './components/NotesOverviewNote';

// Define props
const props = defineProps({
  loadingAllNotes: Boolean,
  configNotesOverview: Object
});

// Store imports

// Variables
const route = useRoute();
const projectId = route.params.projectId;
// Functions
</script>

<template>
  <main :key="projectId">
    <template  v-if="!props.loadingAllNotes">
      <div class="notes-overview">
        <div class="notes-overview__col" v-if="props.configNotesOverview.notes.length > 0">
          <h2 class="notes-overview__title mt-0 mb-5 pe-4">
            {{ props.configNotesOverview.notes.length }} {{ props.configNotesOverview.title }}
          </h2>
          <notes-overview-note
            class="notes-overview__note"
            v-for="item in props.configNotesOverview.notes"
            :key="item.id"
            :title="item.title"
            :url="item.url"
            :description-title="props.configNotesOverview.descriptionTitle"
            :description="item.description"
            :stakeholder-title="props.configNotesOverview.stakeholderTitle"
            :stakeholders="item.stakeholders"
            :issue-title="props.configNotesOverview.issueTitle"
            :issues="item.issues"
            :mod-title="props.configNotesOverview.modTitle"
            :user="item.user"
            :published-text="props.configNotesOverview.publishedText"
            :published-at="item.publishedAt"
            :hour-by="props.configNotesOverview.hourBy">
          </notes-overview-note>
        </div>
        <div class="notes-overview__col" v-if="props.configNotesOverview.notes.length === 0">
          <p>{{ $t('ProjectNote.Frontend.NoNotes') }}</p>
        </div>
      </div>
    </template>
  </main>
</template>

<style lang="scss">
.notes-overview {
  display: grid;
  // grid-template-columns: 2fr 1fr;
  column-gap: calc-rem(10);

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02em;
  }

  &__notes {
    list-style: none;
    border-top: calc-rem(1) solid $separatorLineColor;
  }

  &__note {
    &:not(:last-child) {
      border-bottom: calc-rem(1) solid $separatorLineColor;
    }
  }

  .p-card {
    &-content {
      padding: 0;
    }
  }
}
</style>
