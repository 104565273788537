<script setup>
// import { toRefs } from 'vue';
import useMoment from 'moment';
import sanitizeHtml from 'sanitize-html';

// Component imports
import PCard from 'primevue/card';

// Define props
const props = defineProps({
  title: String,
  url: String,
  descriptionTitle: String,
  description: String,
  stakeholderTitle: String,
  issueTitle: String,
  stakeholderName: String,
  stakeholders: Array,
  issues: Array,
  modTitle: String,
  publishedAt: String,
  user: String,
  publishedText: String,
  hourBy: String
});

const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      // 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      // 'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      // 'img',
      // 'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};
</script>

<template>
  <p-card class="notes-overview-note pt-4 pb-4 mb-2 pe-4 ps-4">
    <template #content>
      <h3 class="mt-0 mb-2">
        <router-link :to="props.url">
          {{ props.title }}
        </router-link>
      </h3>
      <h4 class="mt-0 mb-1" v-if="props.description">
        {{ props.descriptionTitle }}
      </h4>
      <div class="notes-overview-note__description mt-0 mb-0 pb-4" v-if="props.description" v-html="sanitizer(props.description)">
      </div>
      <div class="notes-overview-note__meta row">
        <div class="col-5">
          <div v-if="props.stakeholders.length > 0">
            <h5 class="mt-3 mb-1">
              {{ props.stakeholderTitle }}
            </h5>
            <p class="mt-0 mb-0">
              <span v-for="(stakeholder, index) in props.stakeholders" :key="stakeholder.id">{{ index !== 0 ? ', ' : ''}}{{ stakeholder.name }}</span>
            </p>
          </div>
          <div v-if="props.issues.length > 0">
            <h5 class="mt-3 mb-1">
              {{ props.issueTitle }}
            </h5>
            <p class="mt-0 mb-0">
              <span v-for="(issue, index) in props.issues" :key="issue.id">{{ index !== 0 ? ', ' : ''}}{{ issue.name }}</span>
            </p>
          </div>
        </div>
        <div class="col-7">
          <h5 class="mt-3 mb-1">
            {{ props.modTitle }}
          </h5>
          <p class="mt-0 mb-0">
            {{ props.publishedText }} {{ useMoment(props.publishedAt).format('DD-MM-YYYY HH:mm') }} {{ props.hourBy }} <span>{{ props.user }}</span>
          </p>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.notes-overview-note {
  font-size: calc-rem(14);
  line-height: calc-rem(20);

  h3 {
    font-weight: 600;

    a {
      color: $primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h4 {
    font-weight: 700;
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    border-bottom: calc-rem(1) solid $separatorLineColor;
  }

  &__meta {
    h5 {
      font-size: calc-rem(12);
      line-height: calc-rem(14);
      letter-spacing: -.01em;
      color: $secondaryTextColor;
    }

    p {
      font-size: calc-rem(12);
      line-height: calc-rem(16);

      span {
        color: $primary;
      }
    }
  }
}
</style>
