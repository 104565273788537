<script setup>
import { toRefs } from 'vue';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PAccordion from 'primevue/accordion';
import PAccordionTab from 'primevue/accordiontab';
import UiCardTitle from '@/core/ui/UiCardTitle';

// Define props
const props = defineProps({
  buttons: Object,
  headings: Object,
  contactData: Object,
  hideAddressesDetails: Boolean,
  isEditMode: Boolean,
  readOnly: Boolean
});

// Define emits
const emit = defineEmits([
  'editContact',
  'removeContact',
  'newContact',
  'emitDirty'
]);

// Variables
const { buttons, headings, contactData } = toRefs(props);
const emitEditContact = (value) => {
  emit('editContact', value);
  emit('emitDirty');
};
const emitRemoveContact = (value) => {
  emit('removeContact', value);
};
const emitNewContact = () => {
  emit('newContact');
  emit('emitDirty');
};
// Functions
</script>

<template>
  <p-card class="ui-contact-card">
    <template #content>
      <div :class="!hideAddressesDetails ? 'pt-4 pb-2 pe-1 ps-1' : ''">
        <ui-card-title v-if="!hideAddressesDetails">
          {{ headings.cardTitle }}
        </ui-card-title>
        <div class="ui-contact-card__grid" v-if="!isEditMode && (!hideAddressesDetails &&
          ((contactData.address.trim().length + contactData.zipcode.trim().length + contactData.town.trim().length) > 0) ||
          ((contactData.extraAddress.length + contactData.extraZipcode.length + contactData.extraTown.length) > 0) ||
          contactData.phone.length > 0 ||
          contactData.email.length > 0 ||
          contactData.website.length > 0)">
          <div class="ui-contact-card__grid-item"
            v-if="(contactData.address.trim().length + contactData.zipcode.trim().length + contactData.town.trim().length) > 0">
            <h4 class="mt-0 mb-1">
              {{ headings.address }}
            </h4>
            <p class="mt-0 mb-0">
              <span class="d-block">
                {{ contactData.address }}
              </span>
              <span class="d-block">
                {{ contactData.zipcode }} {{ contactData.town }}
              </span>
            </p>
          </div>
          <div class="ui-contact-card__grid-item" v-if="(contactData.extraAddress.length + contactData.extraZipcode.length + contactData.extraTown.length) > 0">
            <h4 class="mt-0 mb-1">
              {{ headings.extraAddress }}
            </h4>
            <p class="mt-0 mb-0">
              <span class="d-block">
                {{ contactData.extraAddress }}
              </span>
              <span class="d-block">
                {{ contactData.extraZipcode }} {{ contactData.extraTown }}
              </span>
            </p>
          </div>
          <div class="ui-contact-card__grid-item" v-if="contactData.phone.length > 0">
            <h4 class="mt-0 mb-1">
              {{ headings.phone }}
            </h4>
            <p class="mt-0 mb-0">
              {{ contactData.phone }}
            </p>
          </div>
          <div class="ui-contact-card__grid-item" v-if="contactData.email.length > 0">
            <h4 class="mt-0 mb-1">
              {{ headings.email }}
            </h4>
            <p class="mt-0 mb-0">
              <a :href="'mailto:' + contactData.email">
                {{ contactData.email }}
              </a>
            </p>
          </div>
          <div class="ui-contact-card__grid-item" v-if="contactData.website.length > 0">
            <h4 class="mt-0 mb-1">
              {{ headings.website }}
            </h4>
            <p class="mt-0 mb-0">
              <a :href="contactData.website.indexOf('https://') == 0 ? contactData.website : 'https://' + contactData.website"
                target="_blank">
                {{ contactData.website }}
              </a>
            </p>
          </div>
        </div>
        <p v-else-if="!isEditMode">{{ $t('General.NoContactDetalsAreKnown') }}</p>
        <div :class="!hideAddressesDetails ? 'pt-7' : ''">
          <h3 class="ui-contact-card__subtitle mt-0 mb-3">
            {{ headings.subTitle }}
          </h3>
          <p v-if="contactData.contactPersons.length === 0">{{ $t('General.NoContactsYet') }}</p>
          <p-accordion :expand-icon="'pi-chevron-down'" :collapse-icon="'pi-chevron-up'">
            <p-accordion-tab v-for="item in contactData.contactPersons" :key="item.id"
              :header="item.firstname + ' ' + item.lastname">
              <div class="ui-contact-card__grid pb-3">
                <div class="ui-contact-card__grid-item">
                  <h4 class="mt-0 mb-1">
                    {{ headings.phone }}
                  </h4>
                  <p class="mt-0 mb-0">
                    {{ item.telephone }}
                  </p>
                </div>
                <div class="ui-contact-card__grid-item">
                  <h4 class="mt-0 mb-1">
                    {{ headings.jobTitle }}
                  </h4>
                  <p class="mt-0 mb-0">
                    {{ item.contactFunction }}
                  </p>
                </div>
                <div class="ui-contact-card__grid-item">
                  <h4 class="mt-0 mb-1">
                    {{ headings.email }}
                  </h4>
                  <p class="mt-0 mb-0">
                    <a :href="'mailto' + item.email">
                      {{ item.emailAddress }}
                    </a>
                  </p>
                </div>
                <div class="ui-contact-card__grid-item">
                  <h4 class="mt-0 mb-1">
                    {{ headings.description }}
                  </h4>
                  <p class="mt-0 mb-0">
                    {{ item.description }}
                  </p>
                </div>
              </div>
              <div class="pb-4 d-flex align-items-center" v-if="!props.readOnly">
                <p-button class="p-button-secondary" :label="buttons.editContact" @click="emitEditContact(item)">
                </p-button>
                <p-button class="ui-contact-card__button-text p-button-text ms-1" :label="buttons.removeContact"
                  @click="emitRemoveContact(item)">
                </p-button>
              </div>
            </p-accordion-tab>
          </p-accordion>
          <div class="pt-4" v-if="!props.readOnly">
            <p-button :label="buttons.newContact" icon="pi pi-plus" iconPos="left" @click="emitNewContact()">
            </p-button>
          </div>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-contact-card {
  font-size: calc-rem(14);
  line-height: calc-rem(20);

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: calc-rem(20);
    row-gap: calc-rem(20);

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      h4 {
        font-size: calc-rem(14);
        line-height: calc-rem(16);
        color: $secondaryTextColor;
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__subtitle {
    font-size: calc-rem(16);
    font-weight: 600;
    line-height: calc-rem(18);
  }

  &__button-text {
    color: $alertColor  !important;
  }

  .p-accordion {
    &-tab {
      border-bottom: calc-rem(1) solid $separatorLineColor;
    }

    &-header {
      &:hover {
        .p-accordion-header-link {
          background-color: transparent !important;
          border-color: transparent !important;
        }

        .p-accordion-header-text {
          text-decoration: underline;
        }
      }

      &-link {
        padding-top: calc-rem(10) !important;
        padding-right: 0 !important;
        padding-bottom: calc-rem(10) !important;
        padding-left: 0 !important;
        font-size: calc-rem(14);
        line-height: calc-rem(20);
        color: $primary  !important;
      }
    }

    &-toggle-icon {
      margin-left: auto;
      order: 2;
      color: $secondaryTextColor;
    }

    &-content {
      padding-top: calc-rem(5);
      padding-right: 0;
      padding-left: 0;
    }

    .ui-contact-card__grid-item {

      &:nth-child(3),
      &:nth-child(4) {
        grid-column: 1 / span 2;
      }
    }
  }
}
</style>
