<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import sanitizeHtml from 'sanitize-html';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PDialog from 'primevue/dialog';
import POverlayPanel from 'primevue/overlaypanel';
// import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
// import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
// import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
// import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholders: Object,
  crosspoints: Array
});

// Define emits

// Variables
// const { project } = storeToRefs(useProjectsStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const {
  updateStakeholderIssue,
  updateStakeholderIssueImportanceFromMatrix,
  updateStakeholderIssueConcernFromMatrix
} = useStakeholderIssueStore();

// const opColor = ref();
const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
// const onFocusOverlayPanelInput = () => {
//   focusOverlayPanelInput.value = true;
//   showOverlayPanelButtons.value = true;
// };
// const onBlurOverlayPanelInput = () => {
//   focusOverlayPanelInput.value = false;
// };
const hideOverlayPanelButtons = () => {
  showOverlayPanelButtons.value = false;
};
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const cancelSelectColor = () => {
//   opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
// const scrollRight = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value += 1;
//   scrollValue.value -= scrollDistance;

//   // Add class to 1st column and enable left button
//   if (countClicks.value === 1) {
//     disableLeftButton.value = false;
//     addShadowClass.value = true;
//   }
//   // Disable right button on max clicks
//   if (countClicks.value === maxClicks) {
//     disableRightButton.value = true;
//   }
// };
// const scrollLeft = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value -= 1;
//   scrollValue.value += scrollDistance;

//   // Disable left button and remove class from 1st column
//   if (countClicks.value === 0) {
//     disableLeftButton.value = true;
//     addShadowClass.value = false;
//   }

//   // Enable right button
//   if (countClicks.value < maxClicks) {
//     disableRightButton.value = false;
//   }
// };
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Cancel')
  },
  concernTooltip: {
    reset: t('General.Importance.No'),
    extremlyHighImportance: t('General.Importance.Extreme'),
    greatImportance: t('General.Importance.Great'),
    reasonablyImportance: t('General.Importance.Reasonable'),
    hardlyImportance: t('General.Importance.Hardly'),
    unclear: t('Status.Onduidelijk')
  },
  importanceTooltip: {
    reset: t('Status.NoStress'),
    criticism: t('Status.Kritiek'),
    warning: t('Status.Waarschuwing'),
    focus: t('Status.Aandacht'),
    positive: t('Status.Positief'),
    unclear: t('Status.Onduidelijk')
  }
};

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});

const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};

// const getConcern = (concernNr) => {
//   switch (concernNr) {
//     case 5:
//       return { className: '--unclear', name: configMatrix.concernTooltip.unclear };
//     case 4:
//       return { className: '--hardlyimportance', name: configMatrix.concernTooltip.hardlyimportance };
//     case 3:
//       return { className: '--reasonablyimportance', name: configMatrix.concernTooltip.reasonablyimportance };
//     case 2:
//       return { className: '--greatimportance', name: configMatrix.concernTooltip.greatimportance };
//     case 1:
//       return { className: '--extremlyhighimportance', name: configMatrix.concernTooltip.extremlyhighimportance };
//     default:
//       return { className: null, name: null };
//   }
// };

// const getImportance = (importanceNr) => {
//   switch (importanceNr) {
//     case 5:
//       return { className: '--unclear', name: configMatrix.importanceTooltip.unclear };
//     case 4:
//       return { className: '--positive', name: configMatrix.importanceTooltip.positive };
//     case 3:
//       return { className: '--focus', name: configMatrix.importanceTooltip.focus };
//     case 2:
//       return { className: '--warning', name: configMatrix.importanceTooltip.warning };
//     case 1:
//       return { className: '--criticism', name: configMatrix.importanceTooltip.criticism };
//     default:
//       return { className: null, name: null };
//   }
// };

watch(() => props.stakeholders, (first, second) => {
  // console.info('stakeholders', first);
  stakeholders.value = first;
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

const issuesByStakeholder = (stakeholderId) => {
  const issues = props.crosspoints.filter((x) => {
    return x.stakeholderId === stakeholderId;
  });

  try {
    const stakeholderIssuesGroupedByCategory = [];

    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];
      if (!issue.issueCategory) {
        issue.issueCategory = t('General.NoCategory');
      }
      let categoryIndex = stakeholderIssuesGroupedByCategory.findIndex(function (x) {
        return x.id === issue.issueCategory;
      });

      if (categoryIndex === -1) {
        // Add new category
        stakeholderIssuesGroupedByCategory.push({
          id: issue.issueCategory,
          category: issue.issueCategory,
          className: null,
          isCollapsed: true,
          issues: []
        });

        categoryIndex = stakeholderIssuesGroupedByCategory.findIndex(function (x) {
          return x.id === issue.issueCategory;
        });
      }
      stakeholderIssuesGroupedByCategory[categoryIndex].issues.push(issue);
    }
    stakeholderIssuesGroupedByCategory.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
    return stakeholderIssuesGroupedByCategory;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// This collects dynamic refs for all crosspoints
const stakeholders = ref(null);
const crossPoints = ref([]);
const toggleCrossPoint = (event, crossPointId) => {
  // console.info(crossPoints, crossPointId);
  // console.info('crossPointId', crossPoints.value[crossPointId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectenvironmentmonitormatrix.edit', currentUser.value, props.projectId)) {
    crossPoints.value[crossPointId].toggle(event);
  }
};
// const concern = ref([]);
// // const concernTextareas = ref([]);
// const importance = ref([]);
// // const importanceTextareas = ref([]);
// const stakeholderStrategy = ref([]);
// const stakeholderStrategyLabel = ref([]);
// const toggleConcern = (event, concernId) => {
//   // console.info(concern, concernId);
//   // console.info('concernId', crossPoints.value[concernId]);
//   // This will toggle the crosspoint
//   concern.value[concernId].toggle(event);
// };
// const toggleImportance = (event, importanceId) => {
//   // console.info(importance, importanceId);
//   // console.info('importanceId', crossPoints.value[importanceId]);
//   // This will toggle the crosspoint
//   importance.value[importanceId].toggle(event);
// };
// const updateStakeholderConcernValue = (value, categoryIndex, stakeholderId) => {
//   if (value === -1 || value === null) {
//     // console.info('DELETE VIA API FOR', stakeholderId, issueId);
//     // removeStakeholderIssue(props.projectId, stakeholderId);
//   } else {
//     const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
//       return x.id === stakeholderId;
//     });
//     if (stakeholder) {
//       stakeholder.concernNr = value;
//       document.querySelector('#concern-' + stakeholderId + ' .p-button-label').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
//       // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
//       updateStakeholderConcernFromMatrix(project.value, stakeholderId, value);
//     }
//   }
// };
// const updateStakeholderStatusValue = (value, categoryIndex, stakeholderId) => {
//   if (value === -1 || value === null) {
//     // console.info('DELETE VIA API FOR', stakeholderId, issueId);
//     // removeStakeholderIssue(props.projectId, stakeholderId);
//   } else {
//     const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
//       return x.id === stakeholderId;
//     });
//     if (stakeholder) {
//       stakeholder.statusNr = value;
//       document.querySelector('#importance-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
//       // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
//       updateStakeholderStatusFromMatrix(project.value, stakeholderId, value);
//     }
//   }
// };
// const toggleStakeholderStrategy = (event, stakeholderId) => {
//   stakeholderStrategy.value[stakeholderId].toggle(event);
// };
// const updateStakeholderStrategy = (value, valueText, stakeholderId, currentObject) => {
//   document.querySelector('#strategyOptionLabel' + stakeholderId + '').innerText = valueText;
//   updateStakeholderParticipationFromMatrix(project.value, stakeholderId, value);
//   // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
//   stakeholderStrategy.value[stakeholderId].toggle(event);
// };

const importanceClassName = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return '--unclear';
    case 4:
      return '--positive';
    case 3:
      return '--focus';
    case 2:
      return '--warning';
    case 1:
      return '--criticism';
  }
};

const importanceText = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Positief');
    case 3:
      return t('Status.Aandacht');
    case 2:
      return t('Status.Waarschuwing');
    case 1:
      return t('Status.Kritiek');
  }
};

const concernClassName = (concernNr) => {
  switch (concernNr) {
    case 5:
      return '--unclear';
    case 4:
      return '--hardlyimportance';
    case 3:
      return '--reasonablyimportance';
    case 2:
      return '--greatimportance';
    case 1:
      return '--extremlyhighimportance';
  }
};
const concernText = (concernNr) => {
  switch (concernNr) {
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('General.Importance.Hardly');
    case 3:
      return t('General.Importance.Reasonable');
    case 2:
      return t('General.Importance.Great');
    case 1:
      return t('General.Importance.Extreme');
  }
};

const crossPoint = (stakeholderId, issueId) => {
  if (props.crosspoints) {
    const crossPoint = props.crosspoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.importanceClassName = importanceClassName(crossPoint.issueStakeholderImportanceNr);
      crossPoint.issueStakeholderImportanceName = importanceText(crossPoint.issueStakeholderImportanceNr);
      crossPoint.concernClassName = concernClassName(crossPoint.issueStakeholderConcernNr);
      crossPoint.issueStakeholderConcernName = concernText(crossPoint.issueStakeholderConcernNr);
      return crossPoint;
    }
  }
  return {
    issueStakeholderConcernNr: null,
    issueStakeholderConcernName: null,
    issueStakeholderImportanceNr: null,
    issueStakeholderImportanceName: null,
    opinion: null,
    importanceClassName: null
  };
};

const showMoreDialog = ref(false);
const showMoreDialogStakeholderId = ref(null);
const showMoreDialogIssueId = ref(null);
const showMoreDialogAction = ref(null);
const showMoreDialogName = ref(null);
const showMoreDialogTitle = ref(null);
const showMoreDialogText = ref(null);
const showMore = (stakeholderId, issueId, title, action) => {
  if (!IsActionAllowedInRole('projectenvironmentmonitormatrix.edit', currentUser.value, props.projectId)) {
    return;
  }

  showMoreDialog.value = true;
  showMoreDialogStakeholderId.value = stakeholderId;
  showMoreDialogIssueId.value = issueId;
  showMoreDialogAction.value = action;
  switch (showMoreDialogAction.value) {
    case 'narrative':
      showMoreDialogTitle.value = 'Redeneerlijn stakeholder: ' + title;
      break;
    case 'narrativeMotivation':
      showMoreDialogTitle.value = 'Motivatie redeneerlijn: ' + title;
      break;
    case 'batna':
      showMoreDialogTitle.value = 'BATNA: ' + title;
      break;
    case 'viewpoint':
      showMoreDialogTitle.value = 'Onze redeneerlijn: ' + title;
      break;
    case 'conclusion':
      showMoreDialogTitle.value = 'Conclusie: ' + title;
      break;
    case 'strategy':
      showMoreDialogTitle.value = 'Strategie: ' + title;
      break;
  }
  showMoreDialogText.value = document.querySelector('#issue-stakeholder-' + action + '-' + issueId + '-' + stakeholderId).innerHTML;
};
const saveShowMoreDialog = () => {
  document.querySelector('#issue-stakeholder-' + showMoreDialogAction.value + '-' + showMoreDialogIssueId.value + '-' + showMoreDialogStakeholderId.value).innerHTML = showMoreDialogText.value;
  document.querySelector('#' + showMoreDialogAction.value + 'TextEditButton-' + showMoreDialogIssueId.value + '-' + showMoreDialogStakeholderId.value + ' a').innerText = showMoreDialogText.value ? t('General.More') : t('General.Add');
  const crossPoint = props.crosspoints.find(function (x) {
    return x.stakeholderId === showMoreDialogStakeholderId.value && x.issueId === showMoreDialogIssueId.value;
  });
  switch (showMoreDialogAction.value) {
    case 'narrative':
      crossPoint.narrative = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'Redeneerlijn stakeholder ' + title;
      break;
    case 'narrativeMotivation':
      crossPoint.narrativeMotivation = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'Motivatie redeneerlijn ' + title;
      break;
    case 'batna':
      crossPoint.batna = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'BATNA ' + title;
      break;
    case 'viewpoint':
      crossPoint.viewpoint = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'Onze redeneerlijn ' + title;
      break;
    case 'conclusion':
      crossPoint.conclusion = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'Conclusie ' + title;
      break;
    case 'strategy':
      crossPoint.strategy = showMoreDialogText.value;
      // showMoreDialogTitle.value = 'Strategie ' + title;
      break;
  }
  const stakeholderIssue = {
    viewpoint: crossPoint.viewpoint,
    concernNr: crossPoint.issueStakeholderConcernNr,
    concernText: crossPoint.concernText,
    strategy: crossPoint.strategy,
    opinion: crossPoint.opinion,
    batna: crossPoint.batna,
    importanceNr: crossPoint.issueStakeholderImportanceNr,
    narrative: crossPoint.narrative,
    narrativeMotivation: crossPoint.narrativeMotivation,
    conclusion: crossPoint.conclusion,
    issueId: crossPoint.issueId,
    stakeholderId: crossPoint.stakeholderId,
    statusNr: crossPoint.statusNr
  };
  updateStakeholderIssue(props.projectId, showMoreDialogStakeholderId.value, showMoreDialogIssueId.value, stakeholderIssue);
  closeShowMoreDialog();
};
const closeShowMoreDialog = () => {
  showMoreDialog.value = false;
  showMoreDialogStakeholderId.value = null;
  showMoreDialogName.value = null;
  showMoreDialogTitle.value = null;
  showMoreDialogText.value = null;
};

const updateCrossPointImportance = (value, stakeholderId, issueId) => {
  const crossPoint = props.crosspoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPoint) {
    crossPoint.issueStakeholderImportanceNr = value;
    crossPoint.importanceClassName = value;
    updateStakeholderIssueImportanceFromMatrix(props.projectId, stakeholderId, issueId, value);
  }
};

const updateCrossPointConcern = (value, stakeholderId, issueId) => {
  const crossPoint = props.crosspoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPoint) {
    crossPoint.issueStakeholderConcernNr = value;
    crossPoint.concernClassName = value;
    updateStakeholderIssueConcernFromMatrix(props.projectId, stakeholderId, issueId, value);
  }
};
</script>

<template>
  <main>
    <!-- pre style="height: 200px; overflow: scroll;">
      {{ props.crosspoints }}
    </pre -->
    <p-card v-if="stakeholders">
      <template #content>
        <div class="environment-matrix matrix overflow-hidden">
          <div class="matrix__wrap position-relative">
            <div
              class="matrix__matrix position-absolute w-100"
              role="table">
              <div
                class="matrix__matrix-row d-flex"
                role="row"
                aria-label="Environmentmatrix">
                <div
                  class="matrix__matrix-th"
                  role="cell">
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('General.Issues') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3"
                  role="cell">
                  <span>
                    {{ $t('General.Importance.Tension') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('ProjectStakeholder.Narrative') }}
                  </span>
                </div>
                <!-- SOMSET-721: Must not show up in new version -->
                <div
                  v-if="1 === 2"
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('IssueStakeholder.NarrativeMotivation') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('Stakeholder.BATNA') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('ProjectStakeholder.Narrative.Issue') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('IssueStakeholder.Conclusion') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('ProjectStakeholder.Participation') }}
                  </span>
                </div>
              </div>

              <template
                v-for="stakeholderCategory in stakeholders.panels"
                :key="'stakeholderCategory' + stakeholderCategory.id">

                <div
                  class="matrix__matrix-row d-flex"
                  role="row">
                    <div
                      class="matrix__matrix-td --category"
                      role="cell">
                      <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                        <span>
                          {{ stakeholderCategory.panelTitle }}
                        </span>
                      </h3>
                    </div>
                  <div
                    v-for="item in 7"
                    :key="'column-' + item"
                    class="matrix__matrix-td --category"
                    :class="[item == 2 ? '': '--text-only']"
                    role="cell">
                  </div>
                </div>

                <template
                  v-for="stakeholder in stakeholderCategory.panel"
                  :key="'stakeholder' + stakeholder.id">
                  <template
                    v-for="(issueCategory, issueCategoryIndex) in issuesByStakeholder(stakeholder.id)"
                    :key="'issueCategory' + stakeholder.id + issueCategory.id">
                    <template
                      v-for="issue in issueCategory.issues"
                      :key="'issue' + stakeholder.id + issue.id">

                      <div
                        class="matrix__matrix-row d-flex"
                        role="row">

                        <div
                          class="matrix__matrix-td d-flex align-items-center --text"
                          role="cell">
                          <router-link
                            v-if="issueCategoryIndex == 0"
                            :to="stakeholder.urlTo"
                            class="matrix__table-stakeholders-item pe-3 ps-3 d-flex align-items-center"
                            :title="stakeholder.title">
                            {{ stakeholder.title }}
                          </router-link>
                        </div>

                        <div
                          class="matrix__matrix-td --category --text-only"
                          role="cell">
                          <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                            <span>
                              {{ issueCategory.category }}
                            </span>
                          </h3>
                        </div>

                        <div
                          v-for="item in 6"
                          :key="'column-' + item"
                          class="matrix__matrix-td --category"
                          :class="[item == 1 ? '': '--text-only']"
                          role="cell">
                        </div>
                      </div>

                      <div
                        class="matrix__matrix-row d-flex"
                        role="row">
                        <div
                          class="matrix__matrix-td"
                          role="cell">
                        </div>

                        <div
                          class="matrix__matrix-td d-flex align-items-center --text-only"
                          role="cell">
                          <router-link
                            :to="stakeholder.urlTo  + '/issues/' + issue.issueId"
                            class="matrix__table-stakeholders-item pe-3 ps-3 d-flex align-items-center"
                            :title="stakeholder.title">
                            {{ issue.issueName }}
                          </router-link>
                        </div>

                        <div
                          class="matrix__matrix-td d-flex justify-content-center align-items-center"
                          role="cell">
                          <div class="d-flex justify-content-center">
                            <div class="d-grid gap-2">
                              <!-- Start Importance -->
                              <p-button
                                class="matrix__matrix-button p-0"
                                :class="crossPoint(stakeholder.id, issue.issueId).importanceClassName"
                                :label="crossPoint(stakeholder.id, issue.issueId).issueStakeholderImportanceName"
                                aria-haspopup="true"
                                :aria-controls="'crosspoint-importance-' + stakeholder.id"
                                v-tooltip.top="crossPoint(stakeholder.id, issue.issueId).issueStakeholderImportanceName"
                                @click="toggleCrossPoint($event, 'crosspoint-importance-' + stakeholder.id + '-' + issue.issueId)">
                              </p-button>
                              <p-overlay-panel
                                class="matrix__overlay-panel p-2"
                                :class="{ '--focused': focusOverlayPanelInput }"
                                :ref="(el) => { crossPoints['crosspoint-importance-' + stakeholder.id + '-' + issue.issueId] = el; }"
                                :id="'crosspoint-importance-' + stakeholder.id + '-' + issue.issueId"
                                @hide="hideOverlayPanelButtons()">
                                <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                                  <p-button
                                    class="p-button-rounded p-0 --unclear"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--unclear', }"
                                    :label="configMatrix.importanceTooltip.unclear"
                                    v-tooltip.top="configMatrix.importanceTooltip.unclear"
                                    @click="updateCrossPointImportance(5, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --positive"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--positive', }"
                                    :label="configMatrix.importanceTooltip.positive"
                                    v-tooltip.top="configMatrix.importanceTooltip.positive"
                                    @click="updateCrossPointImportance(4, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --focus"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--focus', }"
                                    :label="configMatrix.importanceTooltip.focus"
                                    v-tooltip.top="configMatrix.importanceTooltip.focus"
                                    @click="updateCrossPointImportance(3, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --warning"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--warning', }"
                                    :label="configMatrix.importanceTooltip.warning"
                                    v-tooltip.top="configMatrix.importanceTooltip.warning"
                                    @click="updateCrossPointImportance(2, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --criticism"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--criticism', }"
                                    :label="configMatrix.importanceTooltip.criticism"
                                    v-tooltip.top="configMatrix.importanceTooltip.criticism"
                                    @click="updateCrossPointImportance(1, stakeholder.id, issue.issueId)">
                                  </p-button>
                                </div>
                              </p-overlay-panel>
                                <!-- End Importance -->
                                <!-- Start Concern -->
                                <p-button
                                class="matrix__matrix-button p-0"
                                :class="crossPoint(stakeholder.id, issue.issueId).concernClassName"
                                :label="crossPoint(stakeholder.id, issue.issueId).issueStakeholderConcernName"
                                aria-haspopup="true"
                                :aria-controls="'crosspoint-concern-' + stakeholder.id"
                                v-tooltip.top="crossPoint(stakeholder.id, issue.issueId).issueStakeholderConcernName"
                                @click="toggleCrossPoint($event, 'crosspoint-concern-' + stakeholder.id + '-' + issue.issueId)">
                              </p-button>
                              <p-overlay-panel
                                class="matrix__overlay-panel p-2"
                                :class="{ '--focused': focusOverlayPanelInput }"
                                :ref="(el) => { crossPoints['crosspoint-concern-' + stakeholder.id + '-' + issue.issueId] = el; }"
                                :id="'crosspoint-concern-' + stakeholder.id + '-' + issue.issueId"
                                @hide="hideOverlayPanelButtons()">
                                <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                                  <p-button
                                    class="p-button-rounded p-0 --unclear"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--unclear', }"
                                    :label="configMatrix.concernTooltip.unclear"
                                    v-tooltip.top="configMatrix.concernTooltip.unclear"
                                    @click="updateCrossPointConcern(5, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --hardlyimportance"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--hardlyimportance', }"
                                    :label="configMatrix.concernTooltip.hardlyimportance"
                                    v-tooltip.top="configMatrix.concernTooltip.hardlyimportance"
                                    @click="updateCrossPointConcern(4, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --reasonablyimportance"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--reasonablyimportance', }"
                                    :label="configMatrix.concernTooltip.reasonablyimportance"
                                    v-tooltip.top="configMatrix.concernTooltip.reasonablyimportance"
                                    @click="updateCrossPointConcern(3, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --greatimportance"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--greatimportance', }"
                                    :label="configMatrix.concernTooltip.greatimportance"
                                    v-tooltip.top="configMatrix.concernTooltip.greatimportance"
                                    @click="updateCrossPointConcern(2, stakeholder.id, issue.issueId)">
                                  </p-button>
                                  <p-button
                                    class="p-button-rounded p-0 --extremlyhighimportance"
                                    :class="{ '--active': crossPoint(stakeholder.id, issue.issueId).importanceClassName == '--extremlyhighimportance', }"
                                    :label="configMatrix.concernTooltip.extremlyhighimportance"
                                    v-tooltip.top="configMatrix.concernTooltip.extremlyhighimportance"
                                    @click="updateCrossPointConcern(1, stakeholder.id, issue.issueId)">
                                  </p-button>
                                </div>
                              </p-overlay-panel>
                              <!-- End Concern -->
                            </div>
                          </div>
                        </div>

                        <div
                          class="matrix__matrix-td d-flex align-items-center --text-only"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-narrative-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.narrative ?? '')">
                            </div>
                            <div
                              class="matrix__strategy-more"
                              :id="'narrativeTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'narrative')">
                                {{ issue.narrative ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <!-- SOMSET-721: Must not show up in new version -->
                        <div
                          v-if="1 === 2"
                          class="matrix__matrix-td d-flex align-items-center --text-only"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-narrativeMotivation-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.narrativeMotivation ?? '')">
                            </div>
                            <div
                              class="matrix__strategy-more"
                              :id="'narrativeMotivationTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'narrativeMotivation')">
                                {{ issue.narrativeMotivation ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="matrix__matrix-td d-flex align-items-center --text-only"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-batna-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.batna ?? '')">
                            </div>
                            <div
                              class="matrix__strategy-more"
                              :id="'batnaTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'batna')">
                                {{ issue.batna ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="matrix__matrix-td d-flex align-items-center --text-only"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-viewpoint-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.viewpoint ?? '')">
                            </div>
                            <div
                              class="matrix__strategy-more"
                              :id="'viewpointTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'viewpoint')">
                                {{ issue.viewpoint ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="matrix__matrix-td d-flex align-items-center --text-only --wide"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-conclusion-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.conclusion ?? '')">
                            </div>
                            <div
                              :id="'conclusionTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                class="matrix__strategy-more"
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'conclusion')">
                                {{ issue.conclusion ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="matrix__matrix-td matrix__strategy-text d-flex align-items-center --text-only"
                          role="cell">
                          <div>
                            <div
                              class="matrix__strategy-text"
                              :id="'issue-stakeholder-strategy-' + issue.issueId + '-' + issue.stakeholderId"
                              v-html="sanitizer(issue.strategy ?? '')">
                            </div>
                            <div
                              class="matrix__strategy-more"
                              :id="'strategyTextEditButton-' + issue.issueId + '-' + stakeholder.id">
                              <a
                                @click="showMore(issue.stakeholderId, issue.issueId, issue.issueName + ' - ' + issue.stakeholderName, 'strategy')">
                                {{ issue.strategy ? t('General.More') : t('General.Add') }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </template>
                  </template>
                </template>
              </template>

              <div
                class="matrix__matrix-row d-flex"
                role="row">
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                  <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                    <span>
                      {{ $t('General.LegendImportance') }}
                    </span>
                  </h3>
                </div>
                <div class="matrix__matrix-td d-flex align-items-center --category --legend">
                  <table class="matrix__matrix-legend">
                    <tr>
                      <template
                        v-for="item in 5"
                        :key="'legend-concern-' + item">
                        <td class="pe-3 ps-3">
                          <span class="d-flex align-items-center">
                            <span
                              class="matrix__matrix-legend-badge me-2 d-inline-block"
                              :class="concernClassName(item)"
                              :title="$t(concernText(item))">
                            </span>
                            <span class="matrix__matrix-legend-text">
                              {{ $t(concernText(item)) }}
                            </span>
                          </span>
                        </td>
                      </template>
                  </tr>
                  </table>
                </div>
              </div>

              <div
                class="matrix__matrix-row d-flex"
                role="row">
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                  <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                    <span>
                      {{ $t('General.LegendTension') }}
                    </span>
                  </h3>
                </div>
                <div class="matrix__matrix-td d-flex align-items-center --category --legend">
                  <table class="matrix__matrix-legend">
                    <tr>
                      <template
                        v-for="item in 5"
                        :key="'legend-concern-' + item">
                        <td class="pe-3 ps-3">
                          <span class="d-flex align-items-center">
                            <span
                              class="matrix__matrix-legend-badge me-2 d-inline-block"
                              :class="importanceClassName(item)"
                              :title="$t(importanceText(item))">
                            </span>
                            <span class="matrix__matrix-legend-text">
                              {{ $t(importanceText(item)) }}
                            </span>
                          </span>
                        </td>
                      </template>
                  </tr>
                  </table>
                </div>
              </div>
            </div><!-- End table -->
          </div><!-- End wrap -->
        </div><!-- End matrix -->
      </template>
    </p-card>
    <p-dialog class="w-50" position="center" v-model:visible="showMoreDialog" :modal="true">
      <template #header>
        <h3>
          {{showMoreDialogTitle}}
        </h3>
      </template>
      <div class="px-2">
        <ui-label-editor
          class="mb-6"
          :label-title="''"
          :label-for="''"
          :value-editor="showMoreDialogText"
          v-model="showMoreDialogText">
        </ui-label-editor>
      </div>
      <template #footer>
        <p-button @click="saveShowMoreDialog">Save</p-button>
        <p-button class="p-button-text" @click="closeShowMoreDialog">Close</p-button>
      </template>
    </p-dialog>
    <p-progress-bar
      class="environment-monitor__progressbar"
      :value="50"
      v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.matrix__overlay-panel {
  width: initial;
}
.environment-monitor {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__table {
    > div:first-child {
      > div:nth-child(2) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(3) {
        margin-left: calc-rem(0);
      }

      > div:nth-child(4) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(5) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(5) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(6) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(7) {
        margin-left: calc-rem(8);
      }
    }
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;

    .matrix__th {
      &:last-child {
        position: relative;
        bottom: -4px;
        left: 60px;
        transform: rotate(30deg);
      }
      &.concerncolumn {
        margin-left: 0.5rem;
      }
    }

    .matrix__stakeholders-item {
      &.--tall {
        height: calc-rem(128);
      }

      &.--category {
        color: #495057;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 0.875rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        background-color: #F6F7F8;
      }
    }

    .matrix__td {
      &.importanceconcern__button {
        padding-left: calc-rem(8);

        .matrix__button {
          margin-top: calc-rem(8) !important;
        }
      }
      &.--tall {
        height: calc-rem(128);
      }

      &.--category,
      &.--strategy {
        height: calc-rem(48);
      }

      .matrix__strategy-text {
        height: calc-rem(92);
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc-rem(12);
      }

      .matrix__strategy-more {
        cursor: pointer;
        font-size: calc-rem(12);
      }
    }
  }
}

.environment-matrix {
  .matrix__matrix-td:not(.--category) {
    height: calc-rem(128);
    max-height: calc-rem(128);
  }

  .matrix__matrix-td.--text {
    height: auto;
  }

  .matrix__strategy-text p {
    width: 100%;
  }

  .matrix__strategy-text {
    padding-right: calc-rem(8);
    padding-left: calc-rem(8);
    height: calc-rem(92);
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: calc-rem(12);
  }

  .matrix__strategy-more {
    padding-right: calc-rem(8);
    padding-left: calc-rem(8);
    cursor: pointer;
    font-size: calc-rem(12);

    a {
      font-size: inherit;
    }
  }
}
</style>
