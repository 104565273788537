<script setup>
import { toRefs } from 'vue';
import { storeToRefs } from 'pinia';

// import sanitizeHtml from 'sanitize-html';

// Store imports
import { useUserStore } from '@/store/modules/user';

// Define props
const props = defineProps({
  listContent: Array
});

const { currentUser } = storeToRefs(useUserStore());

// Define emits

// Variables
const { listContent } = toRefs(props);

// Functions
// const sanitizer = (dirty) => {
//   return sanitizeHtml(dirty, {
//     allowedTags: [
//       'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
//       'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
//       'img',
//       'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
//     ],
//     disallowedTagsMode: 'discard',
//     allowedAttributes: {
//       a: ['href', 'name', 'target'],
//       // We don't currently allow img itself by default, but
//       // these attributes would make sense if we did.
//       img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
//       span: ['style']
//     },
//     // Lots of these won't come up by default because we don't allow them
//     selfClosing: ['img', 'br', 'hr'],
//     // URL schemes we permit
//     allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
//     allowedSchemesByTag: {},
//     allowedSchemesAppliedToAttributes: ['href', 'src'],
//     allowProtocolRelative: true,
//     enforceHtmlBoundary: false
//   });
// };
</script>

<template>
  <div class="ui-list-content">
    <ul class="ui-list-content__list mt-0 mb-3 ps-0 pt-5 pb-5">
      <li
        v-for="item in listContent"
        :key="item.id">
        <a :href="'#' + item.hash">
          {{ item.title }}
        </a>
      </li>
    </ul>
<!--    <div-->
<!--      class="ui-list-content__content"-->
<!--      v-for="(item, index) in listContent"-->
<!--      :key="item.id"-->
<!--      :id="item.hash">-->
<!--      <h3>-->
<!--        {{ index + 1 }}. {{ item.title }}-->
<!--      </h3>-->
<!--      <div v-html="sanitizer(item.content)"></div>-->
<!--    </div>-->
    <div
      v-if="currentUser.language === 'nl'"
      class="ui-list-content__content">
      <div>
        <h3><a id="inleiding">1. Inleiding</a></h3>
        <p>Hieronder vind je de belangrijkste informatie die je nodig hebt om te kunnen werken met SOM SET. De nadruk ligt hierbij op het maken van de stakeholder analyse (hoofdstuk 2) en de issue analyse (hoofdstuk 3). In hoofdstuk 4 behandelen we het koppelen van stakeholders en issues, en de rapportages die hieruit voortkomen. Tenslotte gaan we in hoofdstuk 5 (en daarna) in op overige functionaliteiten van SOM SET. Mocht je vragen hebben, &lsquo;bugs&rsquo; tegenkomen of andere punten hebben die je aan ons wil doorgeven dan kan dit in het linker menu door een melding te registreren via de &lsquo;Help &amp; Support&rsquo; functionaliteit. </p>
        <h3><a id="stakeholders">2. Stakeholders</a></h3>
        <p>Hieronder vind je per onderdeel van de stakeholderanalyse een toelichting.</p>
        <h4>2.1 Naam</h4>
        <p>Vul hier de naam in van de stakeholder. Iedere naam is mogelijk maar stakeholders verschijnen vervolgens in alfabetische volgorde in de stakeholderlijst.</p>
        <p>2.2 Categorie</p>
        <p>Vul hier de categorie in. Als je nog niet eerder stakeholders hebt toegevoegd, en aan een categorie hebt toebedeeld, dien je de categorie zelf te typen in het tekstblok. Als je al eerder stakeholders hebt toegevoegd en deze een categorie hebt gegeven, zal dit als optie naar voren komen. Voor categorie&euml;n kun je aan de volgende aspecten denken:</p>
        <ul>
          <li>Overheid. Hier vallen alle diensten over van zowel lokale als rijksoverheid.</li>
          <li>Publieke sectorbedrijven. Dit zijn de bedrijven die vroeger onderdeel van de staat waren maar inmiddels zijn geprivatiseerd. Vaak is een groot deel van de aandelen nog in handen van de staat.</li>
          <li>Maatschappelijke organisaties.&nbsp;Hier gaat het om organisaties met een duidelijk maatschappelijk doel. Ze hebben geen winstoogmerk en zijn vaak georganiseerd als stichting. Deze organisaties staan ook wel bekend als Non-Governmental Organizations (NGO&rsquo;s). Voorbeelden zijn: Greenpeace, Oxfam, Plan etc.</li>
          <li>Bedrijf(sorganisatie).&nbsp;Dit zijn de bedrijven (bv&rsquo;s en nv&rsquo;s) maar ook de koepelorganisaties die hier bij horen.</li>
          <li>Individuen en belangengroepen. In veel projecten krijg je te maken met individuele personen die een grote impact hebben op het project zonder dat ze per se lid zijn van een groep. Soms heb je ook personen die zich organiseren in een groep, met meer of minder structuur. Dit zijn vaak organisaties die zich inzetten voor &eacute;&eacute;n issue of &eacute;&eacute;n gebied. Het gaat hier bijvoorbeeld om bewonersorganisatie.</li>
          <li>Kennisinstellingen. Er zijn veel organisaties die gericht zijn op onderzoek. Dit zijn natuurlijk de universiteiten en hogescholen, maar ook organisaties zoals TNO.</li>
          <li>Politiek(e) partijen.&nbsp;De politiek kan van grote invloed zijn op projecten. Je kan daarom politieke partijen en andere politieke organen in deze categorie kwijt.</li>
          <li>Interne stakeholders. Ook andere onderdelen van je eigen organisatie kunnen stakeholder zijn.</li>
        </ul>
        <h4>2.3. Beschrijving</h4>
        <p>Bij de beschrijving van de stakeholder geef je algemene informatie over de stakeholder. Denk hierbij aan de volgende begrippen:</p>
        <ul>
          <li>type organisatie (publiek/privaat)</li>
          <li>rechtsvorm</li>
          <li>organisatiestructuur</li>
          <li>grootte (aantal leden)</li>
          <li>lokalisatie (landelijk/regionaal)</li>
          <li>doelen/missie</li>
          <li>imago</li>
          <li>financiering</li>
          <li>bijzondere connecties</li>
          <li>omschrijving van leden/aanhangers/achterban</li>
        </ul>
        <h4>2.4. Context en Historie</h4>
        <p>Hier omschrijf je de context waarin de stakeholder wordt betrokken bij het project en de historie die jouw organisatie heeft met de stakeholder. Denk hierbij aan de volgende vragen:</p>
        <ul>
          <li>Is er met de stakeholder eerder contact geweest over dit project?</li>
          <li>Is deze stakeholder op een andere manier bij het project betrokken geweest?</li>
          <li>Zijn er langlopende afspraken met de stakeholder?</li>
          <li>Zijn er convenanten of samenwerkingsovereenkomsten met deze stakeholder?</li>
        </ul>
        <p>Hier kan je ook bijhouden wat je afgelopen periode hebt afgesproken met de stakeholder. </p>
        <h4>2.5. Issues</h4>
        <p>In het drop down menu van issues, kun je deze koppelen aan de desbetreffende stakeholder. Hiervoor moeten de issues wel eerst aangemaakt worden. Zie hiervoor hoofdstuk 3. Via de zoekbalk kun je eenvoudig de issues vinden die voor de stakeholder relevant zijn. Met het blauwe vinkje kun je issues (de)selecteren. Via het kruisje rechts van de zoekbalk kun je het drop down menu weer sluiten.</p>
        <h4>2.6. Spanning</h4>
        <p>Je wilt graag weten hoe groot de spanning is bij een stakeholder bij de huidige staat van het project. Daarvoor gebruiken we &lsquo;stoplichtkleuren&rsquo;. De vraag die je bij deze beoordeling kan stellen is: zou de stakeholder op dit moment proberen de geplande verandering tegen te gaan? De beoordeling is ook gebaseerd op alle andere onderdelen van de analyse. </p>
        <ul>
          <li>Groen (positief) = niets aan de hand</li>
          <li>Geel (aandacht) = wij houden vinger aan de pols</li>
          <li>Oranje (waarschuwing) = wij vinden het spannend, maar oplossing mogelijk</li>
          <li>Rood (kritiek) = wij vinden het heel spannend, op deze manier komt het tot een confrontatie</li>
          <li>Grijs = nog onduidelijk</li>
        </ul>
        <h4>2.7. Belang</h4>
        <p>Vervolgens geef je een algemene beoordeling van het belang van de desbetreffende stakeholder. Hierbij geldt: hoe donkerder blauw de kleur, hoe groter het belang. In het tekstveld kun je onder belang een toelichting geven van je keuze. </p>
        <h4>2.8. NAW-Gegevens</h4>
        <p>Vul hier de gegevens in die nodig zijn om contact op te nemen met de stakeholder. </p>
        <h4>2.9 Kenmerken</h4>
        <h5>2.9.1. Stadium van emotionele verwerking</h5>
        <p>Voor het bepalen van de emotie van een stakeholder maken we gebruik van het model van K&uuml;bler-Ross. Onderstaande schema, ontwikkeld voor rouwverwerking, kan ook worden toegepast bij SOM-projecten. Als een stakeholder namelijk nieuws hoort wat diens belang schaadt, moet hij door deze verschillende fases heen. </p>
        <p><img title="" src="/manual/image1.png" alt="stadia emotie" />&nbsp;</p>
        <p>VIJF FASEN</p>
        <p>Hoewel iedereen op een eigen, persoonlijke manier aan rouwverwerking doet, is er volgens K&uuml;bler-Ross wel een vast patroon te onderscheiden. In haar omgang met terminale pati&euml;nten, ontdekte zij dat deze rouwverwerking vrijwel bij iedereen op dezelfde manier verloopt. Daardoor is de theorie zeer goed van toepassing bij traumatische ervaringen zoals verlies van een dierbare, grote tegenslagen of teleurstellingen en ontslag situaties. In eerste instantie krijgt de persoon na het horen van het slechte nieuws een schok. Vanuit deze schok treedt al snel de eerste fase in werking:</p>
        <p>Fase 1 &ndash; ontkenning</p>
        <p>In eerste instantie reageren mensen met ongeloof op een slecht bericht. Dit algemene afweermechanisme werkt als buffer na een onverwacht schokkend bericht en geeft de persoon kans om tot zichzelf te komen. Vervolgens zal zij geleidelijk herstellen van de schok. Het is daarbij belangrijk dat zij zijn gevoelens kan uiten bij bijvoorbeeld een vertrouwenspersoon. Aan het einde van deze fase gaat de persoon op zoek naar feiten, de waarheid en eventuele schuldigen.</p>
        <p>Fase 2 &ndash; woede</p>
        <p>Wanneer men niet meer langer kan ontkennen, ontstaan er gevoelens van woede, ergernis, jaloezie en wrok. De woede en het onvermogen worden vooral geprojecteerd op de omgeving. De schuld wordt buiten zichzelf gezocht en bij collega&rsquo;s, medewerkers en adviseurs gelegd. Soms richt de woede zich op de brenger van het slechte nieuws.</p>
        <p>Fase 3 &ndash; onderhandelen</p>
        <p>In deze fase probeert men op allerlei manieren onder de verschrikkelijke werkelijkheid uit te komen. Er wordt in deze fase onderhandeld. Door jezelf doelen te stellen, wordt slecht nieuws verzacht. Met de werkomgeving kan in deze fase een moeizame onderhandeling op gang komen betreffende werkafspraken en het doen van beloftes. Bij (dreigend) ontslag kan men in deze fase naar de rechter stappen als vorm van tegenaanval.</p>
        <p>Fase 4 &ndash; depressie</p>
        <p>De waarheid dringt in deze fase steeds meer door en de persoon voelt zich machteloos en niet begrepen. Daardoor gaat deze zich teruggetrokken gedragen en sluit zich af van communicatie. De telefoon wordt niet meer opgenomen en mailtjes worden niet beantwoord. Er is in deze fase kans op een vlucht in alcohol en drugs, zoals pijnstillers, kalmeringstabletten en slaapmiddelen.</p>
        <p>Fase 5 &ndash; aanvaarding</p>
        <p>Als de persoon zich bewust is van het feit dat zij geen hoop meer hoeft te koesteren, kan zij het slechte nieuws aanvaarden. Zij kan bijkomen van de vorige fasen en haar verdriet accepteren. Zij zal na verloop van tijd weer zin hebben om dingen op te pakken en plannen te maken.</p>
        <p>Overigens kan ook gebruik gemaakt worden van een eenvoudigere indeling, van boven naar beneden in de drop-down in SOM SET:</p>
        <ul>
          <li>Geen emotie</li>
          <li>Lage emotie</li>
          <li>Gemiddelde emotie</li>
          <li>Hoge emotie</li>
        </ul>
        <p>Beide onderverdelingen zijn te kiezen in het drop down menu. Bepaal vooraf met elkaar welke onderverdeling je binnen het project aanhoudt.</p>
        <h5>2.9.2. Macht&nbsp;</h5>
        <p>Er zijn verschillende vormen van macht. Het kan voorkomen dat een stakeholder weinig macht heeft. Als je vindt dat een stakeholder meerdere vormen van macht heeft, kies je de meest dominante en geef je in de toelichting aan welke vormen nog meer van toepassing zijn. In SOM SET onderscheiden we op hoofdlijnen de volgende vier categorie&euml;n machtsbronnen, steeds beredeneerd vanuit stakeholders:</p>
        <ul>
          <li>Persoonsmacht. Dit gaat over wie je bent. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Fysieke of geweldsmacht.</li>
          <li>Deskundigheidsmacht.</li>
          <li>Intellectuele macht.<br /> </li>
        </ul>
        <ul>
          <li>Positiemacht. Dit gaat over de positie die je bekleedt. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Bestraffingsmacht.</li>
          <li>Hi&euml;rarchische macht.</li>
          <li>Beloningsmacht.<br /> </li>
        </ul>
        <ul>
          <li>Connectiemacht. Dit gaat over de connecties die je hebt. Deze machtsbron is voornamelijk opgewaardeerd door de inzet van sociale media. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Informatiemacht.</li>
          <li>Zichtbaarheidsmacht.</li>
          <li>Connectiemacht. </li>
        </ul>
        <ul>
          <li>BATNA-macht. Dit gaat over de aanwezigheid van een alternatief. Met een alternatief achter de hand kun je een gesprek over een oplossing maken of breken. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Persoonlijk BATNA.</li>
          <li>Ambtshalve alternatief.</li>
        </ul>
        <p><img title="" src="/manual/image3.png" alt="" /></p>
        <h5>2.9.3. Urgentie</h5>
        <p>De urgentie is gekoppeld aan de kans of risico dat zich in een bepaald tijdsbestek iets voordoet wat impact heeft op het belang van de stakeholder. Hoe zwaarder die impact op korte termijn weegt hoe hoger de urgentie. Een hoge urgentie betekent dat een stakeholder vroeger in het proces betrokken moet worden.</p>
        <h5>2.9.4. Lijn</h5>
        <p>Hier gaat het om de lijn die de stakeholder aanhoudt. Is dit een (zeer) fundamentele lijn, zijn ze redelijk fundamenteel, beperkt fundamenteel of niet fundamenteel. Met (zeer) fundamenteel wordt bedoeld dat ze een sterke mening hebben en daar niet van af willen wijken. Als ze redelijk fundamenteel zijn, staan ze open voor goede argumenten maar kijken ze de kat uit de boom. Als stakeholders een &lsquo;beperkte fundamentele&rsquo; lijn aanhouden, staan ze open voor onderhandelingen en het vinden van consensus. Tot slot kan een stakeholder helemaal niet fundamenteel zijn.</p>
        <h5>2.9.5. Legitimiteit van een stakeholder</h5>
        <p>Wat doe je als een stakeholder veel aandacht trekt, maar eigenlijk geen (zwaarwegend) belang heeft? En wanneer andere stakeholders voor datzelfde issue wel eenzelfde maar dan zwaarwegend belang hebben? Dan lijkt de legitimiteit van de stakeholder beperkt.</p>
        <p>De vraag is hier of het legitiem is dat de stakeholder bij dit project betrokken wordt. Je kan de keuze maken uit zeer legitiem, legitiem, redelijk legitiem, beperkt legitiem en niet legitiem. Baseer je keuze op de volgende punten:</p>
        <ul>
          <li>het belang van de stakeholder bij het project</li>
          <li>de grootte van de achterban die hij vertegenwoordigt</li>
          <li>de macht die de stakeholder heeft</li>
          <li>de relevantie van de stakeholder voor de omgeving kwesties (&lsquo;issues&rsquo;) waarover hij wil meedenken, meedoen of meebeslissen</li>
        </ul>
        <h5>2.9.6. BATNA</h5>
        <p>BATNA staat voor Best Alternative To a Negotiated Agreement. Als je het gedrag van een stakeholder wil voorspellen is het belangrijk dat je weet wat het beste alternatief is voor stakeholders als je er niet samen uitkomt. Er zijn natuurlijk altijd alternatieven naast het komen tot een overeenstemming maar soms heb je een goed alternatief en soms niet. Doordat je van jezelf weet wat je BATNA is en dat van de anderen weet, voorkom je dat je met een uitkomst eindigt die je niet wil. </p>
        <p>In SOM SET onderscheiden we de volgende vormen van een BATNA:</p>
        <ul>
          <li>Sterk BATNA</li>
          <li>Redelijk BATNA</li>
          <li>Zwak BATNA</li>
          <li>Fantoom BATNA: dit is een onrealistisch BATNA dat door de partij zelf als zeer realistisch wordt gezien. Voorbeeld: de rechter zal mij zeker in het gelijk stellen; een andere uitkomst is namelijk niet rechtvaardig! Deze tweedehands Billy boekenkast is zo bijzonder; een andere koper zal er zeker wel de aankoopprijs voor betalen!&nbsp;</li>
        </ul>
        <p>Dan moet wel duidelijk zijn wat het BATNA van deze partij(en) is. Het achterhalen van een BATNA is lastig. Immers, organisaties met een slecht BATNA lopen er niet mee te koop. Organisaties met een goed BATNA kunnen ervoor kiezen dat voor zich te houden of het in te zetten in de onderhandeling. In ieder geval is het belangrijk nader toe te lichten wat we bedoelen met een fantoom BATNA. De ervaring leert dat mensen hun BATNA overschatten. Dat kan tot strategische fouten in een onderhandeling leiden. Het is dus belangrijk het BATNA van betrokken partijen te kennen en te toetsen. Dit is niet eenvoudig. Wat als blijkt dat een partij het alternatief overschat. Dit noemen we een fantoom BATNA: het alternatief bleek geen re&euml;el alternatief, maar was wel bepalend voor het gedrag van de stakeholder aan de onderhandelingstafel. </p>
        <hr />
        <h5>2.9.7. Mate van vertrouwen </h5>
        <p>Voor de toepassing van SOM is een ladder met niveaus van vertrouwen ontwikkeld (zie onderstaand figuur). Ieder niveau van vertrouwen hangt samen met een of meer bronnen van vertrouwen. In SOM SET onderscheiden we de volgende niveaus van vertrouwen: </p>
        <ul>
          <li>Blind vertrouwen</li>
          <li>Afgemeten vertrouwen</li>
          <li>Gezond wantrouwen</li>
          <li>Geen vertrouwen</li>
          <li>Diepgeworteld wantrouwen</li>
        </ul>
        <p>Door de bovenstaande categorie&euml;n (of niveaus) in SOM SET aan te klikken verschijnt hieronder in cursief een nadere verklaring van wat het niveau van vertrouwen inhoudt. In het tekstblok kan je je keuze nader toelichten. </p>
        <p><img title="" src="/manual/image2.png" alt="" /></p>
        <p>2.10. Strategie</p>
        <h5>2.10.1. Participatieniveau</h5>
        <p>De strategie begint met de keuze voor een participatieniveau. Dit doe je aan de hand van de Participatieladder.</p>
        <p>Je kan stakeholders op verschillende manieren betrekken. </p>
        <ul>
          <li>Als je informeert betekent dit dat je eenzijdig informatie verstrekt. Je deelt de voortgang maar de stakeholder heeft hier geen inbreng. </li>
          <li>Bij communiceren&nbsp;wordt wel met de stakeholder gesproken. Jij behoudt echter het recht om er wel of niet iets mee te doen. </li>
          <li>Bij het (ad-hoc) betrekken&nbsp;van een stakeholder geef je aan dat een stakeholder actief wordt betrokken bij het vinden van een oplossing voor het deel van het project waar ze belang bij hebben. </li>
          <li>Als een stakeholder participeert&nbsp;wordt het hele project samen met de stakeholder gezocht naar een oplossing. </li>
          <li>Als het belang van de stakeholder zo groot is dat je doorgang van een project onlosmakelijk wilt of moet verbinden aan het bereiken van consensus vraagt dit veel van het proces. </li>
          <li>De hoogste trede van de participatieladder is empowerment. In dat geval wordt de stakeholder mede-eigenaar van het project. Ze mogen meebeslissen, maar er wordt ook van ze verwacht dat ze zich inzetten om het project te laten slagen. </li>
          <li>Tot slot kan je ervoor kiezen om het participatieniveau van de stakeholder op onbekend te zetten. </li>
        </ul>
        <p>In de toelichting voor dit onderdeel leg je niet alleen uit waarom je voor dit participatieniveau hebt gekozen, maar ook wat dit betekent voor de strategie. Vragen die je daarvoor aan jezelf kunt stellen zijn:</p>
        <ul>
          <li>Wanneer ga je de stakeholder betrekken?</li>
          <li>Hoe ga je dat doen?</li>
          <li>Op welke onderdelen?</li>
          <li>Wat is het doel van de relatie?</li>
        </ul>
        <h5>2.10.2. Vragen en discussiepunten</h5>
        <p>Na het maken van een analyse blijven er altijd vragen over. Informatie die je niet hebt kunnen vinden op internet of die uit voorgaande gesprekken niet is gebleken. Bovendien zitten er waarschijnlijk veel aannames in je analyses. Deze gaten en aannames kun je hier opschrijven. Deze dienen als input voor het ANNA-gesprek (Altijd Nagaan Nooit Aannemen). De vragen die je hier opschrijft kunnen apart worden gedownload als de ANNA-lijst, als je het download menu uitklapt. </p>
        <h4>2.11. Locatie</h4>
        <p>Helemaal onderin staat een kaart waarop je de locatie van de stakeholder kunt aangeven. Dit doe je door op &ldquo;locatie bewerken&rdquo; te klikken. Vervolgens krijg je rechts een verticale balk waar verschillende iconen staan afgebeeld. </p>
        <p><img title="" src="/manual/image4.png" alt="" /></p>
        <p>Van boven naar beneden aan de rechterkant betekenen ze het volgende:</p>
        <ul>
          <li>De locatie druppel, hiermee kun je een puntlocatie aangeven, zoals een huis of bedrijf. Het is ook mogelijk meerdere druppels te plaatsen als deze stakeholder bijvoorbeeld meerdere vestigingen heeft die binnen het projectgebied liggen.</li>
          <li>De lijn, hiermee kun je een lijn tekenen. Het is mogelijk hoeken hierin aan te brengen. Je voltooit de lijn door op het laatst geplaatste punt te klikken.</li>
          <li>Het vierkant, hiermee kun je een vierhoek tekenen. Bijvoorbeeld om de locatie van een weiland aan te geven.</li>
          <li>Multi-hoek, hiermee kun je vrij een hoekige vorm tekenen. Je voltooid het figuur door op het eerste geplaatste punt te klikken. Dit kan bijvoorbeeld gebruikt worden om locaties aan te geven die niet met een cirkel of vierhoek in te tekenen zijn.</li>
          <li>Cirkel met kleine cirkel rechtsboven, hiermee kun je een cirkel tekenen en de diameter van de cirkel instellen. Daarmee kun je kiezen of je een grotere of kleinere cirkel wil.</li>
          <li>Cirkel, hiermee kun je een vooraf ingesteld formaat cirkel plaatsen.</li>
          <li>De pijlen, door hierop te klikken stop je met wat je aan het tekenen was (als de figuur nog niet voltooid is, wordt deze verwijderd), en kun je de kaart waarop je aan het werk bent verslepen. Zo kun je, zoals in dit voorbeeld, naar een ander deel van Almere gaan als een stakeholder hier ook nog bijvoorbeeld een bedrijfsvestiging heeft.</li>
          <li>De schaar, hiermee kun je binnen een getekende vorm een deel wegknippen. Dit kan handig zijn als je bijvoorbeeld de locatie van meerdere weilanden als een groot blok hebt aangegeven, terwijl een van de weilanden binnen dat blok niet van de desbetreffende stakeholder is.</li>
          <li>De gum, hiermee kun je eenvoudig een getekende vorm of punt verwijderen.</li>
          <li>De draaiende pijl, door hierop te klikken kun je meervormige figuren kantelen. In plaats van een horizontaal geplaatste rechthoek kun je bijvoorbeeld een schuingeplaatste rechthoek cre&euml;ren.</li>
        </ul>
        <p>Als alle locaties zijn ingetekend, klik je op de blauwe &ldquo;locatie bewaren&rdquo; knop, en kun je de ingetekende stakeholder terugvinden in de kaart (direct onder het dashboard icoon links). </p>
        <h3><a id="issues">3. Issue</a></h3>
        <h4>3.1. Naam en Categorie </h4>
        <p>Kies een herkenbare naam voor het issue. In de drop-down &lsquo;Categorie&rsquo; kun je aangeven welke andere categorie&euml;n issues met het desbetreffende issue verband houden. Bij het bewerken van een issue (en stakeholder) kan je het veld &lsquo;categorie&rsquo; zelf invullen. Dat is een pul down en editor in &eacute;&eacute;n. Je kan dus:</p>
        <ol start="1">
          <li>Een nieuw issue maken</li>
          <li>Daar zelf een categorie invullen</li>
          <li>Deze categorie vervolgens ook gebruiken voor andere (nieuwe) issues</li>
        </ol>
        <p>3.2. Spanning Issue</p>
        <p>Hier dient wederom een keuze gemaakt te worden welke stoplicht kleur (het beste) toegekend kan worden aan het desbetreffende issue. Net als voor de stakeholders geven we een algemene beoordeling van spanning voor elk issue. Hierbij gaat het om de vraag of, mocht het project nu doorgang vinden, stakeholders de huidige beoogde oplossing accepteren. Dit is reeds toegelicht in hoofdstuk 2.6. </p>
        <h4>3.3. Beschrijving</h4>
        <p>Vul hier een beschrijving in van het issue. Probeer deze zo nauwkeurig en scherp mogelijk te formuleren. Waar gaat het over en waar zit de spanning? </p>
        <h4>3.4. Stakeholders</h4>
        <p>In de drop-down &lsquo;Selecteer de stakeholders&rsquo; kun je aangeven welke stakeholders met dit issue en/of elkaar verband houden. Je kan jezelf hierbij de volgende vraag stellen: kan dit issue worden opgelost zonder een andere stakeholder? Zo niet, welke stakeholders heb je hiervoor nodig? Maar let op, je moet hiervoor al wel een of meerdere stakeholders hebben aangemaakt.</p>
        <h4>3.5. Kenmerken</h4>
        <h5>3.5.1. Begrijpelijkheid van het issue</h5>
        <p>Beargumenteer of het issue voor de meeste mensen een (relatief) begrijpelijk probleem is, of dat deze zeer complex en moeilijk te begrijpen is, waarvoor veel uitleg nodig is. Bij de begrijpelijkheid van het issue is de classificatie ingewikkeld. Als een issue heel begrijpelijk is, vindt iedereen er mogelijk iets van en vraagt dat aandacht in de strategie. Als het issue niet begrijpelijk is, vraagt het ook aandacht: het moet uitgelegd worden! Het transparantieprincipe vraagt immers vroegtijdige duiding van de mogelijke impact van issues op de belangen van stakeholders, ook als het ingewikkelde issues zijn.</p>
        <h5>3.5.2. Zichtbaarheid voor het publiek</h5>
        <p>Naarmate het issue zichtbaarder is, raken stakeholders ook makkelijker betrokken bij het issue. Die emotionele betrokkenheid heeft impact op de te volgen strategie.</p>
        <p>Beargumenteer of het issue een zichtbaar probleem is, dat in de letterlijke betekenis &lsquo;makkelijk te zien&rsquo; is voor het publiek. Als het issue bijvoorbeeld gaat over een te bouwen kantoorflat, dan is dit voor de bewoners in de omgeving een zichtbaar probleem. Beargumenteer ook of het issue tastbaar is voor het publiek, dat men het als het ware kan aanraken. Als bijvoorbeeld de oogst van een boer mislukt vanwege vervuilde grond en de groenten rotten snel, dan is dat een tastbaar probleem. </p>
        <h5>3.5.3. Mediagevoeligheid</h5>
        <p>De mediagevoeligheid hangt samen met de zichtbaarheid voor het publiek. Hoe groot is de kans dat de media dit oppakken en welk frame geven zij eraan?</p>
        <p>Beargumenteer of het issue (in potentie) een mediagevoelig onderwerp is. Is in het verleden gebleken dat de media vaak over dit onderwerp publiceerden? Wat was de toon van de berichtgeving? Was de doelgroep van die berichten breed, of was het voor een select publiek? </p>
        <h5>3.5.4. Ontwikkelstadium</h5>
        <p>Beargumenteer in welke fase het issue zich bevindt. Wordt de kwestie door velen als probleem erkend? Of zijn besluitvormers al in de fase van het debat over mogelijke oplossingsrichtingen? Wordt er al beleid geformuleerd? Als er al besluiten zijn genomen, worden maatregelen getroffen? De laatste fase van het project is de beheerfase (beleid en beheer). Kortom: is het issue in een pril of in een gevorderd stadium?</p>
        <p>Er kan gekozen worden uit de volgende categorie&euml;n:</p>
        <ul>
          <li>Erkenning. In de fase van erkenning vindt er maatschappelijk veel discussie over het issue plaats. In de erkenningsfase, nog voor de beleidsformulering aan de orde is, is een virtueel take-offpunt benoemd. Dit punt markeert het moment waarop issues zodanig serieus worden genomen dat er een dossier wordt aangemaakt.</li>
          <li>Beleidsformulering. In de beleidsformuleringsfase wordt al nagedacht over oplossingen. Zo kan worden bepaald aan welke issues tijd moet worden besteed voor nadere analyse en welke even kunnen wachten. En ook welke stakeholders (die belang hebben bij dat issue) benaderd moeten worden.</li>
          <li>Oplossing. In de oplossingsfase wordt al effectief werk gemaakt van de oplossing voor het issue. E&eacute;n of meerdere stakeholders zijn actief bezig met de oplossing.</li>
          <li>Beleid en beheer. De fase van beleid en beheer beslaat in bredere zin de periode van uitvoer tot nazorg. Los van het oplossen van het issue is er mogelijk een beheerplan nodig voor de langere termijn.</li>
        </ul>
        <p>Wanneer een van de bovenstaande categorie&euml;n wordt geselecteerd in SOM SET verschijnt hieronder in cursief een nadere verklaring van wat deze categorie inhoudt. </p>
        <h4>3.5.5. Complexiteit oplossing </h4>
        <p>De complexiteit van de oplossing heeft ook invloed op de strategie. Sommige issues zijn gemakkelijk op te lossen, terwijl anderen een samenhang hebben met meerdere andere issues of er bestaat geen oplossing voor het issue. In die laatste gevallen is de complexiteit hoog of extreem. Complexiteit duiden we aan met de volgende categorie&euml;n: </p>
        <ul>
          <li>Extreem</li>
          <li>Hoog</li>
          <li>Midden</li>
          <li>Laag</li>
          <li>Niet</li>
        </ul>
        <p>3.5.6. Onze redeneerlijn </p>
        <p>Onze redeneerlijn ten aanzien van het issue weergeven we in een tekstblok. De redeneerlijn geeft aan hoe jij tegen dit issue aankijkt. Wat is de lijn die we aanhouden als we stakeholders spreken over dit issue? De redeneerlijn wil je zo strak mogelijk neerzetten, zodat het projectteam (extern) aan de hand van een gedeelde lijn communiceert. Het gaat hierbij dus om onze eigen redeneerlijn, en niet om die van de stakeholder ten aanzien van het issue in kwestie. </p>
        <p>3.6. Strategie</p>
        <p>Hier beschrijf je de strategie ten aanzien van dit issue. Geef hier aan met wie moet worden gesproken, waarover en op welke termijn. Ook verdere onderzoeken kunnen een onderdeel van de strategie zijn. Probeer dit zo ver mogelijk te concretiseren.</p>
        <h4>3.7. Vragen en discussiepunten</h4>
        <p>Schrijf hier alle vragen op die nog openstaan na het maken van de analyse. </p>
        <h4>3.8. Locatie</h4>
        <p>Hier kunnen (eventueel) dezelfde stappen doorlopen worden als onder paragraaf 2.11.</p>
        <hr />
        <h3><a id="koppelenissuesenstakeholders">4. Koppelen van issues en stakeholders</a></h3>
        <p>Onder de naam van het issue van de stakeholder kan je bij het bewerken ervan, zoals eerder vermeld, stakeholders koppelen (3.4.) of issues koppelen (2.5.). Na het opslaan krijg je in het overzicht van desbetreffende issue of stakeholder een lijst te zien met gekoppelde stakeholders (bij het issue) of gekoppelde issues (bij de stakeholder). </p>
        <p>Deze informatie kan door te klikken op issue-stakeholder (het linker blok onder drop-down) informatie worden aangepast. Hieronder kunnen het belang en de spanning aangepast worden, en wordt weergeven welke stakeholders en/of issues er gekoppeld zijn aan het desbetreffende issue of stakeholder. </p>
        <h4>4.1. Beschrijving Belang en Spanning</h4>
        <p>Allereerst geef je een beoordeling van het belang. Hoe donkerder de kleur, hoe groter het belang van deze stakeholder (bij het issue). In het tekstveld&nbsp;kun je onder belang een toelichting geven van je keuze. </p>
        <p>Hetzelfde geldt voor spanning. Ook dit is eerder toegelicht onder de helpfunctionaliteit in hoofdstuk 2.6. </p>
        <p>Het verschil tussen belang en standpunt</p>
        <p>Een belang is iets dat iemand raakt, doordat zijn voordeel of voorspoed ermee gemoeid is. In vergelijking tot een standpunt, verandert een belang minder snel. Een belang verandert niet doordat iemand je met zijn argumenten van gedachten doet veranderen.</p>
        <p>Naast het belang van &lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo; voor een ouder als stakeholder in het bovengenoemde voorbeeld, kan een belang zijn: het voortbestaan van een familiebedrijf (een ondernemer), zekerheid van inkomen (een werknemer) of het behoud van de bossen (een milieuorganisatie).</p>
        <p>Een standpunt is een manier om tegen iets aan te kijken, een mening die je bent toegediend op een bepaald moment. In tegenstelling tot een belang, kan een standpunt relatief snel veranderen, bijvoorbeeld omdat een persoon een andere kijk krijgt op de kwestie door argumenten die worden aangedragen.</p>
        <p>Vaak zijn stakeholders zich bewust van hun standpunt en kunnen zij dit zonder moeite formuleren. Datzelfde geldt niet altijd voor hun belang. Het kan nodig zijn dat de omgevingsmanager, die namens de projectorganisatie met de stakeholder in dialoog treedt, de stakeholder helpt bij het achterhalen of formuleren van zijn belang.</p>
        <p>Bij een eerste invulling van het dossier op basis van gevonden documentatie, geeft de analist een eigen interpretatie van het belang van de stakeholder. Deze interpretatie dient altijd getoetst te worden bij de stakeholder zelf. Op basis van de gesprekken met de stakeholder, kan het nodig zijn dat de formulering van het belang in het dossier wordt aangepast.</p>
        <p>In het volgende fragment wordt het verschil tussen een standpunt en een belang ge&iuml;llustreerd:</p>
        <p>A: Ik ben tegen kernenergie (standpunt)</p>
        <p>B: Waarom ben je tegen kernenergie?</p>
        <p>A: Het is slecht voor het milieu (standpunt)</p>
        <p>B: Waarom hecht jij belang aan het milieu?</p>
        <p>A: Ik wil een gezonde leefomgeving achterlaten voor mijn kinderen (belang)</p>
        <p>B: En als er een schone vorm van kernenergie wordt uitgevonden?</p>
        <p>A: Dan ben ik niet meer tegen kernenergie (standpunt)</p>
        <p>Uit het fragment blijkt dat het standpunt (&lsquo;Ik ben tegen kernenergie&rsquo;) veranderlijk is, in tegenstelling tot het achterliggende belang (&lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo;). Zolang dat belang gediend wordt, zal persoon A hoogstwaarschijnlijk instemmen met het voorstel.</p>
        <h4>4.2. Beschrijving standpunt</h4>
        <p>Hier vul je het standpunt in dat de stakeholder heeft ingenomen op dit issue. Zie hieronder een uitgebreide uitleg over het verschil tussen standpunt en belang.</p>
        <p>Het verschil tussen standpunt en belang</p>
        <p>Wat is het standpunt van deze stakeholder ten aanzien van (de issues van) het project? Een standpunt is een manier om tegen iets aan te kijken, een mening die je bent toegediend op een bepaald moment. In tegenstelling tot een belang, kan een standpunt relatief snel veranderen, bijvoorbeeld omdat een persoon een andere kijk krijgt op de kwestie door argumenten die worden aangedragen.</p>
        <p>Bij het formuleren van het standpunt van de stakeholder, is het belangrijk dat de omgevingsprofessional zich inleeft in een medewerker of lid van de stakeholder. Daarbij moet hij de volgende vragen in het achterhoofd houden:</p>
        <ul>
          <li>Hoe zou die medewerker of dat lid zijn of haar standpunt formuleren?</li>
          <li>Wat vindt hij/zij belangrijk? Hoe denkt hij/zij?</li>
          <li>Welke &lsquo;taal&rsquo; spreekt hij of zij?</li>
        </ul>
        <p>Een belang is iets dat iemand raakt, doordat zijn voordeel of voorspoed ermee gemoeid is. In vergelijking tot een standpunt, verandert een belang minder snel. Een belang verandert niet doordat iemand je met zijn argumenten van gedachten doet veranderen.</p>
        <p>Naast het belang van &lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo; voor een ouder als stakeholder in het bovengenoemde voorbeeld, kan een belang zijn: het voortbestaan van een familiebedrijf (een ondernemer), zekerheid van inkomen (een werknemer) of het behoud van de bossen (een milieuorganisatie).</p>
        <p>Vaak zijn stakeholders zich bewust van hun standpunt en kunnen zij dit zonder moeite formuleren. Datzelfde geldt niet altijd voor hun belang. Het kan nodig zijn dat de omgevingsmanager, die namens de projectorganisatie met de stakeholder in dialoog treedt, de stakeholder helpt bij het achterhalen of formuleren van zijn belang.</p>
        <p>Bij een eerste invulling van het dossier op basis van gevonden documentatie, geeft de analist een eigen interpretatie van het belang van de stakeholder. Deze interpretatie dient altijd getoetst te worden bij de stakeholder zelf. Op basis van de gesprekken met de stakeholder, kan het nodig zijn dat de formulering van het belang in het dossier wordt aangepast.</p>
        <p>In het volgende fragment wordt het verschil tussen een standpunt en een belang ge&iuml;llustreerd:</p>
        <p>A: Ik ben tegen kernenergie (standpunt)</p>
        <p>B: Waarom ben je tegen kernenergie?</p>
        <p>A: Het is slecht voor het milieu (standpunt)</p>
        <p>B: Waarom hecht jij belang aan het milieu?</p>
        <p>A: Ik wil een gezonde leefomgeving achterlaten voor mijn kinderen (belang)</p>
        <p>B: En als er een schone vorm van kernenergie wordt uitgevonden?</p>
        <p>A: Dan ben ik niet meer tegen kernenergie (standpunt)</p>
        <p>Uit het fragment blijkt dat het standpunt (&lsquo;Ik ben tegen kernenergie&rsquo;) veranderlijk is, in tegenstelling tot het achterliggende belang (&lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo;). Zolang dat belang gediend wordt, zal persoon A hoogstwaarschijnlijk instemmen met het voorstel.</p>
        <h4>4.3. BATNA</h4>
        <p>Onder paragraaf 2.9.6. is BATNA al toegelicht. </p>
        <h4>4.4. Redeneerlijn stakeholder</h4>
        <p>Het is ook belangrijk vooraf na te denken over de redeneerlijn van de andere partij. Bij dit onderdeel kan je die redeneerlijn uitschrijven. </p>
        <p>De redeneerlijn bevat de volgende elementen:</p>
        <ul>
          <li>Feitelijke beschrijving van issue in enkele regels</li>
          <li>Voorgestelde oplossing (zo ver mogelijk uitgewerkt)</li>
          <li>Argumenten voor deze oplossing</li>
          <li>Bekende argumenten tegen deze oplossing en de daarbij passende antwoorden.</li>
        </ul>
        <h4>4.5. Zienswijze</h4>
        <p>Een zienswijze is alleen van toepassing als er een juridische actie is ondernomen door de andere partij. Die kan je hier invullen.</p>
        <h4>4.6. Conclusie</h4>
        <p>Wat is de conclusie ten aanzien van bovenstaande punten. Vragen die je hierbij kan stellen zijn:</p>
        <ul>
          <li>Is het standpunt in lijn met het belang?</li>
          <li>Heeft de stakeholder door dat er wel/geen verschil is?</li>
          <li>Hoe sterk is het BATNA?</li>
          <li>Kent de stakeholder zijn eigen BATNA op dit issue?</li>
          <li>Hebben ze een duidelijke redeneerlijn?</li>
          <li>Is de juridische reactie te verklaren aan de hand van het belang of reageren ze op andere punten?</li>
        </ul>
        <h4>4.7. Strategie </h4>
        <p>Wat is de strategie voor deze stakeholder ten aanzien van dit specifieke issue?</p>
        <h3><a id="rapporateges">5. Rapportages</a></h3>
        <h4>5.1. Issuelijst</h4>
        <p>De issuelijst geeft een overzicht van alle issues in het project en de status van deze issues. De issuelijst is te downloaden als PDF en Word document onder het kopje &lsquo;Issues&rsquo; in de linker kolom in SOM SET. </p>
        <h4>5.2. Stakeholderlijst</h4>
        <p>De stakeholderlijst geeft een overzicht van alle stakeholders en de status van deze issues. De stakeholderlijst is te downloaden als PDF en Word document onder het kopje &lsquo;Stakeholders&rsquo; in de linker kolom in SOM SET. </p>
        <h4>5.3. Issuedossier</h4>
        <p>Het issuedossier geeft de belangrijkste informatie weer over het issue. Alle onderdelen van het issue zoals hierboven besproken, worden weergegeven in het issuedossier. Ook de koppeling met de stakeholders wordt weergegeven. Het issuedossier is te downloaden als pdf en word document. </p>
        <h4>5.4. Stakeholderdossier</h4>
        <p>Het stakeholderdossier geeft de belangrijkste informatie weer over de stakeholder. Alle onderdelen van de stakeholder zoals hierboven besproken, worden weergegeven in het stakeholderdossier. Ook de koppeling met de stakeholders wordt weergegeven. Het issuedossier is te downloaden als pdf en word document.</p>
        <h4>5.5. Belangenmatrix</h4>
        <p>De belangenmatrix is een overzicht van de belangen die de stakeholders hebben bij verschillende issues. Op de horizontale as staan de issues, op de verticale as de stakeholders. In blauwtinten is aangegeven hoe groot het belang is. De kleuren die hier worden weergegeven zijn dezelfde als die bij de stakeholder-issue informatie worden ingevuld (zie 4.1.). Bij het beoordelen van het belang, ga je uit van jouw inschatting van het belang van de ander. Het gaat er dus niet of of de stakeholder zelf zijn belang groot of klein vindt. Dit is natuurlijk wel interessante informatie en dit kan je vergelijken met de inschatting die je zelf maakt. Op basis van de inschatting van belang wordt ook het participatieniveau van de stakeholder ingevuld (zie 2.10.1.). </p>
        <p>De verschillende velden kunnen ook in de matrix aangepast worden. Als op een kleur geklikt wordt verschijnt een pop-up waar de kleur veranderd kan worden. Daarnaast kan ook het algehele belang of strategie aangepast worden door hierop te klikken.</p>
        <p>Door op het pijltje rechtsboven in de pop-up te klikken kunnen ook de achterliggende beschrijvingen, bijvoorbeeld van standpunt en belang worden aangepast. Let op! het is alleen mogelijk waarden te veranderen als eerst bij desbetreffende stakeholder en stakeholder-issue informatie iets in ingevuld. </p>
        <p>De belangenmatrix kan worden gedownload in PDF en Excel format. </p>
        <h4>5.6. Spanningsbarometer</h4>
        <p>De spanningsbarometer biedt een overzicht van de spanning die de stakeholders ervaren bij de verschillende issues. Op de horizontale as staan de issues en op de verticale as de stakeholders. In stoplichtkleuren wordt aangegeven hoe zij de spanning ervaren. De kleuren die hier worden weergegeven zijn wederom dezelfde als die bij de stakeholder-issue informatie worden ingevuld. Daarnaast worden stadium van emotionele verwerking (2.9.1.) en urgentie (2.9.3.) weergegeven.</p>
        <p>De verschillende velden kunnen ook in de matrix aangepast worden. Als er op een kleur wordt geklikt, verschijnt er een pop-up waar de kleur veranderd kan worden. Als er op de emotie of urgentie wordt geklikt, kan ook dit worden aangepast. In de spanningsbarometer is het ook mogelijk om een issue aan een specifieke stakeholder te koppelen. Dit doe je door spanning toe te kennen aan een specifieke stakeholder voor het issue dat je wil koppelen.</p>
        <p>Door op een veld te klikken kan je ook de onderliggende beschrijving (standpunt) aanpassen. In de pop-up kan dan een tekst getypt worden. </p>
        <p>De spanningsbarometer kan worden gedownload in PDF- en Excel format. </p>
        <h4>5.7. Omgevingsmonitor </h4>
        <p>De omgevingsmonitor geeft per stakeholder - per (gecategoriseerde) issue een overzicht van de status. Rechtsboven kan je kiezen welke categorie stakeholder je wilt bekijken. Per stakeholder en per issue worden de kleuren (belang/spanning) weergegeven maar ook de redeneerlijn van de stakeholder, het BATNA, onze redeneerlijn, de conclusie en strategie. </p>
        <p>Let op! de teksten in de omgevingsmonitor zijn de eerste paragrafen van de betreffende velden bij de stakeholder-issue informatie. Hou er bij het invullen van deze velden dus rekening mee dat de kern verwoord moet worden in de eerste alinea. Door te klikken op Lees meer kan ook de rest van de informatie gelezen worden. </p>
        <p>De omgevingsmonitor kan worden gedownload in PDF en Excel format. </p>
        <p>5.8. Voortgangsrapportage</p>
        <p>De voortgangsrapportage geeft de voortgang van het project weer, op basis van de spanning en het belang van stakeholders, aangevuld met het standpunt, participatieniveau en de opgegeven te volgen strategie. Wederom kan er worden gekozen om te selecteren op categorie stakeholder. Hiernaast kan je ook op het kalendericoon een bepaalde datum selecteren. SOM SET geeft vervolgens weer wat op dat moment in tijd per stakeholder de stand van zaken was met betrekking tot de kleur/de grootte van het belang, het belang zelf, de spanning, het standpunt en de strategie. Het geheel kan worden gedownload in PDF- en Excel format.</p>
        <p>Door verschillende momenten door de tijd heen met elkaar te vergelijken, kan je zien in hoeverre er (eventueel per stakeholder) vooruitgang is geboekt.</p>
        <h4>5.9. Stakeholder-issueanalyse</h4>
        <p>De stakeholder-issueanalyse wordt buiten SOM SET gemaakt. Het gaat om een overzicht van issues en de daarbij horende stakeholders. Omdat dit een belangrijke eerste stap is in de SOM-analyse kan deze worden ge&uuml;pload als afbeelding (JPG, PNG) of PDF, via de linker kolom: &lsquo;Rapportages&rsquo; &gt; &lsquo;Stakeholder-issueanalyse&rsquo;. Dit kan bijvoorbeeld een foto van een brown paper zijn of een online gemaakte mindmap. </p>
        <h4>5.10. Contacten</h4>
        <p>De laatste te downloaden rapportage betreft een overzicht van alle contacten, ofwel contactpersonen. Hierop zijn, indien ingevuld, zaken als adresgegevens, e-mailadressen of telefoonnummers te vinden. De contactenlijst kan worden gedownload in PDF en Excel format. </p>
        <h3><a id="zoeken">6. Zoeken</a></h3>
        <p>Als je rechtsboven een zoekopdracht uitvoert worden de zoekresultaten weergeven in SOM SET. Links worden alle hits weergeven, waarbij je aan de rechterkant van de pagina onderscheid kunt maken tussen &lsquo;type&rsquo; en &lsquo;projecten&rsquo;. Het &lsquo;type&rsquo; resultaten kunnen bijvoorbeeld bepaalde stakeholders of issues zijn, een contactpersoon, een issue-stakeholder combinatie et cetera. Daaronder kan je je zoekresultaten specificeren per &lsquo;project&rsquo;. Als je hier niets hebt aangekruist, zoekt SOM SET in alle projecten binnen de SOM SET omgeving. Maar je kunt er ook voor kiezen om binnen een of meerdere geselecteerde projecten te zoeken. </p>
        <p>Binnen elk van deze onderdelen komen de issues die het meest relevant zijn bovenaan te staan. Als je bijvoorbeeld zoekt op het woord &lsquo;gemeente&rsquo; zullen binnen de stakeholders eerst alle stakeholders komen die het woord gemeente in de naam hebben. Dus: &lsquo;Gemeente Amsterdam&rsquo; komt voor &lsquo;Amsterdam&rsquo; waar het woord gemeente alleen in de beschrijving staat. </p>
        <p>Als je zoekt terwijl je in een project aan het werken bent, zoekt SOM SET automatisch binnen dit project. Als je vanuit het Dashboard of de Help sectie zoekt zoek je automatisch over de projecten heen. Overigens kan je je zoekactie altijd aanpassen door vinkjes van projecten aan of uit te zetten.</p>
        <h3><a id="notities">7. Notities</a></h3>
        <p>Niet alle informatie die je vast wilt leggen over een project past binnen het kader van de stakeholder- of issue analyse. Daarom is het ook mogelijk een notitie toe te voegen in SOM SET. </p>
        <p>Je kan een notitie toevoegen door in het projectoverzicht &gt; &lsquo;Notities&rsquo; &gt; op &lsquo;Nieuwe notitie&rsquo; te klikken. Het overzicht is tevens te vinden onder &lsquo;Notities&rsquo;, waarbij wederom geselecteerd kan worden op stakeholder. Bij het aanmaken of bewerken van notities is het mogelijk deze te koppelen aan bepaalde Issues of Stakeholders. Het is ook mogelijk om bepaalde bestanden te uploaden onder je notitie. </p>
        <h3><a id="media">8. Bestanden</a></h3>
        <p>Niet alle relevante informatie voor het omgevingsmanagement van een project wordt in SOM SET gemaakt. Het is daarom mogelijk om andere bestanden toe te voegen in SOM SET en deze wederom te koppelen aan een of meerdere stakeholders of issues. Dit kunnen alle bestandstypen zijn. Denk daarbij bijvoorbeeld aan kaarten, afbeeldingen en rapporten over een specifiek issue. </p>
        <h3><a  id="projectbeheer">9. Projectbeheer</a></h3>
        <h4>9.1. Projectleden</h4>
        <p>Onder &lsquo;Projectleden&rsquo; krijg je een precies overzicht van alle leden die (actief) onderdeel uitmaken van het desbetreffende project. Je kunt hier de verschillende toegewezen rollen opzoeken of zien wanneer iemand voor het laatst heeft ingelogd. Afhankelijk van je eigen rol, kan je er ook voor kiezen om bepaalde projectleden te verwijderen uit het project. </p>
        <h4>9.2 Project bewerken</h4>
        <p>Onder &lsquo;Project bewerken&rsquo; krijg je de mogelijkheid, afhankelijk van je toegewezen rechten, om de grove project- &lsquo;instellingen en regels&rsquo; te wijzigen. Je kunt er bijvoorbeeld voor kiezen om de naam, foto of de omschrijving van het project te wijzigen. </p>
        <p>Dit is ook de plek waar je de eventueel extra toegevoegde stakeholder- of issuevelden kan wijzigingen (verwijderen) of juist (extra) kan aanmaken. Daarnaast kan aan de hand van het kaartje de locatie (GIS) van het project of issues als middelpunt op de kaart aangepast worden of kan deze juist weer verwijderd worden. </p>
        <p>Tot slot kan je de &lsquo;Zichtbare velden&rsquo;, dit zijn de analyse velden stakeholders of analyse velden issues, hier bepalen. Je kunt ervoor kiezen om deze allemaal in het project op te nemen, of om bepaalde juist achterwege te laten (i.e. uitzetten). Aan de hand van de vinkjes kan je hier als gebruiker je eigen keuzes in maken. Spreek met elkaar van tevoren goed af welke velden je in dit project wilt analyseren en welke niet. Deze keuzes zijn van invloed op de analyse in je project. </p>
        <h4>9.3 Project archiveren </h4>
        <p>Je kunt er, wederom afhankelijk van de aan jou toebedeelde rechten, voor kiezen om een project te archiveren. Alleen de administrator kan deze echter weer de-archiveren. </p>
        <h3><a id="versiebeheer">10. Versiebeheer</a></h3>
        <p>Wijzigingen worden opgeslagen iedere keer dat er op opslaan geklikt wordt. De laatste keer opslaan op een dag wordt vastgehouden. Het is bij alle stakeholders, issues en bij de belangenmatrix, spanningsbarometer en omgevingsmonitor mogelijk om terug te kijken wat de inhoud was op iedere willekeurige dag. Dit doe je door rechts bij &lsquo;Bekijk een andere dag&rsquo; een datum in te vullen, of te selecteren door op het kalender-icoon te klikken. </p>
        <p>Bij issues en stakeholders komt dit in grijs onder de huidige waarde te staan zodat je dit kan vergelijken. Bij de rapportages wordt slechts weergegeven hoe de rapportage er op desbetreffende datum uitzag. Oude overzichten kunnen ook worden gedownload, net als de laatste versie. </p>
      </div>
    </div>
    <div
      v-if="currentUser.language === 'en'"
      class="ui-list-content__content">
      <div>
        <h3><a id="inleiding">1. Inleiding</a></h3>
        <p>Hieronder vind je de belangrijkste informatie die je nodig hebt om te kunnen werken met SOM SET. De nadruk ligt hierbij op het maken van de stakeholder analyse (hoofdstuk 2) en de issue analyse (hoofdstuk 3). In hoofdstuk 4 behandelen we het koppelen van stakeholders en issues, en de rapportages die hieruit voortkomen. Tenslotte gaan we in hoofdstuk 5 (en daarna) in op overige functionaliteiten van SOM SET. Mocht je vragen hebben, &lsquo;bugs&rsquo; tegenkomen of andere punten hebben die je aan ons wil doorgeven dan kan dit in het linker menu door een melding te registreren via de &lsquo;Help &amp; Support&rsquo; functionaliteit. </p>
        <h3><a id="stakeholders">2. Stakeholders</a></h3>
        <p>Hieronder vind je per onderdeel van de stakeholderanalyse een toelichting.</p>
        <h4>2.1 Naam</h4>
        <p>Vul hier de naam in van de stakeholder. Iedere naam is mogelijk maar stakeholders verschijnen vervolgens in alfabetische volgorde in de stakeholderlijst.</p>
        <p>2.2 Categorie</p>
        <p>Vul hier de categorie in. Als je nog niet eerder stakeholders hebt toegevoegd, en aan een categorie hebt toebedeeld, dien je de categorie zelf te typen in het tekstblok. Als je al eerder stakeholders hebt toegevoegd en deze een categorie hebt gegeven, zal dit als optie naar voren komen. Voor categorie&euml;n kun je aan de volgende aspecten denken:</p>
        <ul>
          <li>Overheid. Hier vallen alle diensten over van zowel lokale als rijksoverheid.</li>
          <li>Publieke sectorbedrijven. Dit zijn de bedrijven die vroeger onderdeel van de staat waren maar inmiddels zijn geprivatiseerd. Vaak is een groot deel van de aandelen nog in handen van de staat.</li>
          <li>Maatschappelijke organisaties.&nbsp;Hier gaat het om organisaties met een duidelijk maatschappelijk doel. Ze hebben geen winstoogmerk en zijn vaak georganiseerd als stichting. Deze organisaties staan ook wel bekend als Non-Governmental Organizations (NGO&rsquo;s). Voorbeelden zijn: Greenpeace, Oxfam, Plan etc.</li>
          <li>Bedrijf(sorganisatie).&nbsp;Dit zijn de bedrijven (bv&rsquo;s en nv&rsquo;s) maar ook de koepelorganisaties die hier bij horen.</li>
          <li>Individuen en belangengroepen. In veel projecten krijg je te maken met individuele personen die een grote impact hebben op het project zonder dat ze per se lid zijn van een groep. Soms heb je ook personen die zich organiseren in een groep, met meer of minder structuur. Dit zijn vaak organisaties die zich inzetten voor &eacute;&eacute;n issue of &eacute;&eacute;n gebied. Het gaat hier bijvoorbeeld om bewonersorganisatie.</li>
          <li>Kennisinstellingen. Er zijn veel organisaties die gericht zijn op onderzoek. Dit zijn natuurlijk de universiteiten en hogescholen, maar ook organisaties zoals TNO.</li>
          <li>Politiek(e) partijen.&nbsp;De politiek kan van grote invloed zijn op projecten. Je kan daarom politieke partijen en andere politieke organen in deze categorie kwijt.</li>
          <li>Interne stakeholders. Ook andere onderdelen van je eigen organisatie kunnen stakeholder zijn.</li>
        </ul>
        <h4>2.3. Beschrijving</h4>
        <p>Bij de beschrijving van de stakeholder geef je algemene informatie over de stakeholder. Denk hierbij aan de volgende begrippen:</p>
        <ul>
          <li>type organisatie (publiek/privaat)</li>
          <li>rechtsvorm</li>
          <li>organisatiestructuur</li>
          <li>grootte (aantal leden)</li>
          <li>lokalisatie (landelijk/regionaal)</li>
          <li>doelen/missie</li>
          <li>imago</li>
          <li>financiering</li>
          <li>bijzondere connecties</li>
          <li>omschrijving van leden/aanhangers/achterban</li>
        </ul>
        <h4>2.4. Context en Historie</h4>
        <p>Hier omschrijf je de context waarin de stakeholder wordt betrokken bij het project en de historie die jouw organisatie heeft met de stakeholder. Denk hierbij aan de volgende vragen:</p>
        <ul>
          <li>Is er met de stakeholder eerder contact geweest over dit project?</li>
          <li>Is deze stakeholder op een andere manier bij het project betrokken geweest?</li>
          <li>Zijn er langlopende afspraken met de stakeholder?</li>
          <li>Zijn er convenanten of samenwerkingsovereenkomsten met deze stakeholder?</li>
        </ul>
        <p>Hier kan je ook bijhouden wat je afgelopen periode hebt afgesproken met de stakeholder. </p>
        <h4>2.5. Issues</h4>
        <p>In het drop down menu van issues, kun je deze koppelen aan de desbetreffende stakeholder. Hiervoor moeten de issues wel eerst aangemaakt worden. Zie hiervoor hoofdstuk 3. Via de zoekbalk kun je eenvoudig de issues vinden die voor de stakeholder relevant zijn. Met het blauwe vinkje kun je issues (de)selecteren. Via het kruisje rechts van de zoekbalk kun je het drop down menu weer sluiten.</p>
        <h4>2.6. Spanning</h4>
        <p>Je wilt graag weten hoe groot de spanning is bij een stakeholder bij de huidige staat van het project. Daarvoor gebruiken we &lsquo;stoplichtkleuren&rsquo;. De vraag die je bij deze beoordeling kan stellen is: zou de stakeholder op dit moment proberen de geplande verandering tegen te gaan? De beoordeling is ook gebaseerd op alle andere onderdelen van de analyse. </p>
        <ul>
          <li>Groen (positief) = niets aan de hand</li>
          <li>Geel (aandacht) = wij houden vinger aan de pols</li>
          <li>Oranje (waarschuwing) = wij vinden het spannend, maar oplossing mogelijk</li>
          <li>Rood (kritiek) = wij vinden het heel spannend, op deze manier komt het tot een confrontatie</li>
          <li>Grijs = nog onduidelijk</li>
        </ul>
        <h4>2.7. Belang</h4>
        <p>Vervolgens geef je een algemene beoordeling van het belang van de desbetreffende stakeholder. Hierbij geldt: hoe donkerder blauw de kleur, hoe groter het belang. In het tekstveld kun je onder belang een toelichting geven van je keuze. </p>
        <h4>2.8. NAW-Gegevens</h4>
        <p>Vul hier de gegevens in die nodig zijn om contact op te nemen met de stakeholder. </p>
        <h4>2.9 Kenmerken</h4>
        <h5>2.9.1. Stadium van emotionele verwerking</h5>
        <p>Voor het bepalen van de emotie van een stakeholder maken we gebruik van het model van K&uuml;bler-Ross. Onderstaande schema, ontwikkeld voor rouwverwerking, kan ook worden toegepast bij SOM-projecten. Als een stakeholder namelijk nieuws hoort wat diens belang schaadt, moet hij door deze verschillende fases heen. </p>
        <p><img title="" src="/manual/image1.png" alt="stadia emotie" />&nbsp;</p>
        <p>VIJF FASEN</p>
        <p>Hoewel iedereen op een eigen, persoonlijke manier aan rouwverwerking doet, is er volgens K&uuml;bler-Ross wel een vast patroon te onderscheiden. In haar omgang met terminale pati&euml;nten, ontdekte zij dat deze rouwverwerking vrijwel bij iedereen op dezelfde manier verloopt. Daardoor is de theorie zeer goed van toepassing bij traumatische ervaringen zoals verlies van een dierbare, grote tegenslagen of teleurstellingen en ontslag situaties. In eerste instantie krijgt de persoon na het horen van het slechte nieuws een schok. Vanuit deze schok treedt al snel de eerste fase in werking:</p>
        <p>Fase 1 &ndash; ontkenning</p>
        <p>In eerste instantie reageren mensen met ongeloof op een slecht bericht. Dit algemene afweermechanisme werkt als buffer na een onverwacht schokkend bericht en geeft de persoon kans om tot zichzelf te komen. Vervolgens zal zij geleidelijk herstellen van de schok. Het is daarbij belangrijk dat zij zijn gevoelens kan uiten bij bijvoorbeeld een vertrouwenspersoon. Aan het einde van deze fase gaat de persoon op zoek naar feiten, de waarheid en eventuele schuldigen.</p>
        <p>Fase 2 &ndash; woede</p>
        <p>Wanneer men niet meer langer kan ontkennen, ontstaan er gevoelens van woede, ergernis, jaloezie en wrok. De woede en het onvermogen worden vooral geprojecteerd op de omgeving. De schuld wordt buiten zichzelf gezocht en bij collega&rsquo;s, medewerkers en adviseurs gelegd. Soms richt de woede zich op de brenger van het slechte nieuws.</p>
        <p>Fase 3 &ndash; onderhandelen</p>
        <p>In deze fase probeert men op allerlei manieren onder de verschrikkelijke werkelijkheid uit te komen. Er wordt in deze fase onderhandeld. Door jezelf doelen te stellen, wordt slecht nieuws verzacht. Met de werkomgeving kan in deze fase een moeizame onderhandeling op gang komen betreffende werkafspraken en het doen van beloftes. Bij (dreigend) ontslag kan men in deze fase naar de rechter stappen als vorm van tegenaanval.</p>
        <p>Fase 4 &ndash; depressie</p>
        <p>De waarheid dringt in deze fase steeds meer door en de persoon voelt zich machteloos en niet begrepen. Daardoor gaat deze zich teruggetrokken gedragen en sluit zich af van communicatie. De telefoon wordt niet meer opgenomen en mailtjes worden niet beantwoord. Er is in deze fase kans op een vlucht in alcohol en drugs, zoals pijnstillers, kalmeringstabletten en slaapmiddelen.</p>
        <p>Fase 5 &ndash; aanvaarding</p>
        <p>Als de persoon zich bewust is van het feit dat zij geen hoop meer hoeft te koesteren, kan zij het slechte nieuws aanvaarden. Zij kan bijkomen van de vorige fasen en haar verdriet accepteren. Zij zal na verloop van tijd weer zin hebben om dingen op te pakken en plannen te maken.</p>
        <p>Overigens kan ook gebruik gemaakt worden van een eenvoudigere indeling, van boven naar beneden in de drop-down in SOM SET:</p>
        <ul>
          <li>Geen emotie</li>
          <li>Lage emotie</li>
          <li>Gemiddelde emotie</li>
          <li>Hoge emotie</li>
        </ul>
        <p>Beide onderverdelingen zijn te kiezen in het drop down menu. Bepaal vooraf met elkaar welke onderverdeling je binnen het project aanhoudt.</p>
        <h5>2.9.2. Macht&nbsp;</h5>
        <p>Er zijn verschillende vormen van macht. Het kan voorkomen dat een stakeholder weinig macht heeft. Als je vindt dat een stakeholder meerdere vormen van macht heeft, kies je de meest dominante en geef je in de toelichting aan welke vormen nog meer van toepassing zijn. In SOM SET onderscheiden we op hoofdlijnen de volgende vier categorie&euml;n machtsbronnen, steeds beredeneerd vanuit stakeholders:</p>
        <ul>
          <li>Persoonsmacht. Dit gaat over wie je bent. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Fysieke of geweldsmacht.</li>
          <li>Deskundigheidsmacht.</li>
          <li>Intellectuele macht.<br /> </li>
        </ul>
        <ul>
          <li>Positiemacht. Dit gaat over de positie die je bekleedt. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Bestraffingsmacht.</li>
          <li>Hi&euml;rarchische macht.</li>
          <li>Beloningsmacht.<br /> </li>
        </ul>
        <ul>
          <li>Connectiemacht. Dit gaat over de connecties die je hebt. Deze machtsbron is voornamelijk opgewaardeerd door de inzet van sociale media. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Informatiemacht.</li>
          <li>Zichtbaarheidsmacht.</li>
          <li>Connectiemacht. </li>
        </ul>
        <ul>
          <li>BATNA-macht. Dit gaat over de aanwezigheid van een alternatief. Met een alternatief achter de hand kun je een gesprek over een oplossing maken of breken. Hieronder vallen:</li>
        </ul>
        <ul>
          <li>Persoonlijk BATNA.</li>
          <li>Ambtshalve alternatief.</li>
        </ul>
        <p><img title="" src="/manual/image3.png" alt="" /></p>
        <h5>2.9.3. Urgentie</h5>
        <p>De urgentie is gekoppeld aan de kans of risico dat zich in een bepaald tijdsbestek iets voordoet wat impact heeft op het belang van de stakeholder. Hoe zwaarder die impact op korte termijn weegt hoe hoger de urgentie. Een hoge urgentie betekent dat een stakeholder vroeger in het proces betrokken moet worden.</p>
        <h5>2.9.4. Lijn</h5>
        <p>Hier gaat het om de lijn die de stakeholder aanhoudt. Is dit een (zeer) fundamentele lijn, zijn ze redelijk fundamenteel, beperkt fundamenteel of niet fundamenteel. Met (zeer) fundamenteel wordt bedoeld dat ze een sterke mening hebben en daar niet van af willen wijken. Als ze redelijk fundamenteel zijn, staan ze open voor goede argumenten maar kijken ze de kat uit de boom. Als stakeholders een &lsquo;beperkte fundamentele&rsquo; lijn aanhouden, staan ze open voor onderhandelingen en het vinden van consensus. Tot slot kan een stakeholder helemaal niet fundamenteel zijn.</p>
        <h5>2.9.5. Legitimiteit van een stakeholder</h5>
        <p>Wat doe je als een stakeholder veel aandacht trekt, maar eigenlijk geen (zwaarwegend) belang heeft? En wanneer andere stakeholders voor datzelfde issue wel eenzelfde maar dan zwaarwegend belang hebben? Dan lijkt de legitimiteit van de stakeholder beperkt.</p>
        <p>De vraag is hier of het legitiem is dat de stakeholder bij dit project betrokken wordt. Je kan de keuze maken uit zeer legitiem, legitiem, redelijk legitiem, beperkt legitiem en niet legitiem. Baseer je keuze op de volgende punten:</p>
        <ul>
          <li>het belang van de stakeholder bij het project</li>
          <li>de grootte van de achterban die hij vertegenwoordigt</li>
          <li>de macht die de stakeholder heeft</li>
          <li>de relevantie van de stakeholder voor de omgeving kwesties (&lsquo;issues&rsquo;) waarover hij wil meedenken, meedoen of meebeslissen</li>
        </ul>
        <h5>2.9.6. BATNA</h5>
        <p>BATNA staat voor Best Alternative To a Negotiated Agreement. Als je het gedrag van een stakeholder wil voorspellen is het belangrijk dat je weet wat het beste alternatief is voor stakeholders als je er niet samen uitkomt. Er zijn natuurlijk altijd alternatieven naast het komen tot een overeenstemming maar soms heb je een goed alternatief en soms niet. Doordat je van jezelf weet wat je BATNA is en dat van de anderen weet, voorkom je dat je met een uitkomst eindigt die je niet wil. </p>
        <p>In SOM SET onderscheiden we de volgende vormen van een BATNA:</p>
        <ul>
          <li>Sterk BATNA</li>
          <li>Redelijk BATNA</li>
          <li>Zwak BATNA</li>
          <li>Fantoom BATNA: dit is een onrealistisch BATNA dat door de partij zelf als zeer realistisch wordt gezien. Voorbeeld: de rechter zal mij zeker in het gelijk stellen; een andere uitkomst is namelijk niet rechtvaardig! Deze tweedehands Billy boekenkast is zo bijzonder; een andere koper zal er zeker wel de aankoopprijs voor betalen!&nbsp;</li>
        </ul>
        <p>Dan moet wel duidelijk zijn wat het BATNA van deze partij(en) is. Het achterhalen van een BATNA is lastig. Immers, organisaties met een slecht BATNA lopen er niet mee te koop. Organisaties met een goed BATNA kunnen ervoor kiezen dat voor zich te houden of het in te zetten in de onderhandeling. In ieder geval is het belangrijk nader toe te lichten wat we bedoelen met een fantoom BATNA. De ervaring leert dat mensen hun BATNA overschatten. Dat kan tot strategische fouten in een onderhandeling leiden. Het is dus belangrijk het BATNA van betrokken partijen te kennen en te toetsen. Dit is niet eenvoudig. Wat als blijkt dat een partij het alternatief overschat. Dit noemen we een fantoom BATNA: het alternatief bleek geen re&euml;el alternatief, maar was wel bepalend voor het gedrag van de stakeholder aan de onderhandelingstafel. </p>
        <hr />
        <h5>2.9.7. Mate van vertrouwen </h5>
        <p>Voor de toepassing van SOM is een ladder met niveaus van vertrouwen ontwikkeld (zie onderstaand figuur). Ieder niveau van vertrouwen hangt samen met een of meer bronnen van vertrouwen. In SOM SET onderscheiden we de volgende niveaus van vertrouwen: </p>
        <ul>
          <li>Blind vertrouwen</li>
          <li>Afgemeten vertrouwen</li>
          <li>Gezond wantrouwen</li>
          <li>Geen vertrouwen</li>
          <li>Diepgeworteld wantrouwen</li>
        </ul>
        <p>Door de bovenstaande categorie&euml;n (of niveaus) in SOM SET aan te klikken verschijnt hieronder in cursief een nadere verklaring van wat het niveau van vertrouwen inhoudt. In het tekstblok kan je je keuze nader toelichten. </p>
        <p><img title="" src="/manual/image2.png" alt="" /></p>
        <p>2.10. Strategie</p>
        <h5>2.10.1. Participatieniveau</h5>
        <p>De strategie begint met de keuze voor een participatieniveau. Dit doe je aan de hand van de Participatieladder.</p>
        <p>Je kan stakeholders op verschillende manieren betrekken. </p>
        <ul>
          <li>Als je informeert betekent dit dat je eenzijdig informatie verstrekt. Je deelt de voortgang maar de stakeholder heeft hier geen inbreng. </li>
          <li>Bij communiceren&nbsp;wordt wel met de stakeholder gesproken. Jij behoudt echter het recht om er wel of niet iets mee te doen. </li>
          <li>Bij het (ad-hoc) betrekken&nbsp;van een stakeholder geef je aan dat een stakeholder actief wordt betrokken bij het vinden van een oplossing voor het deel van het project waar ze belang bij hebben. </li>
          <li>Als een stakeholder participeert&nbsp;wordt het hele project samen met de stakeholder gezocht naar een oplossing. </li>
          <li>Als het belang van de stakeholder zo groot is dat je doorgang van een project onlosmakelijk wilt of moet verbinden aan het bereiken van consensus vraagt dit veel van het proces. </li>
          <li>De hoogste trede van de participatieladder is empowerment. In dat geval wordt de stakeholder mede-eigenaar van het project. Ze mogen meebeslissen, maar er wordt ook van ze verwacht dat ze zich inzetten om het project te laten slagen. </li>
          <li>Tot slot kan je ervoor kiezen om het participatieniveau van de stakeholder op onbekend te zetten. </li>
        </ul>
        <p>In de toelichting voor dit onderdeel leg je niet alleen uit waarom je voor dit participatieniveau hebt gekozen, maar ook wat dit betekent voor de strategie. Vragen die je daarvoor aan jezelf kunt stellen zijn:</p>
        <ul>
          <li>Wanneer ga je de stakeholder betrekken?</li>
          <li>Hoe ga je dat doen?</li>
          <li>Op welke onderdelen?</li>
          <li>Wat is het doel van de relatie?</li>
        </ul>
        <h5>2.10.2. Vragen en discussiepunten</h5>
        <p>Na het maken van een analyse blijven er altijd vragen over. Informatie die je niet hebt kunnen vinden op internet of die uit voorgaande gesprekken niet is gebleken. Bovendien zitten er waarschijnlijk veel aannames in je analyses. Deze gaten en aannames kun je hier opschrijven. Deze dienen als input voor het ANNA-gesprek (Altijd Nagaan Nooit Aannemen). De vragen die je hier opschrijft kunnen apart worden gedownload als de ANNA-lijst, als je het download menu uitklapt. </p>
        <h4>2.11. Locatie</h4>
        <p>Helemaal onderin staat een kaart waarop je de locatie van de stakeholder kunt aangeven. Dit doe je door op &ldquo;locatie bewerken&rdquo; te klikken. Vervolgens krijg je rechts een verticale balk waar verschillende iconen staan afgebeeld. </p>
        <p><img title="" src="/manual/image4.png" alt="" /></p>
        <p>Van boven naar beneden aan de rechterkant betekenen ze het volgende:</p>
        <ul>
          <li>De locatie druppel, hiermee kun je een puntlocatie aangeven, zoals een huis of bedrijf. Het is ook mogelijk meerdere druppels te plaatsen als deze stakeholder bijvoorbeeld meerdere vestigingen heeft die binnen het projectgebied liggen.</li>
          <li>De lijn, hiermee kun je een lijn tekenen. Het is mogelijk hoeken hierin aan te brengen. Je voltooit de lijn door op het laatst geplaatste punt te klikken.</li>
          <li>Het vierkant, hiermee kun je een vierhoek tekenen. Bijvoorbeeld om de locatie van een weiland aan te geven.</li>
          <li>Multi-hoek, hiermee kun je vrij een hoekige vorm tekenen. Je voltooid het figuur door op het eerste geplaatste punt te klikken. Dit kan bijvoorbeeld gebruikt worden om locaties aan te geven die niet met een cirkel of vierhoek in te tekenen zijn.</li>
          <li>Cirkel met kleine cirkel rechtsboven, hiermee kun je een cirkel tekenen en de diameter van de cirkel instellen. Daarmee kun je kiezen of je een grotere of kleinere cirkel wil.</li>
          <li>Cirkel, hiermee kun je een vooraf ingesteld formaat cirkel plaatsen.</li>
          <li>De pijlen, door hierop te klikken stop je met wat je aan het tekenen was (als de figuur nog niet voltooid is, wordt deze verwijderd), en kun je de kaart waarop je aan het werk bent verslepen. Zo kun je, zoals in dit voorbeeld, naar een ander deel van Almere gaan als een stakeholder hier ook nog bijvoorbeeld een bedrijfsvestiging heeft.</li>
          <li>De schaar, hiermee kun je binnen een getekende vorm een deel wegknippen. Dit kan handig zijn als je bijvoorbeeld de locatie van meerdere weilanden als een groot blok hebt aangegeven, terwijl een van de weilanden binnen dat blok niet van de desbetreffende stakeholder is.</li>
          <li>De gum, hiermee kun je eenvoudig een getekende vorm of punt verwijderen.</li>
          <li>De draaiende pijl, door hierop te klikken kun je meervormige figuren kantelen. In plaats van een horizontaal geplaatste rechthoek kun je bijvoorbeeld een schuingeplaatste rechthoek cre&euml;ren.</li>
        </ul>
        <p>Als alle locaties zijn ingetekend, klik je op de blauwe &ldquo;locatie bewaren&rdquo; knop, en kun je de ingetekende stakeholder terugvinden in de kaart (direct onder het dashboard icoon links). </p>
        <h3><a id="issues">3. Issue</a></h3>
        <h4>3.1. Naam en Categorie </h4>
        <p>Kies een herkenbare naam voor het issue. In de drop-down &lsquo;Categorie&rsquo; kun je aangeven welke andere categorie&euml;n issues met het desbetreffende issue verband houden. Bij het bewerken van een issue (en stakeholder) kan je het veld &lsquo;categorie&rsquo; zelf invullen. Dat is een pul down en editor in &eacute;&eacute;n. Je kan dus:</p>
        <ol start="1">
          <li>Een nieuw issue maken</li>
          <li>Daar zelf een categorie invullen</li>
          <li>Deze categorie vervolgens ook gebruiken voor andere (nieuwe) issues</li>
        </ol>
        <p>3.2. Spanning Issue</p>
        <p>Hier dient wederom een keuze gemaakt te worden welke stoplicht kleur (het beste) toegekend kan worden aan het desbetreffende issue. Net als voor de stakeholders geven we een algemene beoordeling van spanning voor elk issue. Hierbij gaat het om de vraag of, mocht het project nu doorgang vinden, stakeholders de huidige beoogde oplossing accepteren. Dit is reeds toegelicht in hoofdstuk 2.6. </p>
        <h4>3.3. Beschrijving</h4>
        <p>Vul hier een beschrijving in van het issue. Probeer deze zo nauwkeurig en scherp mogelijk te formuleren. Waar gaat het over en waar zit de spanning? </p>
        <h4>3.4. Stakeholders</h4>
        <p>In de drop-down &lsquo;Selecteer de stakeholders&rsquo; kun je aangeven welke stakeholders met dit issue en/of elkaar verband houden. Je kan jezelf hierbij de volgende vraag stellen: kan dit issue worden opgelost zonder een andere stakeholder? Zo niet, welke stakeholders heb je hiervoor nodig? Maar let op, je moet hiervoor al wel een of meerdere stakeholders hebben aangemaakt.</p>
        <h4>3.5. Kenmerken</h4>
        <h5>3.5.1. Begrijpelijkheid van het issue</h5>
        <p>Beargumenteer of het issue voor de meeste mensen een (relatief) begrijpelijk probleem is, of dat deze zeer complex en moeilijk te begrijpen is, waarvoor veel uitleg nodig is. Bij de begrijpelijkheid van het issue is de classificatie ingewikkeld. Als een issue heel begrijpelijk is, vindt iedereen er mogelijk iets van en vraagt dat aandacht in de strategie. Als het issue niet begrijpelijk is, vraagt het ook aandacht: het moet uitgelegd worden! Het transparantieprincipe vraagt immers vroegtijdige duiding van de mogelijke impact van issues op de belangen van stakeholders, ook als het ingewikkelde issues zijn.</p>
        <h5>3.5.2. Zichtbaarheid voor het publiek</h5>
        <p>Naarmate het issue zichtbaarder is, raken stakeholders ook makkelijker betrokken bij het issue. Die emotionele betrokkenheid heeft impact op de te volgen strategie.</p>
        <p>Beargumenteer of het issue een zichtbaar probleem is, dat in de letterlijke betekenis &lsquo;makkelijk te zien&rsquo; is voor het publiek. Als het issue bijvoorbeeld gaat over een te bouwen kantoorflat, dan is dit voor de bewoners in de omgeving een zichtbaar probleem. Beargumenteer ook of het issue tastbaar is voor het publiek, dat men het als het ware kan aanraken. Als bijvoorbeeld de oogst van een boer mislukt vanwege vervuilde grond en de groenten rotten snel, dan is dat een tastbaar probleem. </p>
        <h5>3.5.3. Mediagevoeligheid</h5>
        <p>De mediagevoeligheid hangt samen met de zichtbaarheid voor het publiek. Hoe groot is de kans dat de media dit oppakken en welk frame geven zij eraan?</p>
        <p>Beargumenteer of het issue (in potentie) een mediagevoelig onderwerp is. Is in het verleden gebleken dat de media vaak over dit onderwerp publiceerden? Wat was de toon van de berichtgeving? Was de doelgroep van die berichten breed, of was het voor een select publiek? </p>
        <h5>3.5.4. Ontwikkelstadium</h5>
        <p>Beargumenteer in welke fase het issue zich bevindt. Wordt de kwestie door velen als probleem erkend? Of zijn besluitvormers al in de fase van het debat over mogelijke oplossingsrichtingen? Wordt er al beleid geformuleerd? Als er al besluiten zijn genomen, worden maatregelen getroffen? De laatste fase van het project is de beheerfase (beleid en beheer). Kortom: is het issue in een pril of in een gevorderd stadium?</p>
        <p>Er kan gekozen worden uit de volgende categorie&euml;n:</p>
        <ul>
          <li>Erkenning. In de fase van erkenning vindt er maatschappelijk veel discussie over het issue plaats. In de erkenningsfase, nog voor de beleidsformulering aan de orde is, is een virtueel take-offpunt benoemd. Dit punt markeert het moment waarop issues zodanig serieus worden genomen dat er een dossier wordt aangemaakt.</li>
          <li>Beleidsformulering. In de beleidsformuleringsfase wordt al nagedacht over oplossingen. Zo kan worden bepaald aan welke issues tijd moet worden besteed voor nadere analyse en welke even kunnen wachten. En ook welke stakeholders (die belang hebben bij dat issue) benaderd moeten worden.</li>
          <li>Oplossing. In de oplossingsfase wordt al effectief werk gemaakt van de oplossing voor het issue. E&eacute;n of meerdere stakeholders zijn actief bezig met de oplossing.</li>
          <li>Beleid en beheer. De fase van beleid en beheer beslaat in bredere zin de periode van uitvoer tot nazorg. Los van het oplossen van het issue is er mogelijk een beheerplan nodig voor de langere termijn.</li>
        </ul>
        <p>Wanneer een van de bovenstaande categorie&euml;n wordt geselecteerd in SOM SET verschijnt hieronder in cursief een nadere verklaring van wat deze categorie inhoudt. </p>
        <h4>3.5.5. Complexiteit oplossing </h4>
        <p>De complexiteit van de oplossing heeft ook invloed op de strategie. Sommige issues zijn gemakkelijk op te lossen, terwijl anderen een samenhang hebben met meerdere andere issues of er bestaat geen oplossing voor het issue. In die laatste gevallen is de complexiteit hoog of extreem. Complexiteit duiden we aan met de volgende categorie&euml;n: </p>
        <ul>
          <li>Extreem</li>
          <li>Hoog</li>
          <li>Midden</li>
          <li>Laag</li>
          <li>Niet</li>
        </ul>
        <p>3.5.6. Onze redeneerlijn </p>
        <p>Onze redeneerlijn ten aanzien van het issue weergeven we in een tekstblok. De redeneerlijn geeft aan hoe jij tegen dit issue aankijkt. Wat is de lijn die we aanhouden als we stakeholders spreken over dit issue? De redeneerlijn wil je zo strak mogelijk neerzetten, zodat het projectteam (extern) aan de hand van een gedeelde lijn communiceert. Het gaat hierbij dus om onze eigen redeneerlijn, en niet om die van de stakeholder ten aanzien van het issue in kwestie. </p>
        <p>3.6. Strategie</p>
        <p>Hier beschrijf je de strategie ten aanzien van dit issue. Geef hier aan met wie moet worden gesproken, waarover en op welke termijn. Ook verdere onderzoeken kunnen een onderdeel van de strategie zijn. Probeer dit zo ver mogelijk te concretiseren.</p>
        <h4>3.7. Vragen en discussiepunten</h4>
        <p>Schrijf hier alle vragen op die nog openstaan na het maken van de analyse. </p>
        <h4>3.8. Locatie</h4>
        <p>Hier kunnen (eventueel) dezelfde stappen doorlopen worden als onder paragraaf 2.11.</p>
        <hr />
        <h3><a id="koppelenissuesenstakeholders">4. Koppelen van issues en stakeholders</a></h3>
        <p>Onder de naam van het issue van de stakeholder kan je bij het bewerken ervan, zoals eerder vermeld, stakeholders koppelen (3.4.) of issues koppelen (2.5.). Na het opslaan krijg je in het overzicht van desbetreffende issue of stakeholder een lijst te zien met gekoppelde stakeholders (bij het issue) of gekoppelde issues (bij de stakeholder). </p>
        <p>Deze informatie kan door te klikken op issue-stakeholder (het linker blok onder drop-down) informatie worden aangepast. Hieronder kunnen het belang en de spanning aangepast worden, en wordt weergeven welke stakeholders en/of issues er gekoppeld zijn aan het desbetreffende issue of stakeholder. </p>
        <h4>4.1. Beschrijving Belang en Spanning</h4>
        <p>Allereerst geef je een beoordeling van het belang. Hoe donkerder de kleur, hoe groter het belang van deze stakeholder (bij het issue). In het tekstveld&nbsp;kun je onder belang een toelichting geven van je keuze. </p>
        <p>Hetzelfde geldt voor spanning. Ook dit is eerder toegelicht onder de helpfunctionaliteit in hoofdstuk 2.6. </p>
        <p>Het verschil tussen belang en standpunt</p>
        <p>Een belang is iets dat iemand raakt, doordat zijn voordeel of voorspoed ermee gemoeid is. In vergelijking tot een standpunt, verandert een belang minder snel. Een belang verandert niet doordat iemand je met zijn argumenten van gedachten doet veranderen.</p>
        <p>Naast het belang van &lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo; voor een ouder als stakeholder in het bovengenoemde voorbeeld, kan een belang zijn: het voortbestaan van een familiebedrijf (een ondernemer), zekerheid van inkomen (een werknemer) of het behoud van de bossen (een milieuorganisatie).</p>
        <p>Een standpunt is een manier om tegen iets aan te kijken, een mening die je bent toegediend op een bepaald moment. In tegenstelling tot een belang, kan een standpunt relatief snel veranderen, bijvoorbeeld omdat een persoon een andere kijk krijgt op de kwestie door argumenten die worden aangedragen.</p>
        <p>Vaak zijn stakeholders zich bewust van hun standpunt en kunnen zij dit zonder moeite formuleren. Datzelfde geldt niet altijd voor hun belang. Het kan nodig zijn dat de omgevingsmanager, die namens de projectorganisatie met de stakeholder in dialoog treedt, de stakeholder helpt bij het achterhalen of formuleren van zijn belang.</p>
        <p>Bij een eerste invulling van het dossier op basis van gevonden documentatie, geeft de analist een eigen interpretatie van het belang van de stakeholder. Deze interpretatie dient altijd getoetst te worden bij de stakeholder zelf. Op basis van de gesprekken met de stakeholder, kan het nodig zijn dat de formulering van het belang in het dossier wordt aangepast.</p>
        <p>In het volgende fragment wordt het verschil tussen een standpunt en een belang ge&iuml;llustreerd:</p>
        <p>A: Ik ben tegen kernenergie (standpunt)</p>
        <p>B: Waarom ben je tegen kernenergie?</p>
        <p>A: Het is slecht voor het milieu (standpunt)</p>
        <p>B: Waarom hecht jij belang aan het milieu?</p>
        <p>A: Ik wil een gezonde leefomgeving achterlaten voor mijn kinderen (belang)</p>
        <p>B: En als er een schone vorm van kernenergie wordt uitgevonden?</p>
        <p>A: Dan ben ik niet meer tegen kernenergie (standpunt)</p>
        <p>Uit het fragment blijkt dat het standpunt (&lsquo;Ik ben tegen kernenergie&rsquo;) veranderlijk is, in tegenstelling tot het achterliggende belang (&lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo;). Zolang dat belang gediend wordt, zal persoon A hoogstwaarschijnlijk instemmen met het voorstel.</p>
        <h4>4.2. Beschrijving standpunt</h4>
        <p>Hier vul je het standpunt in dat de stakeholder heeft ingenomen op dit issue. Zie hieronder een uitgebreide uitleg over het verschil tussen standpunt en belang.</p>
        <p>Het verschil tussen standpunt en belang</p>
        <p>Wat is het standpunt van deze stakeholder ten aanzien van (de issues van) het project? Een standpunt is een manier om tegen iets aan te kijken, een mening die je bent toegediend op een bepaald moment. In tegenstelling tot een belang, kan een standpunt relatief snel veranderen, bijvoorbeeld omdat een persoon een andere kijk krijgt op de kwestie door argumenten die worden aangedragen.</p>
        <p>Bij het formuleren van het standpunt van de stakeholder, is het belangrijk dat de omgevingsprofessional zich inleeft in een medewerker of lid van de stakeholder. Daarbij moet hij de volgende vragen in het achterhoofd houden:</p>
        <ul>
          <li>Hoe zou die medewerker of dat lid zijn of haar standpunt formuleren?</li>
          <li>Wat vindt hij/zij belangrijk? Hoe denkt hij/zij?</li>
          <li>Welke &lsquo;taal&rsquo; spreekt hij of zij?</li>
        </ul>
        <p>Een belang is iets dat iemand raakt, doordat zijn voordeel of voorspoed ermee gemoeid is. In vergelijking tot een standpunt, verandert een belang minder snel. Een belang verandert niet doordat iemand je met zijn argumenten van gedachten doet veranderen.</p>
        <p>Naast het belang van &lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo; voor een ouder als stakeholder in het bovengenoemde voorbeeld, kan een belang zijn: het voortbestaan van een familiebedrijf (een ondernemer), zekerheid van inkomen (een werknemer) of het behoud van de bossen (een milieuorganisatie).</p>
        <p>Vaak zijn stakeholders zich bewust van hun standpunt en kunnen zij dit zonder moeite formuleren. Datzelfde geldt niet altijd voor hun belang. Het kan nodig zijn dat de omgevingsmanager, die namens de projectorganisatie met de stakeholder in dialoog treedt, de stakeholder helpt bij het achterhalen of formuleren van zijn belang.</p>
        <p>Bij een eerste invulling van het dossier op basis van gevonden documentatie, geeft de analist een eigen interpretatie van het belang van de stakeholder. Deze interpretatie dient altijd getoetst te worden bij de stakeholder zelf. Op basis van de gesprekken met de stakeholder, kan het nodig zijn dat de formulering van het belang in het dossier wordt aangepast.</p>
        <p>In het volgende fragment wordt het verschil tussen een standpunt en een belang ge&iuml;llustreerd:</p>
        <p>A: Ik ben tegen kernenergie (standpunt)</p>
        <p>B: Waarom ben je tegen kernenergie?</p>
        <p>A: Het is slecht voor het milieu (standpunt)</p>
        <p>B: Waarom hecht jij belang aan het milieu?</p>
        <p>A: Ik wil een gezonde leefomgeving achterlaten voor mijn kinderen (belang)</p>
        <p>B: En als er een schone vorm van kernenergie wordt uitgevonden?</p>
        <p>A: Dan ben ik niet meer tegen kernenergie (standpunt)</p>
        <p>Uit het fragment blijkt dat het standpunt (&lsquo;Ik ben tegen kernenergie&rsquo;) veranderlijk is, in tegenstelling tot het achterliggende belang (&lsquo;een gezonde leefomgeving voor mijn kinderen&rsquo;). Zolang dat belang gediend wordt, zal persoon A hoogstwaarschijnlijk instemmen met het voorstel.</p>
        <h4>4.3. BATNA</h4>
        <p>Onder paragraaf 2.9.6. is BATNA al toegelicht. </p>
        <h4>4.4. Redeneerlijn stakeholder</h4>
        <p>Het is ook belangrijk vooraf na te denken over de redeneerlijn van de andere partij. Bij dit onderdeel kan je die redeneerlijn uitschrijven. </p>
        <p>De redeneerlijn bevat de volgende elementen:</p>
        <ul>
          <li>Feitelijke beschrijving van issue in enkele regels</li>
          <li>Voorgestelde oplossing (zo ver mogelijk uitgewerkt)</li>
          <li>Argumenten voor deze oplossing</li>
          <li>Bekende argumenten tegen deze oplossing en de daarbij passende antwoorden.</li>
        </ul>
        <h4>4.5. Zienswijze</h4>
        <p>Een zienswijze is alleen van toepassing als er een juridische actie is ondernomen door de andere partij. Die kan je hier invullen.</p>
        <h4>4.6. Conclusie</h4>
        <p>Wat is de conclusie ten aanzien van bovenstaande punten. Vragen die je hierbij kan stellen zijn:</p>
        <ul>
          <li>Is het standpunt in lijn met het belang?</li>
          <li>Heeft de stakeholder door dat er wel/geen verschil is?</li>
          <li>Hoe sterk is het BATNA?</li>
          <li>Kent de stakeholder zijn eigen BATNA op dit issue?</li>
          <li>Hebben ze een duidelijke redeneerlijn?</li>
          <li>Is de juridische reactie te verklaren aan de hand van het belang of reageren ze op andere punten?</li>
        </ul>
        <h4>4.7. Strategie </h4>
        <p>Wat is de strategie voor deze stakeholder ten aanzien van dit specifieke issue?</p>
        <h3><a id="rapporateges">5. Rapportages</a></h3>
        <h4>5.1. Issuelijst</h4>
        <p>De issuelijst geeft een overzicht van alle issues in het project en de status van deze issues. De issuelijst is te downloaden als PDF en Word document onder het kopje &lsquo;Issues&rsquo; in de linker kolom in SOM SET. </p>
        <h4>5.2. Stakeholderlijst</h4>
        <p>De stakeholderlijst geeft een overzicht van alle stakeholders en de status van deze issues. De stakeholderlijst is te downloaden als PDF en Word document onder het kopje &lsquo;Stakeholders&rsquo; in de linker kolom in SOM SET. </p>
        <h4>5.3. Issuedossier</h4>
        <p>Het issuedossier geeft de belangrijkste informatie weer over het issue. Alle onderdelen van het issue zoals hierboven besproken, worden weergegeven in het issuedossier. Ook de koppeling met de stakeholders wordt weergegeven. Het issuedossier is te downloaden als pdf en word document. </p>
        <h4>5.4. Stakeholderdossier</h4>
        <p>Het stakeholderdossier geeft de belangrijkste informatie weer over de stakeholder. Alle onderdelen van de stakeholder zoals hierboven besproken, worden weergegeven in het stakeholderdossier. Ook de koppeling met de stakeholders wordt weergegeven. Het issuedossier is te downloaden als pdf en word document.</p>
        <h4>5.5. Belangenmatrix</h4>
        <p>De belangenmatrix is een overzicht van de belangen die de stakeholders hebben bij verschillende issues. Op de horizontale as staan de issues, op de verticale as de stakeholders. In blauwtinten is aangegeven hoe groot het belang is. De kleuren die hier worden weergegeven zijn dezelfde als die bij de stakeholder-issue informatie worden ingevuld (zie 4.1.). Bij het beoordelen van het belang, ga je uit van jouw inschatting van het belang van de ander. Het gaat er dus niet of of de stakeholder zelf zijn belang groot of klein vindt. Dit is natuurlijk wel interessante informatie en dit kan je vergelijken met de inschatting die je zelf maakt. Op basis van de inschatting van belang wordt ook het participatieniveau van de stakeholder ingevuld (zie 2.10.1.). </p>
        <p>De verschillende velden kunnen ook in de matrix aangepast worden. Als op een kleur geklikt wordt verschijnt een pop-up waar de kleur veranderd kan worden. Daarnaast kan ook het algehele belang of strategie aangepast worden door hierop te klikken.</p>
        <p>Door op het pijltje rechtsboven in de pop-up te klikken kunnen ook de achterliggende beschrijvingen, bijvoorbeeld van standpunt en belang worden aangepast. Let op! het is alleen mogelijk waarden te veranderen als eerst bij desbetreffende stakeholder en stakeholder-issue informatie iets in ingevuld. </p>
        <p>De belangenmatrix kan worden gedownload in PDF en Excel format. </p>
        <h4>5.6. Spanningsbarometer</h4>
        <p>De spanningsbarometer biedt een overzicht van de spanning die de stakeholders ervaren bij de verschillende issues. Op de horizontale as staan de issues en op de verticale as de stakeholders. In stoplichtkleuren wordt aangegeven hoe zij de spanning ervaren. De kleuren die hier worden weergegeven zijn wederom dezelfde als die bij de stakeholder-issue informatie worden ingevuld. Daarnaast worden stadium van emotionele verwerking (2.9.1.) en urgentie (2.9.3.) weergegeven.</p>
        <p>De verschillende velden kunnen ook in de matrix aangepast worden. Als er op een kleur wordt geklikt, verschijnt er een pop-up waar de kleur veranderd kan worden. Als er op de emotie of urgentie wordt geklikt, kan ook dit worden aangepast. In de spanningsbarometer is het ook mogelijk om een issue aan een specifieke stakeholder te koppelen. Dit doe je door spanning toe te kennen aan een specifieke stakeholder voor het issue dat je wil koppelen.</p>
        <p>Door op een veld te klikken kan je ook de onderliggende beschrijving (standpunt) aanpassen. In de pop-up kan dan een tekst getypt worden. </p>
        <p>De spanningsbarometer kan worden gedownload in PDF- en Excel format. </p>
        <h4>5.7. Omgevingsmonitor </h4>
        <p>De omgevingsmonitor geeft per stakeholder - per (gecategoriseerde) issue een overzicht van de status. Rechtsboven kan je kiezen welke categorie stakeholder je wilt bekijken. Per stakeholder en per issue worden de kleuren (belang/spanning) weergegeven maar ook de redeneerlijn van de stakeholder, het BATNA, onze redeneerlijn, de conclusie en strategie. </p>
        <p>Let op! de teksten in de omgevingsmonitor zijn de eerste paragrafen van de betreffende velden bij de stakeholder-issue informatie. Hou er bij het invullen van deze velden dus rekening mee dat de kern verwoord moet worden in de eerste alinea. Door te klikken op Lees meer kan ook de rest van de informatie gelezen worden. </p>
        <p>De omgevingsmonitor kan worden gedownload in PDF en Excel format. </p>
        <p>5.8. Voortgangsrapportage</p>
        <p>De voortgangsrapportage geeft de voortgang van het project weer, op basis van de spanning en het belang van stakeholders, aangevuld met het standpunt, participatieniveau en de opgegeven te volgen strategie. Wederom kan er worden gekozen om te selecteren op categorie stakeholder. Hiernaast kan je ook op het kalendericoon een bepaalde datum selecteren. SOM SET geeft vervolgens weer wat op dat moment in tijd per stakeholder de stand van zaken was met betrekking tot de kleur/de grootte van het belang, het belang zelf, de spanning, het standpunt en de strategie. Het geheel kan worden gedownload in PDF- en Excel format.</p>
        <p>Door verschillende momenten door de tijd heen met elkaar te vergelijken, kan je zien in hoeverre er (eventueel per stakeholder) vooruitgang is geboekt.</p>
        <h4>5.9. Stakeholder-issueanalyse</h4>
        <p>De stakeholder-issueanalyse wordt buiten SOM SET gemaakt. Het gaat om een overzicht van issues en de daarbij horende stakeholders. Omdat dit een belangrijke eerste stap is in de SOM-analyse kan deze worden ge&uuml;pload als afbeelding (JPG, PNG) of PDF, via de linker kolom: &lsquo;Rapportages&rsquo; &gt; &lsquo;Stakeholder-issueanalyse&rsquo;. Dit kan bijvoorbeeld een foto van een brown paper zijn of een online gemaakte mindmap. </p>
        <h4>5.10. Contacten</h4>
        <p>De laatste te downloaden rapportage betreft een overzicht van alle contacten, ofwel contactpersonen. Hierop zijn, indien ingevuld, zaken als adresgegevens, e-mailadressen of telefoonnummers te vinden. De contactenlijst kan worden gedownload in PDF en Excel format. </p>
        <h3><a id="zoeken">6. Zoeken</a></h3>
        <p>Als je rechtsboven een zoekopdracht uitvoert worden de zoekresultaten weergeven in SOM SET. Links worden alle hits weergeven, waarbij je aan de rechterkant van de pagina onderscheid kunt maken tussen &lsquo;type&rsquo; en &lsquo;projecten&rsquo;. Het &lsquo;type&rsquo; resultaten kunnen bijvoorbeeld bepaalde stakeholders of issues zijn, een contactpersoon, een issue-stakeholder combinatie et cetera. Daaronder kan je je zoekresultaten specificeren per &lsquo;project&rsquo;. Als je hier niets hebt aangekruist, zoekt SOM SET in alle projecten binnen de SOM SET omgeving. Maar je kunt er ook voor kiezen om binnen een of meerdere geselecteerde projecten te zoeken. </p>
        <p>Binnen elk van deze onderdelen komen de issues die het meest relevant zijn bovenaan te staan. Als je bijvoorbeeld zoekt op het woord &lsquo;gemeente&rsquo; zullen binnen de stakeholders eerst alle stakeholders komen die het woord gemeente in de naam hebben. Dus: &lsquo;Gemeente Amsterdam&rsquo; komt voor &lsquo;Amsterdam&rsquo; waar het woord gemeente alleen in de beschrijving staat. </p>
        <p>Als je zoekt terwijl je in een project aan het werken bent, zoekt SOM SET automatisch binnen dit project. Als je vanuit het Dashboard of de Help sectie zoekt zoek je automatisch over de projecten heen. Overigens kan je je zoekactie altijd aanpassen door vinkjes van projecten aan of uit te zetten.</p>
        <h3><a id="notities">7. Notities</a></h3>
        <p>Niet alle informatie die je vast wilt leggen over een project past binnen het kader van de stakeholder- of issue analyse. Daarom is het ook mogelijk een notitie toe te voegen in SOM SET. </p>
        <p>Je kan een notitie toevoegen door in het projectoverzicht &gt; &lsquo;Notities&rsquo; &gt; op &lsquo;Nieuwe notitie&rsquo; te klikken. Het overzicht is tevens te vinden onder &lsquo;Notities&rsquo;, waarbij wederom geselecteerd kan worden op stakeholder. Bij het aanmaken of bewerken van notities is het mogelijk deze te koppelen aan bepaalde Issues of Stakeholders. Het is ook mogelijk om bepaalde bestanden te uploaden onder je notitie. </p>
        <h3><a id="media">8. Bestanden</a></h3>
        <p>Niet alle relevante informatie voor het omgevingsmanagement van een project wordt in SOM SET gemaakt. Het is daarom mogelijk om andere bestanden toe te voegen in SOM SET en deze wederom te koppelen aan een of meerdere stakeholders of issues. Dit kunnen alle bestandstypen zijn. Denk daarbij bijvoorbeeld aan kaarten, afbeeldingen en rapporten over een specifiek issue. </p>
        <h3><a  id="projectbeheer">9. Projectbeheer</a></h3>
        <h4>9.1. Projectleden</h4>
        <p>Onder &lsquo;Projectleden&rsquo; krijg je een precies overzicht van alle leden die (actief) onderdeel uitmaken van het desbetreffende project. Je kunt hier de verschillende toegewezen rollen opzoeken of zien wanneer iemand voor het laatst heeft ingelogd. Afhankelijk van je eigen rol, kan je er ook voor kiezen om bepaalde projectleden te verwijderen uit het project. </p>
        <h4>9.2 Project bewerken</h4>
        <p>Onder &lsquo;Project bewerken&rsquo; krijg je de mogelijkheid, afhankelijk van je toegewezen rechten, om de grove project- &lsquo;instellingen en regels&rsquo; te wijzigen. Je kunt er bijvoorbeeld voor kiezen om de naam, foto of de omschrijving van het project te wijzigen. </p>
        <p>Dit is ook de plek waar je de eventueel extra toegevoegde stakeholder- of issuevelden kan wijzigingen (verwijderen) of juist (extra) kan aanmaken. Daarnaast kan aan de hand van het kaartje de locatie (GIS) van het project of issues als middelpunt op de kaart aangepast worden of kan deze juist weer verwijderd worden. </p>
        <p>Tot slot kan je de &lsquo;Zichtbare velden&rsquo;, dit zijn de analyse velden stakeholders of analyse velden issues, hier bepalen. Je kunt ervoor kiezen om deze allemaal in het project op te nemen, of om bepaalde juist achterwege te laten (i.e. uitzetten). Aan de hand van de vinkjes kan je hier als gebruiker je eigen keuzes in maken. Spreek met elkaar van tevoren goed af welke velden je in dit project wilt analyseren en welke niet. Deze keuzes zijn van invloed op de analyse in je project. </p>
        <h4>9.3 Project archiveren </h4>
        <p>Je kunt er, wederom afhankelijk van de aan jou toebedeelde rechten, voor kiezen om een project te archiveren. Alleen de administrator kan deze echter weer de-archiveren. </p>
        <h3><a id="versiebeheer">10. Versiebeheer</a></h3>
        <p>Wijzigingen worden opgeslagen iedere keer dat er op opslaan geklikt wordt. De laatste keer opslaan op een dag wordt vastgehouden. Het is bij alle stakeholders, issues en bij de belangenmatrix, spanningsbarometer en omgevingsmonitor mogelijk om terug te kijken wat de inhoud was op iedere willekeurige dag. Dit doe je door rechts bij &lsquo;Bekijk een andere dag&rsquo; een datum in te vullen, of te selecteren door op het kalender-icoon te klikken. </p>
        <p>Bij issues en stakeholders komt dit in grijs onder de huidige waarde te staan zodat je dit kan vergelijken. Bij de rapportages wordt slechts weergegeven hoe de rapportage er op desbetreffende datum uitzag. Oude overzichten kunnen ook worden gedownload, net als de laatste versie. </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-list-content {
  font-size: calc-rem(14);
  line-height: calc-rem(20);

  &__list {
    list-style: none;
    column-count: 2;
    column-gap: calc-rem(20);
    border-top: calc-rem(1) solid $separatorLineColor;
    border-bottom: calc-rem(1) solid $separatorLineColor;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content {
    h3 {
      margin-top: 0;
      margin-bottom: calc-rem(20);
      padding-top: calc-rem(15);
      font-size: calc-rem(16);
      font-weight: 600;
      line-height: calc-rem(18);
      color: $primary;
    }

    h4 {
      margin-top: 0;
      margin-bottom: calc-rem(5);
      padding-top: calc-rem(5);
      font-weight: 700;
    }

    p,
    ol,
    ul {
      margin-top: 0;
      margin-bottom: calc-rem(15);
    }

    ol,
    ul {
      padding-left: calc-rem(20);

      li {
        &:not(:last-child) {
          margin-bottom: calc-rem(10);
        }
      }
    }
  }
}
</style>
