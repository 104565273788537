<script setup>
// import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';
import sanitizeHtml from 'sanitize-html';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import UiDocumentHistoryCard from '@/core/ui/UiDocumentHistoryCard';
import UiCardTitle from '@/core/ui/UiCardTitle';
import UiDownloadButton from '@/core/ui/UiDownloadButton';

// Store imports
import { useNotesStore } from '@/store/modules/notes';

// Variables
const { loadingNote, editNote } = storeToRefs(useNotesStore());
const route = useRoute();
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const {  } = useNotesStore();
const configNote = {
  descriptionTitle: t('ProjectNote.Frontend.Description'),
  bodyTitle: t('ProjectNote.Body'),
  modTitle: t('ProjectNote.Frontend.PublicationNote'),
  startDateTitle: t('ProjectNote.StartDate'),
  publishedText: t('ProjectNote.Frontend.PublicationDate'),
  hourBy: t('ProjectNote.Frontend.HourBy'),
  stakeholderTitle: t('ProjectNote.Frontend.Stakeholders'),
  issueTitle: t('ProjectNote.Frontend.Issues')
};
const configHistory = {
  cardTitle: 'General.DocumentHistory',
  moderator: '',
  moderatorUrl: '/',
  author: '',
  authorUrl: '/',
  dateTime: '',
  subtitleModerator: 'General.LastUpdated',
  subtitleAuthor: 'General.Author',
  moderatorInfo: 'General.By'
};

// Functions

</script>

<template>
  <main>
    <div class="note-detail" v-if="!loadingNote">
      <div class="note-detail__col">
        <p-card class="note-detail__card">
          <template #content>
            <div class="note-detail__content pt-2 pb-2 pe-1 ps-1">
              <h2 class="note-detail__title mt-0 mb-5">
                {{ editNote.name }}
              </h2>
              <h3 class="note-detail__subtitle mt-0 mb-2" v-if="editNote.description != null && editNote.description.length > 0">
                {{ configNote.descriptionTitle }}
              </h3>
              <div v-html="sanitizeHtml(editNote.description)" v-if="editNote.description != null && editNote.description.length > 0">
              </div>
              <h3 class="note-detail__subtitle mt-0 mb-2" v-if="editNote.body != null && editNote.body.length > 0">
                {{ configNote.bodyTitle }}
              </h3>
              <div v-html="sanitizeHtml(editNote.body)" v-if="editNote.body != null && editNote.body.length > 0">
              </div>
            </div>
            <div class="note-detail__meta row position-relative">
              <div class="col-5">
                <div v-if="editNote.stakeholders.length > 0">
                  <h5 class="mt-3 mb-1">
                    {{ configNote.stakeholderTitle }}
                  </h5>
                  <p class="mt-0 mb-0 --link">
                    <span v-for="(stakeholder, index) in editNote.stakeholders" :key="stakeholder.id">
                      {{ index !== 0 ? ', ' : ''}}<router-link :to="'/projects/' + projectId + '/stakeholders/' + stakeholder.id">{{ stakeholder.name }}</router-link>
                    </span>
                  </p>
                </div>
                <div v-if="editNote.issues.length > 0">
                  <h5 class="mt-3 mb-1">
                    {{ configNote.issueTitle }}
                  </h5>
                  <p class="mt-0 mb-0 --link">
                    <span v-for="(issue, index) in editNote.issues" :key="issue.id">
                      {{ index !== 0 ? ', ' : ''}}<router-link :to="'/projects/' + projectId + '/issues/' + issue.id">{{ issue.name }}</router-link>
                    </span>
                  </p>
                </div>
              </div>
              <div class="col-7">
                <h5 class="mt-3 mb-1" v-if="editNote.startDate.length > 0">
                  {{ configNote.startDateTitle }}
                </h5>
                <p class="mt-0 mb-0" v-if="editNote.startDate.length > 0">
                  {{ useMoment(editNote.startDate).format('DD-MM-YYYY') }}
                </p>
                <h5 class="mt-3 mb-1">
                  {{ configNote.modTitle }}
                </h5>
                <p class="mt-0 mb-0">
                  {{ configNote.publishedText }} {{ useMoment(editNote.modDate).format('DD-MM-YYYY HH:mm') }} {{ configNote.hourBy }} <span>{{ (editNote.modUser.firstname || editNote.modUser.lastname
              ? (editNote.modUser.firstname ?? '') +
              ' ' +
              (editNote.modUser.lastname ?? '')
              : editNote.modUser.userName) }}</span>
                </p>
              </div>
            </div>
          </template>
        </p-card>
      </div>
      <div class="note-detail__col">
        <ui-document-history-card v-if="editNote.addUser != null && editNote.modUser != null"
          class="note-detail__card"
          :card-title="$t(configHistory.cardTitle)"
          :moderator="editNote.modUser.firstname || editNote.modUser.lastname
              ? (editNote.modUser.firstname ?? '') +
              ' ' +
              (editNote.modUser.lastname ?? '')
              : editNote.modUser.userName"
          :moderator-url="'/profile/' + editNote.modUser.id"
          :date-time="useMoment(editNote.modUser.modDate).format('DD-MM-YYYY HH:mm')"
          :moderator-info="$t(configHistory.moderatorInfo)"
          :author="editNote.addUser.firstname || editNote.addUser.lastname
              ? (editNote.addUser.firstname ?? '') +
              ' ' +
              (editNote.addUser.lastname ?? '')
              : editNote.addUser.userName"
          :author-url="'/profile/' + editNote.addUser.id"
          :subtitle-author="$t(configHistory.subtitleAuthor)"
          :subtitle-moderator="$t(configHistory.subtitleModerator)">
        </ui-document-history-card>
        <p-card v-if="editNote.notesBinary && editNote.notesBinary.length > 0">
          <template #content>
            <ui-card-title class="pt-2 ps-1">
              {{ $t('General.Annex') }}
            </ui-card-title>
            <div class="pb-2 pe-1 ps-1" v-for="media in editNote.notesBinary" :key="media.id">
              <ui-download-button
                :label="$t('General.Download') + ' ' + media.binaryFilename"
                :filename="media.binaryFilename"
                :type="media.binaryContentType"
                :endpoint="'media/' + media.id +'/download'"
              >
              </ui-download-button>
            </div>
          </template>
        </p-card>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.note-detail {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: calc-rem(10);

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02em;
  }

  &__content {
    font-size: calc-rem(14);
    line-height: calc-rem(20);
  }

  &__subtitle {
    font-size: calc-rem(16);
    font-weight: 600;
    line-height: calc-rem(18);
    color: $secondaryTextColor;
  }

  &__card {
    margin-bottom: calc-rem(9);
  }

  &__meta {
    &::before {
      position: absolute;
      top: 0;
      left: calc-rem(5);
      width: calc(100% - 10px);
      height: calc-rem(1);
      content: '';
      background-color: $separatorLineColor;
    }

    h5 {
      font-size: calc-rem(12);
      line-height: calc-rem(14);
      letter-spacing: -.01em;
      color: $secondaryTextColor;
    }

    p {
      font-size: calc-rem(12);
      line-height: calc-rem(16);
      span {
        color: $primary;
      }
    }

    .--link a {
      text-decoration: none!important;
      color: #005FE3!important;
    }

    .--link a:hover{
      text-decoration: underline!important;
    }

  }
}
</style>
