<script setup>
// Define props
const props = defineProps({
  title: String
});
</script>

<template>
  <fieldset class="ui-fieldset me-0 ms-0 p-0">
    <legend class="ui-fieldset__title mb-3">
      {{ props.title }}
    </legend>
    <slot></slot>
  </fieldset>
</template>

<style lang="scss">
.ui-fieldset {
  border: none;

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.2px;
  }
}
</style>
