<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import sanitizeHtml from 'sanitize-html';
import { useI18n } from 'vue-i18n';
import useMoment from 'moment';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PBadge from 'primevue/badge';
import PDialog from 'primevue/dialog';
import PDropdown from 'primevue/dropdown';
import POverlayPanel from 'primevue/overlaypanel';
import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
import PCalendar from '../../core/ui/calendar/CalendarCustom';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholders: Object,
  stakeholdersByStatus: Object,
  stakeholdersToCompareByStatus: Object,
  loadingProjectStakeholdersToCompare: Boolean,
  calendarValue: Date
});

// Define emits
const emit = defineEmits([
  'getCompare'
]);

// Variables
const { project } = storeToRefs(useProjectsStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const {
  updateStakeholderConcernFromMatrix,
  updateStakeholderConcernTextFromMatrix,
  updateStakeholderStatusFromMatrix,
  updateStakeholderPointOfViewTextFromMatrix,
  updateStakeholderParticipationFromMatrix,
  updateStakeholderParticipationTextFromMatrix
} = useStakeholdersStore();

// const opColor = ref();
// const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
// const onFocusOverlayPanelInput = () => {
//   focusOverlayPanelInput.value = true;
//   showOverlayPanelButtons.value = true;
// };
// const onBlurOverlayPanelInput = () => {
//   focusOverlayPanelInput.value = false;
// };
// const hideOverlayPanelButtons = () => {
//   showOverlayPanelButtons.value = false;
// };
// const cancelSelectColor = () => {
//   opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
const scrollRight = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value += 1;
  scrollValue.value -= scrollDistance;

  // Add class to 1st column and enable left button
  if (countClicks.value === 1) {
    disableLeftButton.value = false;
    addShadowClass.value = true;
  }
  // Disable right button on max clicks
  if (countClicks.value === maxClicks) {
    disableRightButton.value = true;
  }
};
const scrollLeft = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value -= 1;
  scrollValue.value += scrollDistance;

  // Disable left button and remove class from 1st column
  if (countClicks.value === 0) {
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }

  // Enable right button
  if (countClicks.value < maxClicks) {
    disableRightButton.value = false;
  }
};
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Cancel')
  },
  concernTooltip: {
    reset: t('General.Importance.No'),
    extremlyHighImportance: t('General.Importance.Extreme'),
    greatImportance: t('General.Importance.Great'),
    reasonablyImportance: t('General.Importance.Reasonable'),
    hardlyImportance: t('General.Importance.Hardly'),
    unclear: t('Status.Onduidelijk')
  },
  importanceTooltip: {
    reset: t('Status.NoStress'),
    criticism: t('Status.Kritiek'),
    warning: t('Status.Waarschuwing'),
    focus: t('Status.Aandacht'),
    positive: t('Status.Positief'),
    unclear: t('Status.Onduidelijk')
  }
};

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});

const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      // 'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};

const strategyOption = ref([
  {
    name: '',
    value: null
  },
  {
    name: t('Participation.Informeren'),
    value: 1
  },
  {
    name: t('Participation.Communiceren'),
    value: 2
  },
  {
    name: t('Participation.Ad.hoc.betrokken'),
    value: 3
  },
  {
    name: t('Participation.Participeren'),
    value: 4
  },
  {
    name: t('Participation.Consensus'),
    value: 5
  },
  {
    name: t('Participation.Empowerment'),
    value: 6
  }
]);

const getConcern = (concernNr) => {
  switch (concernNr) {
    case 5:
      return { className: '--unclear', name: configMatrix.concernTooltip.unclear };
    case 4:
      return { className: '--hardlyimportance', name: configMatrix.concernTooltip.hardlyimportance };
    case 3:
      return { className: '--reasonablyimportance', name: configMatrix.concernTooltip.reasonablyimportance };
    case 2:
      return { className: '--greatimportance', name: configMatrix.concernTooltip.greatimportance };
    case 1:
      return { className: '--extremlyhighimportance', name: configMatrix.concernTooltip.extremlyhighimportance };
    default:
      return { className: null, name: null };
  }
};

const getImportance = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return { className: '--unclear', name: configMatrix.importanceTooltip.unclear };
    case 4:
      return { className: '--positive', name: configMatrix.importanceTooltip.positive };
    case 3:
      return { className: '--focus', name: configMatrix.importanceTooltip.focus };
    case 2:
      return { className: '--warning', name: configMatrix.importanceTooltip.warning };
    case 1:
      return { className: '--criticism', name: configMatrix.importanceTooltip.criticism };
    default:
      return { className: null, name: null };
  }
};

const importanceClassName = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return '--unclear';
    case 4:
      return '--positive';
    case 3:
      return '--focus';
    case 2:
      return '--warning';
    case 1:
      return '--criticism';
  }
};

const importanceText = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Positief');
    case 3:
      return t('Status.Aandacht');
    case 2:
      return t('Status.Waarschuwing');
    case 1:
      return t('Status.Kritiek');
  }
};
const importanceStatusCount = (importanceNr) => {
  const importance = props.stakeholdersByStatus.panels.find((x) => { return x.id === importanceNr; });
  if (importance) {
    return importance.panel.length;
  }
  return 0;
};
const importanceStatusCountToCompare = (importanceNr) => {
  const importance = props.stakeholdersToCompareByStatus.panels.find((x) => { return x.id === importanceNr; });
  if (importance) {
    return importance.panel.length;
  }
  return 0;
};

const concernClassName = (concernNr) => {
  switch (concernNr) {
    case 5:
      return '--unclear';
    case 4:
      return '--hardlyimportance';
    case 3:
      return '--reasonablyimportance';
    case 2:
      return '--greatimportance';
    case 1:
      return '--extremlyhighimportance';
  }
};
const concernText = (concernNr) => {
  switch (concernNr) {
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Nauwelijks.belang');
    case 3:
      return t('Status.Redelijk.belang');
    case 2:
      return t('Status.Groot.belang');
    case 1:
      return t('Status.Extreem.groot.belang');
  }
};

watch(() => props.stakeholders, (first, second) => {
  // console.info('stakeholders', first);
  stakeholders.value = first;
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

// This collects dynamic refs for all crosspoints
// const projectImportanceMatrix = ref(null);
const stakeholders = ref(null);
const concern = ref([]);
// const concernTextareas = ref([]);
const importance = ref([]);
// const importanceTextareas = ref([]);
const stakeholderStrategy = ref([]);
const stakeholderStrategyLabel = ref([]);
const toggleConcern = (event, concernId) => {
  // console.info(concern, concernId);
  // console.info('concernId', crossPoints.value[concernId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectprogressmatrixview.edit', currentUser.value, props.projectId)) {
    concern.value[concernId].toggle(event);
  }
};
const toggleImportance = (event, importanceId) => {
  // console.info(importance, importanceId);
  // console.info('importanceId', crossPoints.value[importanceId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectprogressmatrixview.edit', currentUser.value, props.projectId)) {
    importance.value[importanceId].toggle(event);
  }
};
const updateStakeholderConcernValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
      return x.id === stakeholderId;
    });
    if (stakeholder) {
      stakeholder.concernNr = value;
      document.querySelector('#concern-' + stakeholderId + ' .p-button-label').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderConcernFromMatrix(project.value, stakeholderId, value);
    }
  }
};
const updateStakeholderStatusValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
      return x.id === stakeholderId;
    });
    if (stakeholder) {
      stakeholder.statusNr = value;
      document.querySelector('#importance-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderStatusFromMatrix(project.value, stakeholderId, value);
    }
  }
};
const toggleStakeholderStrategy = (event, stakeholderId) => {
  if (IsActionAllowedInRole('projectprogressmatrixview.edit', currentUser.value, props.projectId)) {
    stakeholderStrategy.value[stakeholderId].toggle(event);
  }
};
const updateStakeholderStrategy = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#strategyOptionLabel' + stakeholderId + '').innerText = valueText;
  updateStakeholderParticipationFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderStrategy.value[stakeholderId].toggle(event);
};
const showMoreDialog = ref(false);
const showMoreDialogStakeholderId = ref(null);
const showMoreDialogAction = ref(null);
const showMoreDialogName = ref(null);
const showMoreDialogTitle = ref(null);
const showMoreDialogText = ref(null);
const showMore = (stakeholderId, stakeholderName, action) => {
  if (!IsActionAllowedInRole('projectprogressmatrixview.edit', currentUser.value, props.projectId)) {
    return;
  }

  showMoreDialog.value = true;
  showMoreDialogStakeholderId.value = stakeholderId;
  showMoreDialogAction.value = action;
  switch (showMoreDialogAction.value) {
    case 'concern':
      showMoreDialogTitle.value = t('IssueStakeholder.Concern') + ' ' + stakeholderName;
      break;
    case 'importance':
      showMoreDialogTitle.value = t('General.Tension') + ' ' + stakeholderName;
      break;
    case 'strategy':
      showMoreDialogTitle.value = t('Stakeholder.Strategy') + ' ' + stakeholderName;
      break;
  }
  showMoreDialogText.value = document.querySelector('#' + showMoreDialogAction.value + 'Text-' + showMoreDialogStakeholderId.value).innerHTML;
};
const saveShowMoreDialog = () => {
  document.querySelector('#' + showMoreDialogAction.value + 'Text-' + showMoreDialogStakeholderId.value).innerHTML = showMoreDialogText.value;
  document.querySelector('#' + showMoreDialogAction.value + 'TextEditButton-' + showMoreDialogStakeholderId.value + ' a').innerText = showMoreDialogText.value ? 'Bewerken' : 'Toevoegen';
  switch (showMoreDialogAction.value) {
    case 'concern':
      updateStakeholderConcernTextFromMatrix(project.value, showMoreDialogStakeholderId.value, showMoreDialogText.value);
      break;
    case 'importance':
      updateStakeholderPointOfViewTextFromMatrix(project.value, showMoreDialogStakeholderId.value, showMoreDialogText.value);
      break;
    case 'strategy':
      updateStakeholderParticipationTextFromMatrix(project.value, showMoreDialogStakeholderId.value, showMoreDialogText.value);
      break;
  }
  closeShowMoreDialog();
};
const closeShowMoreDialog = () => {
  showMoreDialog.value = false;
  showMoreDialogStakeholderId.value = null;
  showMoreDialogName.value = null;
  showMoreDialogTitle.value = null;
  showMoreDialogText.value = null;
};

const selectedStatus = ref({ name: null, status: null });

// Compare
const compareDate = ref(null);
const compareDates = () => {
  const compareDateVal = compareDate.value === null ? null : useMoment(compareDate.value).format('YYYY-MM-DD');
  if (compareDateVal === useMoment().format('YYYY-MM-DD')) {
    compareDate.value = null;
  }
  emit('getCompare', compareDate.value);
};
const getCompareStakeholderData = (stakeholderId) => {
  let foundStakeholder = null;
  props.stakeholdersToCompareByStatus.panels.forEach(statusPanel => {
    const stakeholder = statusPanel.panel.find((x) => { return x.id === stakeholderId; });
    if (stakeholder !== null && stakeholder !== undefined) {
      foundStakeholder = stakeholder;
    }
  });
  if (foundStakeholder !== null) {
    return foundStakeholder;
  }
  return {
    id: stakeholderId,
    title: null,
    urlTo: null,
    className: null,
    participation: null,
    participationText: null,
    emotion: null,
    urgency: null,
    concernNr: null,
    concernText: null,
    statusNr: null,
    pointOfView: null
  };
};
const showStakeholderCategory = (category) => {
  return category.panel.filter((x) => { return x.statusNr === selectedStatus.value.status || selectedStatus.value.status === null; });
};
</script>

<template>
  <main>
    <p-card
      class="progress-matrix position-relative"
      v-if="stakeholders">
      <template #content>
        <div class="d-flex p-4">
          <p-dropdown v-model="selectedStatus" :placeholder="$t('General.FilterStatus')" :options="[
            { name: $t('General.FilterStatus'), status: null },
            { name: importanceText(1), status: 1 },
            { name: importanceText(2), status: 2 },
            { name: importanceText(3), status: 3 },
            { name: importanceText(4), status: 4 },
            { name: importanceText(5), status: 5 }
          ]" optionLabel="name"
          scrollHeight="400px"></p-dropdown>
        </div>
        <div class="d-flex p-4">
          <span class="date-importance-badge d-inline-block d-flex align-items-center"><strong>{{ props.calendarValue ? useMoment(props.calendarValue).format('DD-MM-YYYY') : useMoment().format('DD-MM-YYYY') }}</strong>: </span><p-badge
            v-for="item in [1, 2, 3, 4, 5]" :key="'count-importance-' + item"
            class="importance__badge me-2 matrix__button d-flex justify-content-center align-items-center"
            :class="importanceClassName(item)"
            :title="importanceText(item)">
            {{ importanceStatusCount(item) }}
          </p-badge>
        </div>
        <div class="d-flex p-4">
          <p-calendar
            class="date-importance-badge"
            v-model="compareDate"
            dateFormat="dd-mm-yy"
            :show-icon="true"
            icon="pi pi-calendar"
            :minDate="project.AddDate"
            :maxDate="new Date()"
            :showButtonBar="true"
            @dateSelect="compareDates()">
          </p-calendar>
          <div v-if="compareDate" class="d-flex">
            <p-badge
              v-for="item in [1, 2, 3, 4, 5]" :key="'count-importance-' + item"
              class="importance__badge me-2 matrix__button d-flex justify-content-center align-items-center"
              :class="importanceClassName(item)"
              :title="importanceText(item)">
              {{ importanceStatusCountToCompare(item) }}
            </p-badge>
          </div>
          <span v-if="loadingProjectStakeholdersToCompare" class=" d-inline-block d-flex align-items-center">De waarden worden ingeladen</span>
        </div>
      </template>
    </p-card>
    <p-card
      class="progress-matrix position-relative"
      v-if="stakeholders">
      <template #content>
        <div class="matrix__grid">
          <div
            class="matrix__grid-item"
            :class="{ '--shade': addShadowClass }">
            <!-- RENDER STAKEHOLDERS -->
            <div
              v-for="stakeholderCategory in stakeholders.panels.filter((x) => { return showStakeholderCategory(x).length > 0;})"
              :key="'stakeholderCategory' + stakeholderCategory.id">
              <h3 class="matrix__stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                <span>
                  {{ stakeholderCategory.panelTitle }}
                </span>
              </h3>
              <ul class="matrix__stakeholders mt-0 mb-0 ps-0">
                <li
                  v-for="stakeholder in (selectedStatus.status !== null ? stakeholderCategory.panel.filter((x) => { return x.statusNr === selectedStatus.status }) : stakeholderCategory.panel)"
                  :key="'stakeholder' + stakeholder.id"
                  class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center --tall"
                  id="shale_drillo"
                  role="rowheader">
                  <!-- span>{{ stakeholder.title }}</span -->
                  <router-link :to="'/projects/' + projectId + '/stakeholders/' + stakeholder.id">
                    {{ stakeholder.title}}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="matrix__grid-item overflow-hidden">
            <div
              class="matrix__scroll-window w-100 h-100 position-relative"
              ref="scrollWindow">
              <div
                class="matrix__matrix position-absolute h-100"
                ref="matrix"
                :style="'transform: translateX( ' + scrollValue + 'px)'">
                <div
                  class="progress-matrix__table"
                  :class="{ '--border-right': !showScrollButtons, 'progress-matrix__table-compare' : compareDate }"
                  role="table"
                  aria-label="Progessmatrix">
                  <div
                    class="d-flex"
                    role="row">
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --smallcolumn"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('Stakeholder.ConcernText') }}
                      </span>
                    </div>
                    <div
                      v-if="1 === 2 && compareDate"
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --smallcolumn"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('Stakeholder.ConcernText.On') }}: {{useMoment(compareDate).format('DD-MM-YYYY')}}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('Stakeholder.ConcernText') }}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --smallcolumn"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('General.Tension') }}
                      </span>
                    </div>
                    <div
                      v-if="compareDate"
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --smallcolumn"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('IssueStakeholder.ImportanceThen') }}: {{useMoment(compareDate).format('DD-MM-YYYY')}}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --text-only --smallcolumn"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('IssueStakeholder.Opinion') }}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('IssueStakeholder.Participation') }}
                      </span>
                    </div>
                    <div
                      v-if="compareDate"
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('IssueStakeholder.ParticipationThen')}}: {{useMoment(compareDate).format('DD-MM-YYYY')}}
                      </span>
                    </div>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center --text-only"
                      role="columnheader">
                      <span class="mt-auto">
                        {{ $t('IssueStakeholder.Strategy') }}
                      </span>
                    </div>
                  </div>
                  <!-- RENDER SECOND ROW - FIRST STAKEHOLDERCATEGORY AND SO ON -->
                  <template
                    v-for="(crosspointStakeholderCategoryPanel, crosspointStakeholderCategoryPanelIndex) in props.stakeholders.panels.filter((x) => { return showStakeholderCategory(x).length > 0;})"
                    :key="'crosspointStakeholderCategoryPanel' + crosspointStakeholderCategoryPanel.id">
                    <div
                      class="d-flex"
                      role="row">
                      <div class="matrix__td --category" role="cell"></div>
                      <div class="matrix__td --category" role="cell" v-if="1 === 2 && compareDate"></div>
                      <div class="matrix__td --category --wide" role="cell"></div>
                      <div class="matrix__td --category" role="cell"></div>
                      <div class="matrix__td --category" role="cell" v-if="compareDate"></div>
                      <div class="matrix__td --category --wide" role="cell"></div>
                      <div class="matrix__td --category --wide" role="cell"></div>
                      <div class="matrix__td --category --wide" role="cell" v-if="compareDate"></div>
                      <div class="matrix__td --strategy" role="cell"></div>
                    </div>
                    <div
                      v-for="crosspointStakeholderPanel in (selectedStatus.status !== null ? crosspointStakeholderCategoryPanel.panel.filter((x) => { return x.statusNr === selectedStatus.status }) : crosspointStakeholderCategoryPanel.panel)"
                      :key="'crosspointStakeholderPanel' + crosspointStakeholderPanel.id"
                      class="d-flex"
                      role="row">
                      <div
                        class="matrix__td d-flex importanceconcern__button"
                        role="cell">
                        <p-button
                          class="matrix__button p-0"
                          :class="getConcern(crosspointStakeholderPanel.concernNr).className"
                          :label="concernText(crosspointStakeholderPanel.concernNr)"
                          aria-haspopup="true"
                          :aria-controls="'concern-' + crosspointStakeholderPanel.id"
                          :id="'concern-' + crosspointStakeholderPanel.id"
                          v-tooltip.top="concernText(crosspointStakeholderPanel.concernNr)"
                          @click="toggleConcern($event, 'concern-' + crosspointStakeholderPanel.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { concern['concern-' + crosspointStakeholderPanel.id] = el; }"
                          :id="'concern-' + crosspointStakeholderPanel.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--unclear', }"
                              :label="configMatrix.concernTooltip.unclear"
                              v-tooltip.top="configMatrix.concernTooltip.unclear"
                              @click="updateStakeholderConcernValue(5, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --hardlyimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--hardlyimportance',}"
                              :label="configMatrix.concernTooltip.hardlyImportance"
                              v-tooltip.top="configMatrix.concernTooltip.hardlyImportance"
                              @click="updateStakeholderConcernValue(4, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --reasonablyimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--reasonablyimportance',}"
                              :label="configMatrix.concernTooltip.reasonablyImportance"
                              v-tooltip.top="configMatrix.concernTooltip.reasonablyImportance"
                              @click="updateStakeholderConcernValue(3, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --greatimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--greatimportance', }"
                              :label="configMatrix.concernTooltip.greatImportance"
                              v-tooltip.top="configMatrix.concernTooltip.greatImportance"
                              @click="updateStakeholderConcernValue(2, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --extremlyhighimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--extremlyhighimportance',}"
                              :label="configMatrix.concernTooltip.extremlyHighImportance"
                              v-tooltip.top="configMatrix.concernTooltip.extremlyHighImportance"
                              @click="updateStakeholderConcernValue(1, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                          </div>
                          <!-- textarea class="
                                matrix__overlay-panel-input
                                pe-2
                                ps-2
                                w-100
                              " :placeholder="'Toelichting'" :ref="
                                (el) => {
                                  concernTextareas[
                                    'concern-' +
                                    crosspointStakeholderPanel.id
                                  ] = el;
                                }
                              " @focus="onFocusOverlayPanelInput()" @blur="onBlurOverlayPanelInput()"></textarea>
                          <Transition name="fade">
                            <div class="d-flex justify-content-end" v-if="showOverlayPanelButtons">
                              <p-button class="
                                    matrix__overlay-panel-button
                                    p-button-text
                                    me-1
                                  " :label="
                                    configMatrix.overlayPanel.cancelButton
                                  " @mousedown="cancelSelectColor()">
                              </p-button>
                              <p-button class="matrix__overlay-panel-button"
                                :label="configMatrix.overlayPanel.saveButton" @mousedown="
                                  updateStakeholderConcernValueDescription(
                                    null,
                                    'concern-' +
                                    crosspointStakeholderPanel.id,
                                    crosspointStakeholderPanel.id
                                  )
                                ">
                              </p-button>
                            </div>
                          </Transition -->
                        </p-overlay-panel>
                      </div>
                      <div
                        v-if="1 === 2 && compareDate"
                        class="matrix__td d-flex importanceconcern__button"
                        role="cell">
                        <p-button
                          v-if="getCompareStakeholderData(crosspointStakeholderPanel.id).concernNr"
                          class="matrix__button p-0"
                          :class="getConcern(getCompareStakeholderData(crosspointStakeholderPanel.id).concernNr).className"
                          :label="getConcern(getCompareStakeholderData(crosspointStakeholderPanel.id).concernNr).name"
                          v-tooltip.top="getConcern(crosspointStakeholderPanel.concernNr).name">
                        </p-button>
                      </div>
                      <div
                        xclass="matrix__td d-flex --wide"
                        class="matrix__td --wide"
                        role="cell">
                        <div
                          class="matrix__strategy-text mt-0 mb-0 pe-2 ps-2"
                          :id="'concernText-' + crosspointStakeholderPanel.id"
                          v-html="sanitizer(crosspointStakeholderPanel.concernText ?? '')">
                        </div>
                        <div class="matrix__strategy-more mt-2 mb-0 pe-2 ps-2 w-100" :id="'concernTextEditButton-' + crosspointStakeholderPanel.id">
                          <a @click="showMore(crosspointStakeholderPanel.id, crosspointStakeholderPanel.title, 'concern')">{{ crosspointStakeholderPanel.concernText ? 'Bewerken' : 'Toevoegen' }}</a>
                        </div>
                      </div>
                      <div
                        class="matrix__td d-flex importanceconcern__button"
                        role="cell">
                        <p-button
                          class="matrix__button p-0"
                          :class="getImportance(crosspointStakeholderPanel.statusNr).className"
                          :label="getImportance(crosspointStakeholderPanel.statusNr).name"
                          aria-haspopup="true"
                          :aria-controls="'importance-' + crosspointStakeholderPanel.id"
                          :id="'importance-' + crosspointStakeholderPanel.id"
                          v-tooltip.top="getImportance(crosspointStakeholderPanel.statusNr).name"
                          @click="toggleImportance($event, 'importance-' + crosspointStakeholderPanel.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { importance['importance-' + crosspointStakeholderPanel.id] = el; }"
                          :id="'importance-' + crosspointStakeholderPanel.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--unclear', }" :label="configMatrix.importanceTooltip.unclear"
                              v-tooltip.top="configMatrix.importanceTooltip.unclear"
                              @click="updateStakeholderStatusValue(5, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--positive',}"
                              :label="configMatrix.importanceTooltip.positive"
                              v-tooltip.top="configMatrix.importanceTooltip.positive"
                              @click="updateStakeholderStatusValue(4, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--focus',}"
                              :label="configMatrix.importanceTooltip.focus"
                              v-tooltip.top="configMatrix.importanceTooltip.focus"
                              @click="updateStakeholderStatusValue(3, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--warning', }"
                              :label="configMatrix.importanceTooltip.warning"
                              v-tooltip.top="configMatrix.importanceTooltip.warning"
                              @click="updateStakeholderStatusValue(2, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{ '--active': getImportance(crosspointStakeholderPanel.statusNr).className == '--criticism', }"
                              :label="configMatrix.importanceTooltip.criticism"
                              v-tooltip.top="configMatrix.importanceTooltip.criticism"
                              @click="updateStakeholderStatusValue(1, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                          </div>
                          <!-- textarea class="
                                matrix__overlay-panel-input
                                pe-2
                                ps-2
                                w-100
                              " :placeholder="'Toelichting'" :ref="
                                (el) => {
                                  importanceTextareas[
                                    'importance-' +
                                    crosspointStakeholderPanel.id
                                  ] = el;
                                }
                              " @focus="onFocusOverlayPanelInput()"
                            @blur="onBlurOverlayPanelInput()"></textarea>
                          <Transition name="fade">
                            <div class="d-flex justify-content-end" v-if="showOverlayPanelButtons">
                              <p-button class="
                                    matrix__overlay-panel-button
                                    p-button-text
                                    me-1
                                  " :label="
                                    configMatrix.overlayPanel.cancelButton
                                  " @mousedown="cancelSelectColor()">
                              </p-button>
                              <p-button class="matrix__overlay-panel-button"
                                :label="configMatrix.overlayPanel.saveButton" @mousedown="
                                  updateCrossPointImportanceDescription(
                                    null,
                                    'importance-' +
                                    crosspointStakeholderPanel.id,
                                    crosspointStakeholderPanel.id
                                  )
                                ">
                              </p-button>
                            </div>
                          </Transition -->
                        </p-overlay-panel>
                      </div>
                      <div
                        v-if="compareDate"
                        class="matrix__td d-flex importanceconcern__button"
                        role="cell">
                        <p-button
                          v-if="getCompareStakeholderData(crosspointStakeholderPanel.id).statusNr"
                          class="matrix__button p-0"
                          :class="getImportance(getCompareStakeholderData(crosspointStakeholderPanel.id).statusNr).className"
                          :label="getImportance(getCompareStakeholderData(crosspointStakeholderPanel.id).statusNr).name"
                          v-tooltip.top="getImportance(crosspointStakeholderPanel.statusNr).name">
                        </p-button>
                      </div>
                      <div
                        xclass="matrix__td d-flex --wide"
                        class="matrix__td --wide"
                        role="cell">
                        <div
                          class="matrix__strategy-text mt-0 mb-0 pe-2 ps-2"
                          :id="'importanceText-' + crosspointStakeholderPanel.id"
                          v-html="sanitizer(crosspointStakeholderPanel.pointOfView ?? '')">
                        </div>
                        <div class="matrix__strategy-more mt-2 mb-0 pe-2 ps-2 w-100" :id="'importanceTextEditButton-' + crosspointStakeholderPanel.id">
                          <a @click="showMore(crosspointStakeholderPanel.id, crosspointStakeholderPanel.title, 'importance')">{{ crosspointStakeholderPanel.pointOfView ? 'Bewerken' : 'Toevoegen' }}</a>
                        </div>
                      </div>
                      <div
                        class="matrix__td d-flex --wide"
                        role="cell">
                        <div>
                          <p-button
                            class="matrix__strategy-button p-button-text"
                            :label="crosspointStakeholderPanel.participation ? crosspointStakeholderPanel.participation.name: 'Onbekend'"
                            :ref="(el) => { stakeholderStrategyLabel[crosspointStakeholderPanel.id] = el; }"
                            :id="'strategyOptionLabel' + crosspointStakeholderPanel.id"
                            @click="toggleStakeholderStrategy($event, crosspointStakeholderPanel.id)">
                          </p-button>
                          <p-overlay-panel
                            class="p-3"
                            :ref="(el) => { stakeholderStrategy[crosspointStakeholderPanel.id] = el; }">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li
                                v-for="option in strategyOption"
                                :key="'strategyOption' + crosspointStakeholderPanel.id + option.value"
                                class="d-flex align-items-center">
                                <p-radio-button
                                  :name="'strategyOption' + crosspointStakeholderPanel.id"
                                  :id="'strategyOption' + crosspointStakeholderPanel.id + option.value"
                                  :value="option.value"
                                  @click="crosspointStakeholderPanel.participation = { nr: option.value, name: option.name, }; updateStakeholderStrategy(option.value, option.name, crosspointStakeholderPanel.id, crosspointStakeholderPanel.participation);">
                                </p-radio-button>
                                <label
                                  :for="'strategyOption' + crosspointStakeholderPanel.id + option.value"
                                  class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                        </div>
                      </div>
                      <div
                        v-if="compareDate"
                        class="matrix__td d-flex --wide"
                        role="cell">
                        <div>
                          <p-button
                            v-if="getCompareStakeholderData(crosspointStakeholderPanel.id).participation"
                            class="matrix__strategy-button p-button-text"
                            :label="getCompareStakeholderData(crosspointStakeholderPanel.id).participation ? getCompareStakeholderData(crosspointStakeholderPanel.id).participation.name: 'Onbekend'">
                          </p-button>
                        </div>
                      </div>
                      <div
                        xclass="matrix__td d-flex --wide"
                        class="matrix__td --wide"
                        role="cell">
                        <div
                          class="matrix__strategy-text mt-0 mb-0 pe-2 ps-2"
                          :id="'strategyText-' + crosspointStakeholderPanel.id"
                          v-html="sanitizer(crosspointStakeholderPanel.participationText ?? '')">
                        </div>
                        <div class="matrix__strategy-more mt-2 mb-0 pe-2 ps-2 w-100" :id="'strategyTextEditButton-' + crosspointStakeholderPanel.id">
                          <a @click="showMore(crosspointStakeholderPanel.id, crosspointStakeholderPanel.title, 'strategy')">{{ crosspointStakeholderPanel.participationText ? 'Bewerken' : 'Toevoegen' }}</a>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="matrix__grid" title="Legend">
          <div
            class="matrix__stakeholder-cat pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
            {{ $t('General.LegendImportance') }}
          </div>
          <div
            class="matrix__td d-flex justify-content-center align-items-center --legend-item --category" role="cell">
          </div>
          <template v-for="item in 5" :key="'legend-concern-' + item">
            <div
              class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
              {{concernText(item)}}
            </div>
            <div
              class="matrix__td d-flex justify-content-center align-items-center --legend-item" role="cell" :title="concernText(item)">
              <p-button class="matrix__button p-0" :class="concernClassName(item)" :label="concernText(item)"></p-button>
            </div>
          </template>
        </div>
        <div class="matrix__grid" title="Legend">
          <div
            class="matrix__stakeholder-cat pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
            {{ $t('General.LegendTension') }}
          </div>
          <div
            class="matrix__td d-flex justify-content-center align-items-center --legend-item --category" role="cell">
          </div>
          <template v-for="item in [1, 2, 3, 4, 0]" :key="'legend-importance-' + item">
            <div
              class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
              {{importanceText(item)}}
            </div>
            <div
              class="matrix__td d-flex justify-content-center align-items-center --legend-item" role="cell" :title="importanceText(item)">
              <p-button class="matrix__button p-0" :class="importanceClassName(item)" :label="importanceText(item)"></p-button>
            </div>
          </template>
        </div>
        <Transition name="fade">
          <ui-scroll-buttons
            class="progress-matrix__buttons position-fixed"
            :right-button="disableRightButton"
            :left-button="disableLeftButton"
            v-if="showScrollButtons" @submit-right="scrollRight()"
            @submit-left="scrollLeft()">
          </ui-scroll-buttons>
        </Transition>
      </template>
    </p-card>
    <p-dialog class="w-50" position="center" v-model:visible="showMoreDialog" :modal="true">
      <template #header>
        <h3>
          {{showMoreDialogTitle}}
        </h3>
      </template>
      <div class="px-2">
        <ui-label-editor
          class="mb-6"
          :label-title="''"
          :label-for="''"
          :value-editor="showMoreDialogText"
          v-model="showMoreDialogText">
        </ui-label-editor>
      </div>
      <template #footer>
        <p-button @click="saveShowMoreDialog">Save</p-button>
        <p-button class="p-button-text" @click="closeShowMoreDialog">Close</p-button>
      </template>
    </p-dialog>
    <p-progress-bar
      class="progress-matrix__progressbar"
      :value="50"
      v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.progress-matrix {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__table {
    > div:first-child {
      > div:nth-child(3) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(5) {
        margin-left: calc-rem(8);
      }
    }
  }
  &__table-compare {
    > div:first-child {
      /* comparedate if concern is on
      > div:nth-child(3) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(4) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(5) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(6) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(7) {
        margin-left: calc-rem(8);
      }
      > div:nth-child(8) {
        margin-left: calc-rem(8);
      }
      */
      > div:nth-child(2) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(3) {
        margin-left: calc-rem(8);
      }

      > div:nth-child(4) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(5) {
        margin-left: calc-rem(0);
      }
      > div:nth-child(6) {
        margin-left: calc-rem(8);
      }
      > div:nth-child(7) {
        margin-left: calc-rem(8);
      }
    }
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;

    .matrix__th {
      &:last-child {
        position: relative;
        bottom: -4px;
        left: 60px;
        transform: rotate(30deg);
      }
    }

    .matrix__stakeholders-item {
      height: calc-rem(128);

      &.--legend-item {
        height: calc-rem(48);
      }
    }

    .matrix__td {
      &.importanceconcern__button {
        padding-left: calc-rem(8);
        .matrix__button {
          margin-top: calc-rem(8) !important;
        }
      }

      height: calc-rem(128);

      &.--legend-item {
        height: calc-rem(48);
      }
      &.--category,
      &.--strategy {
        height: calc-rem(48);
      }

      .matrix__strategy-text {
        height: calc-rem(92);
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc-rem(12);
      }

      .matrix__strategy-more {
        cursor: pointer;
        font-size: calc-rem(12);
      }
    }

    .date-importance-badge {
      width: calc-rem(180);
    }
  }

  .importance__badge {
    width: calc-rem(48) !important;
    height: calc-rem(48) !important;
    font-weight: 600;
    line-height: calc-rem(48);
    border-radius: calc-rem(4);
  }
}
</style>
