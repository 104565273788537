<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';
import UiDownloadButton from '@/core/ui/UiDownloadButton';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';

// Lifecycle hook imports

// Variables

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const downloadOptions = ref([
  {
    label: t('General.Frontend.DownloadPDF'),
    endpoint: 'projectmembers?format=pdf',
    type: 'pdf',
    filename: 'Projectmembers.pdf'
  },
  {
    label: t('General.Frontend.DownloadExcel'),
    endpoint: 'projectmembers?format=xls',
    type: 'xls',
    filename: 'Projectmembers.xls'
  }
]);
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="$t('Projectmembers.DownloadList')">
        <template v-slot:actions>
          <ui-download-buttons-dropdown
            :project-name="$t('Projectmembers.DownloadList')"
            :downloadOptions="downloadOptions"
          ></ui-download-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <div class="mt-2">
        <ui-download-button
          :label="$t('General.Frontend.DownloadExcel')"
          endpoint="projectmembers?format=xls"
          :filename="'Projectmembers.xls'"
          type="xls">
        </ui-download-button>
        <ui-download-button
          :label="$t('General.Frontend.DownloadPDF')"
          endpoint="projectmembers?format=pdf"
          :filename="'Projectmembers.pdf'"
          type="pdf">
        </ui-download-button>
      </div>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-contacts-view {
}
</style>
