<script setup>
// import { ref } from 'vue';
import useMoment from 'moment';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PBadge from 'primevue/badge';
import PPanel from 'primevue/panel';
import UiDownloadButton from './UiDownloadButton.vue';

// Store imports

// Define props
const props = defineProps({
  cardTitle: String,
  cardSubTitle: String,
  cardAlt: String,
  cardUrl: String,
  imgUrl: String,
  totalIssues: Number,
  totalStakeholders: Number,
  modDate: String,
  showUpdates: Boolean,
  updates: Array,
  detailsHeader: String,
  detailsContent: String,
  showImage: Boolean,
  showDownloadButton: Boolean,
  downloadUrl: String,
  downloadFilename: String,
  downloadType: String,
  showEditButton: Boolean,
  editUrl: String
});

// Define emits
const emit = defineEmits(['emitEdit']);

// Variables
const cardDefaultText = {
  details: 'General.ProjectDetails',
  issues: 'General.Issues',
  stakeholders: 'General.Stakeholders',
  modification: 'General.LastChange',
  updates: 'General.Updates',
  modifiedAt: 'Gewijzigd op',
  at: 'op',
  hourBy: 'uur door'
};

// Functions
const editDocument = (url) => {
  emit('emitEdit', url);
};

</script>

<template>
  <p-card class="ui-project-card">
    <template #header>
      <router-link
        class="ui-project-card__link pt-3 pb-3 pe-3 ps-3 d-flex justify-content-between align-items-center w-100"
        :to="props.cardUrl"
        v-if="props.cardUrl">
        <h2 class="ui-project-card__title position-relative mt-0 mb-0 me-3 text-truncate" :title="props.cardAlt">
          {{ props.cardTitle }}
        </h2>
        <div
          class="ui-project-card__img position-relative ms-3 w-100 overflow-hidden"
          v-if="props.showImage ?? true">
          <img
            :src="props.imgUrl"
            class="img-object-fit w-100 h-100"
            :alt="props.cardTitle"
            v-if=" props.imgUrl !== null && props.imgUrl !== '' && props.imgUrl !== undefined" />
        </div>
      </router-link>
      <div
        v-else
        class="ui-project-card__link pt-3 pb-3 pe-3 ps-3 d-flex justify-content-between align-items-center w-100">
        <h2 class="ui-project-card__title position-relative mt-0 mb-0 me-3 text-truncate">
          {{ props.cardTitle }}
        </h2>
        <div
          class="ui-project-card__img position-relative ms-3 w-100 overflow-hidden"
          v-if="props.showImage ?? true">
          <img
            :src="props.imgUrl"
            class="img-object-fit w-100 h-100"
            :alt="props.cardTitle"
            v-if="props.imgUrl !== null && props.imgUrl !== '' && props.imgUrl !== undefined" />
        </div>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-12" v-if="props.cardSubTitle">
          <p class="mt-2 mb-1 ps-3 ui-project-card__subtitle">
            {{ props.cardSubTitle.replaceAll('_', ' ').replaceAll('  ', ' ') }}
          </p>
        </div>
        <div class="col-6">
          <h3 class="mt-0 pt-3 ps-3">
            {{ props.detailsHeader ?? $t(cardDefaultText.details) }}
          </h3>
          <p
            class="mt-0 mb-0 pb-3 ps-3"
            v-if="!props.detailsHeader">
            <span class="me-3 d-inline-block">
              {{ props.totalIssues }} {{ $t(cardDefaultText.issues) }}
            </span>
            <span>
              {{ props.totalStakeholders }} {{ $t(cardDefaultText.stakeholders) }}
            </span>
          </p>
          <p
            class="mt-0 mb-0 pb-3 ps-3"
            v-else>
            <span>
              {{ $t(props.detailsContent) }}
            </span>
          </p>
        </div>
        <div class="col-6">
          <h3 class="mt-0 pt-3">
            {{ $t(cardDefaultText.modification) }}
          </h3>
          <p class="mt-0 mb-0 pb-3 pe-3" v-if="props.modDate">
            {{ useMoment(props.modDate).format('DD-MM-YYYY HH:mm') }}
          </p>
          <p class="mt-0 mb-0 pb-3 pe-3" v-if="!props.modDate">
            {{ $t('General.Unknown') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <ui-download-button
            class="ui-project-card__download ms-3"
            v-if="props.showDownloadButton ?? false"
            label="Download"
            :endpoint="props.downloadUrl"
            :filename="props.downloadFilename"
            :type="props.downloadType">
          </ui-download-button>
        </div>
        <div class="col-2 p-0">
          <p-button
            v-if="props.showEditButton"
            class="pi pi-pencil"
            @click="editDocument(props.editUrl)">
          </p-button>
        </div>
      </div>
    </template>
    <template #footer v-if="props.showUpdates">
      <p-panel
        :toggleable="true"
        :collapsed="true">
        <template #header>
          <div class="d-flex align-items-center">
            <p-badge>
              {{ props.updates.length }}
            </p-badge>
            <h3 class="mt-0 mb-0 ms-2">
              {{ cardDefaultText.updates }}
            </h3>
          </div>
        </template>
        <ul class="ui-project-card__updates mt-0 mb-0">
          <li
            class="ui-project-card__updates-item"
            v-for="update in props.updates"
            :key="update.id">
            <h4 class="ui-project-card__updates-title mt-0 mb-2">
              {{ update.title }}
            </h4>
            <p class="mt-0 mb-0">
              <span class="d-inline-block me-2">
                {{ update.title }}
              </span>
              <span>
                {{ ' ' + cardDefaultText.modifiedAt }} {{ update.publishedAt }}
                {{ cardDefaultText.at }} {{ update.time }}
                {{ cardDefaultText.hourBy + ' ' }}
              </span>
              <a
                :href="update.userUrl"
                class="ui-project-card__updates-link">
                {{ update.username }}
              </a>
            </p>
          </li>
        </ul>
      </p-panel>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-project-card {
  &__link {
    font-size: calc-rem(20);
    line-height: calc-rem(22);
    text-decoration: none;
    border-radius: calc-rem(3) calc-rem(3) 0 0;
    border-bottom: calc-rem(1) solid $separatorLineColor;
    color: $primaryColor;

    &:hover {
      .ui-project-card__title::after {
        opacity: 1;
      }
    }

    &:focus {
      @extend %button-focus;
    }
  }

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc-rem(2);
      content: '';
      background-color: $primaryColor;
      opacity: 0;
      transition: opacity 200ms ease-out;
    }
  }

  &__img {
    max-width: calc-rem(140);
    border-radius: $borderRadius;
    aspect-ratio: 140 / 80;
    background-color: $mainBg;
    background-image: url(../../assets/images/default-image.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      top: 0;
      left: 0;
    }
  }

  &__updates {
    padding-left: 0;
    list-style: none;
    font-size: calc-rem(12);
    line-height: calc-rem(16);

    &-item {
      padding-top: calc-rem(12);
      padding-bottom: calc-rem(12);

      &:nth-child(n + 2) {
        border-top: calc-rem(1) solid $separatorLineColor;
      }
    }

    &-title,
    &-link {
      color: $primaryColor;
    }

    &-title {
      margin-top: calc-rem(12);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }

    &-link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        @extend %button-focus;
      }
    }
  }

  &__download {
    margin-bottom: calc-rem(12);
  }

  .p {
    &-card {
      &-content {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        h3 {
          margin-bottom: calc-rem(6);
          font-size: calc-rem(12);
          line-height: calc-rem(14);
          letter-spacing: 0.01em;
          color: $secondaryTextColor;
        }

        p {
          font-size: calc-rem(14);
          line-height: calc-rem(16);
        }
      }
    }

    &-panel {
      &-header {
        border-radius: 0;

        h3 {
          font-size: calc-rem(14);
          line-height: calc-rem(18);
        }
      }

      &-content {
        border-radius: 0;
      }

      .pi-plus::before {
        content: '\e902';
      }

      .pi-minus::before {
        content: '\e903';
      }
    }

    &-badge {
      border-radius: calc-rem(14);
    }
  }
}
</style>
