<script setup>
// Define props
const props = defineProps({
  title: String
});
</script>

<template>
  <header class="view-header position-relative pt-4 pb-4 d-flex justify-content-between w-100">
    <h1 v-tooltip.bottom="{ value: props.title}" class="view-header__title mt-0 mb-0 me-7">
      {{ props.title }}
    </h1>
    <div class="d-flex align-items-center">
        <slot name="actions"></slot>
      </div>
  </header>
</template>

<style lang="scss">
.view-header {
  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
    line-height: calc-rem(40);
    height: calc-rem(40);
  }
}
</style>
