<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ScreenshotMap from '@/modules/screenshotmap/ScreenshotMap';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Variables
const { loadingProject } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectIssues, fetchSingleProjectStakeholders } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  await fetchSingleProject(projectId);
  await fetchSingleProjectIssues(projectId);
  await fetchSingleProjectStakeholders(projectId);
});
</script>

<template>
  <base-layout
    v-if="!loadingProject"
    class="project-screenshot-map-view">
    <template v-slot:main>
      <screenshot-map></screenshot-map>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-screenshot-map-view {
}
</style>
