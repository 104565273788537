<script setup>
const props = defineProps({
  rightIcon: String,
  labelText: String,
  leftIcon: String,
  rotateIcon: Boolean
});
</script>

<template>
  <button type="button" class="side-bar-button position-relative d-flex flex-nowrap justify-content-between w-100 text-start">
    <span class="d-flex flex-nowrap">
      <span
        class="side-bar-button__icon d-inline-block align-middle"
        :class="props.leftIcon">
      </span>
      <span class="d-inline-block">
        {{ props.labelText }}
      </span>
    </span>
    <span
      class="side-bar-button__icon d-inline-block align-middle"
      :class="[props.rightIcon, { rotate: rotateIcon }]">
    </span>
  </button>
</template>

<style lang="scss">
.side-bar-button {
  padding-top: calc-rem(13);
  padding-right: calc-rem(12);
  padding-bottom: calc-rem(13);
  padding-left: calc-rem(12);
  font-size: calc-rem(14);
  line-height: calc-rem(14);
  border-radius: $borderRadius;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $textColor;
  transition: all $transitionDuration ease-out;

  &:hover {
    background-color: $secondaryButtonHoverBg;
  }

  &:focus {
    @extend %button-focus;
  }

  &__icon {
    transition: transform $transitionDuration ease-out;

    &.pi {
      font-size: inherit;
    }

    &:first-child {
      margin-right: calc-rem(7);
    }

    &:last-child {
      color: $secondaryTextColor;
    }
  }

  .rotate {
    transform: rotate(-180deg);
  }
}
</style>
