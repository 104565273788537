<script setup>
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PDialog from 'primevue/dialog';
import PButton from 'primevue/button';
// import PDropdown from 'primevue/dropdown';
import PDropdownEditable from '../../core/ui/dropdown/DropdownCustom';
import UiFormLabel from '@/core/ui/UiFormLabel';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import UiLabelDropdownEditor from '@/core/ui/UiLabelDropdownEditor';
import UiMapCard from '@/core/ui/UiMapCard';
import UiFieldset from '@/core/ui/UiFieldset';
import UiReviewCardEditor from '@/core/ui/UiReviewCardEditor';
import UiContactCard from '@/core/ui/UiContactCard';
import UiLabelMultiSelect from '@/core/ui/UiLabelMultiSelect';
import StakeholderDetailDialogForm from '../stakeholderdetail/components/StakeholderDetailDialogForm';

// Store imports
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel',
  'emitDelete'
]);

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

const submitDelete = () => {
  emit('emitDelete');
};

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const showContactPersonDialog = ref(false);
const showEditDialogTitle = ref(false);
const showNewDialogTitle = ref(false);
const availableCategories = ref([]);

const addNewContact = () => {
  configDialog.contactId = '';
  configDialog.form.inputs.find(x => x.labelFor === 'firstname').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'lastname').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'telephone').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'mobile').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'contactFunction').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'emailAddress').value = '';

  showContactPersonDialog.value = true;
  showNewDialogTitle.value = true;
  showEditDialogTitle.value = false;
};
const closeDialog = () => {
  showContactPersonDialog.value = false;
};
const saveContact = () => {
  const firstname = configDialog.form.inputs.find(x => x.labelFor === 'firstname');
  const lastname = configDialog.form.inputs.find(x => x.labelFor === 'lastname');
  const telephone = configDialog.form.inputs.find(x => x.labelFor === 'telephone');
  const mobile = configDialog.form.inputs.find(x => x.labelFor === 'mobile');
  const contactFunction = configDialog.form.inputs.find(x => x.labelFor === 'contactFunction');
  const emailAddress = configDialog.form.inputs.find(x => x.labelFor === 'emailAddress');
  const description = configDialog.form.textarea.value;
  if (configDialog.contactId === '') {
    const model = {
      stakeholderId: stakeholderId ?? null,
      firstname: firstname ? firstname.value : '',
      lastname: lastname ? lastname.value : '',
      telephone: telephone ? telephone.value : '',
      mobile: mobile ? mobile.value : '',
      contactFunction: contactFunction ? contactFunction.value : '',
      emailAddress: emailAddress ? emailAddress.value : '',
      description: description ?? ''
    };
    addNewStakeholderContact(projectId, stakeholderId, model);
  } else {
    const model = {
      id: configDialog.contactId,
      stakeholderId: stakeholderId,
      firstname: firstname ? firstname.value : '',
      lastname: lastname ? lastname.value : '',
      telephone: telephone ? telephone.value : '',
      mobile: mobile ? mobile.value : '',
      contactFunction: contactFunction ? contactFunction.value : '',
      emailAddress: emailAddress ? emailAddress.value : '',
      description: description ?? ''
    };
    updateNewStakeholderContact(projectId, stakeholderId, configDialog.contactId, model);
  }

  showContactPersonDialog.value = false;
};
const editContact = (value) => {
  configDialog.contactId = value.id;
  configDialog.stakeholderId = value.stakeholderId;
  configDialog.form.inputs.find(x => x.labelFor === 'firstname').value = value.firstname;
  configDialog.form.inputs.find(x => x.labelFor === 'lastname').value = value.lastname;
  configDialog.form.inputs.find(x => x.labelFor === 'telephone').value = value.telephone;
  configDialog.form.inputs.find(x => x.labelFor === 'mobile').value = value.mobile;
  configDialog.form.inputs.find(x => x.labelFor === 'contactFunction').value = value.contactFunction;
  configDialog.form.inputs.find(x => x.labelFor === 'emailAddress').value = value.emailAddress;
  configDialog.form.textarea.value = value.description;

  showContactPersonDialog.value = true;
  showEditDialogTitle.value = true;
  showNewDialogTitle.value = false;
};
const removeContact = (value) => {
  deleteStakeholderContact(projectId, stakeholderId, value.id);
};
const showField = (fieldName) => {
  if (!project.value.availableStakeholderFields) {
    return true;
  }
  const fields = JSON.parse(project.value.availableStakeholderFields);

  if (fields.length === 0) {
    return true;
  }

  return fields.some(x => x.FieldName === fieldName);
};

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  deleteButton: String,
  buttonsDropdownItems: Array,
  showNameError: Boolean,
  showTelephoneError: Boolean
});

// Variables
// const showMore = ref(true);
const { editStakeholder, issuesEdit, issuesEditSelected, configFeatures, configReview, configImportance, configContact, loadingStakeholderContacts, loadingProjectIssues, hideContactEditor } = storeToRefs(useStakeholdersStore());
const { addNewStakeholderContact, updateNewStakeholderContact, deleteStakeholderContact } = useStakeholdersStore();
const { project, getProjectStakeholdersGroupedByCategory } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);
const route = useRoute();
const projectId = route.params.projectId;
const stakeholderId = route.params.stakeholderId;
const configName = {
  labelName: t('General.Name'),
  labelFor: 'name'
};
const configLocation = {
  legendTitle: t('General.Location'),
  textLabelEdit: t('General.Frontend.EditLocation'),
  textLabelSave: t('General.Frontend.SaveLocation')
};
const configCategory = {
  labelName: t('General.Category'),
  labelFor: 'customCategory'
};
const configDescription = {
  labelName: t('Stakeholder.Description'),
  labelFor: 'description'
};
const configContext = {
  labelName: t('Stakeholder.Context'),
  labelFor: 'context'
};
const configNAW = {
  legendTitle: t('General.NAW')
};
const configStreet = {
  labelName: t('General.Street'),
  labelFor: 'street'
};
const configZipCode = {
  labelName: t('General.Zipcode'),
  labelFor: 'zipcode'
};
const configCity = {
  labelName: t('General.City'),
  labelFor: 'city'
};
const configHousenumber = {
  labelName: t('General.Housenumber'),
  labelFor: 'housenumber'
};
const configTelephone = {
  labelName: t('General.Telephone'),
  labelFor: 'telephone'
};
const configEmailaddress = {
  labelName: t('General.Email'),
  labelFor: 'emailaddress'
};
const configWebsite = {
  labelName: t('General.Website'),
  labelFor: 'website'
};
const configConcernText = {
  labelName: '',
  labelFor: 'concernText'
};
const configPointOfView = {
  labelName: '',
  labelFor: 'pointOfView'
};
const configDiscussionText = {
  labelName: t('Stakeholder.Discussion'),
  labelFor: 'discussionText'
};
const configDialog = {
  titleNew: t('Contact.AddNewContact'),
  titleEdit: t('StakeholderContact.EditContact'),
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  contactId: '',
  stakeholderId: '',
  form: {
    inputs: [
      {
        labelTitle: t('General.Firstname'),
        labelFor: 'firstname',
        value: '',
        errorMessage: t('Contact.AddFirstname')
      },
      {
        labelTitle: t('General.Lastname'),
        labelFor: 'lastname',
        value: '',
        errorMessage: t('Contact.AddLastname')
      },
      {
        labelTitle: t('StakeholderContact.Telephone'),
        labelFor: 'telephone',
        value: '',
        errorMessage: t('Contact.AddPhonenumber')
      },
      {
        labelTitle: t('StakeholderContact.Mobile'),
        labelFor: 'mobile',
        value: '',
        errorMessage: t('Contact.AddPhonenumber')
      },
      {
        labelTitle: t('StakeholderContact.Function'),
        labelFor: 'contactFunction',
        value: '',
        errorMessage: ''
      },
      {
        labelTitle: t('StakeholderContact.Email'),
        labelFor: 'emailAddress',
        value: '',
        errorMessage: t('Contact.AddEmailAddress')
      }
    ],
    textarea: {
      labelTitle: t('General.Description'),
      labelFor: 'description',
      value: '',
      errorMessage: ''
    }
  }
};
const configIssues = {
  legendTitle: t('General.Issues'),
  labelTitle: t('General.Issues'),
  labelFor: 'Issues',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectIssues'),
  optionLabel: 'name',
  emptyMessage: t('General.NoIssuesAvailable'),
  options: []
};
const editStakeholderContext = 'Stakeholder.Context';
const editGeneralNAW = 'General.NAW';
const editGeneralStakeholderContacts = 'General.Stakeholder.Contacts';
const editStakeholderConcernPoV = 'Stakeholder.ConcernPoV';
const editStakeholderPower = 'Stakeholder.Power';
const editStakeholderEmotion = 'Stakeholder.Emotion';
const editStakeholderLine = 'Stakeholder.Line';
const editStakeholderChangableStrategy = 'Stakeholder.ChangableStrategy';
const editStakeholderBatna = 'Stakeholder.Batna';
const editStakeholderLevelOfTrust = 'Stakeholder.LevelOfTrust';
const editStakeholderUrgency = 'Stakeholder.Urgency';
const editStakeholderLegitimity = 'Stakeholder.Legitimity';
const editStakeholderParticipation = 'Stakeholder.Participation';
const editStakeholderDiscussion = 'Stakeholder.Discussion';
const fieldChanged = () => {
  isDirtyPage.value = true;
};
const pointOfViewChanged = (value) => {
  editStakeholder.value.pointOfView = value;
};
const concernTextChanged = (value) => {
  editStakeholder.value.concernText = value;
};
const getCategories = () => {
  const availableCategories = getProjectStakeholdersGroupedByCategory.value;
  const optionsDropdown = [];
  if (availableCategories && availableCategories.panels.length) {
    for (let i = 0; i < availableCategories.panels.length; i++) {
      if (availableCategories.panels[i].panelTitle.trim().toLowerCase() !== t('General.NoCategory')) {
        optionsDropdown.push({ name: availableCategories.panels[i].panelTitle });
      }
    }
  }
  return optionsDropdown;
};
const changeValueCat = (input) => {
  editStakeholder.value.customCategory = input.value.name;
};
const goToSaveGeo = async (value) => {
  isDirtyPage.value = true;
  editStakeholder.value.gis = JSON.stringify(value);
};
const form = ref();
const formWidth = ref();
const getWidth = () => {
  const elem = form.value;
  const elemWidth = elem.offsetWidth;
  formWidth.value = elemWidth;
};

// Functions
onMounted(async () => {
  availableCategories.value = getCategories();
});
onMounted(() => {
  getWidth();
});
window.addEventListener('resize', () => {
  getWidth();
});
</script>

<template>
  <div class="stakeholder-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form ref="form">
          <ui-label-input
            class="mb-5"
            :label-title="configName.labelName"
            :label-for="configName.labelFor"
            :showError="props.showNameError"
            :error-message="$t('General.MandatoryField')"
            @emit-dirty="fieldChanged"
            v-model="editStakeholder.name">
          </ui-label-input>
          <div>
            <ui-form-label
              class="mb-2"
              :label-title="configCategory.labelName"
              :label-for="configCategory.labelFor">
            </ui-form-label>
            <p-dropdown-editable
              class="ui-label-dropdown-checkboxes__dropdown w-100 mb-4"
              :editable="true"
              v-model="editStakeholder.customCategory"
              option-label="name"
              :options="availableCategories"
              scrollHeight="400px"
              @change="changeValueCat($event)">
            </p-dropdown-editable>
          </div>
          <ui-label-editor
            class="stakeholder-edit__textarea mb-6"
            :label-title="configDescription.labelName"
            :label-for="configDescription.labelFor"
            :value-editor="editStakeholder.description"
            @emit-dirty="fieldChanged"
            v-model="editStakeholder.description">
          </ui-label-editor>
          <ui-label-editor
            v-if="showField(editStakeholderContext)"
            class="stakeholder-edit__textarea mb-6"
            :label-title="configContext.labelName"
            :label-for="configContext.labelFor"
            :value-editor="editStakeholder.context"
            @emit-dirty="fieldChanged" v-model="editStakeholder.context">
          </ui-label-editor>
          <ui-fieldset
            class="mb-6"
            :title="configIssues.legendTitle"
            v-if="!loadingProjectIssues">
            <ui-label-multi-select
              :width-value="formWidth"
              :label-title="configIssues.labelTitle"
              :label-for="configIssues.labelFor"
              :show-label="configIssues.showLabel" :show-filter="configIssues.showFilter"
              :placeholder="configIssues.placeholder" :option-label="configIssues.optionLabel"
              :empty-message="configIssues.emptyMessage" :select-options="issuesEdit"
              :selected-options="issuesEditSelected" @emit-dirty="fieldChanged" v-model="editStakeholder.projectIssues">
            </ui-label-multi-select>
          </ui-fieldset>
<!--          <ui-review-card-->
<!--            class="stakeholder-detail__card"-->
<!--            :card-title="configReview.cardTitle"-->
<!--            :checkbox-options="configReview.checkboxes"-->
<!--            :value-editor="editStakeholder.pointOfView"-->
<!--            @emit-dirty="fieldChanged"-->
<!--            v-model="editStakeholder.statusNr">-->
<!--          </ui-review-card>-->
<!--          <ui-label-editor class="issue-edit__textarea mb-6" :label-title="configPointOfView.labelName"-->
<!--                           :label-for="configPointOfView.labelFor" :value-editor="editStakeholder.pointOfView"-->
<!--                           @emit-dirty="fieldChanged" v-model="editStakeholder.pointOfView">-->
<!--          </ui-label-editor>-->
          <ui-review-card-editor
            class="stakeholder-detail__card mb-2"
            :card-title="configReview.cardTitle"
            :checkbox-options="configReview.checkboxes"
            @emit-dirty="fieldChanged"
            :label-foreditor="configPointOfView.labelFor"
            :value-editor="editStakeholder.pointOfView"
            @emit-editorvalue="pointOfViewChanged"
            v-model="editStakeholder.statusNr">
          </ui-review-card-editor>
<!--          <ui-review-card class="stakeholder-detail__card" :card-title="configImportance.cardTitle"-->
<!--            :checkbox-options="configImportance.checkboxes" @emit-dirty="fieldChanged"-->
<!--            v-model="editStakeholder.concernNr">-->
<!--          </ui-review-card>-->
          <ui-review-card-editor
            v-if="showField(editStakeholderConcernPoV)"
            class="stakeholder-detail__card"
            :card-title="configImportance.cardTitle"
            :checkbox-options="configImportance.checkboxes"
            @emit-dirty="fieldChanged"
            v-model="editStakeholder.concernNr"
            :label-foreditor="configConcernText.labelFor"
            :value-editor="editStakeholder.concernText"
            @emit-editorvalue="concernTextChanged">
          </ui-review-card-editor>
          <ui-fieldset
            class="mt-6"
            :title="configNAW.legendTitle">
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configStreet.labelName"
              :label-for="configStreet.labelFor"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.street">
            </ui-label-input>
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configHousenumber.labelName"
              :label-for="configHousenumber.labelFor"
              @emit-dirty="fieldChanged" v-model="editStakeholder.houseNumber">
            </ui-label-input>
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configCity.labelName"
              :label-for="configCity.labelFor"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.city">
            </ui-label-input>
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configZipCode.labelName"
              :label-for="configZipCode.labelFor"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.zipCode">
            </ui-label-input>
            <!-- ui-label-input v-if="showField(editGeneralNAW)" class="mb-5" :label-title="configExtraAddress.labelName"
                            :label-for="configExtraAddress.labelFor" @emit-dirty="fieldChanged" v-model="editStakeholder.extraAddress">
            </ui-label-input>
            <ui-label-input v-if="showField(editGeneralNAW)" class="mb-5" :label-title="configExtraCity.labelName"
                            :label-for="configExtraCity.labelFor" @emit-dirty="fieldChanged" v-model="editStakeholder.extraCity">
            </ui-label-input>
            <ui-label-input v-if="showField(editGeneralNAW)" class="mb-5" :label-title="configExtraZipCode.labelName"
                            :label-for="configExtraZipCode.labelFor" @emit-dirty="fieldChanged" v-model="editStakeholder.extraZipCode">
            </ui-label-input -->
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configTelephone.labelName"
              :label-for="configTelephone.labelFor"
              :showError="props.showTelephoneError"
              :error-message="$t('General.ValidValue')"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.telephone">
            </ui-label-input>
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configEmailaddress.labelName"
              :label-for="configEmailaddress.labelFor"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.emailAddress">
            </ui-label-input>
            <ui-label-input
              v-if="showField(editGeneralNAW)"
              class="mb-5 w-100"
              :label-title="configWebsite.labelName"
              :label-for="configWebsite.labelFor"
              @emit-dirty="fieldChanged"
              v-model="editStakeholder.website">
            </ui-label-input>
          </ui-fieldset>
          <ui-contact-card
            v-if="showField(editGeneralStakeholderContacts) && !loadingStakeholderContacts && !hideContactEditor"
            class="mb-6 stakeholder-detail__card" :headings="configContact.headings" :buttons="configContact.buttons"
            :contact-data="configContact.contactData" :hideAddressesDetails="true" @emit-dirty="fieldChanged"
            @edit-contact="editContact" @remove-contact="removeContact" @new-contact="addNewContact()" :is-edit-mode="true">
          </ui-contact-card>
          <ui-fieldset
            class="mb-3"
            :title="configFeatures.legendTitle">
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderEmotion)"
              class="mb-5"
              :label-title="configFeatures.features[1].labelName" :label-for="configFeatures.features[1].labelFor"
              :dropdown-label="configFeatures.features[1].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[1].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[1].options" :value-editor="editStakeholder.emotion.text"
              :value-combobox="editStakeholder.emotion.nr" @emit-dirty="fieldChanged" v-model="editStakeholder.emotion">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderPower)"
              class="mb-5"
              :label-title="configFeatures.features[0].labelName" :label-for="configFeatures.features[0].labelFor"
              :dropdown-label="configFeatures.features[0].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[0].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[0].options" :value-editor="editStakeholder.power.text"
              :value-combobox="editStakeholder.power.nr" @emit-dirty="fieldChanged" v-model="editStakeholder.power">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderUrgency)"
              class="mb-5"
              :label-title="configFeatures.features[4].labelName" :label-for="configFeatures.features[4].labelFor"
              :dropdown-label="configFeatures.features[4].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[4].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[4].options" :value-editor="editStakeholder.urgency.text"
              :value-combobox="editStakeholder.urgency.nr" @emit-dirty="fieldChanged" v-model="editStakeholder.urgency">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderLine)"
              class="mb-5"
              :label-title="configFeatures.features[2].labelName" :label-for="configFeatures.features[2].labelFor"
              :dropdown-label="configFeatures.features[2].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[2].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[2].options" :value-editor="editStakeholder.line.text"
              :value-combobox="editStakeholder.line.nr" @emit-dirty="fieldChanged" v-model="editStakeholder.line">
            </ui-label-dropdown-editor>
            <!-- SOMSET-721: Must not show up in new version -->
            <ui-label-dropdown-editor
              v-if="1 === 2 && showField(editStakeholderChangableStrategy)"
              class="mb-5"
              :label-title="configFeatures.features[3].labelName" :label-for="configFeatures.features[3].labelFor"
              :dropdown-label="configFeatures.features[3].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[3].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[3].options"
              :value-editor="editStakeholder.changableStrategy.text"
              :value-combobox="editStakeholder.changableStrategy.nr" @emit-dirty="fieldChanged"
              v-model="editStakeholder.changableStrategy">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderLegitimity)"
              class="mb-5"
              :label-title="configFeatures.features[5].labelName" :label-for="configFeatures.features[5].labelFor"
              :dropdown-label="configFeatures.features[5].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[5].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[5].options" :value-editor="editStakeholder.legitimity.text"
              :value-combobox="editStakeholder.legitimity.nr" @emit-dirty="fieldChanged"
              v-model="editStakeholder.legitimity">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
             v-if="showField(editStakeholderBatna)"
              class="mb-5"
              :label-title="configFeatures.features[7].labelName"
              :label-for="configFeatures.features[7].labelFor"
              :label-description="configFeatures.features[7].labelDescription"
              :dropdown-label="configFeatures.features[7].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[7].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[7].options"
              :value-editor="editStakeholder.batna.text"
              :value-combobox="editStakeholder.batna.nr" @emit-dirty="fieldChanged"
              v-model="editStakeholder.batna">
            </ui-label-dropdown-editor>
            <ui-label-dropdown-editor
             v-if="showField(editStakeholderLevelOfTrust)"
              class="mb-5"
              :label-title="configFeatures.features[8].labelName"
              :label-for="configFeatures.features[8].labelFor"
              :label-description="configFeatures.features[8].labelDescription"
              :dropdown-label="configFeatures.features[8].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[8].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[8].options"
              :value-editor="editStakeholder.levelOfTrust.text"
              :value-combobox="editStakeholder.levelOfTrust.nr" @emit-dirty="fieldChanged"
              v-model="editStakeholder.levelOfTrust">
            </ui-label-dropdown-editor>

          </ui-fieldset>

          <ui-fieldset
            class="mb-0"
            :title="$t('Stakeholder.Participation')">
            <ui-label-dropdown-editor
              v-if="showField(editStakeholderParticipation)"
              class="mb-5"
              :label-title="configFeatures.features[6].labelName" :label-for="configFeatures.features[6].labelFor"
              :dropdown-label="configFeatures.features[6].dropdownLabel"
              :dropdown-placeholder="configFeatures.features[6].dropdownPlaceholder"
              :dropdown-options="configFeatures.features[6].options" :value-editor="editStakeholder.participation.text"
              :value-combobox="editStakeholder.participation.nr" @emit-dirty="fieldChanged"
              v-model="editStakeholder.participation">
            </ui-label-dropdown-editor>
          </ui-fieldset>

          <ui-label-editor
            v-for="(customField) in editStakeholder.customFieldsValues"
            :key="customField.FieldName"
            class="issue-edit__textarea mb-5"
            :label-title="customField.FieldName"
            :label-for="customField.FieldName.trim()"
            :value-editor="customField.Value"
            @emit-dirty="fieldChanged"
            v-model="customField.Value">
          </ui-label-editor>

          <ui-label-editor
            v-if="showField(editStakeholderDiscussion)"
            class="issue-edit__textarea mb-5"
            :label-title="configDiscussionText.labelName"
            :label-for="configDiscussionText.labelFor"
            :value-editor="editStakeholder.discussionText"
            @emit-dirty="fieldChanged"
            v-model="editStakeholder.discussionText">
          </ui-label-editor>

          <ui-map-card
            v-if="project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng && project.gisDefaultZoomLevel"
            class="mb-5 --card"
            :card-title="configLocation.legendTitle"
            :text-label-edit="configLocation.textLabelEdit"
            :text-label-save="configLocation.textLabelSave"
            :gis-default-coordinates-lat="project.gisDefaultCoordinatesLat"
            :gis-default-coordinates-lng="project.gisDefaultCoordinatesLng"
            :gis-default-zoom-level="project.gisDefaultZoomLevel"
            :screenshot-name="editStakeholder.name"
            :geo-json="editStakeholder.gis"
            @emit-save="goToSaveGeo">
          </ui-map-card>
        </form>
      </template>
    </p-card>
    <div class="mt-4 d-flex justify-content-end">
      <div class="ui-save-buttons-dropdown d-flex">
        <p-button
          v-if="IsActionAllowedInRole('stakeholder.delete', currentUser, project.id)"
          class="p-button-link me-2 red"
          :label="props.deleteButton"
          @click="submitDelete()">
        </p-button>
      </div>
      <ui-save-buttons-dropdown
        :items="props.buttonsDropdownItems"
        :save-button="props.saveButton"
        :cancel-button="props.cancelButton"
        @emit-save="submitSave()" @emit-cancel="submitCancel()">
      </ui-save-buttons-dropdown>
    </div>
  </div>
  <p-dialog
    class="stakeholder-detail__dialog"
    position="top"
    v-model:visible="showContactPersonDialog"
    :modal="true">
    <template #header>
      <h3 v-if="showNewDialogTitle">
        {{ configDialog.titleNew }}
      </h3>
      <h3 v-if="showEditDialogTitle">
        {{ configDialog.titleEdit }}
      </h3>
    </template>
    <stakeholder-detail-dialog-form
      :form="configDialog.form"
      :contact="configDialog.contact"
      :save-button="configDialog.saveButton"
      :cancel-button="configDialog.cancelButton"
      @close-dialog="closeDialog()"
      @save="saveContact">
    </stakeholder-detail-dialog-form>
    <template #footer></template>
  </p-dialog>
</template>

<style lang="scss">
.stakeholder-edit {
  &__showmore {
    .pi {
      transition: transform 200ms ease-out;
    }

    &.is-rotated {
      .pi {
        transform: rotate(180deg);
      }
    }
  }

  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}

.--card {
  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}
</style>
