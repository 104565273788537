<script setup>
import useMoment from 'moment';
import { ref } from 'vue';

// Service imports
import HttpClient from '@/services/httpclient';
import { AuthService } from '@/services/auth';

// Component imports
import PButton from 'primevue/button';
import PSplitButton from 'primevue/splitbutton';

// Lifecycle hook imports
import { useRoute } from 'vue-router';

// Define props
const props = defineProps({
  downloadOptions: Array,
  projectName: String,
  showOptions: String
});

// Define emits

// Variables
const auth = new AuthService();
const route = useRoute();
const projectId = route.params.projectId;
const spinner = ref(false);
const download = async (endpoint, type, filename) => {
  if (!type) {
    return;
  }
  spinner.value = true;
  endpoint = 'api/v1/projects/' + projectId + '/' + endpoint;
  let acceptHeader = ''; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  switch (type) {
    case 'pdf':
      acceptHeader = 'application/pdf';
      break;
    case 'xls':
      acceptHeader = 'application/vnd.ms-excel';
      // acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'doc':
      acceptHeader = 'application/msword';
      // acceptHeader = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    default:
      acceptHeader = props.type;
  }
  try {
    const response = await HttpClient.get(
      endpoint,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: acceptHeader,
          Authorization: 'Bearer ' + await auth.getIdToken()
        }
      }
    );
    let fileName = useMoment().format('YYYYMMDDHHmmss') + '_' + filename;
    // JWB: very dirty hack! projectname is not always available in download-options. Due to time constraints, a dirty hack
    fileName = fileName.replace('undefined', props.projectName);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    spinner.value = false;
  } catch (e) {
    spinner.value = false;
    console.log('Download failed');
    console.error(e);
  }
};

const downloadOptionsSet = () => {
  const downloadOptions = [];
  for (let i = 0; i < props.downloadOptions.length; i++) {
    downloadOptions.push({
      label: props.downloadOptions[i].label,
      command: () => {
        console.info(props.downloadOptions[i].label);
        download(props.downloadOptions[i].endpoint, props.downloadOptions[i].type, props.downloadOptions[i].filename);
      }
    });
  }
  return downloadOptions;
};
const downloadFirstOption = () => {
  download(props.downloadOptions[0].endpoint, props.downloadOptions[0].type, props.downloadOptions[0].filename);
};
</script>

<template>
  <div class="ui-save-buttons-dropdown d-flex">
    <p-split-button
      :model="downloadOptionsSet()">
      <p-button
        class="ui-save-buttons-dropdown__save"
        label="Download"
        @click="downloadFirstOption()">
      </p-button>
    </p-split-button>
  </div>
</template>

<style lang="scss">
.ui-save-buttons-dropdown {
  &__save {
    margin-right: calc-rem(1) !important;
    border-radius: $borderRadius 0 0 $borderRadius !important;
  }
}

.p {
  &-tieredmenu {
    margin-top: calc-rem(3);
    padding: calc-rem(5);
    width: calc-rem(240);

    &.p-tieredmenu-overlay {
      box-shadow: 0 4px 20px rgba(0, 0, 0, .2);
      z-index: 1000;
    }

    ul {
      width: calc-rem(230);
    }
  }

  &-menuitem {
    &:not(:last-child) {
      margin-bottom: calc-rem(2);
    }

    &-link {
      padding-right: calc-rem(15) !important;
      padding-left: calc-rem(15) !important;
      border-radius: $borderRadius  !important;
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }
  }
}
</style>
