<script setup>
// import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import useMoment from 'moment';
import sanitizeHtml from 'sanitize-html';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PProgressSpinner from 'primevue/progressspinner';
import UiDocumentHistoryCard from '@/core/ui/UiDocumentHistoryCard';
import UiCardTitle from '@/core/ui/UiCardTitle';
import UiDownloadButton from '@/core/ui/UiDownloadButton';

// Store imports
import { useMediaStore } from '@/store/modules/media';

// Variables
const { loadingMedia, editMedia, mediaFile } = storeToRefs(useMediaStore());
const route = useRoute();
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configMedia = {
  descriptionTitle: t('ProjectMedia.Description'),
  modTitle: t('ProjectMedia.Frontend.PublicationNote'),
  publishedText: t('ProjectMedia.Frontend.PublicationDate'),
  hourBy: t('ProjectMedia.Frontend.HourBy'),
  stakeholderTitle: t('ProjectMedia.Frontend.Stakeholders'),
  issueTitle: t('ProjectMedia.Frontend.Issues')
};
const configHistory = {
  cardTitle: 'General.DocumentHistory',
  moderator: '',
  moderatorUrl: '/',
  author: '',
  authorUrl: '/',
  dateTime: '',
  subtitleModerator: 'General.LastUpdated',
  subtitleAuthor: 'General.Author',
  moderatorInfo: 'General.By'
};

// Functions
</script>

<template>
  <main>
    <template v-if="!loadingMedia">
      <div class="media-detail" >
        <div class="media-detail__col">
          <p-card class="media-detail__card">
            <template #content>
              <div class="note-detail__content pt-2 pb-2 pe-1 ps-1">
                <div v-html="sanitizeHtml(editMedia.description)">
                </div>
              </div>
              <div class="media-detail__meta row position-relative">
                <div class="col-5">
                  <div v-if="editMedia.stakeholders.length > 0">
                    <h5 class="mt-3 mb-1">
                      {{ configMedia.stakeholderTitle }}
                    </h5>
                    <p class="mt-0 mb-0 --link">
                      <span v-for="(stakeholder, index) in editMedia.stakeholders" :key="stakeholder.id">
                        {{ index !== 0 ? ', ' : ''}}<router-link :to="'/projects/' + projectId + '/stakeholders/' + stakeholder.id">{{ stakeholder.name }}</router-link>
                      </span>
                    </p>
                  </div>
                  <div v-if="editMedia.issues.length > 0">
                    <h5 class="mt-3 mb-1">
                      {{ configMedia.issueTitle }}
                    </h5>
                    <p class="mt-0 mb-0 --link">
                      <span v-for="(issue, index) in editMedia.issues" :key="issue.id">
                        {{ index !== 0 ? ', ' : ''}}<router-link :to="'/projects/' + projectId + '/issues/' + issue.id">{{ issue.name }}</router-link>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-7">
                  <h5 class="mt-3 mb-1">
                    {{ configMedia.modTitle }}
                  </h5>
                  <p class="mt-0 mb-0">
                    {{ configMedia.publishedText }} {{ useMoment(editMedia.modDate).format('DD-MM-YYYY HH:mm') }} {{ configMedia.hourBy }} <span>{{ (editMedia.modUser.firstname || editMedia.modUser.lastname
              ? (editMedia.modUser.firstname ?? '') +
              ' ' +
              (editMedia.modUser.lastname ?? '')
              : editMedia.modUser.userName) }}</span>
                  </p>
                </div>
              </div>
            </template>
          </p-card>
        </div>
        <div class="media-detail__col">
          <ui-document-history-card v-if="editMedia.addUser != null && editMedia.modUser != null"
                                    class="media-detail__card"
                                    :card-title="$t(configHistory.cardTitle)"
                                    :moderator="editMedia.modUser.firstname || editMedia.modUser.lastname
              ? (editMedia.modUser.firstname ?? '') +
              ' ' +
              (editMedia.modUser.lastname ?? '')
              : editMedia.modUser.userName"
                                    :moderator-url="'/profile/' + editMedia.modUser.id"
                                    :date-time="useMoment(editMedia.modUser.modDate).format('DD-MM-YYYY HH:mm')"
                                    :moderator-info="$t(configHistory.moderatorInfo)"
                                    :author="editMedia.addUser.firstname || editMedia.addUser.lastname
              ? (editMedia.addUser.firstname ?? '') +
              ' ' +
              (editMedia.addUser.lastname ?? '')
              : editMedia.addUser.userName"
                                    :author-url="'/profile/' + editMedia.addUser.id"
                                    :subtitle-author="$t(configHistory.subtitleAuthor)"
                                    :subtitle-moderator="$t(configHistory.subtitleModerator)">
          </ui-document-history-card>
          <p-card v-if="mediaFile.pictureFilename">
            <template #content>
              <div class="pt-2 pb-2 pe-1 ps-1">
                <ui-card-title>
                  {{ $t('General.Annex') }}
                </ui-card-title>
                <h5>
                  {{mediaFile.pictureFilename}}
                </h5>
                <ui-download-button
                  label="Download bijlage"
                  :filename="mediaFile.pictureFilename"
                  :type="mediaFile.pictureFileExtension"
                  :endpoint="'media/' + editMedia.id +'/download'"
                >
                </ui-download-button>
              </div>
            </template>
          </p-card>
        </div>
      </div>
      <p-progress-spinner class="mt-10 me-auto ms-auto" v-if="loadingMedia">
      </p-progress-spinner>
    </template>
  </main>
</template>

<style lang="scss">
.media-detail {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: calc-rem(10);

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02em;
  }

  &__content {
    font-size: calc-rem(14);
    line-height: calc-rem(20);
  }

  &__subtitle {
    font-size: calc-rem(16);
    font-weight: 600;
    line-height: calc-rem(18);
    color: $secondaryTextColor;
  }

  &__card {
    margin-bottom: calc-rem(9);
  }

  &__meta {
    &::before {
      position: absolute;
      top: 0;
      left: calc-rem(5);
      width: calc(100% - 10px);
      height: calc-rem(1);
      content: '';
      background-color: $separatorLineColor;
    }

    h5 {
      font-size: calc-rem(12);
      line-height: calc-rem(14);
      letter-spacing: -.01em;
      color: $secondaryTextColor;
    }

    p {
      font-size: calc-rem(12);
      line-height: calc-rem(16);

      span {
        color: $primary;
      }
    }

    .--link a {
      text-decoration: none!important;
      color: #005FE3!important;
    }

    .--link a:hover{
      text-decoration: underline!important;
    }
  }
}
</style>
