<script setup>
// import { useRoute } from 'vue-router';
// import { storeToRefs } from 'pinia';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import SearchResults from '@/modules/searchresults/SearchResults';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
// import router from "@/router";

// Variables
// const route = useRoute();
const viewTitle = 'General.SearchResults';

// Functions
useMenuBarStore().$patch({
  showLogo: true
});

useSideBarStore().$patch({
  showSideBar: false
});
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="$t(viewTitle)">
      </view-header>
    </template>
    <template v-slot:main>
      <search-results
      ></search-results>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.search-results-view {
  &__search {
    @include media-breakpoint-up(lg) {
      width: calc-rem(300);
    }

    @include media-breakpoint-up(xl) {
      width: calc-rem(380);
    }
  }

  &__button-info {
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    color: $secondaryTextColor;
  }

  &__sort-button {
    height: calc-rem(40.09);

    .pi {
      color: $secondaryTextColor;
      transition: transform 200ms ease-out;
    }
  }

  &__dropdown {
    &-button {
      &:not(:last-child) {
        margin-bottom: calc-rem(2);
      }

      &:hover {
        background-color: $secondary  !important;

        &.p-button-text {
          color: $textColor  !important;
        }
      }

      &.p-button-text {
        color: $textColor;
      }
    }
  }
}
</style>
