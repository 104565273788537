<script setup>
import { ref } from 'vue';

// Component imports
import PDropdown from 'primevue/dropdown';
import UiFormLabel from '@/core/ui/UiFormLabel';
import UiFormErrorMessage from './UiFormErrorMessage';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  placeholder: String,
  optionLabel: String,
  showError: Boolean,
  errorMessage: String,
  options: Array,
  valueCombobox: Object
});

// Define emits
const emit = defineEmits(['emitItem', 'emitDirty']);

// Variables
const emitItem = (val) => {
  emit('emitItem', val);
  emit('emitDirty');
};
const selectedOption = ref(props.valueCombobox);

// Functions
</script>

<template>
  <div class="ui-label-dropdown">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor">
    </ui-form-label>
    <p-dropdown
      class="w-100"
      :id="props.labelFor"
      :options="props.options"
      :option-label="props.optionLabel"
      :placeholder="props.placeholder"
      scrollHeight="400px"
      v-model="selectedOption"
      @change="emitItem($event.value)">
    </p-dropdown>
    <ui-form-error-message
      class="mt-1"
      v-if="props.showError">
      {{ props.errorMessage }}
    </ui-form-error-message>
  </div>
</template>

<style lang="scss">
.ui-label-dropdown {
}
</style>
