<script setup>
// import { onMounted } from 'vue';
// import { storeToRefs } from 'pinia';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import MeTerms from '@/modules/meterms/MeTerms';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';

// Variables

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: false
});

useSideBarStore().$patch({
  showSideBar: false
});
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="$t('General.TermsOfUse')">
      </view-header>
    </template>
    <template v-slot:main>
      <me-terms></me-terms>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.me-terms-view {
}
</style>
