<script setup>
import { ref, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { storeToRefs } from 'pinia';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import MeEdit from '@/modules/meedit/MeEdit';
import PConfirmDialog from 'primevue/confirmdialog';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';

// Router import
import router from '@/router';

// Variables
const { fetchCurrentUser, fetchCurrentUserPicture, updateCurrentUser, updateCurrentUserPicture } = useUserStore();
const { isLoadingUser, isLoadingUserPicture, isDirty, currentUser } = storeToRefs(useUserStore());
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const isSaving = ref(false);

// Variables

// Functions
onMounted(async () => {
  await fetchCurrentUser();
  await fetchCurrentUserPicture();
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: 'Wil je doorgaan zonder opslaan?',
      header: 'Bevestig',
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: async () => {
        isDirtyPage.value = false;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    next();
  }
});

const cancel = () => {
  router.push({ path: '/profile/' + currentUser.value.id });
};

const save = async () => {
  isSaving.value = true;
  await updateCurrentUser();
  await updateCurrentUserPicture();
  // await fetchCurrentUser();
  await fetchCurrentUserPicture();

  isDirtyPage.value = false;
  await router.push({ path: '/profile/' + currentUser.value.id });
  isSaving.value = false;
};

useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: false
});

useSideBarStore().$patch({
  showSideBar: false
});
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header :title="$t('Profile.Heading')">
        <template v-slot:actions>
          <ui-save-buttons
            v-if="!isSaving"
            @emit-save="save()"
            @emit-cancel="cancel()"
            :saveButton="$t('General.Save')"
            :cancelButton="$t('General.Cancel')">
          </ui-save-buttons>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <me-edit
        v-if="!isSaving && !isLoadingUser && !isLoadingUserPicture"
        @emit-save="save"
        @emit-cancel="cancel">
      </me-edit>
      <p-progress-spinner
        class="project-issue-edit-view__spinner position-absolute"
        v-if="isSaving">
      </p-progress-spinner>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
</template>

<style lang="scss">
.me-view {}
</style>
