<script setup>
// import { storeToRefs } from 'pinia';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import Profile from '@/modules/profile/Profile';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';

// Router import
// import router from '@/router';

// Variables
const { isLoadingProfile } = storeToRefs(useUserStore());

// Functions

useMenuBarStore().$patch({
  showLogo: true,
  showLeftButtons: true,
  showAddButton: false
});

useSideBarStore().$patch({
  showSideBar: false
});
</script>

<template>
  <base-layout v-if="!isLoadingProfile">
    <template v-slot:header>
      <view-header :title="$t('Profile.Heading')"></view-header>
    </template>
    <template v-slot:main>
      <profile></profile>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.profile-view {
}
</style>
