<script setup>
import { toRefs } from 'vue';
import sanitizeHtml from 'sanitize-html';

// Components imports
import PCard from 'primevue/card';
import PAccordion from 'primevue/accordion';
import PAccordionTab from 'primevue/accordiontab';
import UiCardTitle from './UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  cardType: String,
  tabs: Array
});

// Define emits
// const emit = defineEmits([]);

// Variables
const { tabs } = toRefs(props);

// Functions
const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};
</script>

<template>
  <p-card class="ui-stakeholders-card">
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <p-accordion :multiple="true" :activeIndex="[0]" expandIcon="pi-chevron-down" collapseIcon="pi-chevron-up">
          <p-accordion-tab v-for="item in tabs" :key="item.id">
            <template #header>
              <span class="ui-stakeholders-card__panel-icon me-2 d-inline-block" :class="item.className">
              </span>
              <span class="ui-stakeholders-card__importance-icon me-1 d-inline-block" :class="item.content.className">
              </span>
              <span class="ui-stakeholders-card__panel-title">
                {{ item.title }}
              </span>
            </template>
            <h3 v-if="sanitizer(item.content.tabContent)" class="mt-2 ui-stakeholders-card__subtitle mt-0 mb-3">
              Standpunt
            </h3>
            <!-- p class="d-flex aligns-items">
              <span class="ui-stakeholders-card__importance-icon me-1 d-inline-block" :class="item.content.className">
              </span>
              <span class="ui-stakeholders-card__importance">
                {{ item.content.tabTitle }}
              </span>
            </p -->
            <div class="mt-2 ui-stakeholders-card__content" v-html="sanitizer(item.content.tabContent)">
            </div>
            <router-link
              class="mt-2 ui-stakeholders-card__panel-button position-relative mb-6 me-2 d-inline-block text-center text-decoration-none"
              :to="item.content.urlButton">
              {{ item.content.textButton }}
            </router-link>
            <router-link
              v-if="props.cardType === 'issues'"
              class="ui-stakeholders-card__panel-button position-relative mb-6 me-2 d-inline-block text-center text-decoration-none"
              :to="item.content.urlIssueButton">
              Open issue
            </router-link>
            <router-link
              v-if="props.cardType === 'stakeholders'"
              class="ui-stakeholders-card__panel-button position-relative mb-6 me-2 d-inline-block text-center text-decoration-none"
              :to="item.content.urlStakeholderButton">
              Open stakeholder
            </router-link>
          </p-accordion-tab>
        </p-accordion>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-stakeholders-card {
  &__subtitle {
    font-size: calc-rem(16);
    font-weight: 600;
    line-height: calc-rem(18);
    color: $secondaryTextColor;
  }

  &__panel {
    &-icon {
      width: calc-rem(20);
      height: calc-rem(20);
      border-radius: $borderRadius;

      &.--unclear {
        background-color: $unclear;
      }

      &.--positive {
        background-color: $positive;
      }

      &.--focus {
        background-color: $focus;
      }

      &.--warning {
        background-color: $warning;
      }

      &.--criticism {
        background-color: $criticism;
      }
    }

    &-title {
      font-size: calc-rem(14);
      line-height: calc-rem(16);
      color: $primaryColor;
      width: calc(100% - 5rem);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-button {
      padding-top: calc-rem(11);
      padding-right: calc-rem(15);
      padding-bottom: calc-rem(11);
      padding-left: calc-rem(15);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
      border-radius: $borderRadius;
      background-color: $secondaryButtonBg;
      border: $secondaryButtonBorder;
      color: $secondaryButtonTextColor;
      transition: $formElementTransition;

      &:hover {
        background-color: $secondaryButtonHoverBg;
        border-color: $secondaryButtonHoverBorderColor;
        color: $secondaryButtonTextHoverColor;
      }

      &:focus {
        box-shadow: $focusShadow;
      }
    }
  }

  &__importance {
    font-size: calc-rem(14);
    line-height: calc-rem(19);
    color: $black;

    &-icon {
      width: calc-rem(20);
      height: calc-rem(20);
      border-radius: $borderRadius;

      &.--unclear {
        background-color: $unclear;
      }

      &.--hardlyimportance {
        background-color: $hardlyimportance;
      }

      &.--reasonablyimportance {
        background-color: $reasonablyimportance;
      }

      &.--greatimportance {
        background-color: $greatimportance;
      }

      &.--extremlyhighimportance {
        background-color: $extremlyhighimportance;
      }
    }
  }

  &__content {
    font-size: calc-rem(14);
    line-height: calc-rem(20);
    word-break: break-word;

    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      margin-top: 0;
    }

    h2 {
      margin-bottom: calc-rem(15);
      font-size: calc-rem(20);
      font-weight: 600;
      line-height: calc-rem(22);
      letter-spacing: -.02px;
    }

    h3 {
      margin-bottom: 0;
      padding-top: calc-rem(5);
      font-size: calc-rem(16);
      font-weight: 600;
      line-height: calc-rem(18);
    }

    h4,
    h5,
    h6 {
      margin-bottom: 0;
      font-size: calc-rem(14);
      font-weight: 700;
      line-height: calc-rem(20);
    }

    p {
      margin-bottom: calc-rem(20);
    }

    a {
      color: $primary;
    }

    img {
      max-width: 100%;
    }
  }

  .p-accordion {
    &-tab {
      &:not(:first-child) {
        border-top: calc-rem(1) solid $separatorLineColor;
      }
    }

    &-header-link,
    &-content {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    &-toggle-icon {
      margin-right: 0;
      margin-left: auto;
      order: 3;
    }
  }
}
</style>
