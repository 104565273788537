<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PEditor from 'primevue/editor';
import POverlayPanel from 'primevue/overlaypanel';
import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
// import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholderstemp: Object,
  stakeholders: Object,
  issues: Object,
  projectConcernMatrix: Object
});

// Define emits

// Variables
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { project } = storeToRefs(useProjectsStore());
const {
  updateStakeholderIssueConcernFromMatrix,
  updateStakeholderIssueConcernDescriptionFromMatrix,
  addStakeholderIssueConcern,
  removeStakeholderIssue
} = useStakeholderIssueStore();
const {
  updateStakeholderConcernFromMatrix,
  updateStakeholderParticipationFromMatrix
} = useStakeholdersStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const opColor = ref();
const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
const onFocusOverlayPanelInput = () => {
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const Cache = (fn) => {
  const cache = {};
  return (...args) => {
    const key = JSON.stringify(args);
    if (!(key in cache)) {
      cache[key] = fn(...args);
    }
    return cache[key];
  };
};
const onFocusOverlayPanelInputEditor = (stakeholderId, issueId) => {
  const crossPointCache = Cache(crossPoint);
  const crossPointData = crossPointCache(stakeholderId, issueId);
  const concernText = crossPointData.concernText;
  const status = crossPointData.issueStakeholderConcernNr;

  const element = document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId);

  if (element) {
    const classList = element.className.trim();
    const expectedClass = 'p-button p-component matrix__matrix-button p-0';

    if (classList === expectedClass) {
      if ((concernText === null || concernText === '') && status === null) {
        Cache(updateCrossPointConcern(5, stakeholderId, issueId));
      }
    }
  }
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const onBlurOverlayPanelInput = () => {
  focusOverlayPanelInput.value = false;
};
const hideOverlayPanelButtons = () => {
  showOverlayPanelButtons.value = false;
};
// const cancelSelectColor = () => {
//   // opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const getConcern = (concernNr) => {
  switch (concernNr) {
    case 5:
      return { className: '--unclear', name: configMatrix.tooltip.unclear };
    case 4:
      return { className: '--hardlyimportance', name: configMatrix.tooltip.hardlyimportance };
    case 3:
      return { className: '--reasonablyimportance', name: configMatrix.tooltip.reasonablyimportance };
    case 2:
      return { className: '--greatimportance', name: configMatrix.tooltip.greatimportance };
    case 1:
      return { className: '--extremlyhighimportance', name: configMatrix.tooltip.extremlyhighimportance };
    default:
      return { className: null, name: null };
  }
};

const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
// const scrollRight = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value += 1;
//   scrollValue.value -= scrollDistance;

//   // Add class to 1st column and enable left button
//   if (countClicks.value === 1) {
//     disableLeftButton.value = false;
//     addShadowClass.value = true;
//   }
//   // Disable right button on max clicks
//   if (countClicks.value === maxClicks) {
//     disableRightButton.value = true;
//   }
// };
// const scrollLeft = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value -= 1;
//   scrollValue.value += scrollDistance;

//   // Disable left button and remove class from 1st column
//   if (countClicks.value === 0) {
//     disableLeftButton.value = true;
//     addShadowClass.value = false;
//   }

//   // Enable right button
//   if (countClicks.value < maxClicks) {
//     disableRightButton.value = false;
//   }
// };
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Cancel')
  },
  tooltip: {
    reset: t('General.Importance.No'),
    extremlyHighImportance: t('General.Importance.Extreme'),
    greatImportance: t('General.Importance.Great'),
    reasonablyImportance: t('General.Importance.Reasonable'),
    hardlyImportance: t('General.Importance.Hardly'),
    unclear: t('Status.Onduidelijk')
  }
};
const strategyOption = Cache(ref([
  {
    name: t('General.Unknown'),
    value: null
  },
  {
    name: t('Participation.Informeren'),
    value: 1
  },
  {
    name: t('Participation.Communiceren'),
    value: 2
  },
  {
    name: t('Participation.Ad.hoc.betrokken'),
    value: 3
  },
  {
    name: t('Participation.Participeren'),
    value: 4
  },
  {
    name: t('Participation.Consensus'),
    value: 5
  },
  {
    name: t('Participation.Empowerment'),
    value: 6
  }
]));
const concernClassName = Cache((concernNr) => {
  switch (concernNr) {
    case 5:
      return '--unclear';
    case 4:
      return '--hardlyimportance';
    case 3:
      return '--reasonablyimportance';
    case 2:
      return '--greatimportance';
    case 1:
      return '--extremlyhighimportance';
    default:
      return '';
  }
});
const concernText = Cache((concernNr) => {
  switch (concernNr) {
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Nauwelijks.belang');
    case 3:
      return t('Status.Redelijk.belang');
    case 2:
      return t('Status.Groot.belang');
    case 1:
      return t('Status.Extreem.groot.belang');
    default:
      return '';
  }
});
const crossPoint = Cache((stakeholderId, issueId) => {
  const projectMatrix = projectConcernMatrix.value?.jsonModel?.MatrixCrossPoints;

  if (projectMatrix) {
    const crossPoint = projectMatrix.find(x => x.stakeholderId === stakeholderId && x.issueId === issueId);

    if (crossPoint) {
      crossPoint.issueStakeholderConcernName = concernText(crossPoint.issueStakeholderConcernNr);
      crossPoint.concernClassName = concernClassName(crossPoint.issueStakeholderConcernNr);

      if (!crossPoint.orgValue) {
        crossPoint.orgValue = crossPoint.concernText;
      }
      return crossPoint;
    }
  }

  return {
    issueStakeholderConcernNr: null,
    issueStakeholderConcernName: null,
    issueStakeholderImportanceNr: null,
    issueStakeholderImportanceName: null,
    concernText: null,
    concernClassName: null,
    orgValue: null
  };
});

// This collects dynamic refs for all crosspoints
const projectConcernMatrix = ref(null);
// const stakeholderstemp = ref({ panels: [] });
const stakeholders = ref({ panels: [] });
const concern = ref([]);
const crossPoints = ref([]);
const crosspointTextareas = ref([]);
const stakeholderStrategy = ref([]);
const stakeholderStrategyLabel = ref([]);
const toggleConcern = (event, concernId) => {
  // console.info(concern, concernId);
  // console.info('concernId', crossPoints.value[concernId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectconcernmatrix.edit', currentUser.value, props.projectId)) {
    // console.log('event:' + JSON.stringify(event))
    concern.value[concernId].toggle(event);
  }
};
const toggleCrossPoint = (event, crossPointId) => {
  // console.info(crossPoints, crossPointId);
  // console.info('crossPointId', crossPoints.value[crossPointId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectconcernmatrix.edit', currentUser.value, props.projectId)) {
    crossPoints.value[crossPointId].toggle(event);
  }
};
const updateStakeholderConcernValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.concernNr = value;
    document.querySelector('#shconcernmain-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderConcernFromMatrix(project.value, stakeholderId, value);
    // }
  }
};

const updateCrossPointConcern = (value, stakeholderId, issueId) => {
  if (value === -1 || value === null) {
    const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (!crossPoint || crossPoint === undefined) {
      return;
    }
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    if (confirm(t('Product.ConcernMatrix.AreYouSure'))) {
      document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
      crossPoint.issueStakeholderConcernNr = null;
      crossPoint.concernClassName = null;
      removeStakeholderIssue(props.projectId, stakeholderId, issueId);
    }
  } else {
    // first update matrix. After that update db. For a better user experience
    document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
    var element = document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId);
    observeClassChange(element, stakeholderId, issueId, getConcern(value).className, forceAssignConcern);
    const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderConcernNr = value;
      crossPoint.concernClassName = value;
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderIssueConcernFromMatrix(props.projectId, stakeholderId, issueId, value);
    } else {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints.push({
        stakeholderId: stakeholderId,
        issueId: issueId,
        issueStakeholderConcernNr: value
      });
      // console.info('SOFT CREATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      addStakeholderIssueConcern(props.projectId, stakeholderId, issueId, value);
    }
  }
};

const observeClassChange = (element, stakeholderId, issueId, value, callback) => {
  const observer = new MutationObserver((mutations) => {
    if (mutations.some((mutation) => mutation.type === 'attributes' && mutation.attributeName === 'class')) {
      // class changed, call callback function
      observer.disconnect();
      // console.log('mutation of class');
      callback(stakeholderId, issueId, value);
    }
  });

  observer.observe(element, { attributes: true });

  setTimeout(() => {
    observer.disconnect();
  }, 10000); // stop observing after 10 seconds
};

const forceAssignConcern = (stakeholderId, issueId, value) => {
  // set attribute using forceAssignConcern
  document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + value);
};
const updateCrossPointConcernDescription = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  const textvalue = document.querySelector('.ql-editor p').innerHTML;
  if (crossPoint) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderIssueConcernDescriptionFromMatrix(props.projectId, stakeholderId, issueId, textvalue);

    const indexOfObject = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].orgValue = crosspointTextareas.value[crossPointId].value;
    }
  }
  showOverlayPanelButtons.value = false;
};
const cancelCrossPointConcernDescription = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPointValue = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPointValue) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    const indexOfObject = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].concernText = crossPointValue.orgValue;
    }
  }
  focusOverlayPanelInput.value = false;
  showOverlayPanelButtons.value = false;
  crossPoints.value[crossPointId].toggle();
};
const toggleStakeholderStrategy = (event, stakeholderId) => {
  stakeholderStrategy.value[stakeholderId].toggle(event);
};
const updateStakeholderStrategy = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#strategyOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderParticipationFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderStrategy.value[stakeholderId].toggle(event);
};

// const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// const addPanelsWithDelay = async () => {
//   if (stakeholders.value) {
//     const temp = JSON.parse(JSON.stringify(stakeholders.value));
//     temp.panels = temp.panels.splice(0, 1);
//     stakeholderstemp.value = JSON.parse(JSON.stringify(temp));
//   }
//   for (var i = 1; i < stakeholders.value.panels.length; i++) {
//     await delay(1000); // Delay of 3 seconds
//     stakeholderstemp.value.panels.push(stakeholders.value.panels[i]);
//   }
// };

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});
watch(() => props.projectConcernMatrix, (first, second) => {
  projectConcernMatrix.value = first;
});
watch(() => props.stakeholders, (first, second) => {
  stakeholders.value = first;

  // addPanelsWithDelay();
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

</script>

<template>
  <main>
    <p-card v-if="stakeholders && props.issues && projectConcernMatrix">
      <template #content>
        <div class="concern-matrix matrix overflow-hidden">
          <div class="matrix__wrap position-relative">
            <div
              class="matrix__matrix position-absolute w-100"
              role="table">
              <div
                class="matrix__matrix-row d-flex"
                role="row"
                aria-label="Belangenmatrix">
                <div
                  class="matrix__matrix-th"
                  role="cell">
                </div>
                <template
                  v-for="issueCategory in props.issues.panels"
                  :key="'issueCategory' + issueCategory.id">
                  <div
                    class="matrix__matrix-th pt-3 pb-3"
                    role="cell">
                    <span>
                      {{ issueCategory.panelTitle }}
                    </span>
                  </div>
                  <div
                    v-for="issue in issueCategory.panel"
                    :key="'issue' + issue.id"
                    class="matrix__matrix-th pt-3 pb-3"
                    role="cell">
                    <span>
                      <router-link
                        :to="issue.urlTo"
                        :title="issue.title">
                        {{ issue.title }}
                      </router-link>
                    </span>
                  </div>
                </template>
                <div
                  class="matrix__matrix-th pt-3 pb-3"
                  role="cell">
                  <span>
                    {{ $t('Stakeholder.Concern.Excel') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('Issue.Strategy') }}
                  </span>
                </div>
              </div>

              <template
                v-for="stakeholderCategory in stakeholders.panels"
                :key="'stakeholderCategory' + stakeholderCategory.id">
                <div
                  class="matrix__matrix-row d-flex"
                  role="row">
                  <div
                    class="matrix__matrix-td --category"
                    role="cell">
                    <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                      <span>
                        {{ stakeholderCategory.panelTitle }}
                      </span>
                    </h3>
                  </div>
                  <template
                    v-for="issueCategory in props.issues.panels"
                    :key="'issueCategory' + issueCategory.id">
                    <div
                      class="matrix__matrix-td --category"
                      role="cell">
                    </div>
                    <div
                      v-for="issue in issueCategory.panel"
                      :key="'issue' + issue.id"
                      class="matrix__matrix-td --category"
                      role="cell">
                    </div>
                  </template>
                  <div
                    class="matrix__matrix-td --category"
                    role="cell">
                  </div>
                  <div
                    class="matrix__matrix-td --category --text-only"
                    role="cell">
                  </div>
                </div>
                <template
                  v-for="stakeholder in stakeholderCategory.panel"
                  :key="'stakeholder' + stakeholder.id">
                  <div
                    class="matrix__matrix-row d-flex"
                    role="row">
                    <div
                      class="matrix__matrix-td d-flex align-items-center"
                      role="cell">
                      <router-link
                        :to="stakeholder.urlTo"
                        class="matrix__table-stakeholders-item pe-3 ps-3 d-flex align-items-center"
                        :title="stakeholder.title">
                        {{ stakeholder.title }}
                      </router-link>
                    </div>

                    <template
                      v-for="issueCategory in props.issues.panels"
                      :key="'issueCategory' + issueCategory.id">
                      <div class="matrix__matrix-td"></div>
                      <div
                        v-for="issue in issueCategory.panel"
                        :key="'crosspoint' + stakeholder.id + ' - ' + issue.id"
                        class="matrix__matrix-td d-flex justify-content-center align-items-center"
                        role="cell"
                        :debugme-title="'stakeholder: ' + stakeholder.title + ' - issue: ' + issue.title + ' - value: ' + crossPoint(stakeholder.id, issue.id).issueStakeholderConcernNr + ' - ' + crossPoint(stakeholder.id, issue.id).issueStakeholderConcernName + ' - ' + crossPoint(stakeholder.id, issue.id).concernText">
                        <p-button
                          class="matrix__matrix-button p-0"
                          :class="crossPoint(stakeholder.id, issue.id).concernClassName"
                          :label="crossPoint(stakeholder.id, issue.id).issueStakeholderConcernName"
                          aria-haspopup="true"
                          :aria-controls="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          v-tooltip.top="crossPoint(stakeholder.id, issue.id).issueStakeholderConcernName"
                          :id="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          @click="toggleCrossPoint($event, 'crosspoint-' + stakeholder.id + '-' + issue.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__matrix-overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }" :ref="(el) => { crossPoints['crosspoint-' + stakeholder.id + '-' + issue.id] = el; }"
                          :id="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__matrix-overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --reset"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == null, }"
                              :label="configMatrix.tooltip.reset"
                              icon="pi pi-times"
                              v-tooltip.top="configMatrix.tooltip.reset"
                              @click="updateCrossPointConcern(null, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateCrossPointConcern(5, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --hardlyimportance"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == '--hardlyimportance', }"
                              :label="configMatrix.tooltip.hardlyimportance"
                              v-tooltip.top="configMatrix.tooltip.hardlyimportance"
                              @click="updateCrossPointConcern(4, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --reasonablyimportance"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == '--reasonablyimportance', }"
                              :label="configMatrix.tooltip.reasonablyimportance"
                              v-tooltip.top="configMatrix.tooltip.reasonablyimportance"
                              @click="updateCrossPointConcern(3, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --greatimportance"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == '--greatimportance', }"
                              :label="configMatrix.tooltip.greatimportance"
                              v-tooltip.top="configMatrix.tooltip.greatimportance"
                              @click="updateCrossPointConcern(2, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --extremlyhighimportance"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).concernClassName == '--extremlyhighimportance', }"
                              :label="configMatrix.tooltip.extremlyhighimportance"
                              v-tooltip.top="configMatrix.tooltip.extremlyhighimportance"
                              @click="updateCrossPointConcern(1, stakeholder.id, issue.id)">
                            </p-button>
                          </div>

                          <p-editor
                            class="mt-2"
                            :ref="(el) => { crosspointTextareas['crosspoint-' + stakeholder.id + '-' + issue.id] = el; }"
                            @click="onFocusOverlayPanelInputEditor(stakeholder.id, issue.id)"
                            v-model="crossPoint(stakeholder.id,issue.id).concernText">
                            <template v-slot:toolbar>
                              <span class="ql-formats">
                                <button
                                  class="ql-bold"
                                  v-tooltip.bottom="'Bold'">
                                </button>
                                <button
                                  class="ql-italic"
                                  v-tooltip.bottom="'Italic'">
                                </button>
                                <button
                                  class="ql-underline"
                                  v-tooltip.bottom="'Underline'">
                                </button>
                              </span>
                            </template>
                          </p-editor>
                          <textarea
                            class="p-hidden"
                            :placeholder="$t('General.Explanation')"
                            :ref="(el) => { crosspointTextareas[ 'crosspoint-' + stakeholder.id + '-' + issue.id ] = el; }"
                            @focus="onFocusOverlayPanelInput()"
                            @blur="onBlurOverlayPanelInput()"
                            v-model="crossPoint(stakeholder.id, issue.id).concernText">
                          </textarea>
                          <Transition name="fade">
                            <div
                              class="d-flex justify-content-end"
                              v-if="showOverlayPanelButtons">
                              <p-button
                                class="matrix__overlay-panel-button p-button-text me-1"
                                :label="configMatrix.overlayPanel.cancelButton"
                                @mousedown="cancelCrossPointConcernDescription(null, 'crosspoint-' + stakeholder.id + '-' + issue.id, stakeholder.id, issue.id)">
                              </p-button>
                              <p-button
                                class="matrix__overlay-panel-button"
                                :label="configMatrix.overlayPanel.saveButton"
                                @mousedown="updateCrossPointConcernDescription(null, 'crosspoint-' + stakeholder.id + '-' + issue.id, stakeholder.id, issue.id)">
                              </p-button>
                            </div>
                          </Transition>
                        </p-overlay-panel>
                      </div>
                    </template>

                    <div
                      class="matrix__matrix-td d-flex justify-content-center align-items-center"
                      role="cell">
                      <div class="d-flex justify-content-center">
                        <p-button
                          class="matrix__matrix-button p-0"
                          :class="concernClassName(stakeholder.concernNr)"
                          :label="$t(concernText(stakeholder.concernNr))"
                          aria-haspopup="true"
                          :aria-controls="'shconcern-' + stakeholder.id"
                          :id="'shconcernmain-' + stakeholder.id"
                          v-tooltip.top="$t(concernText(stakeholder.concernNr))"
                          @click="toggleConcern($event, 'shconcern-' + stakeholder.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { concern['shconcern-' + stakeholder.id] = el; }"
                          :id="'shconcern-' + stakeholder.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getConcern(stakeholder.concernNr).className == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateStakeholderConcernValue(5, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --hardlyimportance"
                              :class="{'--active': getConcern(stakeholder.concernNr).className == '--hardlyimportance',}"
                              :label="configMatrix.tooltip.hardlyImportance"
                              v-tooltip.top="configMatrix.tooltip.hardlyImportance"
                              @click="updateStakeholderConcernValue(4, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --reasonablyimportance"
                              :class="{'--active': getConcern(stakeholder.concernNr).className == '--reasonablyimportance',}"
                              :label="configMatrix.tooltip.reasonablyImportance"
                              v-tooltip.top="configMatrix.tooltip.reasonablyImportance"
                              @click="updateStakeholderConcernValue(3, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --greatimportance"
                              :class="{'--active': getConcern(stakeholder.concernNr).className == '--greatimportance', }"
                              :label="configMatrix.tooltip.greatImportance"
                              v-tooltip.top="configMatrix.tooltip.greatImportance"
                              @click="updateStakeholderConcernValue(2, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --extremlyhighimportance"
                              :class="{'--active': getConcern(stakeholder.concernNr).className == '--extremlyhighimportance',}"
                              :label="configMatrix.tooltip.extremlyHighImportance"
                              v-tooltip.top="configMatrix.tooltip.extremlyHighImportance"
                              @click="updateStakeholderConcernValue(1, null, stakeholder.id)">
                            </p-button>
                          </div>
                        </p-overlay-panel>
                      </div>
                    </div>
                    <div
                      class="matrix__matrix-td d-flex justify-content-center align-items-center --text-only"
                      role="cell">
                      <div class="d-flex justify-content-center">
                        <p-button
                          class="matrix__matrix-strategy-button p-button-text"
                          :label="stakeholder.participation ? stakeholder.participation.name : $t('General.Unknown')"
                          :ref="(el) => { stakeholderStrategyLabel[stakeholder.id] = el;
  }
" :id="
  'strategyOptionLabel' +
  stakeholder.id
" @click="
  toggleStakeholderStrategy(
    $event,
    stakeholder.id
  )
">
                        </p-button>
                        <p-overlay-panel class="p-3" :ref="
                            (el) => {
                              stakeholderStrategy[
                              stakeholder.id
                              ] = el;
                            }
                          ">
                          <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                            <li v-for="option in strategyOption" :key="
                                'strategyOption' +
                                stakeholder.id +
                                option.value
                              " class="d-flex align-items-center">
                              <p-radio-button :name="
                                  'strategyOption' +
                                  stakeholder.id
                                " :id="
  'strategyOption' +
  stakeholder.id +
  option.value
" :value="option.value" @click="
  stakeholder.participation = {
    nr: option.value,
    name: option.name,
  };
updateStakeholderStrategy(
  option.value,
  option.name,
  stakeholder.id,
  stakeholder.participation
);
">
                              </p-radio-button>
                              <label :for="
                                  'strategyOption' +
                                  stakeholder.id +
                                  option.value
                                " class="matrix__strategy-label d-inline-block">
                                {{ option.name }}
                              </label>
                            </li>
                          </ul>
                        </p-overlay-panel>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <div
                class="matrix__matrix-row d-flex"
                role="row">
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                  <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                    <span>
                      {{ $t('General.LegendImportance') }}
                    </span>
                  </h3>
                </div>
                <div class="matrix__matrix-td d-flex align-items-center --category --legend">
                  <table class="matrix__matrix-legend">
                    <tr>
                      <template
                        v-for="item in 5"
                        :key="'legend-concern-' + item">
                        <td class="pe-3 ps-3">
                          <span class="d-flex align-items-center">
                            <span
                              class="matrix__matrix-legend-badge me-2 d-inline-block"
                              :class="concernClassName(item)"
                              :title="$t(concernText(item))">
                            </span>
                            <span class="matrix__matrix-legend-text">
                              {{ $t(concernText(item)) }}
                            </span>
                          </span>
                        </td>
                      </template>
                    </tr>
                  </table>
                </div>
              </div>
            </div><!-- End table -->
          </div><!-- End wrap -->
        </div><!-- End matrix -->
      </template>
    </p-card>

    <p-progress-bar
      class="concern-matrix__progressbar"
      :value="50"
      v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.concern-matrix {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;
  }

  .matrix__matrix-row:first-child:before {
    width: calc(100% + 2544px);
  };

  .matrix__th {
    position: relative;
    bottom: -4px;
    left: 60px;
    transform: rotate(30deg);
  }
  .matrix__th.--strategy > span {
    transform: rotate(-30deg);
  }
}
</style>
