<script setup>
import { ref } from 'vue';
import useMoment from 'moment';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PSelectButton from 'primevue/selectbutton';

// Store imports

// Define props
const props = defineProps({
  cardTitle: String,
  cardUrl: String,
  imgUrl: String,
  modDate: String,
  selectRoleOptions: Array,
  selectRoleOptionLabel: String,
  detailsHeader: String,
  detailsContent: String,
  showImage: Boolean,
  member: Object,
  editMode: Boolean
});

// Define emits
const emit = defineEmits([
  'updateMemberRole',
  'deleteMember'
]);

// Variables
const cardDefaultText = {
  details: 'General.ProjectDetails',
  modification: 'General.LastLogin'
};
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const roles = [
  {
    nr: 0,
    name: t('Maintenance.Admin')
  },
  {
    nr: 1,
    name: t('Members.MemberTypeManage')
  },
  {
    nr: 2,
    name: t('Members.MemberTypeEdit')
  },
  {
    nr: 3,
    name: t('Members.MemberTypeReadOnly')
  }
];

const selectedRole = {
  nr: props.member.role.nr,
  name: roles[props.member.role.nr].name
};

const selectedRoleOption = ref(selectedRole);

const translateRoleName = (nr) => {
  switch (nr) {
    case 0:
      return 'Members.MemberTypeAdmin';
    case 1:
      return 'Members.MemberTypeManage';
    case 2:
      return 'Members.MemberTypeEdit';
    case 3:
      return 'Members.MemberTypeReadOnly';
  }
};

// Functions
const deleteMember = () => {
  emit('deleteMember', props.member.id);
};

const updateMemberRole = (value) => {
  const result = {
    id: props.member.id,
    role: value
  };
  emit('updateMemberRole', result);
};
</script>

<template>
  <p-card class="ui-project-member-card">
    <template #header>
      <router-link
        class="ui-project-member-card__link pt-3 pb-3 pe-3 ps-3 d-flex justify-content-between align-items-center w-100"
        :to="props.cardUrl"
        v-if="props.cardUrl">
        <h2 class="ui-project-member-card__title position-relative mt-0 mb-0 me-3 text-truncate">
          {{ props.cardTitle }}
        </h2>
        <div
          class="ui-project-member-card__img position-relative ms-3 w-100 overflow-hidden"
          v-if="props.showImage ?? true">
          <img
            :src="props.imgUrl"
            class="img-object-fit w-100 h-100"
            :alt="props.cardTitle"
            v-if=" props.imgUrl !== null && props.imgUrl !== '' && props.imgUrl !== undefined" />
        </div>
      </router-link>
      <div
        v-else
        class="ui-project-member-card__link pt-3 pb-3 pe-3 ps-3 d-flex justify-content-between align-items-center w-100">
        <h2 class="ui-project-member-card__title position-relative mt-0 mb-0 me-3 text-truncate">
          {{ props.cardTitle }}
        </h2>
        <div
          class="ui-project-member-card__img position-relative ms-3 w-100 overflow-hidden"
          v-if="props.showImage ?? true">
          <img
            :src="props.imgUrl"
            class="img-object-fit w-100 h-100"
            :alt="props.cardTitle"
            v-if="props.imgUrl !== null && props.imgUrl !== '' && props.imgUrl !== undefined" />
        </div>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-6">
          <h3 class="mt-0 pt-3 ps-3">
            {{ props.detailsHeader ?? $t(cardDefaultText.details) }}
          </h3>
          <p
            class="mt-0 mb-0 pb-3 ps-3">
            <span>
              {{ $t(translateRoleName(selectedRoleOption.nr)) }} {{props.detailsContent}}
            </span>
          </p>
        </div>
        <div class="col-6">
          <h3 class="mt-0 pt-3">
            {{ $t(cardDefaultText.modification) }}
          </h3>
          <p class="mt-0 mb-0 pb-3 pe-3" v-if="props.modDate">
            {{ useMoment(props.modDate).format('DD-MM-YYYY HH:mm') }}
          </p>
          <p class="mt-0 mb-0 pb-3 pe-3" v-if="!props.modDate">
            {{ $t('General.Unknown') }}
          </p>
        </div>
      </div>
      <div class="row mb-1" v-if="props.editMode">
        <div class="col-12 d-flex justify-content-between" v-if="member.role.nr > 0">
          <p-select-button
            class="mb-5 ms-3"
            v-model="selectedRoleOption"
            :options="props.selectRoleOptions.filter(x => x.nr > 0)"
            :option-label="props.selectRoleOptionLabel"
            :multiple="props.multipleSelect"
            @change="updateMemberRole($event.value)">
          </p-select-button>
          <p-button
            :label="props.buttonLabel"
            class="ui-project-member-card__delete p-button-secondary mb-5 me-3"
            icon="pi pi-times"
            iconPos="center"
            @click="deleteMember">
          </p-button>
        </div>
        <div class="col-12 d-flex justify-content-between" v-if="member.role.nr === 0">
          <p-select-button
            class="mb-5 ms-3"
            v-model="selectedRoleOption"
            :options="props.selectRoleOptions"
            :option-label="props.selectRoleOptionLabel"
            :multiple="props.multipleSelect"
            :disabled="true"
            @change="updateMemberRole($event.value)">
          </p-select-button>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-project-member-card {
  &__link {
    font-size: calc-rem(20);
    line-height: calc-rem(22);
    text-decoration: none;
    border-radius: calc-rem(3) calc-rem(3) 0 0;
    border-bottom: calc-rem(1) solid $separatorLineColor;
    color: $primaryColor;

    &:hover {
      .ui-project-member-card__title::after {
        opacity: 1;
      }
    }

    &:focus {
      @extend %button-focus;
    }
  }

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc-rem(2);
      content: '';
      background-color: $primaryColor;
      opacity: 0;
      transition: opacity 200ms ease-out;
    }
  }

  &__img {
    max-width: calc-rem(140);
    border-radius: $borderRadius;
    aspect-ratio: 140 / 80;
    background-color: $mainBg;
    background-image: url(../../assets/images/default-image.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      top: 0;
      left: 0;
    }
  }

  &__updates {
    padding-left: 0;
    list-style: none;
    font-size: calc-rem(12);
    line-height: calc-rem(16);

    &-item {
      padding-top: calc-rem(12);
      padding-bottom: calc-rem(12);

      &:nth-child(n + 2) {
        border-top: calc-rem(1) solid $separatorLineColor;
      }
    }

    &-title,
    &-link {
      color: $primaryColor;
    }

    &-title {
      margin-top: calc-rem(12);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }

    &-link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        @extend %button-focus;
      }
    }
  }

  &__download {
    margin-bottom: calc-rem(12);
  }

  &__delete {
    padding-right: calc-rem(11);
    padding-left: calc-rem(11);

    .p-button-label {
      @extend %sr-only;
    }
  }

  .p {
    &-card {
      &-content {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        h3 {
          margin-bottom: calc-rem(6);
          font-size: calc-rem(12);
          line-height: calc-rem(14);
          letter-spacing: 0.01em;
          color: $secondaryTextColor;
        }

        p {
          font-size: calc-rem(14);
          line-height: calc-rem(16);
        }
      }
    }

    &-panel {
      &-header {
        border-radius: 0;

        h3 {
          font-size: calc-rem(14);
          line-height: calc-rem(18);
        }
      }

      &-content {
        border-radius: 0;
      }

      .pi-plus::before {
        content: '\e902';
      }

      .pi-minus::before {
        content: '\e903';
      }
    }

    &-badge {
      border-radius: calc-rem(14);
    }
  }
}
</style>
