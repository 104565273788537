export class ProjectConfigConstants {
  static get configName () {
    return {
      labelName: 'General.Name',
      labelFor: 'name'
    };
  }

  static get configFileUpload () {
    return {
      labelTitle: 'Project.Foto',
      labelFor: 'image',
      fileTypes: 'image/*',
      fileLimit: 1,
      fileSize: 5242880,
      fileUrl: '/',
      moreFiles: false,
      thumbnailWidth: 60,
      chooseLabel: 'ProjectMedia.Frontend.Select'
    };
  }

  static get configProfilePictureUpload () {
    return {
      labelTitle: 'Profile.ChooseProfilePicture',
      labelFor: 'image',
      fileTypes: 'image/*',
      fileLimit: 1,
      fileSize: 5242880,
      fileUrl: '/',
      moreFiles: false,
      thumbnailWidth: 60,
      chooseLabel: 'ProjectMedia.Frontend.Select'
    };
  }

  static get configDescription () {
    return {
      labelName: 'Project.Frontend.Description',
      labelFor: 'description'
    };
  }

  static get configStakeholder () {
    return {
      labelName: 'Project.CustomStakeholderFields',
      labelFor: 'stakeholder',
      defaultMessage: 'Project.AddParts',
      errorMessage: 'Project.StakeholderComponent'
    };
  }

  static get configIssue () {
    return {
      labelName: 'Project.CustomIssueFields',
      labelFor: 'issue',
      defaultMessage: 'Project.AddParts',
      errorMessage: 'Project.IssueComponent'
    };
  }

  static get configGisDefaultCoordinatesLat () {
    return {
      labelName: 'Project.Latidude',
      labelFor: 'latitude'
    };
  }

  static get configGisDefaultCoordinatesLng () {
    return {
      labelName: 'Project.Longitude',
      labelFor: 'longitude'
    };
  }

  static get configGisDefaultZoomLevel () {
    return {
      labelName: 'Project.ZoomLevel',
      labelFor: 'zoomlevel'
    };
  }

  static get configFieldsetCustomFields () {
    return {
      labelName: 'Project.ExtraCustomFields'
    };
  }

  static get configFieldsetGis () {
    return {
      labelName: 'Project.GIS'
    };
  }

  static get configFieldsetVisibleFields () {
    return {
      labelName: 'Project.VisibleFields'
    };
  }

  static get checkboxesStakeholders () {
    return {
      title: 'Project.AvailableStakeholderFields',
      checkboxes: [
        {
          id: 0,
          title: 'Stakeholder.Context',
          checked: true
        },
        {
          id: 1,
          title: 'General.NAW',
          checked: true
        },
        {
          id: 2,
          title: 'Stakeholder.Contacts',
          checked: true
        },
        {
          id: 3,
          title: 'ProjectStakeholder.ConcernPoV',
          checked: true
        },
        {
          id: 4,
          title: 'ProjectStakeholder.Power',
          checked: true
        },
        {
          id: 5,
          title: 'ProjectStakeholder.Emotion.Excel',
          checked: true
        },
        {
          id: 6,
          title: 'Stakeholder.Line',
          checked: true
        },
        // <!-- SOMSET-721: Must not show up in new version -->
        // {
        //   id: 7,
        //   title: 'Veranderkundig profiel',
        //   checked: true
        // },
        {
          id: 8,
          title: 'ProjectStakeholder.Urgency',
          checked: true
        },
        {
          id: 9,
          title: 'Stakeholder.StakeholderLegitimacy',
          checked: true
        },
        {
          id: 12,
          title: 'Stakeholder.BATNA',
          checked: true
        },
        {
          id: 13,
          title: 'Stakeholder.DegreeOfTrust',
          checked: true
        },
        {
          id: 10,
          title: 'Stakeholder.Participation',
          checked: true
        },
        {
          id: 11,
          title: 'Stakeholder.Discussion',
          checked: true
        }
      ]
    };
  }

  static get checkboxesIssues () {
    return {
      title: 'Project.AvailableIssueFields',
      checkboxes: [
        // <!-- SOMSET-721: Must not show up in new version -->
        // {
        //   id: 0,
        //   title: 'Emotie',
        //   checked: true
        // },
        {
          id: 1,
          title: 'Issue.Frontend.Intelligibility',
          checked: true
        },
        {
          id: 2,
          title: 'Issue.Frontend.Visibility',
          checked: true
        },
        {
          id: 3,
          title: 'Issue.MediaSensitivity',
          checked: true
        },
        {
          id: 4,
          title: 'Issue.DevelopmentPhase',
          checked: true
        },
        {
          id: 5,
          title: 'Issue.Complexity',
          checked: true
        },
        {
          id: 6,
          title: 'Issue.Narrative',
          checked: true
        },
        {
          id: 7,
          title: 'Issue.Strategy',
          checked: true
        },
        {
          id: 8,
          title: 'Issue.DiscussionText',
          checked: true
        }
      ]
    };
  }

  static get configButtons () {
    return {
      saveButton: 'General.Save',
      cancelButton: 'General.Cancel'
    };
  }

  static get issueFieldNames () {
    return [
      { FieldName: 'Issue.Emotion', Available: false },
      { FieldName: 'Issue.Intelligibility', Available: false },
      { FieldName: 'Issue.Visibility', Available: false },
      { FieldName: 'Issue.MediaSensitivity', Available: false },
      { FieldName: 'Issue.DevelopmentPhase', Available: false },
      { FieldName: 'Issue.Complexity', Available: false },
      { FieldName: 'Issue.Narrative', Available: false },
      { FieldName: 'Issue.Strategy', Available: false },
      { FieldName: 'Issue.DiscussionText', Available: false }
    ];
  }

  static get stakeholderFieldNames () {
    return [
      { FieldName: 'Stakeholder.Context', Available: false },
      { FieldName: 'General.NAW', Available: false },
      { FieldName: 'General.Stakeholder.Contacts', Available: false },
      { FieldName: 'Stakeholder.ConcernPoV', Available: false },
      { FieldName: 'Stakeholder.Power', Available: false },
      { FieldName: 'Stakeholder.Emotion', Available: false },
      { FieldName: 'Stakeholder.Line', Available: false },
      { FieldName: 'Stakeholder.ChangableStrategy', Available: false },
      { FieldName: 'Stakeholder.Batna', Available: false },
      { FieldName: 'Stakeholder.LevelOfTrust', Available: false },
      { FieldName: 'Stakeholder.Urgency', Available: false },
      { FieldName: 'Stakeholder.Legitimity', Available: false },
      { FieldName: 'Stakeholder.Participation', Available: false },
      { FieldName: 'Stakeholder.Discussion', Available: false }
    ];
  }

  // Note JWB: fieldname is not a translation! Only title field
  static get issueFieldNameWithTitle () {
    return [
      {
        title: 'Issue.Emotion',
        FieldName: 'Issue.Emotion'
      },
      {
        title: 'Issue.Frontend.Intelligibility',
        FieldName: 'Issue.Intelligibility'
      },
      {
        title: 'Issue.Frontend.Visibility',
        FieldName: 'Issue.Visibility'
      },
      {
        title: 'Issue.MediaSensitivity',
        FieldName: 'Issue.MediaSensitivity'
      },
      {
        title: 'Issue.DevelopmentPhase',
        FieldName: 'Issue.DevelopmentPhase'
      },
      {
        title: 'Issue.Complexity',
        FieldName: 'Issue.Complexity'
      },
      {
        title: 'Issue.Narrative',
        FieldName: 'Issue.Narrative'
      },
      {
        title: 'Issue.Strategy',
        FieldName: 'Issue.Strategy'
      },
      {
        title: 'Issue.DiscussionText',
        FieldName: 'Issue.DiscussionText'

      }
    ];
  }

  // Note JWB: fieldname is not a translation! Only title field
  static get configStakeholderFieldNameWithTitle () {
    return [
      {
        title: 'Stakeholder.Context',
        FieldName: 'Stakeholder.Context'
      },
      {
        title: 'General.NAW',
        FieldName: 'General.NAW'
      },
      {
        title: 'Stakeholder.Contacts',
        FieldName: 'General.Stakeholder.Contacts'
      },
      {
        title: 'ProjectStakeholder.ConcernPoV',
        FieldName: 'Stakeholder.ConcernPoV'
      },
      {
        title: 'ProjectStakeholder.Power',
        FieldName: 'Stakeholder.Power'
      },
      {
        title: 'ProjectStakeholder.Emotion.Excel',
        FieldName: 'Stakeholder.Emotion'
      },
      {
        title: 'Stakeholder.Line',
        FieldName: 'Stakeholder.Line'
      },
      {
        title: 'ProjectStakeholder.ChangableStrategy',
        FieldName: 'Stakeholder.ChangableStrategy'
      },
      {
        title: 'Stakeholder.BATNA',
        FieldName: 'Stakeholder.Batna'
      },
      {
        title: 'Stakeholder.DegreeOfTrust',
        FieldName: 'Stakeholder.LevelOfTrust'
      },
      {
        title: 'ProjectStakeholder.Urgency',
        FieldName: 'Stakeholder.Urgency'
      },
      {
        title: 'Stakeholder.StakeholderLegitimacy',
        FieldName: 'Stakeholder.Legitimity'
      },
      {
        title: 'Stakeholder.Participation',
        FieldName: 'Stakeholder.Participation'
      },
      {
        title: 'Stakeholder.Discussion',
        FieldName: 'Stakeholder.Discussion'
      }
    ];
  }
}
