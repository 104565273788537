<script setup>
// import { ref } from 'vue';

// Component imports
import IssuesListCard from './components/IssuesListCard';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports

// Variables
const { getProjectIssuesGroupedByStatus, getProjectIssuesGroupedByCategory } = storeToRefs(useProjectsStore());

const props = defineProps({
  groupBy: String
});

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
</script>

<template>
  <div class="issues-list row">
    <div class="col-12">
      <issues-list-card
        :card="
          props.groupBy === 'category'
            ? getProjectIssuesGroupedByCategory
            : getProjectIssuesGroupedByStatus
        "
      >
      </issues-list-card>
    </div>
  </div>
</template>

<style lang="scss">
.issues-list {
}
</style>
