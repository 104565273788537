<script setup>
import { ref, watch } from 'vue';
import concernimportancehelper from '@/services/concernimportancehelper';
import useMoment from 'moment';

// Components imports
import PCard from 'primevue/card';
import PCheckbox from 'primevue/checkbox';
import PButton from 'primevue/button';
import UiCardTitle from './UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  checkboxOptions: Array,
  modelValue: Object,
  readOnly: Boolean,
  versionDetails: Object
});

// Define emits
const emit = defineEmits(['update-value', 'emitDirty']);

// Variables
const tooltipReset = 'Geen';
const selectedCheckbox = ref(props.checkboxOptions.filter(i => i.checked));

// Functions
const resetCheckboxes = () => {
  selectedCheckbox.value = [];
};
const onClick = (item) => {
  selectedCheckbox.value = [item];
  emit('update-value', item);
  emit('update:modelValue', item.id);
  emit('emitDirty');
};

watch(() => props.modelValue, (val) => {
  const selectedBox = props.checkboxOptions.filter(i => i.id === val);
  selectedCheckbox.value = selectedBox;
});
</script>

<template>
  <p-card class="ui-review-card">
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <div class="d-flex">
          <p-button
            v-if="!props.readOnly"
            class="ui-review-card__reset"
            label="Geen"
            icon="pi pi-times"
            iconPos="center"
            v-tooltip.top="tooltipReset"
            @click="resetCheckboxes()">
          </p-button>
          <div
            v-for="item in checkboxOptions"
            :key="item.id">
            <label
              :for="item.value"
              class="sr-only">
              {{ item.labelTitle }}
            </label>
            <p-checkbox
              v-if="!props.readOnly"
              class="ui-review-card__checkbox"
              :class="item.className"
              :id="item.value"
              v-model="selectedCheckbox"
              :value="item"
              :name="item.nameCheckboxes"
              trueValue="1"
              @input="onClick(item)"
              v-tooltip.top="item.labelTitle">
            </p-checkbox>
            <p-checkbox
              v-if="props.readOnly"
              class="ui-review-card__checkbox"
              :class="item.className"
              :value="item"
              :name="item.nameCheckboxes"
              trueValue="1"
              v-tooltip.top="item.labelTitle">
            </p-checkbox>
          </div>
        </div>
        <div v-if="versionDetails">
          <p class="ui-review-card__version"><em>Op {{useMoment(versionDetails.date).format('DD-MM-YYYY')}} was de waarde:</em>
            <span class="m-1" v-if="versionDetails.type === 'concern'">
              <div>
                <p-checkbox
                  class="ui-review-card__checkbox ui-review-card__checkbox-version"
                  :class="concernimportancehelper.getConcern(versionDetails.value).className"
                  v-tooltip.top="concernimportancehelper.getConcern(versionDetails.value).name">
                </p-checkbox>
              </div>
            </span>
            <span class="m-1" v-if="versionDetails.type === 'status'">
              <div>
                <p-checkbox
                  class="ui-review-card__checkbox ui-review-card__checkbox-version"
                  :class="concernimportancehelper.getImportance(versionDetails.value).className"
                  v-tooltip.top="concernimportancehelper.getImportance(versionDetails.value).name">
                </p-checkbox>
              </div>
            </span>
          </p>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-review-card {
  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
  }

  &__reset {
    margin-right: calc-rem(7);
    padding-right: calc-rem(12);
    padding-left: calc-rem(12);
    height: calc-rem(40);
    background-color: $formBgHover;
    border-color: $secondaryHover;
    color: $secondaryTextColor;

    &:hover {
      background-color: $secondaryHover  !important;
      border-color: $secondaryHover  !important;
      color: $secondaryTextColor  !important;
    }

    .p-button-label {
      @extend %sr-only;
    }
  }

  &__version {
    span {
      display: inline-block;
    }
  }

  &__checkbox {
    margin-right: calc-rem(7);
    width: calc-rem(40);
    height: calc-rem(40);

    &-version {
      width: calc-rem(20);
      height: calc-rem(20);
    }

    .p-checkbox {
      &-box {
        width: inherit !important;
        height: inherit !important;

        &-icon {
          font-size: calc-rem(18) !important;
        }
      }
    }

    &.--unclear {
      .p-checkbox-box {
        background-color: $unclear  !important;
        border-color: $unclear  !important;
      }
    }

    &.--positive {
      .p-checkbox-box {
        background-color: $positive  !important;
        border-color: $positive  !important;
      }
    }

    &.--focus {
      .p-checkbox-box {
        background-color: $focus  !important;
        border-color: $focus  !important;
      }
    }

    &.--warning {
      .p-checkbox-box {
        background-color: $warning  !important;
        border-color: $warning  !important;
      }
    }

    &.--criticism {
      .p-checkbox-box {
        background-color: $criticism  !important;
        border-color: $criticism  !important;
      }
    }

    &.--hardlyimportance {
      .p-checkbox-box {
        background-color: $hardlyimportance;
        border-color: $hardlyimportance;
      }
    }

    &.--reasonablyimportance {
      .p-checkbox-box {
        background-color: $reasonablyimportance;
        border-color: $reasonablyimportance;
      }
    }

    &.--greatimportance {
      .p-checkbox-box {
        background-color: $greatimportance;
        border-color: $greatimportance;
      }
    }

    &.--extremlyhighimportance {
      .p-checkbox-box {
        background-color: $extremlyhighimportance;
        border-color: $extremlyhighimportance;
      }
    }

    &.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: $secondaryActive  !important;
    }
  }
}
</style>
