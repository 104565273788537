<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
// import PCalendar from 'primevue/calendar';
import PCalendar from '../../../core/ui/calendar/CalendarCustom';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';
import PButton from 'primevue/button';
import PProgressSpinner from 'primevue/progressspinner';
import StakeholderDetail from '@/modules/stakeholderdetail/StakeholderDetail';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useProjectsStore } from '@/store/modules/projects';
import { useMediaStore } from '@/store/modules/media';
import { useNotesStore } from '@/store/modules/notes';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Variables
const { project } = storeToRefs(useProjectsStore());
const { fetchSingleProject } = useProjectsStore();
const { loadingStakeholder, loadingStakeholderContacts, loadingIssueStakeholders, stakeholder } = storeToRefs(useStakeholdersStore());
const { fetchStakeholder } = useStakeholdersStore();
const { fetchMediaByStakeholder } = useMediaStore();
const { loadingAllMediaStakeholder } = storeToRefs(useMediaStore());
const { fetchNotesByStakeholder } = useNotesStore();
const { loadingAllNotesStakeholder } = storeToRefs(useNotesStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const stakeholderEditButton = t('Stakeholder.Edit');
const calendarValue = ref('');
const showCalendarIcon = true;
const route = useRoute();
const projectId = route.params.projectId;
const stakeholderId = route.params.stakeholderId;
const downloadOptions = ref([]);

// Functions
const goToEdit = () => {
  router.push({ path: '/projects/' + route.params.projectId + '/stakeholders/' + route.params.stakeholderId + '/edit' });
};

useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  loadingStakeholder.value = true;
  loadingStakeholderContacts.value = true;
  loadingIssueStakeholders.value = true;
  loadingAllMediaStakeholder.value = true;
  loadingAllNotesStakeholder.value = true;
  await fetchSingleProject(projectId);
  await fetchStakeholder(project.value, stakeholderId);
  await fetchMediaByStakeholder(projectId, stakeholderId);
  await fetchNotesByStakeholder(projectId, stakeholderId);

  downloadOptions.value = [
    {
      label: t('General.Frontend.DownloadPDF'),
      endpoint: 'stakeholders/' + stakeholderId + '/export?format=pdf',
      type: 'pdf',
      filename: project.name + '_stakeholder.pdf'
    },
    {
      label: t('General.Frontend.DownloadWord'),
      endpoint: 'stakeholders/' + stakeholderId + '/export?format=doc',
      type: 'doc',
      filename: project.name + '__stakeholder.doc'
    },
    {
      label: t('General.Frontend.DownloadAnnaPDF'),
      endpoint: 'stakeholders/' + stakeholderId + '/exportanna?format=pdf',
      type: 'pdf',
      filename: project.name + '_stakeholder_anna.pdf'
    },
    {
      label: t('General.Frontend.DownloadAnnaWord'),
      endpoint: 'stakeholders/' + stakeholderId + '/exportanna?format=doc',
      type: 'doc',
      filename: project.name + '__stakeholder_anna.doc'
    }
  ];
});

const enabledDates = () => {
  const returnValue = [];
  const historicDates = stakeholder.value.historicDates;
  if (historicDates === null) {
    return [];
  }
  for (let i = 0; i < historicDates.length; i++) {
    const historicDate = historicDates[i].versionDate.split('-');
    returnValue.push(new Date(parseInt(historicDate[0], 10), parseInt(historicDate[1], 10) - 1, parseInt(historicDate[2], 10)));
  }
  returnValue.push(new Date());
  return returnValue;
};
const versionText = () => {
  if (useMoment(calendarValue.value).format('DD-MM-YYYY') === useMoment().format('DD-MM-YYYY')) {
    calendarValue.value = null;
  }
  return (calendarValue.value ? ' - ' + useMoment(calendarValue.value).format('DD-MM-YYYY') : '');
};
</script>

<template>
  <base-layout v-if="!loadingStakeholder && !loadingIssueStakeholders && !loadingAllMediaStakeholder && !loadingAllNotesStakeholder">
    <template v-slot:header>
      <view-header :title="(stakeholder.name ?? '') + versionText()">
        <template v-slot:actions>
          <p-calendar
            class="me-5"
            v-model="calendarValue" dateFormat="dd-mm-yy"
            :minDate="new Date(stakeholder.addDate)"
            :maxDate="new Date()"
            :enabledDates="enabledDates()"
            :show-icon="showCalendarIcon"
            :showButtonBar="true"
            icon="pi pi-calendar">
          </p-calendar>
          <ui-download-buttons-dropdown
            class="me-5"
            :project-name="project.name"
            :downloadOptions="downloadOptions">
          </ui-download-buttons-dropdown>
          <p-button
            v-if="IsActionAllowedInRole('stakeholder.edit', currentUser, route.params.projectId)"
            class="project-stakeholder-view__edit-btn"
            :label="stakeholderEditButton"
            @click="goToEdit()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <stakeholder-detail :selectedDate="calendarValue ? useMoment(calendarValue).format('YYYY-MM-DD') : null"></stakeholder-detail>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-stakeholder-view__spinner position-absolute"
    v-if="loadingStakeholder && loadingIssueStakeholders && loadingAllMediaStakeholder && loadingAllNotesStakeholder">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-stakeholder-view {
  &__edit-btn {
    min-width: calc-rem(171);
    white-space: nowrap;
  }

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
