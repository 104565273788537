<script setup>
import { ref, watch } from 'vue';

// Component imports
import PCheckbox from 'primevue/checkbox';

// Define props
const props = defineProps({
  checkboxList: Object,
  refresh: Boolean
});

// Define emits
const emit = defineEmits(['emitCheckboxList', 'emitDirty']);

// Functions
const emitCheckboxList = () => {
  emit('emitCheckboxList', checkboxes.value);
  emit('emitDirty');
};

// Variables
const checkboxes = ref([]);
const initialValues = props.checkboxList.checkboxes.filter(c => c.checked === true).map(c => c.title);
if (initialValues && initialValues.length) {
  checkboxes.value = initialValues;
}

// Watchers
// When user hits 'Save'-button, the model needs to be refreshed so a new project can be made.
watch(() => props.refresh, (val) => {
  if (val === true) {
    checkboxes.value = [];
  }
});
</script>

<template>
  <div class="ui-checkbox-list">
    <h4
      class="ui-checkbox-list__title mt-0 mb-3"
      v-if="props.checkboxList.title">
      {{ $t(props.checkboxList.title) }}
    </h4>
    <TransitionGroup
      name="fade"
      tag="ul">
      <li
        class="d-flex align-items-center"
        v-for="item in checkboxList.checkboxes"
        :key="item.id">
        <p-checkbox
          :value="item.title"
          :id="item.id"
          :name="item.id"
          v-model="checkboxes"
          @change="emitCheckboxList()">
        </p-checkbox>
        <label
          class="ui-checkbox-list__label d-inline-block"
          :for="item.id">
          {{ $t(item.title) }}
        </label>
      </li>
    </TransitionGroup>
  </div>
</template>

<style lang="scss">
.ui-checkbox-list {
  &__title {
    font-size: calc-rem(14);
    font-weight: 600;
    line-height: calc-rem(16);
    color: $textColor;
  }

  &__label {
    margin-left: calc-rem(7);
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    cursor: pointer;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: calc-rem(15);
      }
    }
  }
}
</style>
