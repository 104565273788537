<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia/dist/pinia.esm-browser';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import router from '@/router';
import { useI18n } from 'vue-i18n';
// Components imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import StakeholderEdit from '@/modules/stakeholderedit/StakeholderEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = t('Stakeholder.Edit');
const { editStakeholder, loadingEditStakeholder } = storeToRefs(useStakeholdersStore());
const { updateStakeholder, fetchEditStakeholder, deleteStakeholder, updateStakeholderGeoJson } = useStakeholdersStore();
const { isDirty } = storeToRefs(useGlobalStore());
const { project } = storeToRefs(useProjectsStore());
const { fetchSingleProjectStakeholders, fetchSingleProject, fetchProjects } = useProjectsStore();
// const store = useIssuesStore();
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const showNameError = ref(false);
const showTelephoneError = ref(false);
const route = useRoute();
const projectId = route.params.projectId;
const stakeholderId = route.params.stakeholderId;
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  deleteButton: t('General.Delete'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
    // {
    //   label: t('General.Delete'),
    //   command: () => {
    //     confirmDeleteStakeholder();
    //   }
    // }
  ]
};

const cancel = () => {
  editStakeholder.value = {};
  router.push({ path: '/projects/' + projectId + '/stakeholders/' + stakeholderId });
};

const save = async () => {
  if (editStakeholder.value.name === undefined || editStakeholder.value.name === '') {
    showNameError.value = true;
    console.log('error: no name');
  } else if (editStakeholder.value.telephone && editStakeholder.value.telephone.length > 0 && !validateTelephone(editStakeholder.value.telephone)) {
    showTelephoneError.value = true;
  } else {
    showNameError.value = false;
    showTelephoneError.value = false;
    await saveStakeholder(false);
  }
};

const saveNew = async () => {
  if (editStakeholder.value.name === undefined || editStakeholder.value.name === '') {
    showNameError.value = true;
    console.log('error: no name');
  } else if (editStakeholder.value.telephone.length > 0 && !validateTelephone(editStakeholder.value.telephone)) {
    showTelephoneError.value = true;
  } else {
    showNameError.value = false;
    showTelephoneError.value = false;
    await saveStakeholder(true);
  }
};

const saveStakeholder = async (newStakeholder) => {
  loadingEditStakeholder.value = true;
  if (newStakeholder) {
    console.log('save new stakeholder');
  } else {
    await updateStakeholder(projectId, stakeholderId, editStakeholder.value);
    await updateStakeholderGeoJson(projectId, stakeholderId, editStakeholder.value.gis);
    await fetchSingleProjectStakeholders(projectId);
    isDirtyPage.value = false;
  }

  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.issueToAdd = {};
  // });

  if (newStakeholder) {
    await router.push({ path: '/projects/' + projectId + '/stakeholders/create' });
  } else {
    await router.push({ path: '/projects/' + projectId + '/stakeholders/' + stakeholderId });
  }
};

const validateTelephone = (value) => {
  if (value.length > 25) {
    return false;
  }
  if (value.length === 0) {
    return true;
  }
  const validationOutcome = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/i.test(value);
  return validationOutcome;
};

const confirmDeleteStakeholder = () => {
  confirm.require({
    message: t('Stakeholder.Delete'),
    header: t('General.Confirm'),
    acceptLabel: t('General.Yes'),
    rejectLabel: t('General.No'),
    accept: async () => {
      loadingEditStakeholder.value = true;
      await deleteStakeholder(projectId, stakeholderId);
      loadingEditStakeholder.value = true;
      await fetchSingleProjectStakeholders(projectId);
      await fetchProjects();
      await router.push({ path: '/projects/' + projectId + '/stakeholders/' });
    },
    reject: () => {
      console.log('reject');
    }
  });
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  await fetchEditStakeholder(project.value, stakeholderId);
  isDirtyPage.value = false;
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        loadingEditStakeholder.value = true;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    loadingEditStakeholder.value = true;
    next();
  }
});
</script>

<template>
  <base-layout class="project-issues-view">
    <template v-slot:header>
      <view-header
        :title="viewTitle">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <stakeholder-edit
        v-if="!loadingEditStakeholder"
        :showNameError="showNameError"
        :showTelephoneError="showTelephoneError"
        :save-button="buttons.saveButton"
        :cancel-button="buttons.cancelButton"
        :delete-button="buttons.deleteButton"
        :buttons-dropdown-items="buttons.buttonsDropdownItems"
        @emit-save="save()"
        @emit-cancel="cancel()"
        @emit-delete="confirmDeleteStakeholder()">
      </stakeholder-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-stakeholder-edit-view__spinner position-absolute"
    v-if="loadingEditStakeholder">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-stakeholder-edit-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
