<script setup>
// import { ref } from 'vue';

// Component import
import PButton from 'primevue/button';

// Define props
const props = defineProps({
  rightButton: Boolean,
  leftButton: Boolean
});

// Define emits
const emit = defineEmits([
  'submitRight',
  'submitLeft'
]);

// Variables
const rightButton = 'Naar rechts';
const leftButton = 'Naar links';
const submitRight = () => {
  emit('submitRight');
};
const submitLeft = () => {
  emit('submitLeft');
};

// Functions

</script>

<template>
  <div class="ui-scroll-buttons d-inline-block">
    <div class="d-flex">
      <p-button
        class="ui-scroll-buttons__button"
        :label="leftButton"
        icon="pi pi-arrow-left"
        iconPos="center"
        @click="submitLeft()"
        :disabled="props.leftButton">
      </p-button>
      <p-button
        class="ui-scroll-buttons__button"
        :label="rightButton"
        icon="pi pi-arrow-right"
        iconPos="center"
        @click="submitRight()"
        :disabled="props.rightButton">
      </p-button>
    </div>

  </div>
</template>

<style lang="scss">
.ui-scroll-buttons {
  box-shadow: 0 0 20px rgba(0,0,0,.15);

  &__button {
    padding-right: calc-rem(13);
    padding-left: calc-rem(13);
    width: calc-rem(40);
    height: calc-rem(40);
    background-color: $white;
    border-color: $white;
    color: $textColor;

    &:hover {
      background-color: $secondaryHover !important;
      border-color: $secondaryHover !important;
      color: $textColor !important;
    }

    &:nth-child(1) {
      border-radius: $borderRadius 0 0 $borderRadius;
    }

    &:nth-child(2) {
      border-radius: 0 $borderRadius $borderRadius 0;
    }

    &:disabled {
      cursor: not-allowed;

      .pi {
        opacity: .5;
      }
    }
  }

  .p-button-label {
    @extend %sr-only;
  }
}
</style>
