<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';
import PDropdown from 'primevue/dropdown';
import PProgressSpinner from 'primevue/progressspinner';
import NotesOverview from '@/modules/notesoverview/NotesOverview';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';
import { useProjectsStore } from '@/store/modules/projects';
import { useNotesStore } from '@/store/modules/notes';

// Lifecycle hook imports
import router from '@/router';

// Variables
const { loadingProjectStakeholders, projectStakeholders } = storeToRefs(useProjectsStore());
const { fetchSingleProjectStakeholders } = useProjectsStore();
const { loadingAllNotes, configNotesOverview } = storeToRefs(useNotesStore());
const { fetchNotes } = useNotesStore();
const route = useRoute();
const projectId = route.params.projectId;
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());

// Functions
onMounted(async () => {
  loadingAllNotes.value = true;
  await fetchSingleProjectStakeholders(projectId);
  await fetchNotes(projectId);
  loadingAllNotes.value = false;
});

useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const goToCreateNew = () => {
  router.push({ path: '/projects/' + projectId + '/notes/create' });
};

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const selectedStakeholder = ref({ name: t('ProjectNote.Filter.SelectStakeholder'), id: null });
const allStakeholders = () => {
  const allStakeholders = [];
  allStakeholders.push({ name: t('ProjectNote.Filter.SelectStakeholder'), id: null });
  projectStakeholders.value.forEach(element => {
    allStakeholders.push({ name: element.name, id: element.id });
  });
  return allStakeholders;
};

const filteredNotes = () => {
  const filteredNotes = Object.create(configNotesOverview.value);
  filteredNotes.notes = configNotesOverview.value.notes.slice();
  if (selectedStakeholder.value.id === null) {
    return filteredNotes;
  }
  const preFilteredNotes = filteredNotes.notes.slice();
  filteredNotes.notes = [];
  for (let i = 0; i < preFilteredNotes.length; i++) {
    const shFound = preFilteredNotes[i].stakeholders.find((x) => { return x.id === selectedStakeholder.value.id; });
    if (shFound !== undefined) {
      filteredNotes.notes.push(preFilteredNotes[i]);
    }
  }
  return filteredNotes;
};
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header :title="$t('General.ProjectNotes')">
        <template v-slot:actions>
          <p-dropdown
            v-if="!loadingProjectStakeholders"
            class="project-concern-matrix-view__dropdown me-2"
            :option-label="'name'"
            :options="allStakeholders()"
            v-model="selectedStakeholder"
            scrollHeight="400px">
          </p-dropdown>
          <p-button
            v-if="IsActionAllowedInRole('notes.edit', currentUser, route.params.projectId)"
            :label="$t('ProjectNote.Frontend.Add')"
            icon="pi pi-plus"
            iconPos="left"
            @click="goToCreateNew()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <!-- pre>
        {{filteredNotes().notes[1]}}
      </pre -->
      <p-progress-spinner
        class="project-notes-view__spinner position-absolute"
        v-if="loadingAllNotes">
      </p-progress-spinner>
      <notes-overview v-if="!loadingAllNotes" :loading-all-notes="loadingAllNotes" :config-notes-overview="filteredNotes()"></notes-overview>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-notes-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
