<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PEditor from 'primevue/editor';
import POverlayPanel from 'primevue/overlaypanel';
import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
// import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useIssuesStore } from '@/store/modules/issues';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholders: Object,
  issues: Object,
  projectImportanceMatrix: Object
});

// Define emits

// Variables
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { project } = storeToRefs(useProjectsStore());
const {
  updateStakeholderIssueImportanceFromMatrix,
  updateStakeholderIssueImportanceOpinionFromMatrix,
  addStakeholderIssueImportance,
  removeStakeholderIssue
} = useStakeholderIssueStore();

const {
  updateStakeholderStatusFromMatrix,
  updateStakeholderEmotionFromMatrix,
  updateStakeholderUrgencyFromMatrix
} = useStakeholdersStore();

const {
  updateIssueStatusFromMatrix
} = useIssuesStore();

const {
  updateSingleProjectStakeholders,
  updateSingleProjectIssues
} = useProjectsStore();

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

// const opColor = ref();
const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
const onFocusOverlayPanelInput = () => {
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};

const Cache = (fn) => {
  const cache = {};
  return (...args) => {
    const key = JSON.stringify(args);
    if (!(key in cache)) {
      cache[key] = fn(...args);
    }
    return cache[key];
  };
};
const onFocusOverlayPanelInputEditor = (stakeholderId, issueId) => {
  const crossPointCache = Cache(crossPoint);
  const crossPointData = crossPointCache(stakeholderId, issueId);
  const opinion = crossPointData.opinion;
  const status = crossPointData.issueStakeholderImportanceNr;

  const element = document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId);

  if (element) {
    const classList = element.className.trim();
    const expectedClass = 'p-button p-component matrix__matrix-button p-0';
    if (classList === expectedClass) {
      if ((opinion === null || opinion === '') && status === null) {
        Cache(updateCrossPointImportance(0, stakeholderId, issueId));
      }
    }
  }
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
  Cache.clear('crossPoint');
};
const onBlurOverlayPanelInput = () => {
  focusOverlayPanelInput.value = false;
};
const hideOverlayPanelButtons = () => {
  showOverlayPanelButtons.value = false;
};

// const cancelSelectColor = () => {
//   opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const getImportance = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return { className: '--unclear', name: configMatrix.tooltip.unclear };
    case 4:
      return { className: '--positive', name: configMatrix.tooltip.positive };
    case 3:
      return { className: '--focus', name: configMatrix.tooltip.focus };
    case 2:
      return { className: '--warning', name: configMatrix.tooltip.warning };
    case 1:
      return { className: '--criticism', name: configMatrix.tooltip.criticism };
    default:
      return { className: null, name: null };
  }
};
const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
// const scrollRight = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value += 1;
//   scrollValue.value -= scrollDistance;

//   // Add class to 1st column and enable left button
//   if (countClicks.value === 1) {
//     disableLeftButton.value = false;
//     addShadowClass.value = true;
//   }
//   // Disable right button on max clicks
//   if (countClicks.value === maxClicks) {
//     disableRightButton.value = true;
//   }
// };
// const scrollLeft = () => {
//   const currentWindowWidth = scrollWindow.value.offsetWidth;
//   const currentMatrixWidth = matrix.value.offsetWidth;
//   const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
//   countClicks.value -= 1;
//   scrollValue.value += scrollDistance;

//   // Disable left button and remove class from 1st column
//   if (countClicks.value === 0) {
//     disableLeftButton.value = true;
//     addShadowClass.value = false;
//   }

//   // Enable right button
//   if (countClicks.value < maxClicks) {
//     disableRightButton.value = false;
//   }
// };
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Cancel')
  },
  tooltip: {
    reset: t('Status.NoStress'),
    criticism: t('Status.Kritiek'),
    warning: t('Status.Waarschuwing'),
    focus: t('Status.Aandacht'),
    positive: t('Status.Positief'),
    unclear: t('Status.Onduidelijk')
  }
};

const emotionOption = ref([
  {
    value: null,
    name: t('General.Unknown')
  },
  {
    value: 0,
    name: t('Emotion.Geen.emotie')
  },
  {
    value: 1,
    name: t('Emotion.Ontkenning')
  },
  {
    value: 2,
    name: t('Emotion.Woede')
  },
  {
    value: 3,
    name: t('Emotion.Onderhandelen')
  },
  {
    value: 4,
    name: t('Emotion.Depressie')
  },
  {
    value: 5,
    name: t('Emotion.Aanvaarding')
  },
  {
    value: 6,
    name: t('Emotion.Low')
  },
  {
    value: 7,
    name: t('Emotion.Average')
  },
  {
    value: 8,
    name: t('Emotion.High')
  }
]);

const urgencyOptions = ref([
  {
    value: null,
    name: t('General.Unknown')
  },
  {
    value: 1,
    name: t('Urgency.Zeer.urgent')
  },
  {
    value: 2,
    name: t('Urgency.Urgent')
  },
  {
    value: 3,
    name: t('Urgency.Redelijk.urgent')
  },
  {
    value: 4,
    name: t('Urgency.Beperkt.urgent')
  },
  {
    value: 5,
    name: t('Urgency.Niet.urgent')
  }
]);

const importanceClassName = (importanceNr) => {
  switch (importanceNr) {
    case 0:
    case 5:
      return '--unclear';
    case 4:
      return '--positive';
    case 3:
      return '--focus';
    case 2:
      return '--warning';
    case 1:
      return '--criticism';
  }
};

const importanceText = (importanceNr) => {
  switch (importanceNr) {
    case 0:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Positief');
    case 3:
      return t('Status.Aandacht');
    case 2:
      return t('Status.Waarschuwing');
    case 1:
      return t('Status.Kritiek');
  }
};

const crossPoint = Cache((stakeholderId, issueId) => {
  if (projectImportanceMatrix.value && projectImportanceMatrix.value.jsonModel) {
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderImportanceName = importanceText(crossPoint.issueStakeholderImportanceNr);
      crossPoint.importanceClassName = importanceClassName(crossPoint.issueStakeholderImportanceNr);
      if (!crossPoint.orgValue) {
        crossPoint.orgValue = crossPoint.opinion;
      }
      return crossPoint;
    }
  }
  return {
    issueStakeholderConcernNr: null,
    issueStakeholderConcernName: null,
    issueStakeholderImportanceNr: null,
    issueStakeholderImportanceName: null,
    opinion: null,
    importanceClassName: null
  };
});

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});
watch(() => props.projectImportanceMatrix, (first, second) => {
  // console.info('projectImportanceMatrix', first);
  projectImportanceMatrix.value = first;
});
watch(() => props.stakeholders, (first, second) => {
  // console.info('stakeholders', first);
  stakeholders.value = first;
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

// This collects dynamic refs for all crosspoints
const projectImportanceMatrix = ref(null);
const stakeholders = ref(null);
const importance = ref([]);
const crossPoints = ref([]);
const crosspointTextareas = ref([]);
const stakeholderEmotion = ref([]);
const stakeholderEmotionLabel = ref([]);
const stakeholderUrgency = ref([]);
const stakeholderUrgencyLabel = ref([]);
const toggleImportance = (event, importanceId) => {
  // console.info(importance, importanceId);
  // console.info('importanceId', crossPoints.value[importanceId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectimportancematrix.edit', currentUser.value, props.projectId)) {
    importance.value[importanceId].toggle(event);
  }
};
const toggleCrossPoint = (event, crossPointId) => {
  // console.info(crossPoints, crossPointId);
  // console.info('crossPointId', crossPoints.value[crossPointId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectimportancematrix.edit', currentUser.value, props.projectId)) {
    crossPoints.value[crossPointId].toggle(event);
  }
};
const updateStakeholderStatusValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.statusNr = value;
    document.querySelector('#shimportancemain-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderStatusFromMatrix(project.value, stakeholderId, value);
    updateSingleProjectStakeholders(stakeholderId, value);
    // }
  }
};
const updateIssueStatusValue = (value, categoryIndex, issueId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.statusNr = value;
    document.querySelector('#issue-importance-' + issueId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateIssueStatusFromMatrix(project.value, issueId, value);
    updateSingleProjectIssues(issueId, value);
    // }
  }
};
const updateCrossPointImportance = (value, stakeholderId, issueId) => {
  if (value === -1 || value === null) {
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (!crossPoint || crossPoint === undefined) {
      return;
    }
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    if (confirm(t('Product.ConcernMatrix.AreYouSure'))) {
      document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
      crossPoint.issueStakeholderImportanceNr = null;
      crossPoint.importanceClassName = null;
      removeStakeholderIssue(props.projectId, stakeholderId, issueId);
    }
  } else {
    document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + getImportance(value).className);
    var element = document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId);
    observeClassChange(element, stakeholderId, issueId, getImportance(value).className, forceAssignConcern);
    const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderImportanceNr = value;
      crossPoint.importanceClassName = value;
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderIssueImportanceFromMatrix(props.projectId, stakeholderId, issueId, value);
    } else {
      projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.push({
        stakeholderId: stakeholderId,
        issueId: issueId,
        issueStakeholderImportanceNr: value
      });
      // console.info('SOFT CREATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      addStakeholderIssueImportance(props.projectId, stakeholderId, issueId, value);
    }
  }
};

const observeClassChange = (element, stakeholderId, issueId, value, callback) => {
  const observer = new MutationObserver((mutations) => {
    if (mutations.some((mutation) => mutation.type === 'attributes' && mutation.attributeName === 'class')) {
      // class changed, call callback function
      observer.disconnect();
      // console.log('mutation of class');
      callback(stakeholderId, issueId, value);
    }
  });

  observer.observe(element, { attributes: true });

  setTimeout(() => {
    observer.disconnect();
  }, 10000); // stop observing after 10 seconds
};

const forceAssignConcern = (stakeholderId, issueId, value) => {
  // set attribute using forceAssignConcern
  document.querySelector('#crosspoint-' + stakeholderId + '-' + issueId).setAttribute('class', 'p-button p-component matrix__button p-0 ' + value);
};
const updateStakeholderIssueImportanceOpinion = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPoint = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  const textvalue = document.querySelector('.ql-editor p').innerHTML;
  if (crossPoint) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, textvalue);
    updateStakeholderIssueImportanceOpinionFromMatrix(props.projectId, stakeholderId, issueId, textvalue);

    const indexOfObject = projectImportanceMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectImportanceMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].orgValue = crosspointTextareas.value[crossPointId].value;
    }
  }
  showOverlayPanelButtons.value = false;
};
const toggleStakeholderEmotion = (event, stakeholderId) => {
  stakeholderEmotion.value[stakeholderId].toggle(event);
};
const updateStakeholderEmotion = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#emotionOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderEmotionFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderEmotion.value[stakeholderId].toggle(event);
};
const toggleStakeholderUrgency = (event, stakeholderId) => {
  stakeholderUrgency.value[stakeholderId].toggle(event);
};
const updateStakeholderUrgency = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#urgencyOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderUrgencyFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderUrgency.value[stakeholderId].toggle(event);
};

function resizeBefore () {
  const targetElementSelector =
  '#app > div > div.app__main.position-relative.w-100 > div:nth-child(2) > div:nth-child(2) > div > div > main > div > div > div > div > div > div > div:nth-child(1)';
  const extraSpacePerDiv = 48;
  const extraSpacePadding = 0;
  const extraSpacePoint = 38;
  const styleElement = document.createElement('style');
  document.head.appendChild(styleElement);

  const targetElement = document.querySelector(targetElementSelector);
  if (targetElement) {
    const divs = targetElement.querySelectorAll('div.matrix__matrix-th.pt-3.pb-3');
    const extraSpaceNeeded = (divs.length - extraSpacePoint) * extraSpacePerDiv + extraSpacePadding;
    styleElement.sheet.insertRule(`
        ${targetElementSelector}::before {
        width: calc(100% + ${extraSpaceNeeded}px);
        }
    `, styleElement.sheet.cssRules.length);
  } else {
    styleElement.sheet.insertRule(`
        ${targetElementSelector}::before {
        width: calc(100%);
        }
    `, styleElement.sheet.cssRules.length);
    console.log('Target element not found.');
  }
}
let scrolled = false;

function waitForMatrixWrap () {
  const matrixWrap = document.querySelector('.matrix__wrap.position-relative');

  if (matrixWrap) {
    const scrollHandler = function () {
      if (!scrolled) {
        scrolled = true;
        resizeBefore();
        matrixWrap.removeEventListener('scroll', scrollHandler);
      }
    };

    matrixWrap.addEventListener('scroll', scrollHandler);
  } else {
    setTimeout(waitForMatrixWrap, 100); // try again in 100ms
  }
}

waitForMatrixWrap();
</script>

<template>
  <main>
    <p-card v-if="stakeholders && props.issues && projectImportanceMatrix">
      <template #content>
        <div class="importance-matrix matrix overflow-hidden">
          <div class="matrix__wrap position-relative">
            <div
              class="matrix__matrix position-absolute w-100"
              role="table">
              <div
                class="matrix__matrix-row d-flex"
                role="row"
                aria-label="Belangenmatrix">
                <div
                  class="matrix__matrix-th"
                  role="cell">
                </div>
                <template
                  v-for="issueCategory in props.issues.panels"
                  :key="'issueCategory' + issueCategory.id">
                  <div
                    class="matrix__matrix-th pt-3 pb-3"
                    role="cell">
                    <span>
                      {{ issueCategory.panelTitle }}
                    </span>
                  </div>
                  <div
                    v-for="issue in issueCategory.panel"
                    :key="'issue' + issue.id"
                    class="matrix__matrix-th pt-3 pb-3"
                    role="cell">
                    <span>
                      <router-link
                        :to="issue.urlTo"
                        :title="issue.title">
                        {{ issue.title }}
                      </router-link>
                    </span>
                  </div>
                </template>
                <div
                  class="matrix__matrix-th pt-3 pb-3"
                  role="cell">
                  <span>
                    {{ $t('ProjectStakeholder.Status.Excel') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('ProjectStakeholder.Emotion.Excel') }}
                  </span>
                </div>
                <div
                  class="matrix__matrix-th pt-3 pb-3 --text-only"
                  role="cell">
                  <span>
                    {{ $t('Stakeholder.Urgency') }}
                  </span>
                </div>
              </div>

              <template
                v-for="stakeholderCategory in stakeholders.panels"
                :key="'stakeholderCategory' + stakeholderCategory.id">
                <div
                  class="matrix__matrix-row d-flex"
                  role="row">
                  <div
                    class="matrix__matrix-td --category"
                    role="cell">
                    <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                      <span>
                        {{ stakeholderCategory.panelTitle }}
                      </span>
                    </h3>
                  </div>
                  <template
                    v-for="issueCategory in props.issues.panels"
                    :key="'issueCategory' + issueCategory.id">
                    <div
                      class="matrix__matrix-td --category"
                      role="cell">
                    </div>
                    <div
                      v-for="issue in issueCategory.panel"
                      :key="'issue' + issue.id"
                      class="matrix__matrix-td --category"
                      role="cell">
                    </div>
                  </template>
                  <div
                    class="matrix__matrix-td --category"
                    role="cell">
                  </div>
                  <div
                    class="matrix__matrix-td --category --text-only"
                    role="cell">
                  </div>
                  <div
                    class="matrix__matrix-td --category --text-only"
                    role="cell">
                  </div>
                </div>
                <template
                  v-for="stakeholder in stakeholderCategory.panel"
                  :key="'stakeholder' + stakeholder.id">
                  <div
                    class="matrix__matrix-row d-flex"
                    role="row">
                    <div
                      class="matrix__matrix-td d-flex align-items-center"
                      role="cell">
                      <router-link
                        :to="stakeholder.urlTo"
                        class="matrix__table-stakeholders-item pe-3 ps-3 d-flex align-items-center"
                        :title="stakeholder.title">
                        {{ stakeholder.title }}
                      </router-link>
                    </div>

                    <template
                      v-for="issueCategory in props.issues.panels"
                      :key="'issueCategory' + issueCategory.id">
                      <div class="matrix__matrix-td"></div>
                      <div
                        v-for="issue in issueCategory.panel"
                        :key="'crosspoint' + stakeholder.id + ' - ' + issue.id"
                        class="matrix__matrix-td d-flex justify-content-center align-items-center"
                        role="cell"
                        :debugme-title="'stakeholder: ' + stakeholder.title + ' - issue: ' + issue.title + ' - value: ' + crossPoint(stakeholder.id, issue.id).issueStakeholderImportanceNr + ' - ' + crossPoint(stakeholder.id, issue.id).issueStakeholderImportanceName + ' - ' + crossPoint(stakeholder.id, issue.id).opinion">
                        <p-button
                          class="matrix__matrix-button p-0"
                          :class="crossPoint(stakeholder.id, issue.id).importanceClassName"
                          :label="crossPoint(stakeholder.id, issue.id).issueStakeholderImportanceName"
                          aria-haspopup="true"
                          :aria-controls="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          v-tooltip.top="crossPoint(stakeholder.id, issue.id).issueStakeholderImportanceName"
                          :id="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          @click="toggleCrossPoint($event, 'crosspoint-' + stakeholder.id + '-' + issue.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__matrix-overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }" :ref="(el) => { crossPoints['crosspoint-' + stakeholder.id + '-' + issue.id] = el; }"
                          :id="'crosspoint-' + stakeholder.id + '-' + issue.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__matrix-overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --reset"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == null, }"
                              :label="configMatrix.tooltip.reset"
                              icon="pi pi-times"
                              v-tooltip.top="configMatrix.tooltip.reset"
                              @click="updateCrossPointImportance(null, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateCrossPointImportance(5, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == '--positive', }"
                              :label="configMatrix.tooltip.positive"
                              v-tooltip.top="configMatrix.tooltip.positive"
                              @click="updateCrossPointImportance(4, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == '--focus', }"
                              :label="configMatrix.tooltip.focus"
                              v-tooltip.top="configMatrix.tooltip.focus"
                              @click="updateCrossPointImportance(3, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == '--warning', }"
                              :label="configMatrix.tooltip.warning"
                              v-tooltip.top="configMatrix.tooltip.warning"
                              @click="updateCrossPointImportance(2, stakeholder.id, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{ '--active': crossPoint(stakeholder.id, issue.id).importanceClassName == '--criticism', }"
                              :label="configMatrix.tooltip.criticism"
                              v-tooltip.top="configMatrix.tooltip.criticism"
                              @click="updateCrossPointImportance(1, stakeholder.id, issue.id)">
                            </p-button>
                          </div>

                          <p-editor
                            class="mt-2"
                            :ref="(el) => { crosspointTextareas['crosspoint-' + stakeholder.id + '-' + issue.id] = el; }"
                            @click="onFocusOverlayPanelInputEditor(stakeholder.id, issue.id)"
                            v-model="crossPoint(stakeholder.id,issue.id).opinion">
                            <template v-slot:toolbar>
                              <span class="ql-formats">
                                <button
                                  class="ql-bold"
                                  v-tooltip.bottom="'Bold'">
                                </button>
                                <button
                                  class="ql-italic"
                                  v-tooltip.bottom="'Italic'">
                                </button>
                                <button
                                  class="ql-underline"
                                  v-tooltip.bottom="'Underline'">
                                </button>
                              </span>
                            </template>
                          </p-editor>
                          <textarea
                            class="p-hidden"
                            :placeholder="$t('General.Explanation')"
                            :ref="(el) => { crosspointTextareas[ 'crosspoint-' + stakeholder.id + '-' + issue.id ] = el; }"
                            @focus="onFocusOverlayPanelInput()"
                            @blur="onBlurOverlayPanelInput()"
                            v-model="crossPoint(stakeholder.id, issue.id).opinion">
                          </textarea>
                          <Transition name="fade">
                            <div
                              class="d-flex justify-content-end"
                              v-if="showOverlayPanelButtons">
                              <p-button
                                class="matrix__overlay-panel-button p-button-text me-1"
                                :label="configMatrix.overlayPanel.cancelButton"
                                @mousedown="cancelStakeholderIssueImportanceOpinion(null, 'crosspoint-' + stakeholder.id + '-' + issue.id, stakeholder.id, issue.id)">
                              </p-button>
                              <p-button
                                class="matrix__overlay-panel-button"
                                :label="configMatrix.overlayPanel.saveButton"
                                @mousedown="updateStakeholderIssueImportanceOpinion(null, 'crosspoint-' + stakeholder.id + '-' + issue.id, stakeholder.id, issue.id)">
                              </p-button>
                            </div>
                          </Transition>
                        </p-overlay-panel>
                      </div>
                    </template>

                    <div
                      class="matrix__matrix-td d-flex justify-content-center align-items-center"
                      role="cell">
                      <div class="d-flex justify-content-center">
                        <p-button
                          class="matrix__matrix-button p-0"
                          :class="importanceClassName(stakeholder.statusNr)"
                          :label="importanceText(stakeholder.statusNr)"
                          aria-haspopup="true"
                          :aria-controls="'shimportance-' + stakeholder.id"
                          :id="'shimportancemain-' + stakeholder.id"
                          v-tooltip.top="importanceText(stakeholder.statusNr)"
                          @click="toggleImportance($event, 'shimportance-' + stakeholder.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { importance['shimportance-' + stakeholder.id] = el; }"
                          :id="'shimportance-' + stakeholder.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getImportance(stakeholder.statusNr).className == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateStakeholderStatusValue(5, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{'--active': getImportance(stakeholder.statusNr).className == '--positive',}"
                              :label="configMatrix.tooltip.positive"
                              v-tooltip.top="configMatrix.tooltip.positive"
                              @click="updateStakeholderStatusValue(4, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{'--active': getImportance(stakeholder.statusNr).className == '--focus',}"
                              :label="configMatrix.tooltip.focus"
                              v-tooltip.top="configMatrix.tooltip.focus"
                              @click="updateStakeholderStatusValue(3, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{'--active': getImportance(stakeholder.statusNr).className == '--warning', }"
                              :label="configMatrix.tooltip.warning"
                              v-tooltip.top="configMatrix.tooltip.warning"
                              @click="updateStakeholderStatusValue(2, null, stakeholder.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{'--active': getImportance(stakeholder.statusNr).className == '--criticism',}"
                              :label="configMatrix.tooltip.criticism"
                              v-tooltip.top="configMatrix.tooltip.criticism"
                              @click="updateStakeholderStatusValue(1, null, stakeholder.id)">
                            </p-button>
                          </div>
                        </p-overlay-panel>
                      </div>
                    </div>
                    <!-- emotionOptions -->
                    <div
                      class="matrix__matrix-td d-flex justify-content-center align-items-center --text-only"
                      role="cell">
                      <div class="d-flex justify-content-center">
                        <p-button
                          class="matrix__matrix-strategy-button p-button-text"
                          :label="stakeholder.emotion ? emotionOption[stakeholder.emotion.nr].name : $t('General.Unknown')"
                          :ref="(el) => { stakeholderEmotionLabel[stakeholder.id] = el; }"
                          :id="'emotionOptionLabel' + stakeholder.id"
                          @click="toggleStakeholderEmotion($event, stakeholder.id)">
                        </p-button>
                        <p-overlay-panel
                            class="p-3"
                            :ref="(el) => { stakeholderEmotion[stakeholder.id] = el; }">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li v-for="option in emotionOption"
                                :key="'emotionOption' + stakeholder.id + option.value"
                                class="d-flex align-items-center">
                                <p-radio-button
                                  :name="'emotionOption' + stakeholder.id"
                                  :id="'emotionOption' + stakeholder.id + option.value"
                                  :value="option.value"
                                  @click="
                                    stakeholder.emotion = {
                                        nr: option.value,
                                        name: option.name,
                                    };
                                    updateStakeholderEmotion(
                                      option.value,
                                      option.name,
                                      stakeholder.id,
                                      stakeholder.emotion
                                    );">
                                </p-radio-button>
                                <label
                                  :for="'emotionOption' + stakeholder.id + option.value"
                                  class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                      </div>
                    </div>
                    <!-- urgencyOptions -->
                    <div
                      class="matrix__matrix-td d-flex justify-content-center align-items-center --text-only"
                      role="cell">
                      <div class="d-flex justify-content-center">
                        <p-button
                          class="matrix__matrix-strategy-button p-button-text"
                          :label="stakeholder.urgency ? urgencyOptions[stakeholder.urgency.nr].name : $t('General.Unknown')"
                          :ref="(el) => { stakeholderUrgencyLabel[stakeholder.id] = el; }"
                          :id="'urgencyOptionLabel' + stakeholder.id"
                          @click="toggleStakeholderUrgency($event, stakeholder.id)">
                        </p-button>
                        <p-overlay-panel
                            class="p-3"
                            :ref="(el) => { stakeholderUrgency[stakeholder.id] = el; }">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li v-for="option in urgencyOptions"
                                :key="'urgencyOptions' + stakeholder.id + option.value"
                                class="d-flex align-items-center">
                                <p-radio-button
                                  :name="'urgencyOptions' + stakeholder.id"
                                  :id="'urgencyOptions' + stakeholder.id + option.value"
                                  :value="option.value"
                                  @click="
                                    stakeholder.urgency = {
                                        nr: option.value,
                                        name: option.name,
                                    };
                                    updateStakeholderUrgency(
                                      option.value,
                                      option.name,
                                      stakeholder.id,
                                      stakeholder.emotion
                                    );">
                                </p-radio-button>
                                <label
                                  :for="'urgencyOptions' + stakeholder.id + option.value"
                                  class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <!-- RENDER BEOORDELING ISSUE ROW -->
              <div
                class="matrix__matrix-row d-flex"
                role="row">
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                  <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                    <span>
                      {{ $t('Issue.Frontend.Status') }}
                    </span>
                  </h3>
                </div>
                <template
                  v-for="issueCategory in props.issues.panels"
                  :key="'issueImportanceCategory' + issueCategory.id">
                  <div
                      class="matrix__matrix-td --category"
                      role="cell">
                    </div>
                  <div
                    v-for="issue in issueCategory.panel"
                    :key="'issue' + issue.id"
                    class="matrix__matrix-td d-flex justify-content-center align-items-center"
                    role="cell">
                    <p-button
                        class="matrix__matrix-button p-0"
                        :class="importanceClassName(issue.statusNr)"
                        :label="importanceText(issue.statusNr)"
                        :id="'issue-importance-' + issue.id"
                        aria-haspopup="true"
                        :aria-controls="'issue-importance-' + issue.id"
                        v-tooltip.top="importanceText(issue.statusNr)"
                        @click="toggleCrossPoint($event, 'issue-importance-' + issue.id)">
                      </p-button>
                      <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { crossPoints['issue-importance-' + issue.id] = el; }"
                          :id="'issue-importance-' + issue.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateIssueStatusValue(0, null, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --positive"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--positive', }"
                              :label="configMatrix.tooltip.positive"
                              v-tooltip.top="configMatrix.tooltip.positive"
                              @click="updateIssueStatusValue(4, null, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --focus"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--focus', }"
                              :label="configMatrix.tooltip.focus"
                              v-tooltip.top="configMatrix.tooltip.focus"
                              @click="updateIssueStatusValue(3, null, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --warning"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--warning', }"
                              :label="configMatrix.tooltip.warning"
                              v-tooltip.top="configMatrix.tooltip.warning"
                              @click="updateIssueStatusValue(2, null, issue.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --criticism"
                              :class="{ '--active': importanceClassName(issue.statusNr) == '--criticism', }"
                              :label="configMatrix.tooltip.criticism"
                              v-tooltip.top="configMatrix.tooltip.criticism"
                              @click="updateIssueStatusValue(1, null, issue.id)">
                            </p-button>
                          </div>
                      </p-overlay-panel>
                  </div>
                </template>
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                </div>
                <div
                  class="matrix__matrix-td --category --text-only"
                  role="cell">
                </div>
                <div
                  class="matrix__matrix-td --category --text-only"
                  role="cell">
                </div>
              </div>

              <div
                class="matrix__matrix-row d-flex"
                role="row">
                <div
                  class="matrix__matrix-td --category"
                  role="cell">
                  <h3 class="matrix__matrix-category matrix__matrix-stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                    <span>
                      {{ $t('General.LegendTension') }}
                    </span>
                  </h3>
                </div>
                <div class="matrix__matrix-td d-flex align-items-center --category --legend">
                  <table class="matrix__matrix-legend">
                    <tr>
                      <template
                        v-for="item in [1, 2, 3, 4, 0]"
                        :key="'legend-concern-' + item">
                        <td class="pe-3 ps-3">
                          <span class="d-flex align-items-center">
                            <span
                              class="matrix__matrix-legend-badge me-2 d-inline-block"
                              :class="importanceClassName(item)"
                              :title="$t(importanceText(item))">
                            </span>
                            <span class="matrix__matrix-legend-text">
                              {{ $t(importanceText(item)) }}
                            </span>
                          </span>
                        </td>
                      </template>
                  </tr>
                  </table>
                </div>
              </div>

            </div><!-- End table -->
          </div><!-- End wrap -->
        </div><!-- End matrix -->
      </template>
    </p-card>
    <p-progress-bar
      class="importance-matrix__progressbar"
      :value="50"
      v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.importance-matrix {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;
  }

  .matrix__th:not(:nth-last-child(2)) {
    position: relative;
    bottom: -4px;
    left: 60px;
    transform: rotate(30deg);
  }
  .matrix__th.--strategy > span {
    transform: rotate(-30deg);
  }
  .matrix__td.--category.--last-in-cat,
  .matrix__stakeholder-cat.--last-in-cat {
    border-bottom: 0.0625rem solid #E2E5E9;
  }
}
</style>
