<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

// Component imports
import PDropdown from 'primevue/dropdown';
import PEditor from 'primevue/editor';
import UiFormLabel from './UiFormLabel';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  labelDescription: String,
  dropdownLabel: String,
  dropdownPlaceholder: String,
  dropdownOptions: Array,
  modelValue: Object,
  valueEditor: String,
  valueCombobox: Object
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables
const inputValue = ref(props.valueEditor);
// let modifiedInputValue = ref('');
const selectedOption = ref(props.valueCombobox);
const editor = ref(false);
const isFocused = ref(false);
// const placeholder = 'Toelichting';
const onFocus = (value) => {
  isFocused.value = value;
};
// const showEditor = () => {
//   editor.value = true;
// };
// const showPlaceholder = () => {
//   if (inputValue.value.length) {
//     return true;
//   } else {
//     return false;
//   }
// };
// const removeHTML = () => {
//   modifiedInputValue = inputValue.value.replace(/<\/?[^>]+(>|$)/g, '');
// };
const target = ref(null);
const value = {
  nr: props.valueCombobox !== null ? props.valueCombobox : { nr: null, text: null },
  text: props.valueEditor
};

// Functions
onClickOutside(target, () => {
  editor.value = false;
});
const onChange = (event) => {
  value.nr = event.value;
  emit('update:modelValue', value);
  emit('emitDirty');
};
const onChangeText = (event) => {
  value.text = event.htmlValue;
  inputValue.value = value.text;
  emit('update:modelValue', value);
  emit('emitDirty');
};
// const cropText = (inputValue) => {
//   const returnValue = inputValue.replace(/<\/?[^>]+>/gi, '');
//   return returnValue.slice(0, 135) + (inputValue.length > 135 ? '...' : '');
// };
</script>

<template>
  <!--  <pre>-->
  <!--    {{ props.inputText }}-->
  <!--    {{ props.inputNr }}-->
  <!--  </pre>-->
  <div class="ui-label-dropdown-editor">
    <ui-form-label class="mb-2 w-100" :label-title="props.labelTitle" :label-for="props.LabelFor">
    </ui-form-label>
    <small class="mt-2 mb-3 d-block" v-if="props.labelDescription">
      <em>{{ props.labelDescription }}</em>
    </small>
    <div class="ui-label-dropdown-editor">
      <div class="mb-2">
        <p-dropdown class="w-100 ui-label-dropdown-editor__dropdown" :id="props.LabelFor"
          scrollHeight="400px"
          :options="props.dropdownOptions" :option-label="props.dropdownLabel" :placeholder="props.dropdownPlaceholder"
          v-model="selectedOption" @change="onChange($event)">
        </p-dropdown>
        <small class="mt-2 mb-3 ms-1 d-block" v-if="selectedOption">
          <em>{{ selectedOption.description }}</em>
        </small>
      </div>
      <div class="mb-2">
        <p-editor class="ui-label-dropdown-editor__editor" :class="{ 'is-focused': isFocused }" ref="target"
          v-show="(selectedOption || inputValue)" v-model="inputValue" aria-labelledby="ui-label-dropdown-editor__button" @click="onFocus(true)"
          @text-change="onChangeText($event)">
          <template #toolbar>
            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
            <button class="ql-strike" v-tooltip.bottom="'Strike'"></button>
            <button class="ql-link" v-tooltip.bottom="'Link'"></button>
            <button class="ql-list" value="ordered" v-tooltip.bottom="'List ordered'"></button>
            <button class="ql-list" value="bullet" v-tooltip.bottom="'List bullet'"></button>
            <button class="ql-indent" value="-1" v-tooltip.bottom="'Indent -1'"></button>
            <button class="ql-indent" value="+1" v-tooltip.bottom="'Indent +1'"></button>
            <select class="ql-color" v-tooltip.bottom="'Color'"></select>
            <select class="ql-background" v-tooltip.bottom="'Background'"></select>
            <button class="ql-clean" v-tooltip.bottom="'Clean'"></button>
          </template>
        </p-editor>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-label-dropdown-editor {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: calc-rem(22);
    align-items: start;
  }

  &__button,
  &__dropdown {
    height: calc-rem(40);
  }

  &__button {
    padding-top: calc-rem(12);
    padding-bottom: calc-rem(12);
    border-radius: $borderRadius;
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    border: calc-rem(1) solid $borderColor;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      border-color: $primary;
    }

    &:focus {
      @extend %button-focus;
    }
  }

  &__placeholder {
    opacity: 0.5;
  }

  &__crop {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__editor {
    &.is-focused {
      box-shadow: 0 0 0 2px rgba(0, 95, 227, 0.4);
    }
  }

  .p {
    &-editor {
      &-container {
        border-radius: $borderRadius;
        border: calc-rem(1) solid $secondaryActive;

        &:hover {
          border-color: $primary;
        }

        .ql-tooltip.ql-editing a.ql-action::after {
          content: 'Opslaan';
        }
        .ql-tooltip[data-mode=link]::before {
          content: 'Link:';
        }
      }

      &-toolbar {
        padding: $editorToolbarPadding;
        border-bottom: calc-rem(1) solid $secondaryActive  !important;
      }
    }
  }
}
</style>
