<script setup>
import { ref } from 'vue';
import apiTestUrlsJson from '@/config/apiUrls.json';
import apiProductionUrlsJson from '@/config/apiUrls.production.json';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiFieldset from '@/core/ui/UiFieldset';
import UiLabelInfoInput from '@/core/ui/UiLabelInfoInput';
import UiFileUpload from '@/core/ui/UiFileUpload';
import UiFormLabel from '@/core/ui/UiFormLabel';
import UiLabelDropdown from '@/core/ui/UiLabelDropdown';
import UiLabelEditor from '@/core/ui/UiLabelEditor';

// import UiLabelIntroButton from '@/core/ui/UiLabelIntroButton';
// import UiLabelTextarea from '@/core/ui/UiLabelTextarea';

// Constants import
import { ProjectConfigConstants } from '@/config/projectsConfigConstants';

// Store imports
import { storeToRefs } from 'pinia';
import { useGraphApiStore } from '@/store/modules/graphapi';
import { useUserStore } from '@/store/modules/user';
import { useGlobalStore } from '@/store/modules/global';

// Api Urls
const apiUrlsJson = (process.env.VUE_APP_SOMSET_ENV !== 'production' ? apiTestUrlsJson : apiProductionUrlsJson);
// console.info('MeEdit process.env.VUE_APP_SOMSET_ENV', process.env.VUE_APP_SOMSET_ENV, apiUrlsJson);

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

// Variables
const { setCurrentUserEnvironment } = useGlobalStore();
const { currentUser, currentUserPicture, isDirty } = storeToRefs(useUserStore());
const { userEnvironments } = storeToRefs(useGraphApiStore());
const { getCurrentUserEnvironment } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);
const configFileUpload = ProjectConfigConstants.configProfilePictureUpload;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const currentUserEnvironment = ref({});

const configEditForm = {
  firstName: 'Profile.Firstname',
  forFirstName: 'voornaam',
  lastName: 'Profile.Lastname',
  forLastName: 'achternaam',
  companyName: 'Profile.Company',
  forCompanyName: 'bedrijf',
  jobTitle: 'Profile.Functionname',
  forJobTitle: 'functie',
  optional: 'Profile.Optioneel',
  email: 'Profile.Username',
  forEmail: 'email',
  tooltipText: 'Profile.Tooltip',
  emailDisabled: true,
  phone: 'Profile.Phone',
  forPhone: 'telefoon',
  bio: 'Profile.Biography',
  forBio: 'bio',
  placeholder: 'Profile.Biography.Placeholder',
  settings: 'Profile.Settings',
  language: 'Profile.Language',
  forLanguage: 'taal',
  languagePlaceholder: 'Language.Choose',
  languageLabel: 'label',
  languages: [
    {
      label: 'Language.Dutch',
      code: 'nl'
    },
    {
      label: 'Language.English',
      code: 'en'
    }
  ],
  // twoFactorAuth: t('Profile.Authentication'),
  // forTwoFactorAuth: 'auth',
  // twoFactorAuthIntro: 'Google Authenticator is NIET ingeschakeld',
  // buttonTextAuth: 'Inschakelen Google Authenticator',
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  configFileUpload: {
    chooseTitle: 'Profile.ChooseProfilePicture',
    labelTitle: 'Profile.Photo',
    labelFor: 'image',
    fileTypes: 'image/jpg,image/jpeg',
    fileLimit: 1,
    fileSize: 5242880,
    fileUrl: '/',
    moreFiles: false,
    thumbnailWidth: 60,
    chooseLabel: 'Profile.Upload',
    removeButton: 'Profile.RemovePicture'
  }
};

const configBio = {
  labelName: t('Profile.Biography'),
  labelFor: 'bio'
};

const validUserEnvironments = () => {
  const environments = [];
  for (let i = 0; i < apiUrlsJson.length; i++) {
    if (userEnvironments.value.findIndex(function (x) {
      return x === apiUrlsJson[i].code;
    }) >= 0) {
      environments.push(apiUrlsJson[i]);
    }
  }
  return environments;
};
async function setApiUrlToCookie (environment) {
  setCurrentUserEnvironment(environment.code, validUserEnvironments());
  // const theChosenOne = validUserEnvironments().find(function (x) {
  //   return x.code === environment.code;
  // });
  // window.$cookies.set('ApiEnvironment', theChosenOne.code);
  // window.$cookies.set('ApiBaseUrl', theChosenOne.url);
  // findCurrentUserEnvironment();
}
// const findCurrentUserEnvironment = () => {
//   const environmentsCookie = window.$cookies.get('ApiEnvironment');

//   currentUserEnvironment.value = validUserEnvironments().find(function (x) {
//     return x.code === environmentsCookie;
//   });
// };
const removeImage = () => {
  currentUserPicture.value.picture = null;
};

const fieldChanged = () => {
  isDirtyPage.value = true;
};

const resetPicture = () => {
  isDirtyPage.value = true;
  currentUserPicture.value.upload = [];
  currentUserPicture.picture = null;
};
</script>

<template>
  <div class="me-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form>
          <div class="row">
            <div class="col-12 col-lg-8">
              <ui-label-input
                class="mb-5"
                :label-title="$t(configEditForm.firstName)"
                :label-for="configEditForm.forFirstName"
                @emit-dirty="fieldChanged"
                v-model="currentUser.firstname"
              >
              </ui-label-input>
              <ui-label-input
                class="mb-5"
                :label-title="$t(configEditForm.lastName)"
                :label-for="configEditForm.forLastName"
                @emit-dirty="fieldChanged"
                v-model="currentUser.lastname"
              >
              </ui-label-input>
              <ui-label-input
                class="mb-5"
                :label-title="$t(configEditForm.companyName)"
                :label-for="configEditForm.forCompanyName"
                @emit-dirty="fieldChanged"
                v-model="currentUser.company"
              >
              </ui-label-input>
              <ui-label-input
                class="mb-5"
                :label-title="$t(configEditForm.jobTitle)"
                :label-for="configEditForm.forJobTitle"
                :optional="$t(configEditForm.optional)"
                @emit-dirty="fieldChanged"
                v-model="currentUser.functionname"
              >
              </ui-label-input>
              <ui-label-info-input
                class="mb-5"
                :label-title="$t(configEditForm.email)"
                :label-for="configEditForm.forEmail"
                :tooltip-text="configEditForm.tooltipText"
                :disabled="configEditForm.emailDisabled"
                @emit-dirty="fieldChanged"
                v-model="currentUser.userName"
              >
              </ui-label-info-input>
              <!-- ui-label-input
                class="mb-4"
                :label-title="configEditForm.email"
                :label-for="configEditForm.forEmail"
                v-model="currentUser.userName"
                readonly
              >
              </ui-label-input -->
              <ui-label-input
                class="mb-4"
                :label-title="$t(configEditForm.phone)"
                :label-for="configEditForm.forPhone"
                :optional="$t(configEditForm.optional)"
                @emit-dirty="fieldChanged"
                v-model="currentUser.phone"
              >
              </ui-label-input>
              <ui-label-editor
                class="issue-edit__textarea mb-6"
                :label-title="$t(configBio.labelName)"
                :label-for="configBio.labelFor"
                :value-editor="currentUser.bio"
                @emit-dirty="fieldChanged"
                v-model="currentUser.bio">
              </ui-label-editor>
              <ui-fieldset :title="$t(configEditForm.settings)">
                <ui-label-dropdown
                  class="mb-5"
                  :card-title="$t('Profile.Environment')"
                  :card-for="$t('Profile.Environment')"
                  :placeholder="getCurrentUserEnvironment.label"
                  :options="validUserEnvironments()"
                  :option-label="configEditForm.languageLabel"
                  @emit-dirty="fieldChanged"
                  @emit-item="setApiUrlToCookie"
                >
                </ui-label-dropdown>
                <!-- ui-label-dropdown
                  class="mb-5"
                  :card-title="configEditForm.language"
                  :card-for="configEditForm.forLanguage"
                  :placeholder="configEditForm.languagePlaceholder"
                  :options="configEditForm.languages"
                  @emit-dirty="fieldChanged"
                  :option-label="configEditForm.languageLabel"
                >
                </ui-label-dropdown -->
                <!-- <ui-label-intro-button
                  :label-title="configEditForm.twoFactorAuth"
                  :label-for="configEditForm.forTwoFactorAuth"
                  :intro="configEditForm.twoFactorAuthIntro"
                  :button-text="configEditForm.buttonTextAuth"
                  @emit-click="openGoogleAuth()"
                >
                </ui-label-intro-button> -->
              </ui-fieldset>
            </div>
            <div class="col-12 col-lg-4">
              <ui-form-label
                class="mb-3"
                :label-title="$t(configEditForm.configFileUpload.labelTitle)"
                :label-for="configEditForm.configFileUpload.labelFor"
                :optional="$t(configEditForm.optional)"
              >
              </ui-form-label>
              <div
                class="me-edit__user-img position-relative mb-2 overflow-hidden"
              >
                <span
                  class="
                    me-edit__user-img--default
                    position-absolute
                    pi pi-user
                  "
                ></span>
                <img v-if="currentUserPicture.picture" :src="'data:image/jpeg;base64,' + currentUserPicture.picture" class="img-object-fit position-absolute w-100 h-100"
                  :alt="currentUser.firstname + ' ' + currentUser.lastname"
                />
                <img v-if="currentUserPicture.upload && currentUserPicture.upload.length === 1" :src="currentUserPicture.upload[0].base64Full" class="img-object-fit position-absolute w-100 h-100"
                     :alt="currentUser.firstname + ' ' + currentUser.lastname"
                />
              </div>
              <ui-file-upload class="me-edit__file-upload"
                :label-title="$t('Profile.ChooseProfilePicture')"
                :label-for="configFileUpload.labelFor"
                :file-types="configFileUpload.fileTypes"
                :file-limit="configFileUpload.fileLimit"
                :file-size="configFileUpload.fileSize"
                :file-url="configFileUpload.fileUrl"
                :more-files="configFileUpload.moreFiles"
                :thumbnail-width="configFileUpload.thumbnailWidth"
                :choose-label="$t(configFileUpload.chooseLabel)"
                @emit-dirty="fieldChanged"
                @emit-remove="resetPicture"
                v-model="currentUserPicture.upload">
              </ui-file-upload>
              <p-button
                class="me-edit__button p-button-link"
                :label="$t(configEditForm.configFileUpload.removeButton)"
                @click="removeImage()"
              >
              </p-button>
            </div>
          </div>
        </form>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4">
      <ui-save-buttons
        @emit-save="submitSave()"
        @emit-cancel="submitCancel()"
        :saveButton="$t('General.Save')"
        :cancelButton="$t('General.Cancel')">
      </ui-save-buttons>
    </div>
    <!-- pre>
TODO's:
- Taal
- 2FA link
- IsAdmin???
- ui-label-dropdown-editor css global maken. Nu kopie van UiLabelDropdownEditor
- remove image
</pre
    -->
  </div>
</template>

<style lang="scss">
.me-edit {
  &__user-img {
    aspect-ratio: 1 / 1;
    border-radius: $borderRadius;
    background-color: $mainBg;
    color: $secondaryTextColor;

    &--default {
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: calc-rem(150);
      opacity: 0.2;
      transform: translate(50%, 50%);
    }

    img {
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__file-upload {
    margin-bottom: calc-rem(2);
  }

  &__button {
    height: calc-rem(40);
  }

  .p-card-content {
    padding: 0;
  }
}

/* TODO: Deze eruit? */
.ui-label-dropdown-editor {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: calc-rem(22);
    align-items: start;
  }

  &__button,
  &__dropdown {
    height: calc-rem(40);
  }

  &__button {
    padding-top: calc-rem(12);
    padding-bottom: calc-rem(12);
    border-radius: $borderRadius;
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    border: calc-rem(1) solid $borderColor;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      border-color: $primary;
    }

    &:focus {
      @extend %button-focus;
    }
  }

  &__placeholder {
    opacity: 0.5;
  }

  &__editor {
    &.is-focused {
      box-shadow: 0 0 0 2px rgba(0, 95, 227, 0.4);
    }
  }

  .p {
    &-editor {
      &-container {
        border-radius: $borderRadius;
        border: calc-rem(1) solid $secondaryActive;

        &:hover {
          border-color: $primary;
        }
      }

      &-toolbar {
        padding: $editorToolbarPadding;
        border-bottom: calc-rem(1) solid $secondaryActive !important;
      }
    }
  }
}
</style>
