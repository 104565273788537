<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.control.layers.tree';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-simple-map-screenshoter';
import 'leaflet-search';
import 'leaflet-search/src/leaflet-search.css';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports

// Store imports
import { useProjectsStore } from '@/store/modules/projects';

// Define props

// Define emits
const emit = defineEmits([
  'emitGoto'
]);

// Variables
const { project, projectIssuesGeo, projectStakeholdersGeo, loadingProjectGeo } = storeToRefs(useProjectsStore());
const { fetchSingleProjectGeo } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;
const displayMap = ref(true);
const map = ref(null);
const osmUrl = ref(null);
const osm = ref(null);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const osmAttrib = ref(null);

const markerCriticism = L.icon({
  iconUrl: '/assets/images/map-marker-criticism.svg',
  shadowUrl: '',
  iconSize: [24, 29],
  iconAnchor: [12, 28]
});

const markerWarning = L.icon({
  iconUrl: '/assets/images/map-marker-warning.svg',
  shadowUrl: '',
  iconSize: [24, 29],
  iconAnchor: [12, 28]
});

const markerFocus = L.icon({
  iconUrl: '/assets/images/map-marker-focus.svg',
  shadowUrl: '',
  iconSize: [24, 29],
  iconAnchor: [12, 28]
});

const markerPositive = L.icon({
  iconUrl: '/assets/images/map-marker-positive.svg',
  shadowUrl: '',
  iconSize: [24, 29],
  iconAnchor: [12, 28]
});

const markerUnclear = L.icon({
  iconUrl: '/assets/images/map-marker-unclear.svg',
  shadowUrl: '',
  iconSize: [24, 29],
  iconAnchor: [12, 28]
});

const getColor = (assessment) => {
  return assessment === 'criticism' ? '#E71413' : assessment === 'warning' ? '#FF7733' : assessment === 'focus' ? '#FFCA00' : assessment === 'positive' ? '#7CC839' : assessment === 'unclear' ? '#B3B3B3' : '#005FE3';
};

const style = (feature) => {
  return {
    fillColor: getColor(feature.properties.assessment),
    color: getColor(feature.properties.assessment),
    weight: 2,
    fillOpacity: 0.6
  };
};

const setOptions = (feature, layer) => {
  if (feature.properties && feature.properties.popupContent) {
    layer.bindPopup(feature.properties.popupContent, {
      closeButton: false,
      type: feature.dataType,
      id: feature.id
    });
  }
  layer.bindTooltip(feature.properties.name, { permanent: false, opacity: 0.8 });
};

const options = {
  style: style,
  onEachFeature: setOptions,
  pointToLayer: (feature, latlng) => {
    if (feature.properties.shape && feature.properties.shape === 'Circle' && feature.properties.radius) {
      return new L.Circle(latlng, feature.properties.radius);
    } else if (feature.properties.shape && feature.properties.shape === 'CircleMarker') {
      return new L.CircleMarker(latlng);
    } else {
      switch (feature.properties.assessment) {
        case 'unclear':
          return new L.Marker(latlng, { icon: markerUnclear });
        case 'criticism':
          return new L.Marker(latlng, { icon: markerCriticism });
        case 'warning':
          return new L.Marker(latlng, { icon: markerWarning });
        case 'focus':
          return new L.Marker(latlng, { icon: markerFocus });
        case 'positive':
          return new L.Marker(latlng, { icon: markerPositive });
        default:
          return new L.Marker(latlng);
      }
    }
  }
};

const IssueStakeholderTree = {
  label: '<span class="ms-1">' + t('Project.General.IssuesAndStakeholders') + '</span>',
  selectAllCheckbox: true,
  children: []
};

// Functions
const delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

const toggleLabelEvent = (event) => {
  if (document.getElementById('toggleCheckbox') !== null) {
    toggleLabels(document.getElementById('toggleCheckbox').checked);
  }
};

const toggleLabels = (enable) => {
  map.value.eachLayer(function (layer) {
    if (layer.getTooltip()) {
      const tooltip = layer.getTooltip();
      tooltip.options.permanent = enable;
      layer.bindTooltip(tooltip);
    }
  });
};

const gotoIssue = (value) => {
  const url = '/projects/' + projectId + '/issues/' + value;
  emit('emitGoto', url);
};

const gotoStakeholder = (value) => {
  const url = '/projects/' + projectId + '/stakeholders/' + value;
  emit('emitGoto', url);
};

const getChildrenStatus = (geoJSON, geoLayers) => {
  const children = [];

  for (let idx = 0; idx < geoJSON.length; idx++) {
    const layer = geoLayers.getLayers().find(x => x.feature.idx === geoJSON[idx].idx);
    if (layer !== undefined) {
      let extra = '';
      if (geoJSON.length > 1) {
        if (idx + 1 < geoJSON.length && geoJSON[idx].properties.name === geoJSON[idx + 1].properties.name) {
          extra = ' (marker ' + (idx + 1) + ')';
        } else if (idx - 1 >= 0 && geoJSON[idx - 1].properties.name === geoJSON[idx].properties.name) {
          extra = ' (marker ' + (idx + 1) + ')';
        }
      }
      const item = {
        label: '<span class="ms-1">' + geoJSON[idx].properties.name + extra + '</span>',
        layer: layer
      };

      children.push(item);
    }
  }

  return children;
};

const getLayerStatus = (geoJSON) => {
  const children = [];
  if (geoJSON && geoJSON.features && geoJSON.features.length > 0) {
    const unclearGeo = geoJSON.features.filter(x => x.properties.assessment === 'unclear');
    const criticismGeo = geoJSON.features.filter(x => x.properties.assessment === 'criticism');
    const warningGeo = geoJSON.features.filter(x => x.properties.assessment === 'warning');
    const focusGeo = geoJSON.features.filter(x => x.properties.assessment === 'focus');
    const positiveGeo = geoJSON.features.filter(x => x.properties.assessment === 'positive');

    let typeLayers = L.geoJSON(criticismGeo, options);
    // const criticismGeoUnique = [...new Set(criticismGeo.map(item => item.id))];
    const criticism = {
      label: '<span class="ms-1 --criticism">' + t('Status.Kritiek') + ' (' + criticismGeo.length + ')</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(criticismGeo, typeLayers)
    };
    if (criticism.children.length) {
      children.push(criticism);
    }

    typeLayers = L.geoJSON(warningGeo, options);
    // const warningGeoUnique = [...new Set(warningGeo.map(item => item.id))];
    const warning = {
      label: '<span class="ms-1 --warning">' + t('Status.Waarschuwing') + ' (' + warningGeo.length + ')</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(warningGeo, typeLayers)
    };
    if (warning.children.length) {
      children.push(warning);
    }

    typeLayers = L.geoJSON(focusGeo, options);
    // const focusGeoUnique = [...new Set(focusGeo.map(item => item.id))];
    const focus = {
      label: '<span class="ms-1 --focus">' + t('Status.Aandacht') + ' (' + focusGeo.length + ')</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(focusGeo, typeLayers)
    };
    if (focus.children.length) {
      children.push(focus);
    }

    typeLayers = L.geoJSON(positiveGeo, options);
    // const positiveGeoUnique = [...new Set(positiveGeo.map(item => item.id))];
    const positive = {
      label: '<span class="ms-1 --positive">' + t('Status.Positief') + ' (' + positiveGeo.length + ')</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(positiveGeo, typeLayers)
    };
    if (positive.children.length) {
      children.push(positive);
    }

    typeLayers = L.geoJSON(unclearGeo, options);
    // const unclearGeoUnique = [...new Set(unclearGeo.map(item => item.id))];
    const unclear = {
      label: '<span class="ms-1 --unclear">' + t('Status.Onduidelijk') + ' (' + unclearGeo.length + ')</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(unclearGeo, typeLayers)
    };
    if (unclear.children.length) {
      children.push(unclear);
    }
  }

  return children;
};

// const addLayersToMap = (layers, map, searchLayers) => {
//   for (let idx = 0; idx < layers.length; idx++) {
//     layers[idx].layer.addTo(map.value);
//     searchLayers.push(layers[idx].layer);
//   }
// };

const initMap = async () => {
  // possible fix SOMSET-588
  // https://stackoverflow.com/a/20373342
  const mapContainer = document.getElementById('mapContainer');
  if (mapContainer) {
    mapContainer.innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
  }

  // create basemap
  osmUrl.value = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  map.value = L.map('map', {
    zoomControl: false,
    fullscreenControl: true,
    scrollWheelZoom: false,
    maxBounds: [[-90, -180], [90, 180]]
  });
  map.value.on('load move unload', (e) => console.log(e));
  map.value.setView(new L.LatLng(project.value.gisDefaultCoordinatesLat, project.value.gisDefaultCoordinatesLng), project.value.gisDefaultZoomLevel);

  const minZoomSetting = 2;
  const maxZoomSetting = 18;
  osm.value = L.tileLayer(osmUrl.value, {
    minZoom: minZoomSetting,
    maxZoom: maxZoomSetting,
    attribution: ''
  });
  map.value.addLayer(osm.value);

  // filter
  const issues = {
    label: '<span class="ms-1">' + t('General.Issues') + '</span>',
    selectAllCheckbox: true,
    children: getLayerStatus(projectIssuesGeo.value)
  };

  const stakeholders = {
    label: '<span class="ms-1">' + t('General.Stakeholders') + '</span>',
    selectAllCheckbox: true,
    children: getLayerStatus(projectStakeholdersGeo.value)
  };

  if (issues.children.length) {
    IssueStakeholderTree.children.push(issues);
  }

  if (stakeholders.children.length) {
    IssueStakeholderTree.children.push(stakeholders);
  }

  // place all layers detault on map
  // https://github.com/stefanocudini/leaflet-search
  const searchLayers = L.layerGroup();
  for (let i = 0; i < IssueStakeholderTree.children.length; i++) {
    for (let j = 0; j < IssueStakeholderTree.children[i].children.length; j++) {
      for (let k = 0; k < IssueStakeholderTree.children[i].children[j].children.length; k++) {
        IssueStakeholderTree.children[i].children[j].children[k].layer.addTo(map.value);
        searchLayers.addLayer(IssueStakeholderTree.children[i].children[j].children[k].layer);
      }
    }
  }

  // search option
  L.control.search({
    position: 'topright',
    textPlaceholder: t('Project.Dashboard.SearchByName'),
    layer: searchLayers,
    initial: false,
    // zoom: 13,
    zoom: project.value.gisDefaultZoomLevel,
    propertyName: 'name',
    buildTip: function (text, val) {
      const type = val.layer.feature.dataType;
      return '<a href="#" class="' + type + '">' + text + ' (' + type + ')</a>';
    }
  }).addTo(map.value);

  // filter option
  const baseTree = {
    label: t('General.Location'),
    noShow: true,
    children: [{
      label: '<span class="ms-1">' + project.value.name + '</span>',
      layer: osm.value
    }
    ]
  };

  const ctl = L.control.layers.tree(baseTree, null, {
    namedToggle: true,
    collapsed: false
  });
  ctl.addTo(map.value).collapseTree(true);

  if (IssueStakeholderTree.children.length) {
    ctl.setOverlayTree(IssueStakeholderTree).collapseTree(true);
  }

  // default icon
  L.Icon.Default.prototype.options.iconUrl = '../../assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.shadowUrl = null;

  // zoom option
  L.control.zoom({
    zoomInTitle: t('Project.Dashboard.ZoomIn'),
    zoomOutTitle: t('Project.Dashboard.ZoomOut')
  }).addTo(map.value);

  map.value.on('zoomstart', function () {
    // Close all popup
    const popup = document.querySelector('.leaflet-pane.leaflet-popup-pane');
    popup.innerHTML = '';
  });
  map.value.on('zoomend', function () {
    if (document.getElementById('toggleCheckbox') !== null) {
      if (document.getElementById('toggleCheckbox').checked) {
        toggleLabels(true);
      }
    }
  });

  // get bounds
  const bounds = L.latLngBounds([]);
  map.value.eachLayer(function (layer) {
    // Note from JWB: If the layer contains a circle, the getBounds function crashes.
    // I have not found a ready solution on the web.
    // After hours of trying solutions I just put a try / catch around it.
    // There is a good chance that the map does not align properly.
    try {
      const layerBounds = layer.getBounds();
      // extend the bounds of the collection to fit the bounds of the new feature
      bounds.extend(layerBounds);
    } catch {
      //
    }
  });
  if (bounds._southWest !== undefined) {
    map.value.fitBounds(bounds);
  }

  // screenshot option
  const pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: false, // hide screen icon
    preventDownload: false, // prevent download on button click
    domtoimageOptions: {}, // see options for dom-to-image
    position: 'topleft', // position of take screen icon
    screenName: project.value.name, // string or function
    // iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
    hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
    mimeType: 'image/png', // used if format == image,
    caption: null, // string or function, added caption to bottom of screen
    captionFontSize: 15,
    captionFont: 'Arial',
    captionColor: 'black',
    captionBgColor: 'white',
    captionOffset: 5,
    // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
    // and screenshot not created
    onPixelDataFail: async function ({ node, plugin, error, mapPane, domtoimageOptions }) {
      // Solutions:
      // decrease size of map
      // or decrease zoom level
      // or remove elements with big distanses
      // and after that return image in Promise - plugin._getPixelDataOfNormalMap
      return plugin._getPixelDataOfNormalMap(domtoimageOptions);
    }
  };
  L.simpleMapScreenshoter(pluginOptions).addTo(map.value);

  const command = L.control({ position: 'topright' });
  command.onAdd = function (map) {
    const div = L.DomUtil.create('div');
    div.innerHTML = `
    <div class="leaflet-control-layers leaflet-control-layers-expanded">
      <form>
        <input class="leaflet-control-layers-overlays" type="checkbox" id="toggleCheckbox">` + t('Project.Dashboard.DisplayNames') + `</input>
      </form>
    </div>`;
    return div;
  };
  command.addTo(map.value);

  document.addEventListener('click', function (event) {
    if (event.target && event.target.classList.contains('js-popup') && event.target.getAttribute('data-id') !== '' && event.target.getAttribute('data-type') !== '') {
      const id = event.target.getAttribute('data-id');
      const type = event.target.getAttribute('data-type');
      if (type === 'issue') {
        gotoIssue(id);
      } else if (type === 'stakeholder') {
        gotoStakeholder(id);
      }
    }
  });

  document.getElementById('toggleCheckbox').addEventListener('click', toggleLabelEvent, false);

  const dashboardMap = document.getElementById('dashboardMap');
  if (dashboardMap) {
    console.log('remove hide from map');
    dashboardMap.classList.remove('hideDashboardMap');
  }
};

onMounted(async () => {
  if (!project.value.gisDefaultCoordinatesLat || !project.value.gisDefaultCoordinatesLng || !project.value.gisDefaultZoomLevel) {
    console.log('properties map not set correctly');
    displayMap.value = false;
    return;
  }

  await fetchSingleProjectGeo(project.value.id);

  // add delay. this helps showing map the correct wat
  await delay(1000);

  await initMap();
});
</script>

<template>
  <div class="dashboard-map row" v-if="displayMap && !loadingProjectGeo">
    <div class="col-12">
      <div class="dashboard-map__map overflow-hidden w-100 h-75 hideDashboardMap" id="dashboardMap">
        <div class="w-100 h-100" id="mapContainer"></div>
      </div>
    </div>
    <div class="dashboard-map row" v-if="!displayMap">
      <p>{{ $t('Project.Dashboard.IncorrectSettingsChart') }}</p>
    </div>
  </div>
</template>

<style lang="scss">
@import 'leaflet/dist/leaflet.css';

.dashboard-map {
  &__map {
    border-radius: calc-rem(5);
    aspect-ratio: 785 / 577;
    background-color: $white;
    border: calc-rem(1) solid $separatorLineColor;
  }

  .hideDashboardMap {
    visibility: hidden;
  }

  .leaflet-layerstree-header-label {
    input[type=radio] {
      display: none;
    }
  }

  .leaflet-popup-content p {
    display: none;
  }
  .leaflet-popup-content p:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
</style>
