<script setup>
import { ref } from 'vue';

// Component imports
import UiFormLabel from '@/core/ui/UiFormLabel';
import PFileUpload from 'primevue/fileupload';
import PButton from 'primevue/button';
import UiFormErrorMessage from '@/core/ui/UiFormErrorMessage';

// Define emits

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  fileTypes: String,
  fileLimit: Number,
  fileSize: Number,
  fileUrl: String,
  moreFiles: Boolean,
  thumbnailWidth: Number,
  chooseLabel: String,
  modelValue: Array,
  auto: Boolean,
  mode: String,
  showUploadButton: Boolean,
  showCancelButton: Boolean,
  showError: Boolean,
  errorMessage: String
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty', 'emitRemove']);

// Variables
const filesToUpload = ref(props.modelValue);

// Functions
async function updateValue (target) {
  const files = target.files;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const base64 = await toBase64(file);
    filesToUpload.value.push({
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type,
      base64Full: base64,
      base64: base64.split(',')[1],
      status: 'new',
      id: null
    });
  }
  emit('update:modelValue', filesToUpload.value);
  emit('emitDirty');
}

async function removeFile (file) {
  for (let i = 0; i < filesToUpload.value.length; i++) {
    if (filesToUpload.value[i].name === file.name) {
      if (filesToUpload.value[i].status === 'new') {
        filesToUpload.value.splice(i, 1);
      } else {
        filesToUpload.value[i].status = 'delete';
      }
      break;
    }
  }
  emit('emitDirty');
  emit('update:modelValue', filesToUpload.value);
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
</script>

<template>
  <div class="ui-file-upload">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor"
    >
    </ui-form-label>
    <div v-if="props.auto && filesToUpload.length > 0" class="mb-3">
        <div v-for="file of filesToUpload" :key="file" class="mb-3">
          <div v-if="file.status !== 'delete'" class="d-flex align-items-center">
            <p-button
              v-if="!props.readOnly && !editMode"
              class="me-2 ui-file-upload__button"
              :label="props.textLabelEdit"
              icon="pi pi-trash"
              iconPos="center"
              @click="removeFile(file)">
            </p-button>
            {{ file.name }}
          </div>
        </div>
    </div>
    <p-file-upload
      :accept="props.fileTypes"
      :fileLimit="props.fileLimit"
      :maxFileSize="props.fileSize"
      :url="props.fileUrl"
      :multiple="props.moreFiles"
      :previewWidth="props.thumbnailWidth"
      :chooseLabel="props.chooseLabel"
      :mode="props.mode"
      :showUploadButton="props.showUploadButton"
      :showCancelButton="props.showCancelButton"
      :value="props.modelValue"
      :auto="props.auto"
      @remove="removeFile($event.file)"
      v-on:input="updateValue($event.target)"
    >
    </p-file-upload>
    <ui-form-error-message
      class="mt-1"
      v-if="props.showError && Object.keys(props.modelValue).length === 0">
      {{ props.errorMessage }}
    </ui-form-error-message>
  </div>
</template>

<style lang="scss">
.ui-file-upload {
  .p-fileupload {
    &-buttonbar {
      padding: 0;

      .p-fileupload-choose {
        background-color: $secondaryButtonBg;
        border: $secondaryButtonBorder;
        color: $secondaryButtonTextColor;

        &:hover {
          background-color: $secondaryButtonHoverBg;
          border-color: $secondaryButtonHoverBg;
          color: $secondaryButtonTextHoverColor;
        }
      }

      button {
        display: none;
      }
    }

    &-files {
      img {
        border-radius: calc-rem(3);
      }
    }

    &-row > div {
      padding: 0.9375rem;
    }

    .p-fileupload-files .p-fileupload-row > div {
      flex: 0 0 auto;
      width: auto;

      &:last-child {
        flex-grow: 1;

        .pi-times {
          transform: rotate(45deg);

          &::before {
            content: '\e90e';
          }
        }
      }

      .p-button-icon-only {
        background-color: transparent;
        border-color: transparent;
        color: $textColor;
      }
    }

    &-content {
      margin-top: calc-rem(5);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
      border-radius: calc-rem(5);
      background-color: $mainBg;
      color: $black;
    }

    .p-message.p-message-error {
      border: none;
    }

    .p-progressbar {
      border-radius: calc-rem(5);
      background-color: $mainBg;
    }
  }

  &__button {
    aspect-ratio: 1 / 1;
  }
}
</style>
