<script setup>
import sanitizeHtml from 'sanitize-html';

// Components imports
import PCard from 'primevue/card';

// Define props
const props = defineProps({
  h2: String,
  h3: String,
  content: String
});

// Define emits
// const emit = defineEmits([]);

// Variables

// Functions
const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup', 'div'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style'],
      div: ['class']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};
</script>

<template>
  <p-card class="ui-content-card">
    <template #content>
      <h2 class="mt-0" v-if="props.h2">
        {{ props.h2 }}
      </h2>
      <h3 class="mt-0" v-if="props.h3">
        {{ props.h3 }}
      </h3>
      <div class="ui-content-card__content pt-4 pb-2 pe-1 ps-1" v-html="sanitizer(props.content)">
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-content-card {
  font-size: calc-rem(14);
  line-height: calc-rem(20);
  word-break: break-word;

  &__content {
    @media (min-width: 1440px) {
      columns: 1; // 2;
      column-gap: calc-rem(24);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-top: 0;
  }

  h2 {
    margin-bottom: calc-rem(25);
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    letter-spacing: -.02px;
  }

  h3 {
    margin-bottom: calc-rem(10);
    padding-top: calc-rem(5);
    font-size: calc-rem(16);
    font-weight: 600;
    line-height: calc-rem(18);
    color: $secondaryTextColor;
  }

  h4,
  h5,
  h6 {
    margin-bottom: calc-rem(5);
    font-size: calc-rem(14);
    font-weight: 700;
    line-height: calc-rem(20);
  }

  p {
    margin-bottom: calc-rem(20);
  }

  ul,
  ol {
    padding-left: calc-rem(20);
  }

  a {
    color: $primary;
  }

  img {
    max-width: 100%;
  }
}
</style>
