<script setup>
// Component imports
import PInputtext from 'primevue/inputtext';
import UiFormErrorMessage from '@/core/ui/UiFormErrorMessage';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  modelValue: String,
  showError: Boolean,
  disabled: Boolean,
  errorMessage: String,
  tooltipText: String
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables
const updateValue = (value) => {
  emit('update:modelValue', value);
  emit('emitDirty');
};
</script>

<template>
  <div class="ui-label-info-input">
    <label
      class="ui-label-info-input__label d-flex align-items-center"
      :for="props.labelFor">
      <span>
        {{ props.labelTitle }}&nbsp;
      </span>
      <span
        class="ml-1 ui-label-info-input__label-info d-inline-block pi pi-info-circle"
        v-tooltip.bottom.focus="{ value: props.tooltipText }">
      </span>
    </label>
    <p-inputtext
      class="ui-label-info-input__input d-block w-100"
      :id="props.labelFor"
      :name="props.labelFor"
      :value="props.modelValue"
      :disabled="props.disabled"
      @input="updateValue($event.target.value)">
    </p-inputtext>
    <ui-form-error-message
      class="mt-1"
      v-if="props.showError">
      {{ props.errorMessage }}
    </ui-form-error-message>
  </div>
</template>

<style lang="scss">
.ui-label-info-input {
  &__label {
    font-size: calc-rem(14);
    font-weight: 600;
    line-height: calc-rem(16);
    color: $textColor;

    &-info {
      width: calc-rem(14);
      height: calc-rem(14);
      cursor: pointer;
    }
  }

  &__input {
    height: calc-rem(40);
  }
}
</style>
