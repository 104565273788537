<script setup>
// import { ref } from 'vue';

// Component imports
import PButton from 'primevue/button';

// Define props
const props = defineProps({
  leftButton: String,
  leftButtonState: Boolean,
  rightButton: String,
  rightButtonState: Boolean,
  onlyButton: String,
  showOnlyButton: Boolean
});

// Define emits
const emit = defineEmits([
  'emitRight',
  'emitLeft',
  'emitPrimary'
]);

// Variables
const clickMap = () => {
  emit('emitLeft');
};
const clickList = () => {
  emit('emitRight');
};
const clickReport = () => {
  emit('emitPrimary');
};
</script>

<template>
  <div class="ui-toggle-buttons d-flex">
    <div
      class="p-buttonset"
      :class="{ 'mt-2': props.showOnlyButton }">
      <p-button
        class="ui-toggle-buttons__button p-button-secondary"
        :class="{ 'is-active': !props.leftButtonState }"
        :label="props.leftButton" icon="pi pi-map-marker"
        iconPos="left"
        @click="clickMap()">
      </p-button>
      <p-button
        class="ui-toggle-buttons__button p-button-secondary"
        :class="{ 'is-active': !props.rightButtonState }"
        :label="props.rightButton"
        icon="pi pi-list"
        iconPos="left" @click="clickList()">
      </p-button>
    </div>
    <p-button
      v-if="props.showOnlyButton"
      class="ui-toggle-buttons__button"
      :label="props.onlyButton"
      icon="pi pi-chart-bar"
      iconPos="left"
      @click="clickReport()">
    </p-button>
  </div>
</template>

<style lang="scss">
.ui-toggle-buttons {
  &__button {
    .p-button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .pi {
      &::before {
        font-size: calc-rem(14);
      }
    }
  }
}
</style>
