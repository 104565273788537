<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';

// Component imports
import PProgressSpinner from 'primevue/progressspinner';
import UiProjectCard from '@/core/ui/UiProjectCard';

// Store imports
import { useMediaStore } from '@/store/modules/media';

// Lifecycle hook imports
import router from '@/router';

// Variables
const { allMedia, loadingAllMedia } = storeToRefs(useMediaStore());
const { fetchMedia } = useMediaStore();
const route = useRoute();
const projectId = route.params.projectId;

const goToLocatioEdit = (value) => {
  router.push({ path: value });
};

onMounted(async () => {
  await fetchMedia(projectId);
});

// Functions
</script>

<template>
  <main class="media" :key="projectId">
    <template v-if="!loadingAllMedia">
      <div class="media__cards-grid" v-if="allMedia.length > 0">
        <div v-for="item in allMedia" :key="item.id">
          <ui-project-card
            :card-title="item.name"
            :card-sub-title="item.binaryFilename"
            :card-alt="item.name + '(' + item.binaryFilename + ')'"
            :card-url="'/projects/' + projectId + '/media/' + item.id"
            :mod-date="item.modDate"
            :show-updates="false"
            :detailsHeader="$t('General.CreationDate')"
            :detailsContent="useMoment(item.addDate).format('DD-MM-YYYY HH:mm')"
            :showImage="false"
            :showDownloadButton="true"
            :downloadUrl="'media/' + item.id + '/download'"
            :downloadFilename="item.binaryFilename"
            :downloadType="item.binaryContentType"
            :show-edit-button="false"
            :edit-url="'/projects/' + projectId + '/media/' + item.id + '/edit'"
            @emit-edit="goToLocatioEdit">
          </ui-project-card>
        </div>
      </div>
      <div
        class="media__cards-grid"
        v-if="allMedia.length === 0">
        <p>
          {{ $t('ProjectMedia.Frontend.NoFiles') }}
        </p>
      </div>
    </template>
    <p-progress-spinner
      class="mt-10 me-auto ms-auto"
      v-if="loadingAllMedia">
    </p-progress-spinner>
    <div v-show="error">
      <!-- TODO Remove and add production worthy solution -->
      {{ $t('ProjectMedia.Error') }}
    </div>
  </main>
</template>

<style lang="scss">
.media {
  &__grid,
  &__cards-grid {
    display: grid;
  }

  &__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      &:nth-child(1) {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
          border-left: calc-rem(1) solid $separatorLineColor;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
          min-height: calc-rem(139);
        }
      }

      h3,
      h4 {
        color: $secondaryTextColor;
      }

      h3 {
        margin-top: calc-rem(3);
        font-size: calc-rem(16);
        font-weight: 600;
        line-height: calc-rem(18);
      }

      h4 {
        margin-bottom: calc-rem(7);
        line-height: calc-rem(16);
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__cards-grid {
    // row-gap: calc-rem(12);

    // @include media-breakpoint-up(lg) {
    //   grid-template-columns: repeat(4, 25%);
    //   column-gap: calc-rem(12);
    // }

    gap: 1em;
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 300px) {
      .ui-project-card__title {
        white-space: normal;
        max-width: 100%;
      }
    }

    @media (min-width: 767px) {
      .ui-project-card__title {
        white-space: nowrap;
        max-width: 18rem;
      }
    }

    @media (min-width: 1000px) {
      .ui-project-card__title {
        max-width: 11rem;
      }
    }

    @media (min-width: 1200px) {
      .ui-project-card__title {
        max-width: 11rem;
      }
    }

    @media (min-width: 1400px) {
      .ui-project-card__title {
        max-width: 14rem;
      }
    }

    @media (min-width: 1600px) {
      .ui-project-card__title {
        max-width: 15rem;
      }
    }

    @media (min-width: 1800px) {
      .ui-project-card__title {
        max-width: 19rem;
      }
    }

  }

  &__img {
    aspect-ratio: 308 / 296;
    border-radius: $borderRadius;
    background-color: $mainBg;
    color: $secondaryTextColor;

    &--default {
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: calc-rem(150);
      opacity: 0.2;
      transform: translate(-50%, -50%);
    }

    img {
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .p-card-content {
    padding: 0;
  }
}
</style>
