<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';
import PProgressSpinner from 'primevue/progressspinner';
import NoteDetail from '@/modules/notedetail/NoteDetail';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useNotesStore } from '@/store/modules/notes';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttonLabel = t('ProjectNote.Edit');
const { editNote, loadingNote } = storeToRefs(useNotesStore());
const { fetchSingleNote } = useNotesStore();
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const route = useRoute();
const projectId = route.params.projectId;
const noteId = route.params.noteId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true,
  toggleSideBarClass: true
});

const goToEdit = () => {
  router.push({ path: '/projects/' + projectId + '/notes/' + noteId + '/edit' });
};

onMounted(async () => {
  await fetchSingleNote(projectId, noteId);
});
</script>

<template>
  <base-layout v-if="!loadingNote">
    <template v-slot:header>
      <view-header
        :title="editNote.name">
        <template v-slot:actions>
          <p-button
            v-if="IsActionAllowedInRole('note.edit', currentUser, route.params.projectId)"
            :label="buttonLabel"
            @click="goToEdit()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <note-detail></note-detail>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-note-view position-absolute"
    v-if="loadingNote">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-note-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
