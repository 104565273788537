<script setup>
// import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';
import MediaOverview from '@/modules/mediaoverview/MediaOverview';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Lifecycle hook imports
import router from '@/router';

// Variables
const route = useRoute();
const projectId = route.params.projectId;
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const goToCreateNew = () => {
  router.push({ path: '/projects/' + projectId + '/media/create' });
};
</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="$t('General.ProjectMedias')">
        <template v-slot:actions>
          <p-button
            v-if="IsActionAllowedInRole('media.new', currentUser, route.params.projectId)"
            :label="$t('General.NewFile')"
            icon="pi pi-plus"
            iconPos="left" @click="goToCreateNew()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <media-overview></media-overview>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-media-view {
}
</style>
