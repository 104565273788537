<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
// import PCalendar from 'primevue/calendar';
import PCalendar from '../../../core/ui/calendar/CalendarCustom';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';
import PButton from 'primevue/button';
import PProgressSpinner from 'primevue/progressspinner';
import IssueDetail from '@/modules/issuedetail/IssueDetail';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useIssuesStore } from '@/store/modules/issues';
import { useProjectsStore } from '@/store/modules/projects';
import { useMediaStore } from '@/store/modules/media';
import { useNotesStore } from '@/store/modules/notes';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Variables
const { issue } = storeToRefs(useIssuesStore());
const { loadingIssue, loadingIssueStakeholders } = storeToRefs(useIssuesStore());
const { project } = storeToRefs(useProjectsStore());
const { fetchSingleProject } = useProjectsStore();
const { fetchSingleIssue } = useIssuesStore();
const { fetchMediaByIssue } = useMediaStore();
const { fetchNotesByIssue } = useNotesStore();
const { loadingAllMediaIssue } = storeToRefs(useMediaStore());
const { loadingAllNotesIssue } = storeToRefs(useNotesStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const issueEditButton = t('Issue.Edit');
const route = useRoute();
const projectId = route.params.projectId;
const issuesId = route.params.issueId;
const calendarValue = ref('');
const showCalendarIcon = true;
const downloadOptions = ref([]);

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const goToEdit = () => {
  router.push({ path: '/projects/' + route.params.projectId + '/issues/' + route.params.issueId + '/edit' });
};

onMounted(async () => {
  loadingIssue.value = true;
  loadingAllMediaIssue.value = true;
  loadingAllNotesIssue.value = true;
  loadingIssueStakeholders.value = true;
  if (projectId !== project.value.id) {
    await fetchSingleProject(projectId);
  }
  await fetchSingleIssue(project.value, issuesId);
  await fetchMediaByIssue(projectId, issuesId);
  await fetchNotesByIssue(projectId, issuesId);

  downloadOptions.value = [
    {
      label: t('General.Frontend.DownloadPDF'),
      endpoint: 'issues/' + issuesId + '/export?format=pdf',
      type: 'pdf',
      filename: project.name + '_issue.pdf'
    },
    {
      label: t('General.Frontend.DownloadWord'),
      endpoint: 'issues/' + issuesId + '/export?format=doc',
      type: 'doc',
      filename: project.name + '_issue.doc'
    }
  ];
});
const enabledDates = () => {
  const returnValue = [];
  const historicDates = issue.value.historicDates;
  if (historicDates === null) {
    return [];
  }
  for (let i = 0; i < historicDates.length; i++) {
    const historicDate = historicDates[i].versionDate.split('-');
    returnValue.push(new Date(parseInt(historicDate[0], 10), parseInt(historicDate[1], 10) - 1, parseInt(historicDate[2], 10)));
  }
  returnValue.push(new Date());
  return returnValue;
};
const versionText = () => {
  if (useMoment(calendarValue.value).format('DD-MM-YYYY') === useMoment().format('DD-MM-YYYY')) {
    calendarValue.value = null;
  }
  return (calendarValue.value ? ' - ' + useMoment(calendarValue.value).format('DD-MM-YYYY') : '');
};
</script>

<template>
  <base-layout v-if="!loadingIssue && !loadingIssueStakeholders && !loadingAllMediaIssue && !loadingAllNotesIssue">
    <template v-slot:header>
      <view-header :title="(issue.name ?? '') + versionText()">
        <template v-slot:actions>
          <p-calendar
            class="me-5"
            v-model="calendarValue" dateFormat="dd-mm-yy"
            :minDate="new Date(issue.addDate)"
            :maxDate="new Date()"
            :enabledDates="enabledDates()"
            :show-icon="showCalendarIcon"
            :showButtonBar="true"
            icon="pi pi-calendar">
          </p-calendar>
          <ui-download-buttons-dropdown
            v-if="IsActionAllowedInRole('issue.download', currentUser, route.params.projectId)"
            class="me-5"
            :project-name="project.name"
            :downloadOptions="downloadOptions">
          </ui-download-buttons-dropdown>
          <p-button
            v-if="IsActionAllowedInRole('issue.edit', currentUser, route.params.projectId)"
            class="project-issue-view__edit-btn"
            :label="issueEditButton"
            @click="goToEdit()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <issue-detail :selectedDate="calendarValue ? useMoment(calendarValue).format('YYYY-MM-DD') : null"></issue-detail>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-issue-view__spinner position-absolute"
    v-if="loadingIssue && loadingIssueStakeholders && loadingAllMediaIssue && loadingAllNotesIssue">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-issue-view {
  &__edit-btn {
    min-width: calc-rem(130);
    white-space: nowrap;
  }

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
