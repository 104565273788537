<script setup>
import { onMounted, ref, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

// Component imports
import PCard from 'primevue/card';
// import PPanel from 'primevue/panel';
import PPanel from '../../../core/ui/panel/PanelCustom';
import PBadge from 'primevue/badge';
import InputText from 'primevue/inputtext';

// Store imports
import { useUserStore } from '@/store/modules/user';
import { useProjectsStore } from '@/store/modules/projects';
import { useRolesStore } from '@/store/modules/roles';
import { useI18n } from 'vue-i18n';

// Define props
const props = defineProps({
  card: Object
});

// Variables
const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const { currentUser } = storeToRefs(useUserStore());
const { updateCustomCategoryStakeholdersByProject, fetchSingleProjectStakeholders } = useProjectsStore();
const { IsActionAllowedInRole } = useRolesStore();
const { card } = toRefs(props);
const cardCategories = ref(null);
const noCategory = ref(t('General.NoCategory'));
const projectId = route.params.projectId;

// Functions
const editCategory = (index) => {
  cardCategories.value[index].editable = !cardCategories.value[index].editable;
};
const saveCategory = async (index) => {
  if (cardCategories.value[index].newValue.length > 0 && (cardCategories.value[index].id !== cardCategories.value[index].newValue)) {
    try {
      await updateCustomCategoryStakeholdersByProject(projectId, cardCategories.value[index].id, cardCategories.value[index].newValue);
      cardCategories.value[index].id = cardCategories.value[index].newValue;
      cardCategories.value[index].panelTitle = cardCategories.value[index].newValue;

      await fetchSingleProjectStakeholders(projectId);
    } catch (ex) {
      console.error('saveCategory' + JSON.stringify(ex));
    }
  }

  cardCategories.value[index].editable = false;
};

onMounted(async () => {
  cardCategories.value = card.value.panels;
});
</script>

<template>
  <p-card v-if="cardCategories != null" class="stakeholders-list-card">
    <template #header>
      <h2 class="stakeholders-list-card__title mt-0 mb-5 pt-4 pe-3 ps-3">
        {{ card.cardTitle }}
      </h2>
      <h3 class="stakeholders-list-card__subtitle mt-0 mb-0 pe-3 ps-3">
        {{ card.cardSubtitle }}
      </h3>
    </template>
    <template #content>
      <p-panel
        :toggleable="true"
        :collapsed="false"
        v-for="(item, index) in cardCategories"
        :key="item.id">
        <template v-if="cardCategories[index].editable === false" #header>
          <div class="d-flex align-items-center">
            <p-badge
              class="stakeholders-list-card__badge me-2"
              :class="item.className">
              {{ item.panel.length }}
            </p-badge>
            <span>
              {{ item.panelTitle }}
            </span>
          </div>
        </template>
        <template #icons>
          <div class="d-flex align-items-center w-100"
               v-if="currentUser.isAdministrator || IsActionAllowedInRole('stakeholder.updatecategory', currentUser, projectId)">
            <InputText v-if="cardCategories[index].editable === true" class="w-50 panel-input" type="text" size="small" v-model="cardCategories[index].newValue" :placeholder="cardCategories[index].id" />
          </div>
          <div v-if="currentUser.isAdministrator || currentUser.isAdministrator || IsActionAllowedInRole('stakeholder.updatecategory', currentUser, projectId)">
            <i v-if="cardCategories[index].editable === false && cardCategories[index].id.trim() !== noCategory" @click="editCategory(index)" class="pi pi-pencil w-pencil"></i>
            <i v-if="cardCategories[index].editable === true && cardCategories[index].id.trim() !== noCategory" @click="saveCategory(index)" class="pi pi-save w-pencil"></i>
          </div>
        </template>
        <TransitionGroup
          name="fade"
          tag="ul"
          :class="'stakeholders-list-card__list m-0 pt-0 pb-3 ps-0'">
          <li
            class="stakeholders-list-card__list-item align-items-center"
            v-for="(listitem) in item.panel"
            :key="listitem.id">
            <span
              class="stakeholders-list-card__list-icon me-2 d-inline-block"
              :class="listitem.className"
              v-if="listitem.className !== null && listitem.className !== ''">
            </span>
            <router-link
              class="stakeholders-list-card__list-link d-inline-block"
              :to="listitem.urlTo"
              v-if="listitem.urlTo !== null && listitem.urlTo !== ''">
              {{ listitem.title }}
            </router-link>
            <span
              class="stakeholders-list-card__list-nolink d-inline-block"
              v-if="listitem.urlTo === null || listitem.urlTo === ''">
              {{ listitem.title }}
            </span>
          </li>
        </TransitionGroup>
      </p-panel>
    </template>
  </p-card>
</template>

<style lang="scss">
.stakeholders-list-card {
  width: 100%;

  .w-pencil {
    width: 32px;
  }

  .panel-input {
    position: absolute;
    left: 15px;
  }

  &__title {
    font-size: calc-rem(20);
    font-weight: 600;
    line-height: calc-rem(22);
    color: $primary;
  }

  &__subtitle {
    padding-bottom: calc-rem(12);
    font-size: calc-rem(12);
    line-height: calc-rem(14);
    color: $secondaryTextColor;
  }

  &__badge {
    width: calc-rem(34);
    height: calc-rem(34);
    font-weight: 600;
    line-height: calc-rem(34);
    border-radius: calc-rem(20);
  }

  &__list {
    list-style: none;

    &-item {
      display: flex;

      &:nth-child(n+2) {
        border-top: calc-rem(1) solid $separatorLineColor;
      }

      &.--button {
        border-top: none;
      }
    }

    &-link,
    &-nolink {
      padding-top: calc-rem(12);
      padding-bottom: calc-rem(12);
      font-size: calc-rem(14);
      line-height: calc-rem(16);
    }

    &-link {
      text-decoration: none;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        @extend %button-focus;
      }
    }

    &-nolink {
      color: $secondaryTextColor;
    }

    &-icon {
      width: calc-rem(20);
      height: calc-rem(20);
      border-radius: $borderRadius;

      &.--criticism {
        background-color: $criticism;
      }

      &.--warning {
        background-color: $warning;
      }

      &.--focus {
        background-color: $focus;
      }

      &.--positive {
        background-color: $positive;
      }

      &.--unclear {
        background-color: $unclear;
      }
    }
  }

  .p-card {
    &-content {
      padding: 0;
    }
  }

  .p-panel {
    border-top: calc-rem(1) solid $separatorLineColor;

    &-toggler {
      .pi-plus {
        &::before {
          content: '\e902';
        }
      }

      .pi-minus {
        &::before {
          content: '\e903'
        }
      }
    }
  }

  .--criticism {
    &.stakeholders-list-card__badge {
      background-color: $criticism;
    }
  }

  .--warning {
    &.stakeholders-list-card__badge {
      background-color: $warning;
    }
  }

  .--focus {
    &.stakeholders-list-card__badge {
      background-color: $focus;
      color: $black;
    }
  }

  .--positive {
    &.stakeholders-list-card__badge {
      background-color: $positive;
    }
  }

  .--unclear {
    &.stakeholders-list-card__badge {
      background-color: $unclear;
    }
  }
}
</style>
