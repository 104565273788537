<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <slot name="header"></slot>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
