<script setup>
import { ref } from 'vue';

// Component imports
// import PDropdown from 'primevue/dropdown';
import PDropdownEditable from '../../core/ui/dropdown/DropdownCustom';
import PButton from 'primevue/button';
import PCheckbox from 'primevue/checkbox';
import UiFormLabel from './UiFormLabel';

// Define props
const props = defineProps({
  titleDropdown: String,
  forDropdown: String,
  optionsDropdown: Array,
  optionsLabel: String,
  placeholderDropdown: String,
  titleCheckboxes: String,
  forCheckboxes: String,
  checkboxOptions: Array,
  valueCategory: String,
  modelValue: Object
});

// Define emits
// const emit = defineEmits(['update-value']);
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables
const tooltipReset = 'Reset';
const selectedCheckbox = ref(props.checkboxOptions.filter(i => i.checked));
const selectedCategory = ref(props.valueCategory);
const value = {
  selectedCategory: props.valueCategory,
  selectedCheckboxId: ref(props.checkboxOptions.filter(i => i.checked)).value ? ref(props.checkboxOptions.filter(i => i.checked)).value[0] : {}
};
emit('update:modelValue', value); // update model with initial value. If not clicked it could be empty

// Functions
const resetCheckboxes = () => {
  selectedCheckbox.value = null;
  value.selectedCheckbox = null;
  emit('update:modelValue', value);
  emit('emitDirty', value);
};
const onClickCheckbox = (item) => {
  selectedCheckbox.value = [item];
  value.selectedCheckboxId = item;
  emit('update:modelValue', value);
  emit('emitDirty', value);
};
const updateValue = (input) => {
  value.selectedCategory = input;
  emit('update:modelValue', value);
  emit('emitDirty', value);
};
const changeValue = (input) => {
  value.selectedCategory = input.value.name;
  emit('update:modelValue', value);
  emit('emitDirty', value);
};
</script>

<template>
  <div class="ui-label-dropdown-checkboxes">
    <div>
      <ui-form-label
        class="mb-2"
        :label-title="props.titleDropdown"
        :label-for="props.forDropdown">
      </ui-form-label>
      <p-dropdown-editable
        class="ui-label-dropdown-checkboxes__dropdown w-100"
        :editable="true"
        v-model="selectedCategory"
        :options="props.optionsDropdown"
        :option-label="props.optionsLabel"
        :placeholder="props.placeholderDropdown"
        scrollHeight="400px"
        @input="updateValue($event.target.value)"
        @change="changeValue($event)">
      </p-dropdown-editable>
    </div>
    <div>
      <ui-form-label
        class="mb-2"
        :label-title="props.titleCheckboxes"
        :label-for="props.forCheckboxes">
      </ui-form-label>
      <div class="d-flex">
        <p-button
          class="ui-label-dropdown-checkboxes__reset"
          label="reset"
          icon="pi pi-times"
          iconPos="center"
          v-tooltip.top="tooltipReset"
          @click="resetCheckboxes()">
        </p-button>
        <div
          v-for="item in checkboxOptions"
          :key="item.id">
          <label
            :for="item.value"
            class="sr-only">
            {{ item.labelTitle }}
          </label>
          <p-checkbox
            class="ui-label-dropdown-checkboxes__checkbox"
            :class="item.className"
            :id="item.value"
            v-model="selectedCheckbox"
            :value="item"
            :name="item.nameCheckboxes"
            trueValue="1"
            @input="onClickCheckbox(item)"
            v-tooltip.top="item.labelTitle">
          </p-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-label-dropdown-checkboxes {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: calc-rem(22);
  align-items: start;

  &__dropdown,
  &__reset {
    height: calc-rem(40);
  }

  &__reset {
    margin-right: calc-rem(7);
    padding-right: calc-rem(12);
    padding-left: calc-rem(12);
    background-color: $formBgHover;
    border-color: $secondaryHover;
    color: $secondaryTextColor;

    &:hover {
      background-color: $secondaryHover !important;
      border-color: $secondaryHover !important;
      color: $secondaryTextColor !important;
    }

    .p-button-label {
      @extend %sr-only;
    }
  }

  &__checkbox {
    margin-right: calc-rem(7);
    width: calc-rem(40);
    height: calc-rem(40);

    .p-checkbox {
      &-box {
        width: inherit !important;
        height: inherit !important;

        &-icon {
          font-size: calc-rem(18) !important;
        }
      }
    }

    &.--unclear {
      .p-checkbox-box {
        background-color: $unclear !important;
        border-color: $unclear !important;
      }
    }

    &.--positive {
      .p-checkbox-box {
        background-color: $positive !important;
        border-color: $positive !important;
      }
    }

    &.--focus {
      .p-checkbox-box {
        background-color: $focus !important;
        border-color: $focus !important;
      }
    }

    &.--warning {
      .p-checkbox-box {
        background-color: $warning !important;
        border-color: $warning !important;
      }
    }

    &.--criticism {
      .p-checkbox-box {
        background-color: $criticism !important;
        border-color: $criticism !important;
      }
    }

    &.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: $secondaryActive !important;
    }
  }
}
</style>
