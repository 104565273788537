<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useMoment from 'moment';
import { useI18n } from 'vue-i18n';

// Component imports
import PDialog from 'primevue/dialog';
import UiContentCard from '@/core/ui/UiContentCard';
import UiReviewCard from '@/core/ui/UiReviewCard';
import UiParticipationCard from '@/core/ui/UiParticipationCard';
import UiMapCard from '@/core/ui/UiMapCard';
import UiContactCard from '@/core/ui/UiContactCard';
import UiDocumentHistoryCard from '@/core/ui/UiDocumentHistoryCard';
import StakeholderDetailDialogForm from './components/StakeholderDetailDialogForm';
import UiStakeholdersCard from '@/core/ui/UiStakeholdersCard';
import UiFilesCard from '@/core/ui/UiFilesCard';
import UiNotesCard from '@/core/ui/UiNotesCard';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useMediaStore } from '@/store/modules/media';
import { useNotesStore } from '@/store/modules/notes';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  selectedDate: String
});

// Define emits

// Variables
const { configReview, configImportance, configParticipation, configContact, author, moderator, contentCard, issueOverview, stakeholder, stakeholderVersion } = storeToRefs(useStakeholdersStore());
const { fetchStakeholder, updateStakeholderStatus, updateStakeholderConcern, updateStakeholderParticipation, addNewStakeholderContact, updateNewStakeholderContact, deleteStakeholderContact, updateStakeholderGeoJson } = useStakeholdersStore();
const { project } = storeToRefs(useProjectsStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { allMediaStakeholder, loadingAllMediaStakeholder } = storeToRefs(useMediaStore());
const { allNotesStakeholder, loadingAllNotesStakeholder } = storeToRefs(useNotesStore());
const route = useRoute();
const projectId = route.params.projectId;
const stakeholderId = route.params.stakeholderId;
const showContactPersonDialog = ref(false);
const showEditDialogTitle = ref(false);
const showNewDialogTitle = ref(false);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configMap = {
  cardTitle: t('General.Location'),
  textLabelEdit: t('General.Frontend.EditLocation'),
  textLabelSave: t('General.Frontend.SaveLocation')
};
const configHistory = {
  cardTitle: t('General.DocumentHistory'),
  moderator: '',
  moderatorUrl: '/',
  author: '',
  authorUrl: '/',
  dateTime: '',
  subtitleModerator: t('General.LastUpdated'),
  subtitleAuthor: t('General.Author'),
  moderatorInfo: t('General.By')
};
const configDialog = {
  titleNew: t('Contact.AddNewContact'),
  titleEdit: t('StakeholderContact.EditContact'),
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  contactId: '',
  stakeholderId: '',
  form: {
    inputs: [
      {
        labelTitle: t('General.Firstname'),
        labelFor: 'firstname',
        value: '',
        errorMessage: t('Contact.AddFirstname')
      },
      {
        labelTitle: t('General.Lastname'),
        labelFor: 'lastname',
        value: '',
        errorMessage: t('Contact.AddLastname')
      },
      {
        labelTitle: t('StakeholderContact.Telephone'),
        labelFor: 'telephone',
        value: '',
        errorMessage: t('Contact.AddPhonenumber')
      },
      {
        labelTitle: t('StakeholderContact.Mobile'),
        labelFor: 'mobile',
        value: '',
        errorMessage: t('Contact.AddPhonenumber')
      },
      {
        labelTitle: t('StakeholderContact.Function'),
        labelFor: 'contactFunction',
        value: '',
        errorMessage: ''
      },
      {
        labelTitle: t('StakeholderContact.Email'),
        labelFor: 'emailAddress',
        value: '',
        errorMessage: t('Contact.AddEmailAddress')
      }
    ],
    textarea: {
      labelTitle: t('General.Description'),
      labelFor: 'description',
      value: '',
      errorMessage: ''
    }
  }
};
const displayGeneralNAW = 'General.NAW';
const configFiles = {
  cardTitle: t('General.ProjectMedias')
};
const configNotes = {
  cardTitle: t('General.ProjectNotes')
};

// Functions
const goToSaveGeo = async (value) => {
  await updateStakeholderGeoJson(projectId, stakeholderId, JSON.stringify(value));
};
const UpdateStatus = (value) => {
  updateStakeholderStatus(projectId, stakeholderId, value.id);
};
const UpdateConcern = (value) => {
  updateStakeholderConcern(projectId, stakeholderId, value.id);
};
const participationItem = ref();
const updateParticipationValue = (value) => {
  updateStakeholderParticipation(projectId, stakeholderId, value.value);
  participationItem.value = value;
};
const addNewContact = () => {
  configDialog.contactId = '';
  configDialog.form.inputs.find(x => x.labelFor === 'firstname').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'lastname').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'telephone').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'mobile').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'contactFunction').value = '';
  configDialog.form.inputs.find(x => x.labelFor === 'emailAddress').value = '';
  configDialog.form.textarea.value = '';

  showContactPersonDialog.value = true;
  showNewDialogTitle.value = true;
  showEditDialogTitle.value = false;
};
const closeDialog = () => {
  showContactPersonDialog.value = false;
};
const saveContact = () => {
  const firstname = configDialog.form.inputs.find(x => x.labelFor === 'firstname');
  const lastname = configDialog.form.inputs.find(x => x.labelFor === 'lastname');
  const telephone = configDialog.form.inputs.find(x => x.labelFor === 'telephone');
  const mobile = configDialog.form.inputs.find(x => x.labelFor === 'mobile');
  const contactFunction = configDialog.form.inputs.find(x => x.labelFor === 'contactFunction');
  const emailAddress = configDialog.form.inputs.find(x => x.labelFor === 'emailAddress');
  const description = configDialog.form.textarea.value;
  if (configDialog.contactId === '') {
    const model = {
      stakeholderId: stakeholderId ?? null,
      firstname: firstname ? firstname.value : '',
      lastname: lastname ? lastname.value : '',
      telephone: telephone ? telephone.value : '',
      mobile: mobile ? mobile.value : '',
      contactFunction: contactFunction ? contactFunction.value : '',
      emailAddress: emailAddress ? emailAddress.value : '',
      description: description ?? ''
    };
    addNewStakeholderContact(projectId, stakeholderId, model);
  } else {
    const model = {
      id: configDialog.contactId,
      stakeholderId: stakeholderId,
      firstname: firstname ? firstname.value : '',
      lastname: lastname ? lastname.value : '',
      telephone: telephone ? telephone.value : '',
      mobile: mobile ? mobile.value : '',
      contactFunction: contactFunction ? contactFunction.value : '',
      emailAddress: emailAddress ? emailAddress.value : '',
      description: description ?? ''
    };
    updateNewStakeholderContact(projectId, stakeholderId, configDialog.contactId, model);
  }

  showContactPersonDialog.value = false;
};
const editContact = (value) => {
  configDialog.contactId = value.id;
  configDialog.stakeholderId = value.stakeholderId;
  configDialog.form.inputs.find(x => x.labelFor === 'firstname').value = value.firstname;
  configDialog.form.inputs.find(x => x.labelFor === 'lastname').value = value.lastname;
  configDialog.form.inputs.find(x => x.labelFor === 'telephone').value = value.telephone;
  configDialog.form.inputs.find(x => x.labelFor === 'mobile').value = value.mobile;
  configDialog.form.inputs.find(x => x.labelFor === 'contactFunction').value = value.contactFunction;
  configDialog.form.inputs.find(x => x.labelFor === 'emailAddress').value = value.emailAddress;
  configDialog.form.textarea.value = value.description;

  showContactPersonDialog.value = true;
  showEditDialogTitle.value = true;
  showNewDialogTitle.value = false;
};
const removeContact = (value) => {
  deleteStakeholderContact(projectId, stakeholderId, value.id);
};
const showField = (fieldName) => {
  if (!project.value.availableStakeholderFields) {
    return true;
  }
  const fields = JSON.parse(project.value.availableStakeholderFields);

  if (fields.length === 0) {
    return true;
  }

  return fields.some(x => x.FieldName === fieldName);
};

const selectedDate = ref(null);
watch(() => props.selectedDate, async (first, second) => {
  selectedDate.value = first;
  if (selectedDate.value === useMoment().format('YYYY-MM-DD')) {
    selectedDate.value = null;
  }
  await fetchStakeholder(project.value, stakeholderId, selectedDate.value);
});
</script>

<template>
  <div class="stakeholder-detail">
    <div>
      <ui-content-card
        class="stakeholder-detail__card"
        :content="contentCard">
      </ui-content-card>
    </div>
    <div class="stakeholder-detail__sub-grid">
      <div>
        <ui-review-card
          class="stakeholder-detail__card"
          :card-title="configReview.cardTitle"
          :checkbox-options="configReview.checkboxes"
          :read-only="!IsActionAllowedInRole('stakeholder.checkbox.review', currentUser, route.params.projectId)"
          @update-value="UpdateStatus"
          @submit-value="''"
          :versionDetails="(props.selectedDate === null ? null : { date: props.selectedDate, value: stakeholderVersion.concernNr, type: 'status' })">
        </ui-review-card>
        <ui-review-card
          class="stakeholder-detail__card"
          :card-title="configImportance.cardTitle"
          :checkbox-options="configImportance.checkboxes"
          :read-only="!IsActionAllowedInRole('stakeholder.checkbox.importance', currentUser, route.params.projectId)"
          @update-value="UpdateConcern"
          @submit-value="''"
          :versionDetails="(props.selectedDate === null ? null : { date: props.selectedDate, value: stakeholderVersion.statusNr, type: 'concern' })">
        </ui-review-card>
        <ui-participation-card
          class="stakeholder-detail__card"
          :card-title="configParticipation.cardTitle"
          :items="configParticipation.options"
          :option-label="configParticipation.optionLabel"
          :placeholder="configParticipation.placeholder"
          :selected-option="configParticipation.selectedValue"
          :read-only="!IsActionAllowedInRole('stakeholder.dropdown.participation', currentUser, route.params.projectId)"
          @emit-value="updateParticipationValue"
          :versionDetails="(props.selectedDate === null ? null : { date: props.selectedDate, value: stakeholderVersion.participationNr })">
        </ui-participation-card>
        <ui-stakeholders-card
          v-if="issueOverview.tabs.length > 0"
          class="mb-2"
          :card-title="issueOverview.cardTitle"
          card-type="issues"
          :tabs="issueOverview.tabs">
        </ui-stakeholders-card>
      </div>
      <div>
        <ui-map-card
          v-if="project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng && project.gisDefaultZoomLevel"
          class="stakeholder-detail__card"
          :card-title="configMap.cardTitle"
          :text-label-edit="configMap.textLabelEdit"
          :text-label-save="configMap.textLabelSave"
          :gis-default-coordinates-lat="project.gisDefaultCoordinatesLat"
          :gis-default-coordinates-lng="project.gisDefaultCoordinatesLng"
          :gis-default-zoom-level="project.gisDefaultZoomLevel"
          :screenshot-name="stakeholder.name"
          :geo-json="stakeholder.gis"
          :read-only="!IsActionAllowedInRole('stakeholder.map.edit', currentUser, route.params.projectId)"
          @emit-save="goToSaveGeo">
        </ui-map-card>
        <ui-contact-card
          v-if="showField(displayGeneralNAW)"
          :read-only="!IsActionAllowedInRole('stakeholder.contacts.edit', currentUser, route.params.projectId)"
          class="stakeholder-detail__card"
          :headings="configContact.headings"
          :buttons="configContact.buttons"
          :contact-data="configContact.contactData"
          :hideAddressesDetails="false"
          @edit-contact="editContact"
          @remove-contact="removeContact"
          @new-contact="addNewContact()">
        </ui-contact-card>
        <ui-document-history-card
          v-if="moderator != null && author != null"
          class="stakeholder-detail__card"
          :card-title="configHistory.cardTitle"
          :moderator="moderator.firstname || moderator.lastname
              ? (moderator.firstname ?? '') +
              ' ' +
              (moderator.lastname ?? '')
              : moderator.userName"
          :moderator-url="'/profile/' + moderator.id"
          :date-time="useMoment(moderator.modDate).format('DD-MM-YYYY HH:mm')"
          :moderator-info="configHistory.moderatorInfo"
          :author="author.firstname || author.lastname
              ? (author.firstname ?? '') +
              ' ' +
              (author.lastname ?? '')
              : author.userName"
          :author-url="'/profile/' + author.id"
          :subtitle-moderator="configHistory.subtitleModerator">
        </ui-document-history-card>
        <ui-files-card
          v-if="!loadingAllMediaStakeholder && allMediaStakeholder.length > 0"
          class="mb-2"
          :card-title="configFiles.cardTitle"
          :files="allMediaStakeholder">
        </ui-files-card>
        <ui-notes-card
          v-if="!loadingAllNotesStakeholder && allNotesStakeholder.length > 0"
          class="mb-2"
          :card-title="configNotes.cardTitle"
          :notes="allNotesStakeholder">
        </ui-notes-card>
      </div>
    </div>
    <p-dialog
      v-if="showField(displayGeneralNAW)"
      class="stakeholder-detail__dialog"
      position="top"
      v-model:visible="showContactPersonDialog"
      :modal="true">
      <template #header>
        <h3 v-if="showNewDialogTitle">
          {{ configDialog.titleNew }}
        </h3>
        <h3 v-if="showEditDialogTitle">
          {{ configDialog.titleEdit }}
        </h3>
      </template>
      <stakeholder-detail-dialog-form
        v-if="showField(displayGeneralNAW)"
        :form="configDialog.form"
        :contact="configDialog.contact"
        :save-button="configDialog.saveButton"
        :cancel-button="configDialog.cancelButton"
        @close-dialog="closeDialog()"
        @save="saveContact">
      </stakeholder-detail-dialog-form>
      <template #footer></template>
    </p-dialog>
  </div>
</template>

<style lang="scss">
.stakeholder-detail {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    column-gap: calc-rem(9);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 3fr 2fr;
  }

  @media (min-width: 1530px) {
    grid-template-columns: 4fr 2fr;
  }

  @media (min-width: 1665px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr;
  }

  &__sub-grid {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;

    @media (min-width: 1665px) {
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: calc-rem(9);
    }
  }

  &__card {
    margin-bottom: calc-rem(9);
  }

  &__dialog {
    min-width: calc-rem(400);

    .p-dialog {
      &-header {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        h3 {
          margin: 0;
        }
      }

      &-content {
        padding: 0;
      }
    }

    .p-inputtextarea {
      min-height: calc-rem(104);
    }
  }

  .p-card .p-card-content h2 {
    margin-bottom: 0.5625rem
  }
}
</style>
