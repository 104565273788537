<script setup>
import { ref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
// import { storeToRefs } from 'pinia';
import useMoment from 'moment';
import { onClickOutside } from '@vueuse/core';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import IssuesList from '@/modules/issueslist/IssuesList';
import PButton from 'primevue/button';
import UiDropdownMenu from '@/core/ui/UiDropdownMenu';
// import PCalendar from 'primevue/calendar';
import PCalendar from '../../../core/ui/calendar/CalendarCustom';
import PProgressSpinner from 'primevue/progressspinner';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Lifecycle hook imports
import router from '@/router';

// Variables
const { loadingProject, loadingProjectIssues, project, projectIssues } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectIssues } = useProjectsStore();
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const projectId = route.params.projectId;
const newIssueButton = t('Issue.New');
const reviewButton = t('General.JudgementValue');
const categoryButton = t('General.Category');
const sortText = t('General.SortBy');
const sortBy = ref('category');
const sortMenu = ref(false);
const showSortMenu = () => {
  sortMenu.value = !sortMenu.value;
};
const calendarValue = ref(null);
const targetCat = ref();
const downloadOptions = [
  {
    label: t('General.Frontend.DownloadPDF'),
    endpoint: 'issues/export?format=pdf',
    type: 'pdf',
    filename: project.name + '_issues.pdf'
  },
  {
    label: t('General.Frontend.DownloadWord'),
    endpoint: 'issues/export?format=doc',
    type: 'doc',
    filename: project.name + '_issues.doc'
  }
];

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const goToCreateNewIssue = () => {
  router.push({ path: '/projects/' + projectId + '/issues/create' });
};

onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  if (projectIssues.value.length === 0) {
    await fetchSingleProjectIssues(projectId);
  }
});

watch(calendarValue, async (first, second) => {
  calendarValue.value = first;
  let calendarVal = calendarValue.value === null ? null : useMoment(calendarValue.value).format('YYYY-MM-DD');
  if (calendarVal !== null) {
    if (useMoment(calendarValue.value).format('YYYY-MM-DD') === useMoment(new Date()).format('YYYY-MM-DD')) {
      calendarVal = null;
      calendarValue.value = null;
    }
  }
  if (calendarVal !== null) {
    await fetchSingleProjectIssues(projectId, calendarVal);
  } else {
    await fetchSingleProjectIssues(projectId);
  }
});

onClickOutside(targetCat, () => {
  sortMenu.value = false;
});
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="project.name">
        <template v-slot:actions>
          <p-calendar
            class="me-5"
            v-model="calendarValue"
            dateFormat="dd-mm-yy"
            :minDate="new Date(project.addDate)"
            :maxDate="new Date()"
            :show-icon="true"
            :showButtonBar="true"
            icon="pi pi-calendar">
          </p-calendar>
          <div
            ref="targetCat"
            class="position-relative me-3">
            <div class="d-flex align-items-center">
              <span class="project-issues-view__button-info me-1 d-inline-block">
                {{ sortText }}
              </span>
              <p-button
                class="project-issues-view__sort-button p-button-secondary"
                :label="sortBy === 'status' ? $t('General.JudgementValue') : $t('General.Category')"
                icon="pi pi-chevron-down"
                iconPos="right"
                @click="showSortMenu()">
              </p-button>
            </div>
            <Transition name="right-slide-fade">
              <ui-dropdown-menu
                class="project-issues-view__dropdown"
                v-show="sortMenu">
                <p-button
                  class="project-issues-view__dropdown-button w-100 p-button-text"
                  @click="sortBy = 'status'; sortMenu = false;">
                  {{ reviewButton }}
                </p-button>
                <p-button
                  class="project-issues-view__dropdown-button w-100 p-button-text"
                  @click="sortBy = 'category'; sortMenu = false;">
                  {{ categoryButton }}
                </p-button>
              </ui-dropdown-menu>
            </Transition>
          </div>
          <ui-download-buttons-dropdown
            v-if="IsActionAllowedInRole('issues.download', currentUser, route.params.projectId)"
            class="me-5"
            :project-name="project.name"
            :downloadOptions="downloadOptions">
          </ui-download-buttons-dropdown>
          <p-button
            v-if="IsActionAllowedInRole('issues.newissue', currentUser, route.params.projectId)"
            class="project-issues-view__button"
            :label="newIssueButton"
            :title="newIssueButton"
            icon="pi pi-plus"
            iconPos="left"
            @click="goToCreateNewIssue()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <p-progress-spinner
        class="project-concern-matrix-view__spinner position-absolute"
        v-if="loadingProjectIssues || loadingProject">
      </p-progress-spinner>
      <issues-list v-if="!loadingProjectIssues" :group-by="sortBy"></issues-list>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-issues-view {
  &__button {
    .p-button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__button-info {
    font-size: calc-rem(14);
    line-height: calc-rem(16);
    color: $secondaryTextColor;
  }

  &__sort-button {
    .pi {
      color: $secondaryTextColor;
      transition: transform 200ms ease-out;
    }
  }

  &__dropdown {
    &-button {
      &:not(:last-child) {
        margin-bottom: calc-rem(2);
      }

      &:hover {
        background-color: $secondary  !important;

        &.p-button-text {
          color: $textColor  !important;
        }
      }

      &.p-button-text {
        color: $textColor;
      }
    }
  }

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
