<script setup>
import PButton from 'primevue/button';
import UiAvatar from '@/core/ui/UiAvatar';

const props = defineProps({
  title: String,
  imgUrl: String
});
</script>

<template>
  <p-button class="menu-bar-avatar-button p-button-secondary d-flex">
    <ui-avatar
      class="menu-bar-avatar-button__avatar"
      :title="props.title"
      :img-url="props.imgUrl">
    </ui-avatar>
    <span class="menu-bar-avatar-button__title d-inline-block">
      {{ props.title }}
    </span>
    <span class="pi pi-chevron-down"></span>
  </p-button>
</template>

<style lang="scss" scoped>
.menu-bar-avatar-button {
  padding-top: calc-rem(6);
  padding-right: calc-rem(13);
  padding-bottom: calc-rem(6);
  padding-left: calc-rem(7);

  &:focus {
    @extend %button-focus;
  }

  &__avatar {
    margin-right: calc-rem(7);
    width: calc-rem(26);
    height: calc-rem(26);
    border-radius: $borderRadius;
    overflow: hidden;
  }

  &__title {
    padding-right: calc-rem(7);
  }

  .pi {
    color: $secondaryTextColor;
  }
}
</style>
