<script setup>
// import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Store imports
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/store/modules/global';

// Lifecycle hook imports
import { useRoute } from 'vue-router';

// Variables
const { getCurrentUserEnvironment } = storeToRefs(useGlobalStore());
const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const helpdeskUrl = route.params.projectId ? '/help/' + route.params.projectId : null;
const urlTitle = t('General.Frontend.Helpdesk');
</script>

<template>
  <footer v-if="getCurrentUserEnvironment.code !== ''" class="footer mt-21 pt-2 pb-4 d-flex justify-content-between align-items-center" :key="getCurrentUserEnvironment.code">
    <div>
      <h3 class="footer__title mt-0">
        {{  $t('General.LicensedBy') }} {{ getCurrentUserEnvironment.licenseFor }}
      </h3>
      <p class="mt-0" v-if="helpdeskUrl">
        {{  $t('General.Frontend.QuestionsTool') }} <router-link :to="helpdeskUrl" class="footer__link fw-bold">{{ urlTitle }}</router-link>
      </p>
    </div>
    <div class="footer__logo w-100 text-end">
      {{  $t('General.PoweredBy') }}
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  font-family: $fontFamily;
  font-size: calc-rem(12);
  line-height: calc-rem(14);
  border-top: calc-rem(1) solid $separatorLineColor;
  color: $secondaryTextColor;

  &__title {
    margin-bottom: calc-rem(3);
    font-weight: 600;
  }

  &__link {
    text-decoration: underline;
    color: $primaryColor;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      @extend %button-focus;
    }
  }

  &__logo {
    max-width: calc-rem(200);
  }
}
</style>
