<script setup>
// import { ref } from 'vue';

// Component imports
import StakeholdersListCard from './components/StakeholdersListCard';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports

// Variables
const { getProjectStakeholdersGroupedByStatus, getProjectStakeholdersGroupedByCategory } = storeToRefs(useProjectsStore());

const props = defineProps({
  groupBy: String
});

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
</script>

<template>
  <div class="issues-list row">
    <div class="col-12">
      <stakeholders-list-card
        :card="
          props.groupBy === 'category'
            ? getProjectStakeholdersGroupedByCategory
            : getProjectStakeholdersGroupedByStatus
        "
      >
        >
      </stakeholders-list-card>
    </div>
  </div>
</template>

<style lang="scss">
.stakeholders-list {
}
</style>
