<script setup>
import PButton from 'primevue/button';

const props = defineProps({
  title: String
});
</script>

<template>
  <p-button class="menu-bar-double-icon-button p-button-secondary d-flex align-items-center">
    <span class="menu-bar-double-icon-button__icon pi pi-clone d-inline-block"></span>
    <span class="menu-bar-double-icon-button__title d-inline-block">
      {{ props.title }}
    </span>
    <span class="pi pi-chevron-down"></span>
  </p-button>
</template>

<style lang="scss" scoped>
.menu-bar-double-icon-button {
  padding-top: calc-rem(11);
  padding-right: calc-rem(13);
  padding-bottom: calc-rem(11);
  padding-left: calc-rem(7);
  height: calc-rem(40);

  &:focus {
    @extend %button-focus;
  }

  &__icon {
    margin-right: calc-rem(7);
    width: calc-rem(14);
    height: calc-rem(14);

    &::before {
      color: $black;
    }
  }

  &__title {
    padding-right: calc-rem(7);
  }

  .pi {
    color: $secondaryTextColor;
  }
}
</style>
