<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiFileUpload from '@/core/ui/UiFileUpload';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiLabelMultiSelect from '@/core/ui/UiLabelMultiSelect';
import UiFieldset from '@/core/ui/UiFieldset';

// Store imports
import { useMediaStore } from '@/store/modules/media';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  showError: Boolean,
  deleteButton: String
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel',
  'emitDelete'
]);

const { IsActionAllowedInRole } = useRolesStore();

// Variables
const { editMedia, mediaFile } = storeToRefs(useMediaStore());
const { project, getProjectStakeholderByIdName, getProjectIssuesByIdName, loadingProjectStakeholders } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const { currentUser } = storeToRefs(useUserStore());
const isDirtyPage = ref(isDirty);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const configName = {
  labelName: t('ProjectMedia.Name'),
  labelFor: 'name'
};
const configFileUpload = {
  labelTitle: t('General.File'),
  labelFor: 'file',
  fileTypes: '',
  fileLimit: 1,
  fileSize: 15728640,
  fileUrl: '/',
  moreFiles: false,
  thumbnailWidth: 60,
  chooseLabel: t('ProjectMedia.Frontend.Select')
};
const configDescription = {
  labelName: t('ProjectMedia.Frontend.Description'),
  labelFor: 'description'
};
const configStakeholders = {
  legendTitle: t('General.Stakeholders'),
  labelTitle: t('General.Stakeholders'),
  labelFor: 'stakeholders',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectStakeholders'),
  optionLabel: 'name',
  emptyMessage: t('General.NoStakeholdersAvailable'),
  options: []
};
const configIssues = {
  legendTitle: t('General.Issues'),
  labelTitle: t('General.Issues'),
  labelFor: 'Issues',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectIssues'),
  optionLabel: 'name',
  emptyMessage: t('General.NoIssuesAvailable'),
  options: []
};
const configButtons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel')
};
const submitSave = () => {
  emit('emitSave');
};
const submitCancel = () => {
  emit('emitCancel');
};
const submitDelete = () => {
  emit('emitDelete');
};
const fieldChanged = () => {
  isDirtyPage.value = true;
};
const form = ref();
const formWidth = ref();
const getWidth = () => {
  const elem = form.value;
  const elemWidth = elem.offsetWidth;
  formWidth.value = elemWidth;
};

// Functions
onMounted(() => {
  getWidth();
});
window.addEventListener('resize', () => {
  getWidth();
});
</script>

<template>
  <div class="media-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form ref="form">
          <div class="row">
            <div class="col-12">
              <ui-label-input class="mb-5"
                :label-title="configName.labelName"
                :label-for="configName.labelFor"
                @emit-dirty="fieldChanged"
                v-model="editMedia.name"
                :showError="props.showError"
                :error-message="$t('General.MandatoryField')"
              >
              </ui-label-input>
              <ui-file-upload class="mb-6" :label-title="configFileUpload.labelTitle"
                :label-for="configFileUpload.labelFor" :file-types="configFileUpload.fileTypes"
                :file-limit="configFileUpload.fileLimit" :file-size="configFileUpload.fileSize"
                :file-url="configFileUpload.fileUrl" :more-files="configFileUpload.moreFiles"
                :thumbnail-width="configFileUpload.thumbnailWidth" :choose-label="configFileUpload.chooseLabel"
                :showError="props.showError"
                :error-message="$t('General.MandatoryField')"
                @emit-dirty="fieldChanged" v-model="mediaFile">
              </ui-file-upload>
              <p> {{ mediaFile.pictureFilename }}</p>
              <ui-label-editor class="mb-5" :label-title="configDescription.labelName"
                :label-for="configDescription.labelFor" :value-editor="editMedia.description"
                v-model="editMedia.description" @emit-dirty="fieldChanged">
              </ui-label-editor>
              <ui-fieldset
                class="mb-6 --label-multi-select"
                :title="configIssues.legendTitle">
                <ui-label-multi-select
                  :width-value="formWidth"
                  :label-title="configIssues.labelTitle"
                  :label-for="configIssues.labelFor"
                  :show-label="configIssues.showLabel"
                  :show-filter="configIssues.showFilter"
                  :placeholder="configIssues.placeholder"
                  :option-label="configIssues.optionLabel"
                  :empty-message="configIssues.emptyMessage"
                  :select-options="getProjectIssuesByIdName"
                  :selected-options="editMedia.issues"
                  @emit-dirty="fieldChanged"
                  v-model="editMedia.issues">
                </ui-label-multi-select>
              </ui-fieldset>
              <ui-fieldset
                class="mb-6 --label-multi-select legend"
                :title="configStakeholders.legendTitle">
                <ui-label-multi-select
                  :width-value="formWidth"
                  :label-title="configStakeholders.labelTitle"
                  :label-for="configStakeholders.labelFor"
                  :show-label="configStakeholders.showLabel"
                  :show-filter="configStakeholders.showFilter"
                  :placeholder="configStakeholders.placeholder"
                  :option-label="configStakeholders.optionLabel"
                  :empty-message="configStakeholders.emptyMessage"
                  :select-options="getProjectStakeholderByIdName"
                  :selected-options="editMedia.stakeholders"
                  v-model="editMedia.stakeholders"
                  @emit-dirty="fieldChanged">
                </ui-label-multi-select>
              </ui-fieldset>
            </div>
          </div>
        </form>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4"
      v-if="!loadingMedia && !loadingProjectIssues && !loadingProjectStakeholders">
      <div class="ui-save-buttons-dropdown d-flex">
        <p-button
          v-if="IsActionAllowedInRole('media.delete', currentUser, project.id)"
          class="p-button-link me-2 red"
          :label="props.deleteButton"
          @click="submitDelete()">
        </p-button>
      </div>
      <ui-save-buttons @emit-save="submitSave()" @emit-cancel="submitCancel()" :saveButton="configButtons.saveButton"
        :cancelButton="configButtons.cancelButton">
      </ui-save-buttons>
    </div>
  </div>
</template>

<style lang="scss">
.media {
  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}
.--label-multi-select legend {
  font-size: calc-rem(16)!important;
  font-weight: 600!important;
  line-height: 1rem!important;
  color: #2B323B!important;
  margin: 0!important;
}
</style>
