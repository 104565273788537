<script setup>
// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';

// Lifecycle hook imports
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

onMounted(async () => {
  await fetchSingleProject(projectId);
});

// Variables
const { loadingProject, project } = storeToRefs(useProjectsStore());
const { fetchSingleProject } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="'ProjectAllIssuesView' + project.name"></view-header>
    </template>
    <template v-slot:main>
      NIET OPPAKKEN TOT ER DUIDELIJKHEID IS!
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-all-issues-view {
}
</style>
