<script setup>
import { ref, onMounted } from 'vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-simple-map-screenshoter';
import { storeToRefs } from 'pinia';

// Component imports

// Store imports
import { useProjectsStore } from '@/store/modules/projects';

// Define props

// Define emits

// Variables
const { project, projectIssuesGeo, projectStakeholdersGeo, loadingProjectGeo } = storeToRefs(useProjectsStore());
const { fetchSingleProjectGeo } = useProjectsStore();
const displayMap = ref(true);
const map = ref(null);
const osmUrl = ref(null);
const osm = ref(null);
// const osmAttrib = ref(null);

const markerCriticism = L.icon({
  iconUrl: '/assets/images/map-marker-criticism.svg',
  shadowUrl: ''
});

const markerWarning = L.icon({
  iconUrl: '/assets/images/map-marker-warning.svg',
  shadowUrl: ''
});

const markerFocus = L.icon({
  iconUrl: '/assets/images/map-marker-focus.svg',
  shadowUrl: ''
});

const markerPositive = L.icon({
  iconUrl: '/assets/images/map-marker-positive.svg',
  shadowUrl: ''
});

const markerUnclear = L.icon({
  iconUrl: '/assets/images/map-marker-unclear.svg',
  shadowUrl: ''
});

const getColor = (assessment) => {
  return assessment === 'criticism' ? '#E71413' : assessment === 'warning' ? '#FF7733' : assessment === 'focus' ? '#FFCA00' : assessment === 'positive' ? '#7CC839' : assessment === 'unclear' ? '#B3B3B3' : '#005FE3';
};

const style = (feature) => {
  return {
    fillColor: getColor(feature.properties.assessment),
    color: getColor(feature.properties.assessment),
    weight: 2,
    fillOpacity: 0.6
  };
};

const setOptions = (feature, layer) => {
  if (feature.properties && feature.properties.popupContent) {
    layer.bindPopup(feature.properties.popupContent, { closeButton: false, type: feature.dataType, id: feature.id });
  }
  layer.bindTooltip(feature.properties.name);
};

const options = {
  style: style,
  onEachFeature: setOptions,
  pointToLayer: (feature, latlng) => {
    if (feature.properties.shape && feature.properties.shape === 'Circle' && feature.properties.radius) {
      return new L.Circle(latlng, feature.properties.radius);
    } else if (feature.properties.shape && feature.properties.shape === 'CircleMarker') {
      return new L.CircleMarker(latlng);
    } else {
      switch (feature.properties.assessment) {
        case 'unclear':
          return new L.Marker(latlng, { icon: markerUnclear });
        case 'criticism':
          return new L.Marker(latlng, { icon: markerCriticism });
        case 'warning':
          return new L.Marker(latlng, { icon: markerWarning });
        case 'focus':
          return new L.Marker(latlng, { icon: markerFocus });
        case 'positive':
          return new L.Marker(latlng, { icon: markerPositive });
        default:
          return new L.Marker(latlng);
      }
    }
  }
};

const IssueStakeholderTree = {
  label: '<span class="ms-1">Issues en stakeholders</span>',
  selectAllCheckbox: true,
  children: []
};

// Functions
const getChildrenStatus = (geoJSON, geoLayers) => {
  const children = [];

  for (let idx = 0; idx < geoJSON.length; idx++) {
    const layer = geoLayers.getLayers().find(x => x.feature.idx === geoJSON[idx].idx);
    if (layer !== undefined) {
      const item = {
        label: '<span class="ms-1">' + geoJSON[idx].properties.name + '</span>',
        layer: layer
      };

      children.push(item);
    }
  }

  return children;
};

const getLayerStatus = (geoJSON) => {
  const children = [];
  if (geoJSON && geoJSON.features && geoJSON.features.length > 0) {
    const unclearGeo = geoJSON.features.filter(x => x.properties.assessment === 'unclear');
    const criticismGeo = geoJSON.features.filter(x => x.properties.assessment === 'criticism');
    const warningGeo = geoJSON.features.filter(x => x.properties.assessment === 'warning');
    const focusGeo = geoJSON.features.filter(x => x.properties.assessment === 'focus');
    const positiveGeo = geoJSON.features.filter(x => x.properties.assessment === 'positive');

    let typeLayers = L.geoJSON(criticismGeo, options);
    const criticism = {
      label: '<span class="ms-1 --criticism">Kritiek</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(criticismGeo, typeLayers)
    };
    if (criticism.children.length) {
      children.push(criticism);
    }

    typeLayers = L.geoJSON(warningGeo, options);
    const warning = {
      label: '<span class="ms-1 --warning">Waarschuwing</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(warningGeo, typeLayers)
    };
    if (warning.children.length) {
      children.push(warning);
    }

    typeLayers = L.geoJSON(focusGeo, options);
    const focus = {
      label: '<span class="ms-1 --focus">Aandacht</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(focusGeo, typeLayers)
    };
    if (focus.children.length) {
      children.push(focus);
    }

    typeLayers = L.geoJSON(positiveGeo, options);
    const positive = {
      label: '<span class="ms-1 --positive">Positief</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(positiveGeo, typeLayers)
    };
    if (positive.children.length) {
      children.push(positive);
    }

    typeLayers = L.geoJSON(unclearGeo, options);
    const unclear = {
      label: '<span class="ms-1 --unclear">Onduidelijk</span>',
      selectAllCheckbox: true,
      children: getChildrenStatus(unclearGeo, typeLayers)
    };
    if (unclear.children.length) {
      children.push(unclear);
    }
  }

  return children;
};

const addLayersToMap = (layers, map) => {
  for (let idx = 0; idx < layers.length; idx++) {
    layers[idx].layer.addTo(map.value);
  }
};

const initMap = () => {
  // create basemap
  osmUrl.value = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  osm.value = L.tileLayer(osmUrl.value, {
    maxZoom: 18,
    attribution: ''
  });

  // initialize map
  map.value = L.map('mapContainer', { zoomControl: false, fullscreenControl: true }).setView(new L.LatLng(project.value.gisDefaultCoordinatesLat, project.value.gisDefaultCoordinatesLng), project.value.gisDefaultZoomLevel);
  osmUrl.value = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  osm.value.addTo(map.value);

  const issues = {
    label: '<span class="ms-1">Issues</span>',
    selectAllCheckbox: true,
    children: getLayerStatus(projectIssuesGeo.value)
  };

  const stakeholders = {
    label: '<span class="ms-1">Stakeholders</span>',
    selectAllCheckbox: true,
    children: getLayerStatus(projectStakeholdersGeo.value)
  };

  if (issues.children.length) {
    IssueStakeholderTree.children.push(issues);
  }

  if (stakeholders.children.length) {
    IssueStakeholderTree.children.push(stakeholders);
  }

  // place all layers detault on map
  for (let i = 0; i < IssueStakeholderTree.children.length; i++) {
    for (let j = 0; j < IssueStakeholderTree.children[i].children.length; j++) {
      addLayersToMap(IssueStakeholderTree.children[i].children[j].children, ref(map.value));
    }
  }

  L.Icon.Default.prototype.options.iconUrl = '/assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.shadowUrl = null;

  const bounds = L.latLngBounds([]);
  map.value.eachLayer(function (layer) {
    // Note from JWB: If the layer contains a circle, the getBounds function crashes.
    // I have not found a ready solution on the web.
    // After hours of trying solutions I just put a try / catch around it.
    // There is a good chance that the map does not align properly.
    try {
      const layerBounds = layer.getBounds();
      // extend the bounds of the collection to fit the bounds of the new feature
      bounds.extend(layerBounds);
    } catch {
      //
    }
  });

  if (bounds._southWest !== undefined) {
    map.value.fitBounds(bounds);
  }

  const pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: false, // hide screen icon
    preventDownload: false, // prevent download on button click
    domtoimageOptions: {}, // see options for dom-to-image
    position: 'topleft', // position of take screen icon
    screenName: project.value.name, // string or function
    // iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
    hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
    mimeType: 'image/png', // used if format == image,
    caption: null, // string or function, added caption to bottom of screen
    captionFontSize: 15,
    captionFont: 'Arial',
    captionColor: 'black',
    captionBgColor: 'white',
    captionOffset: 5,
    // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
    // and screenshot not created
    onPixelDataFail: async function ({ node, plugin, error, mapPane, domtoimageOptions }) {
      // Solutions:
      // decrease size of map
      // or decrease zoom level
      // or remove elements with big distanses
      // and after that return image in Promise - plugin._getPixelDataOfNormalMap
      return plugin._getPixelDataOfNormalMap(domtoimageOptions);
    }
  };
  const simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(map.value);

  setTimeout(() => {
    simpleMapScreenshoter.takeScreen('image', {
      mimeType: 'image/jpeg'
    }).then(image => {
      document.getElementById('dashboard-map').classList.add('d-none');
      const img = document.createElement('img');
      img.src = image;
      document.getElementById('screenshot').prepend(img);
    }).catch(e => {
      document.getElementById('screenshot').innerText = 'Fout bij het genereren van een afbeelding: ' + e.string;
    });
  }, 1000);

  // map.value.on('popupopen js-popup', function (event) {
  //   event.stopPropagation()
  //   // map.value.originalEvent.preventDefault();
  //   if (event.popup.options && event.popup.options.type) {
  //     if (event.popup.options.type === 'issue') {
  //       gotoIssue(event.popup.options.id);
  //     } else if (event.popup.options.type === 'stakeholder') {
  //       gotoStakeholder(event.popup.options.id);
  //     }
  //   }
  // });

  // map.value.on('click', function (event) {
  //   // map.value.originalEvent.preventDefault();
  //   console.log('event:' + JSON.stringify(event));
  //   if (event.popup.options && event.popup.options.type) {
  //     if (event.popup.options.type === 'issue') {
  //       gotoIssue(event.popup.options.id);
  //     } else if (event.popup.options.type === 'stakeholder') {
  //       gotoStakeholder(event.popup.options.id);
  //     }
  //   }
  // });
};

onMounted(async () => {
  if (!project.value.gisDefaultCoordinatesLat || !project.value.gisDefaultCoordinatesLng || !project.value.gisDefaultZoomLevel) {
    console.log('properties map not set correctly');
    displayMap.value = false;
    return;
  }

  await fetchSingleProjectGeo(project.value.id);

  initMap();
});
</script>

<template>
  <div
    class="dashboard-map__map overflow-hidden w-100"
    id="screenshot">
  </div>
  <div
    id="dashboard-map"
    class="dashboard-map"
    v-if="displayMap && !loadingProjectGeo">
    <div class="dashboard-map__map overflow-hidden w-100">
      <div
        class="w-100 h-100"
        id="mapContainer">
    </div>
    </div>
  </div>
  <div
    class="dashboard-map"
    v-if="!displayMap">
    <p>Instellingen voor kaart niet juist. Zie projectbeheer</p>
  </div>
</template>

<style lang="scss">
@import 'leaflet/dist/leaflet.css';

.dashboard-map {
  &__map {
    border-radius: calc-rem(5);
    aspect-ratio: 785 / 577;
    background-color: $white;
    border: calc-rem(1) solid $separatorLineColor;
  }
}
</style>
