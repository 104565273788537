<script setup>
import useMoment from 'moment';
import { ref } from 'vue';

// Service imports
import HttpClient from '@/services/httpclient';
import { AuthService } from '@/services/auth';

// Component imports
import PButton from 'primevue/button';

// Store imports

// Lifecycle hook imports
import { useRoute } from 'vue-router';

// Define props
const props = defineProps({
  class: String,
  label: String,
  endpoint: String,
  filename: String,
  type: String,
  iconPos: String
});

// Variables
const auth = new AuthService();
const route = useRoute();
const projectId = route.params.projectId;
const spinner = ref(false);
const download = async () => {
  if (!props.type) {
    return;
  }
  spinner.value = true;
  const endpoint = 'api/v1/projects/' + projectId + '/' + props.endpoint;
  let acceptHeader = ''; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  switch (props.type) {
    case 'pdf':
      acceptHeader = 'application/pdf';
      break;
    case 'xls':
      acceptHeader = 'application/vnd.ms-excel';
      // acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'doc':
      acceptHeader = 'application/msword';
      // acceptHeader = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    default:
      acceptHeader = props.type;
  }
  try {
    const response = await HttpClient.get(
      endpoint,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: acceptHeader,
          Authorization: 'Bearer ' + await auth.getIdToken()
        }
      }
    );
    const fileName = useMoment().format('YYYYMMDDHHmmss') + '_' + props.filename;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    spinner.value = false;
  } catch (e) {
    spinner.value = false;
    console.log('download failed');
    console.error(e);
  }
};

// Functions
</script>

<template>
  <p-button
    v-if="!spinner"
    class="ui-download-button"
    :class="props.class ? props.class : 'ui-toggle-buttons__button p-button-secondary me-2'"
    icon="pi pi-download me-2"
    :iconPos="props.class ? props.class : 'left'"
    :label="props.label"
    @click="download()">
  </p-button>
  <p-button
    v-if="spinner"
    class="ui-download-button"
    :class="props.class ? props.class : 'ui-toggle-buttons__button p-button-secondary me-2'"
    icon="pi pi-spin pi-spinner me-2"
    :iconPos="props.class ? props.class : 'left'"
    :label="props.label">
  </p-button>
</template>

<style lang="scss">
.ui-download-button {
  .p-button-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
