<script setup>
import { ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

// Component imports
import PEditor from 'primevue/editor';
import UiFormLabel from './UiFormLabel';
import UiFormErrorMessage from './UiFormErrorMessage';

// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  valueEditor: String,
  showError: Boolean,
  errorMessage: String,
  // placeholder: String,
  modelValue: String
});

// Define emits
const emit = defineEmits(['update:modelValue', 'emitDirty']);

// Variables
const editor = ref(false);
const inputValue = ref(props.valueEditor);

const isFocused = ref(false);
// const onFocus = (value) => {
//   isFocused.value = value;
// };
// const showPlaceholder = () => {
//   if (props.modalValue) {
//     return true;
//   } else {
//     return false;
//   }
// };
const target = ref(null);
const onChangeText = (event) => {
  emit('update:modelValue', event.htmlValue);
  emit('emitDirty');
};

// Functions
onClickOutside(target, () => {
  editor.value = false;
});

watch(() => props.valueEditor, (val) => {
  inputValue.value = val;
});
</script>

<template>
  <div class="ui-label-editor">
    <ui-form-label
      class="mb-2"
      :label-title="props.labelTitle"
      :label-for="props.labelFor">
    </ui-form-label>
    <div :id="props.labelFor">
<!--      <button-->
<!--        type="button"-->
<!--        class="ui-label-editor__button pe-3 ps-3 w-100 text-start"-->
<!--        v-show="!editor"-->
<!--        @click="showEditor()">-->
<!--        <span-->
<!--          class="ui-label-editor__placeholder"-->
<!--          v-if="!showPlaceholder()">-->
<!--          {{ placeholder }}-->
<!--        </span>-->
<!--        <span v-if="showPlaceholder()">-->
<!--          {{ props.modelValue }}-->
<!--        </span>-->
<!--      </button>-->
      <p-editor
        class="ui-label-editor__editor"
        :class="{ 'is-focused': isFocused }"
        aria-labelledby="ui-label-editor__button"
        v-model="inputValue"
        @text-change="onChangeText($event)">
        <template #toolbar>
          <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
          <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
          <button class="ql-strike" v-tooltip.bottom="'Strike'"></button>
          <button class="ql-link" v-tooltip.bottom="'Link'"></button>
          <button class="ql-list" value="ordered" v-tooltip.bottom="'List ordered'"></button>
          <button class="ql-list" value="bullet" v-tooltip.bottom="'List bullet'"></button>
          <button class="ql-indent" value="-1" v-tooltip.bottom="'Indent -1'"></button>
          <button class="ql-indent" value="+1" v-tooltip.bottom="'Indent +1'"></button>
          <select class="ql-color" v-tooltip.bottom="'Color'"></select>
          <select class="ql-background" v-tooltip.bottom="'Background'"></select>
          <button class="ql-clean" v-tooltip.bottom="'Clean'"></button>
        </template>
      </p-editor>
    </div>
    <ui-form-error-message
      class="mt-1"
      v-if="props.showError">
      {{ props.errorMessage }}
    </ui-form-error-message>
  </div>
</template>

<style lang="scss">
.ui-label-editor {
  &__editor {
    &.is-focused {
      box-shadow: 0 0 0 2px rgba(0, 95, 227, .4);
    }
  }

  .p {
    &-editor {
      &-container {
        border-radius: $borderRadius;
        border: calc-rem(1) solid $secondaryActive;

        &:hover {
          border-color: $primary;
        }

        .ql-tooltip.ql-editing a.ql-action::after {
          content: 'Opslaan';
        }
        .ql-tooltip[data-mode=link]::before {
          content: 'Link:';
        }
      }

      &-toolbar {
        padding: $editorToolbarPadding;
        border-bottom: calc-rem(1) solid $secondaryActive  !important;
      }
    }
  }
}
</style>
