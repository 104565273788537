<script setup>
import { toRefs, ref } from 'vue';

// Component imports
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiLabelTextarea from '@/core/ui/UiLabelTextarea';
import UiSaveButtons from '@/core/ui/UiSaveButtons';

// Store imports

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  contact: Object,
  form: Object
});

// Define emits
const emit = defineEmits([
  'save', 'closeDialog'
]);

// Variables
const { form } = toRefs(props);
const errorMsgVoornaam = ref('');
const errorMsgAchternaam = ref('');
const errorMsgPhone = ref('');
const errorMsgMobile = ref('');
const errorMsgEmail = ref('');
const saveDialog = (value) => {
  let error = false;
  // voornaam
  if (form.value.inputs[0].value === '') {
    errorMsgVoornaam.value = form.value.inputs[0].errorMessage;
    error = true;
  } else {
    errorMsgVoornaam.value = '';
  }
  // achternaam
  if (form.value.inputs[1].value === '') {
    errorMsgAchternaam.value = form.value.inputs[1].errorMessage;
    error = true;
  } else {
    errorMsgAchternaam.value = '';
  }
  // phonenumbers Disabled validation by Ticket #3872
  // if (!validateTelephone(form.value.inputs[2].value)) {
  //   errorMsgPhone.value = form.value.inputs[2].errorMessage;
  //   error = true;
  // } else {
  //   errorMsgPhone.value = '';
  // }
  // if (!validateTelephone(form.value.inputs[3].value)) {
  //   errorMsgMobile.value = form.value.inputs[3].errorMessage;
  //   error = true;
  // } else {
  //   errorMsgMobile.value = '';
  // }
  // email
  if (form.value.inputs[5].value === '' || !validateEmail(form.value.inputs[5].value)) {
    errorMsgEmail.value = form.value.inputs[5].errorMessage;
    error = true;
  } else {
    errorMsgEmail.value = '';
  }
  if (!error) {
    emit('save', form.value);
  }
};
const cancelDialog = () => {
  emit('closeDialog');
};
const validateEmail = (value) => {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(value);
};
// const validateTelephone = (value) => {
//   if (value.length > 25) {
//     return false;
//   }
//   if (value.length === 0) {
//     return true;
//   }
//   const validationOutcome = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/i.test(value);
//   return validationOutcome;
// };
</script>

<template>
  <form class="stakeholder-detail-dialog-form">
    <fieldset  class="stakeholder-detail-dialog-form__fieldset m-0 pt-3 pb-2 pe-4 ps-4">
      <!-- voornaam -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[0].labelTitle"
        v-model="form.inputs[0].value"
        :label-for="form.inputs[0].labelFor"
        :error-message="errorMsgVoornaam"
        :show-error="errorMsgVoornaam.length > 0"
      >
      </ui-label-input>
      <!-- achternaam -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[1].labelTitle"
        v-model="form.inputs[1].value"
        :label-for="form.inputs[1].labelFor"
        :error-message="errorMsgAchternaam"
        :show-error="errorMsgAchternaam.length > 0"
      >
      </ui-label-input>
      <!-- Telefoon -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[2].labelTitle"
        v-model="form.inputs[2].value"
        :label-for="form.inputs[2].labelFor"
        :error-message="errorMsgPhone"
        :show-error="errorMsgPhone.length > 0"
      >
      </ui-label-input>
      <!-- Mobiel -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[3].labelTitle"
        v-model="form.inputs[3].value"
        :label-for="form.inputs[3].labelFor"
        :error-message="errorMsgMobile"
        :show-error="errorMsgMobile.length > 0"
      >
      </ui-label-input>
      <!-- Functie -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[4].labelTitle"
        v-model="form.inputs[4].value"
        :label-for="form.inputs[4].labelFor"
      >
      </ui-label-input>
      <!-- Email -->
      <ui-label-input
        class="mb-5"
        :label-title="form.inputs[5].labelTitle"
        v-model="form.inputs[5].value"
        :label-for="form.inputs[5].labelFor"
        :error-message="errorMsgEmail"
        :show-error="errorMsgEmail.length > 0"
      >
      </ui-label-input>
      <ui-label-textarea
        class="mb-5"
        :label-title="form.textarea.labelTitle"
        :label-for="form.textarea.labelFor"
        v-model="form.textarea.value">
      </ui-label-textarea>
    </fieldset>
    <ui-save-buttons
      class="pt-2 pb-1 me-2"
      :save-button="props.saveButton"
      :cancel-button="props.cancelButton"
      :hide-spinner="true"
      @emit-save="saveDialog(this)"
      @emit-cancel="cancelDialog()">
    </ui-save-buttons>
  </form>
</template>

<style lang="scss">
.stakeholder-detail-dialog-form {
  &__fieldset {
    border: none;
    border-bottom: calc-rem(1) solid $separatorLineColor;
  }

  .ui-save-buttons {
    justify-content: end;
  }
}
</style>
