<script setup>
import { onMounted, ref, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiFieldset from '@/core/ui/UiFieldset';
import UiMapCard from '@/core/ui/UiMapCard';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import UiLabelDropdownEditor from '@/core/ui/UiLabelDropdownEditor';
import UiLabelDropdownCheckboxes from '@/core/ui/UiLabelDropdownCheckboxes';
import UiLabelMultiSelect from '@/core/ui/UiLabelMultiSelect';
// import UiMap from '@/core/ui/UiMap';

// Store imports
import { useIssuesStore } from '@/store/modules/issues';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel',
  'emitDelete'
]);

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

const submitDelete = () => {
  emit('emitDelete');
};

// Define props
const props = defineProps({
  saveButton: String,
  cancelButton: String,
  deleteButton: String,
  buttonsDropdownItems: Array,
  showError: Boolean
});

// Variables
const showMore = ref(true);
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { issueToAdd, loadingProjectStakeholders, configReview, stakeholdersEdit, stakeholdersEditSelected } = storeToRefs(useIssuesStore());
const { project, getProjectIssuesGroupedByCategory } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);
const availableCategories = ref([]);
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

const configName = {
  labelName: t('General.Name'),
  labelFor: 'name'
};
const configCategory = {
  titleDropdown: t('General.Category'),
  forDropdown: 'categorie',
  optionLabelDropdown: 'name',
  placeholderDropdown: t('General.ChooseCategory'),
  optionsDropdown: [],
  titleCheckboxes: t('General.Tension'),
  forCheckboxes: 'beoordelingspanning',
  checkboxes: [
    {
      id: 0,
      labelTitle: t('Status.Onduidelijk'),
      value: 'onduidelijk',
      nameCheckboxes: 'checkboxes',
      className: '--unclear',
      checked: false
    },
    {
      id: 4,
      labelTitle: t('Status.Positief'),
      value: 'positief',
      nameCheckboxes: 'checkboxes',
      className: '--positive',
      checked: false
    },
    {
      id: 3,
      labelTitle: t('Status.Aandacht'),
      value: 'aandacht',
      nameCheckboxes: 'checkboxes',
      className: '--focus',
      checked: false
    },
    {
      id: 2,
      labelTitle: t('Status.Waarschuwing'),
      value: 'waarschuwing',
      nameCheckboxes: 'checkboxes',
      className: '--warning',
      checked: false
    },
    {
      id: 1,
      labelTitle: t('Status.Kritiek'),
      value: 'kritiek',
      nameCheckboxes: 'checkboxes',
      className: '--criticism',
      checked: false
    }
  ]
};
const editIssueEmotion = 'Issue.Emotion';
const editIssueIntelligibility = 'Issue.Intelligibility';
const editIssueVisibility = 'Issue.Visibility';
const editIssueMediaSensitivity = 'Issue.MediaSensitivity';
const editIssueDevelopmentPhase = 'Issue.DevelopmentPhase';
const editIssueComplexity = 'Issue.Complexity';
const editIssueNarrative = 'Issue.Narrative';
const editIssueStrategy = 'Issue.Strategy';
const editIssueDiscussionText = 'Issue.DiscussionText';
const configDescription = {
  labelName: t('General.Description'),
  labelFor: 'description'
};
const configStakeholders = {
  legendTitle: t('General.Stakeholders'),
  labelTitle: t('General.Stakeholders'),
  labelFor: 'stakeholders',
  showLabel: false,
  showFilter: true,
  placeholder: t('General.SelectStakeholders'),
  optionLabel: 'name',
  emptyMessage: t('General.NoStakeholdersAvailable'),
  options: []
};
const configFeatures = {
  legendTitle: t('General.Features'),
  dropdownOptionLabel: 'name',
  features: [
    {
      id: 0,
      labelName: t('ProjectStakeholder.Emotion.Excel'),
      labelFor: 'emotie',
      fieldModel: 'issueToAdd.emotion',
      textFieldModel: 'issueToAdd.emotionText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: 0,
          name: t('General.Not')
        },
        {
          nr: 1,
          name: t('General.High')
        },
        {
          nr: 2,
          name: t('General.Low')
        }
      ]
    },
    {
      id: 1,
      labelName: t('Issue.Frontend.Intelligibility'),
      labelFor: 'begrijpelijk',
      fieldModel: 'issueToAdd.intelligibility',
      textFieldModel: 'issueToAdd.intelligibilityText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: null,
          name: ''
        },
        {
          nr: 1,
          name: t('General.Intelligibility.VeryUnderstandable')
        },
        {
          nr: 2,
          name: t('General.Intelligibility.Understandable')
        },
        {
          nr: 3,
          name: t('General.Intelligibility.ReasonableUnderstandable')
        },
        {
          nr: 4,
          name: t('General.Intelligibility.LimitedUnderstandable')
        },
        {
          nr: 5,
          name: t('General.Intelligibility.NotUnderstandable')
        }
      ]
    },
    {
      id: 2,
      labelName: t('General.Visibility'),
      labelFor: 'zichtbaarheid',
      fieldModel: 'issueToAdd.visibility',
      textFieldModel: 'issueToAdd.visibilityText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: null,
          name: ''
        },
        {
          nr: 1,
          name: t('General.Visibility.VeryVisible')
        },
        {
          nr: 2,
          name: t('General.Visibility.Visible')
        },
        {
          nr: 3,
          name: t('General.Visibility.ReasonableVisible')
        },
        {
          nr: 4,
          name: t('General.Visibility.LimitedVisible')
        },
        {
          nr: 5,
          name: t('General.Visibility.NotVisible')
        }
      ]
    },
    {
      id: 3,
      labelName: t('General.MediaSensitivity'),
      labelFor: 'mediagevoeligheid',
      labelDescription: t('General.MediaSensitivity.Description'),
      fieldModel: 'issueToAdd.mediaSensitivity',
      textFieldModel: 'issueToAdd.mediaSensitivityText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: null,
          name: ''
        },
        {
          nr: 1,
          name: t('General.MediaSensitivity.VerySensitive')
        },
        {
          nr: 2,
          name: t('General.MediaSensitivity.Sensitive')
        },
        {
          nr: 3,
          name: t('General.MediaSensitivity.ReasonableSensitive')
        },
        {
          nr: 4,
          name: t('General.MediaSensitivity.LimitedSensitive')
        },
        {
          nr: 5,
          name: t('General.MediaSensitivity.NotSensitive')
        }
      ]
    },
    {
      id: 4,
      labelName: t('General.DevelopmentStage'),
      labelFor: 'ontwikkelstadium',
      fieldModel: 'issueToAdd.developmentPhase',
      textFieldModel: 'issueToAdd.developmentPhaseText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: null,
          name: '',
          description: null
        },
        {
          nr: 1,
          name: t('General.DevelopmentStage.Acknowledgement'),
          description: t('General.DevelopmentStage.Acknowledgemen.Description')
        },
        {
          nr: 2,
          name: t('General.DevelopmentStage.PolicyFormulation'),
          description: t('General.DevelopmentStage.PolicyFormulation.Description')
        },
        {
          nr: 3,
          name: t('General.DevelopmentStage.Solution'),
          description: t('General.DevelopmentStage.Solution.Description')
        },
        {
          nr: 4,
          name: t('General.DevelopmentStage.PolicyAndManagement'),
          description: 'General.DevelopmentStage.PolicyAndManagement.Description'
        }
      ]
    },
    {
      id: 5,
      labelName: t('General.ComplexityOfSolution'),
      labelFor: 'complexiteitoplossing',
      fieldModel: 'issueToAdd.complexity',
      textFieldModel: 'issueToAdd.complexityText',
      dropdownPlaceholder: t('General.MakeAChoice'),
      dropdownLabel: 'name',
      options: [
        {
          nr: 2,
          name: t('General.ComplexityOfSolution.Extreme')
        },
        {
          nr: 3,
          name: t('General.ComplexityOfSolution.High')
        },
        {
          nr: 4,
          name: t('General.ComplexityOfSolution.Middle')
        },
        {
          nr: 5,
          name: t('General.ComplexityOfSolution.Low')
        },
        {
          nr: 6,
          name: t('General.ComplexityOfSolution.Not')
        }
      ]
    }
  ]
};
const configManage = {
  labelName: t('Issue.Narrative'),
  labelFor: 'manage'
};
const configStrategy = {
  labelName: t('Issue.Strategy'),
  labelFor: 'strategy'
};
const configQuestions = {
  labelName: t('Issue.DiscussionText'),
  labelFor: 'questions'
};
const configLocation = {
  legendTitle: t('General.Location'),
  textLabelEdit: t('General.Frontend.EditLocation'),
  textLabelSave: t('General.Frontend.SaveLocation')
};
const showMoreFeatures = () => {
  showMore.value = !showMore.value;
};
const showMoreText = () => {
  if (showMore.value === false) {
    return t('General.ShowAllFeatures');
  }
  return t('General.ShowFewerFeatures');
};
const showField = (fieldName) => {
  if (!project.value.availableIssueFields) {
    return true;
  }
  const fields = JSON.parse(project.value.availableIssueFields);

  if (fields.length === 0) {
    return true;
  }

  return fields.some(x => x.FieldName === fieldName);
};
const getAvailableIssueFields = () => {
  return project.value.availableIssueFields;
};
const fieldChanged = () => {
  isDirtyPage.value = true;
};
const getCategories = () => {
  const availableCategories = getProjectIssuesGroupedByCategory.value;
  const optionsDropdown = [];
  if (availableCategories && availableCategories.panels.length) {
    for (let i = 0; i < availableCategories.panels.length; i++) {
      if (availableCategories.panels[i].panelTitle.trim().toLowerCase() !== 'geen categorie') {
        optionsDropdown.push({ name: availableCategories.panels[i].panelTitle });
      }
    }
  }
  return optionsDropdown;
};
const goToSaveGeo = async (value) => {
  isDirtyPage.value = true;
  issueToAdd.value.gis = JSON.stringify(value);
};
const form = ref();
const formWidth = ref();
const getWidth = () => {
  const elem = form.value;
  if (elem) {
    formWidth.value = elem.offsetWidth;
  }
};

// Functions
onMounted(async () => {
  availableCategories.value = getCategories();
});
onMounted(() => {
  nextTick(() => {
    getWidth();
  });
});
window.addEventListener('resize', () => {
  getWidth();
});
</script>

<template>
  <div class="issue-edit">
    <p-card
      class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form ref="form">
          <ui-label-input
            class="mb-5"
            :label-title="configName.labelName"
            :label-for="configName.labelFor"
            :showError="props.showError"
            :error-message="$t('General.MandatoryField')"
            v-model="issueToAdd.name"
            @emit-dirty="fieldChanged">
          </ui-label-input>
          <ui-label-dropdown-checkboxes
            class="mb-5"
            :title-dropdown="configCategory.titleDropdown"
            :for-dropdown="configCategory.forDropdown"
            :options-dropdown="availableCategories"
            :options-label="configCategory.optionLabelDropdown"
            :placeholder-dropdown="configCategory.placeholderDropdown"
            :title-checkboxes="configCategory.titleCheckboxes"
            :for-checkboxes="configCategory.forCheckboxes"
            :checkbox-options="configReview.checkboxes"
            :value-category="issueToAdd.CategoryRating.selectedCategory"
            @emit-dirty="fieldChanged"
            v-model="issueToAdd.CategoryRating">
          </ui-label-dropdown-checkboxes>
          <ui-label-editor v-if="showField(editIssueStrategy)"
                           class="issue-edit__textarea mb-6"
                           :label-title="configDescription.labelName"
                           :label-for="configDescription.labelFor"
                           :value-editor="issueToAdd.description"
                           @emit-dirty="fieldChanged"
                           v-model="issueToAdd.description">
          </ui-label-editor>
          <!--            <ui-label-editor-->
          <!--            class="issue-edit__textarea mb-6"-->
          <!--            :label-title="configDescription.labelName"-->
          <!--            :label-for="configDescription.labelFor"-->
          <!--            :value-editor="issueToAdd.description"-->
          <!--            v-model="issueToAdd.description">-->
          <!--          </ui-label-editor>-->
          <ui-fieldset
            class="mb-6"
            :title="configStakeholders.legendTitle"
            v-if="!loadingProjectStakeholders">
            <ui-label-multi-select
              :width-value="formWidth"
              :label-title="configStakeholders.labelTitle"
              :label-for="configStakeholders.labelFor"
              :show-label="configStakeholders.showLabel"
              :show-filter="configStakeholders.showFilter"
              :placeholder="configStakeholders.placeholder"
              :option-label="configStakeholders.optionLabel"
              :empty-message="configStakeholders.emptyMessage"
              :select-options="stakeholdersEdit"
              :selected-options="stakeholdersEditSelected"
              :show-fields="getAvailableIssueFields"
              @emit-dirty="fieldChanged"
              v-model="issueToAdd.projectStakeholders">
            </ui-label-multi-select>
          </ui-fieldset>
          <ui-fieldset
            class="mb-1"
            :title="configFeatures.legendTitle">
            <Transition>
              <div
                v-show="showMore">
                <!-- SOMSET-721: Must not show up in new version -->
                <ui-label-dropdown-editor v-if="1 === 2 && showField(editIssueEmotion)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[0].labelName"
                                          :label-for="configFeatures.features[0].labelFor"
                                          :label-description="configFeatures.features[0].labelDescription"
                                          :dropdown-label="configFeatures.features[0].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[0].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[0].options"
                                          :value-editor="issueToAdd.emotion.text"
                                          :value-combobox="issueToAdd.emotion.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.emotion">
                </ui-label-dropdown-editor>
                <ui-label-dropdown-editor v-if="showField(editIssueIntelligibility)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[1].labelName"
                                          :label-for="configFeatures.features[1].labelFor"
                                          :label-description="configFeatures.features[1].labelDescription"
                                          :dropdown-label="configFeatures.features[1].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[1].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[1].options"
                                          :value-editor="issueToAdd.intelligibility.text"
                                          :value-combobox="issueToAdd.intelligibility.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.intelligibility">
                </ui-label-dropdown-editor>
                <ui-label-dropdown-editor v-if="showField(editIssueVisibility)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[2].labelName"
                                          :label-for="configFeatures.features[2].labelFor"
                                          :label-description="configFeatures.features[2].labelDescription"
                                          :dropdown-label="configFeatures.features[2].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[2].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[2].options"
                                          :value-editor="issueToAdd.visibility.text"
                                          :value-combobox="issueToAdd.visibility.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.visibility">
                </ui-label-dropdown-editor>
                <ui-label-dropdown-editor v-if="showField(editIssueMediaSensitivity)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[3].labelName"
                                          :label-for="configFeatures.features[3].labelFor"
                                          :label-description="configFeatures.features[3].labelDescription"
                                          :dropdown-label="configFeatures.features[3].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[3].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[3].options"
                                          :value-editor="issueToAdd.mediaSensitivity.text"
                                          :value-combobox="issueToAdd.mediaSensitivity.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.mediaSensitivity">
                </ui-label-dropdown-editor>
                <ui-label-dropdown-editor v-if="showField(editIssueDevelopmentPhase)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[4].labelName"
                                          :label-for="configFeatures.features[4].labelFor"
                                          :label-description="configFeatures.features[4].labelDescription"
                                          :dropdown-label="configFeatures.features[4].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[4].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[4].options"
                                          :value-editor="issueToAdd.developmentPhase.text"
                                          :value-combobox="issueToAdd.developmentPhase.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.developmentPhase">
                </ui-label-dropdown-editor>
                <ui-label-dropdown-editor v-if="showField(editIssueComplexity)"
                                          class="mb-5"
                                          :label-title="configFeatures.features[5].labelName"
                                          :label-for="configFeatures.features[5].labelFor"
                                          :label-description="configFeatures.features[5].labelDescription"
                                          :dropdown-label="configFeatures.features[5].dropdownLabel"
                                          :dropdown-placeholder="configFeatures.features[5].dropdownPlaceholder"
                                          :dropdown-options="configFeatures.features[5].options"
                                          :value-editor="issueToAdd.complexity.text"
                                          :value-combobox="issueToAdd.complexity.nr"
                                          @emit-dirty="fieldChanged"
                                          v-model="issueToAdd.complexity">
                </ui-label-dropdown-editor>
                <ui-label-editor v-if="showField(editIssueNarrative)"
                                 class="issue-edit__textarea mb-6"
                                 :label-title="configManage.labelName"
                                 :label-for="configManage.labelFor"
                                 :value-editor="issueToAdd.narrative"
                                 @emit-dirty="fieldChanged"
                                 v-model="issueToAdd.narrative">
                </ui-label-editor>
              </div>
            </Transition>
            <p-button
              class="issue-edit__showmore mb-6 p-button-secondary"
              :class="{ 'is-rotated': showMore }"
              :label="showMoreText()"
              icon="pi pi-chevron-down"
              iconPos="right"
              @click="showMoreFeatures()">
            </p-button>
            <ui-label-editor v-if="showField(editIssueStrategy)"
                             class="issue-edit__textarea mb-6"
                             :label-title="configStrategy.labelName"
                             :label-for="configStrategy.labelFor"
                             :value-editor="issueToAdd.strategy"
                             @emit-dirty="fieldChanged"
                             v-model="issueToAdd.strategy">
            </ui-label-editor>
            <ui-label-editor v-if="showField(editIssueDiscussionText)"
                             class="issue-edit__textarea mb-6"
                             :label-title="configQuestions.labelName"
                             :label-for="configQuestions.labelFor"
                             :value-editor="issueToAdd.discussionText"
                             @emit-dirty="fieldChanged"
                             v-model="issueToAdd.discussionText">
            </ui-label-editor>
          </ui-fieldset>
          <ui-label-editor v-for="(customField) in issueToAdd.customFieldsValues" :key="customField.FieldName"
                           class="issue-edit__textarea mb-6"
                           :label-title="customField.FieldName"
                           :label-for="customField.FieldName.trim()"
                           :value-editor="customField.Value"
                           @emit-dirty="fieldChanged"
                           v-model="customField.Value">
          </ui-label-editor>
          <ui-map-card
            v-if="project.gisDefaultCoordinatesLat && project.gisDefaultCoordinatesLng && project.gisDefaultZoomLevel"
            class="mb-6 --card"
            :card-title="configLocation.legendTitle"
            :text-label-edit="configLocation.textLabelEdit"
            :text-label-save="configLocation.textLabelSave"
            :gis-default-coordinates-lat="project.gisDefaultCoordinatesLat"
            :gis-default-coordinates-lng="project.gisDefaultCoordinatesLng"
            :gis-default-zoom-level="project.gisDefaultZoomLevel"
            :screenshot-name="issueToAdd.name"
            :geo-json="issueToAdd.gis"
            @emit-save="goToSaveGeo">
          </ui-map-card>
        </form>
      </template>
    </p-card>
    <div class="mt-4 d-flex justify-content-end">
      <div class="ui-save-buttons-dropdown d-flex">
        <p-button
          v-if="IsActionAllowedInRole('issue.delete', currentUser, project.id)"
          class="p-button-link me-2 red"
          :label="props.deleteButton"
          @click="submitDelete()">
        </p-button>
      </div>
      <ui-save-buttons-dropdown
        :items="props.buttonsDropdownItems"
        :save-button="props.saveButton"
        :cancel-button="props.cancelButton"
        @emit-save="submitSave()"
        @emit-cancel="submitCancel()">
      </ui-save-buttons-dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.issue-edit {
  &__showmore {
    .pi {
      transition: transform 200ms ease-out;
    }

    &.is-rotated {
      .pi {
        transform: rotate(180deg);
      }
    }
  }

  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}

.--card {
  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}
</style>
