<script setup>
// Define props
const props = defineProps({
  labelTitle: String,
  labelFor: String,
  optional: String
});
</script>

<template>
  <label
    class="ui-form-label d-flex align-items-center"
    :for="props.labelFor">
    <span class="d-inline-block">
      {{ props.labelTitle }}
    </span>
    <span
      class="ui-form-label__optional d-inline-block ms-2"
      v-if="props.optional">
      {{ props.optional }}
    </span>
  </label>
</template>

<style lang="scss">
.ui-form-label {
  font-size: calc-rem(14);
  font-weight: 600;
  line-height: calc-rem(16);
  color: $textColor;

  &__optional {
    font-weight: 400;
    color: $secondaryTextColor;
  }
}
</style>
