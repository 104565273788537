<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useConfirm } from 'primevue/useconfirm';
import sanitizeHtml from 'sanitize-html';
import { useI18n } from 'vue-i18n';

// Router import
import router from '@/router';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PConfirmDialog from 'primevue/confirmdialog';

// Store imports
import { useUserStore } from '@/store/modules/user';

// Variables
const { latestTermsOfUse, currentUser, isLoadingTermsOfUse } = storeToRefs(useUserStore());
const { fetchLatestTermsOfUse, acceptTermsOfUse } = useUserStore();
const confirm = useConfirm();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});

// Functions
const accept = (event) => {
  confirm.require({
    target: event.currentTarget,
    message: t('General.AcceptTerms'),
    header: t('General.Confirm'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('General.Yes'),
    rejectLabel: t('General.No'),
    accept: async () => {
      await acceptTermsOfUse();
      router.push({ path: '/' });
    },
    reject: () => {
      console.log('reject');
    }
  });
};

onMounted(async () => {
  await fetchLatestTermsOfUse();
});

// Functions
const sanitizer = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'blockquote', 'hr', 'ul', 'ol', 'li', 'p', 'pre',
      'img',
      'a', 'br', 'strong', 'em', 'span', 'sub', 'sup'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['style']
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'mailto', 'tel', 'data'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src']
  });
};
</script>

<template>
  <main class="me-terms">
    <p-card
      class="mb-7 pe-4"
      v-if="!isLoadingTermsOfUse">
      <template #content>
        <div class="me-terms__grid">
          <div class="me-terms__grid-item pt-5 ps-4">
            <div class="row" v-if="currentUser.language === 'nl'" v-html="sanitizer(latestTermsOfUse.body)"></div>
            <div class="row" v-if="currentUser.language === 'en'" v-html="sanitizer(latestTermsOfUse.bodyEn)"></div>
            <div class="row" v-html="sanitizer(t('General.AcceptTerms.Intro'))"></div>
            <div class="row">
              <div class="col-1 pb-4">
                <p-button
                  v-if="!currentUser.termsOfUseVersion || currentUser.termsOfUseVersion.length === 0 || currentUser.termsOfUseVersion !== latestTermsOfUse.id"
                  :label="$t('General.Accept')"
                  icon="pi pi-check"
                  iconPos="left"
                  @click="accept($event)">
                </p-button>
                <p-button
                  v-if="currentUser.termsOfUseVersion === latestTermsOfUse.id"
                  :label="$t('General.Accepted')"
                  disabled="disabled"
                  icon="pi pi-check"
                  iconPos="left">
                </p-button>
              </div>
              <p-confirm-dialog></p-confirm-dialog>
            </div>
          </div>
        </div>
      </template>
    </p-card>
  </main>
</template>

<style lang="scss">
.me-terms {
  &__grid,
  &__cards-grid {
    display: grid;
  }

  &__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      &:nth-child(1) {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
          border-left: calc-rem(1) solid $separatorLineColor;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
          min-height: calc-rem(139);
        }
      }

      h3,
      h4 {
        color: $secondaryTextColor;
      }

      h3 {
        margin-top: calc-rem(3);
        font-size: calc-rem(16);
        font-weight: 600;
        line-height: calc-rem(18);
      }

      h4 {
        margin-bottom: calc-rem(7);
        line-height: calc-rem(16);
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
      ul, ol {
        padding-left: calc(var(--bs-gutter-x)*.5 + calc(1.5rem));
        padding-right: calc(var(--bs-gutter-x)*.5 + calc(1.5rem));
      }
    }
  }

  &__cards-grid {
    row-gap: calc-rem(12);

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
      column-gap: calc-rem(12);
    }
  }

  &__img {
    aspect-ratio: 308 / 296;
    border-radius: $borderRadius;
    background-color: $mainBg;
    color: $secondaryTextColor;

    &--default {
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: calc-rem(150);
      opacity: .2;
      transform: translate(-50%,-50%);
    }

    img {
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .p-card-content {
    padding: 0;
  }
}
</style>
