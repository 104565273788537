<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import UiFileUpload from '@/core/ui/UiFileUpload';
// import UiDownloadButton from '@/core/ui/UiDownloadButton';
import UiSaveButtons from '@/core/ui/UiSaveButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

// Variables
const route = useRoute();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const { projectProjectStakeholderIssueAnalysis, projectProjectStakeholderIssueAnalysisFile } = storeToRefs(useProjectsStore());
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const configFileUpload = {
  labelTitle: t('General.File'),
  labelFor: 'file',
  fileTypes: '',
  fileLimit: 1,
  fileSize: 5242880,
  fileUrl: '/',
  moreFiles: false,
  thumbnailWidth: 60,
  chooseLabel: t('ProjectMedia.Frontend.Select')
};
const configButtons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel')
};
// Functions
const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};
</script>

<template>
  <div class="media-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4" v-if="IsActionAllowedInRole('projectstakeholderissueanalysisview.edit', currentUser, route.params.projectId)">
      <template #content>
        <form>
          <ui-file-upload
            class="mb-6"
            :label-title="configFileUpload.labelTitle"
            :label-for="configFileUpload.labelFor"
            :file-types="configFileUpload.fileTypes"
            :file-limit="configFileUpload.fileLimit"
            :file-size="configFileUpload.fileSize"
            :file-url="configFileUpload.fileUrl"
            :more-files="configFileUpload.moreFiles"
            :thumbnail-width="configFileUpload.thumbnailWidth"
            :choose-label="configFileUpload.chooseLabel"
            v-model="projectProjectStakeholderIssueAnalysisFile"
          >
          </ui-file-upload>
        </form>
        <iframe
          v-if="projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisFilename"
          title="Document"
          :src="'data:' + projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysisContentType + ';base64,' + projectProjectStakeholderIssueAnalysis.stakeholderIssueAnalysis"
          width="100%"
          height="800"
        ></iframe>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4" v-if="projectProjectStakeholderIssueAnalysisFile.length > 0">
      <ui-save-buttons
        @emit-save="submitSave()"
        @emit-cancel="submitCancel()"
        :saveButton="configButtons.saveButton"
        :cancelButton="configButtons.cancelButton">
      </ui-save-buttons>
    </div>
  </div>
</template>

<style lang="scss">
.media {
  .p-inputtextarea {
    min-height: calc-rem(76);
  }
}
</style>
