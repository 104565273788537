import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';

export const useMediaStore = defineStore({
  id: 'media',
  state: () => ({
    allMedia: [],
    allMediaIssue: [],
    allMediaStakeholder: [],
    loadingAllMedia: true,
    loadingAllMediaIssue: true,
    loadingAllMediaStakeholder: true,
    loadingMedia: true,
    error: null,
    media: {},
    editMedia: {},
    mediaFile: {}
  }),
  getters: {

  },
  actions: {
    async fetchMedia (projectId) {
      this.loadingAllMedia = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/media');
        this.allMedia = response.data;
        this.loadingAllMedia = false;
      } catch (err) {
        this.loadingAllMedia = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchMediaByIssue (projectId, issueId) {
      this.loadingAllMediaIssue = true;

      try {
        this.allMediaIssue = [];
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/media');
        if (response.data) {
          this.allMediaIssue = response.data.filter(x => x.issues.some(s => s.id === issueId));
        }
        this.loadingAllMediaIssue = false;
      } catch (err) {
        this.loadingAllMediaIssue = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchMediaByStakeholder (projectId, stakeholderId) {
      this.loadingAllMediaStakeholder = true;

      try {
        this.allMediaStakeholder = [];
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/media');
        if (response.data) {
          this.allMediaStakeholder = response.data.filter(x => x.stakeholders.some(s => s.id === stakeholderId));
        }
        this.loadingAllMediaStakeholder = false;
      } catch (err) {
        this.loadingAllMediaStakeholder = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchSingleMedia (projectId, mediaId) {
      this.loadingMedia = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/media/' + mediaId);
        this.editMedia = response.data;

        this.mediaFile.picture = this.editMedia.binaryContent;
        this.mediaFile.pictureFileExtension = this.editMedia.binaryContentType;
        this.mediaFile.pictureFilename = this.editMedia.binaryFilename;

        const issues = [];
        for (let i = 0; i < this.editMedia.issues.length; i++) {
          let classStatus = '--unclear';
          if (this.editMedia.issues[i].statusNr) {
            switch (this.editMedia.issues[i].statusNr) {
              case 1:
                classStatus = '--criticism';
                break;
              case 2:
                classStatus = '--warning';
                break;
              case 3:
                classStatus = '--focus';
                break;
              case 4:
                classStatus = '--positive';
                break;
              case 5:
                classStatus = '--unclear';
                break;
            }
          } else {
            classStatus = '--unclear';
          }
          issues.push({
            id: this.editMedia.issues[i].id,
            name: this.editMedia.issues[i].name,
            className: classStatus
          });
        }
        this.editMedia.issues = issues;

        const stakeholders = [];
        for (let i = 0; i < this.editMedia.stakeholders.length; i++) {
          let classStatus = '--unclear';
          if (this.editMedia.stakeholders[i].statusNr) {
            switch (this.editMedia.stakeholders[i].statusNr) {
              case 1:
                classStatus = '--criticism';
                break;
              case 2:
                classStatus = '--warning';
                break;
              case 3:
                classStatus = '--focus';
                break;
              case 4:
                classStatus = '--positive';
                break;
              case 5:
                classStatus = '--unclear';
                break;
            }
          } else {
            classStatus = '--unclear';
          }
          stakeholders.push({
            id: this.editMedia.stakeholders[i].id,
            name: this.editMedia.stakeholders[i].name,
            className: classStatus
          });
        }
        this.editMedia.stakeholders = stakeholders;

        this.loadingMedia = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingMedia = false;
      }
    },
    async fetchNewMedia (projectId) {
      this.loadingMedia = true;
      try {
        this.editMedia = {
          name: null,
          description: null,
          projectId: projectId,
          issueId: null,
          stakeholderId: null
        };

        this.mediaFile = [];
        this.loadingMedia = false;
      } catch (error) {
        this.loadingMedia = false;
        this.error = error;
      }
    },
    async updateMedia (projectId, mediaId, media, mediaFile) {
      try {
        const updateMedia = {
          id: mediaId,
          name: media.name,
          description: media.description,
          projectId: projectId,
          issues: media.issues,
          stakeholders: media.stakeholders
        };

        if (mediaFile.length > 0) {
          updateMedia.binaryContent = mediaFile[0].base64;
          updateMedia.binaryFilename = mediaFile[0].name;
          updateMedia.binaryContentType = mediaFile[0].type;
        } else {
          updateMedia.binaryContent = this.editMedia.binaryContent;
          updateMedia.binaryFilename = this.editMedia.binaryFilename;
          updateMedia.binaryContentType = this.editMedia.binaryContentType;
        }

        const response = await HttpClient.put('api/v1/projects/' + projectId + '/media/' + mediaId, updateMedia);

        this.editMedia = {};
        this.mediaFile = {};

        return response.data;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async deleteMedia (projectId, mediaId) {
      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/media/' + mediaId);
        this.editMedia = {};
        this.mediaFile = {};
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async createNewMedia (projectId, media, mediaFile) {
      try {
        if (mediaFile.length > 0) {
          media.binaryContent = mediaFile[0].base64;
          media.binaryContentType = mediaFile[0].type;
          media.binaryFilename = mediaFile[0].name;
        }
        const response = await HttpClient.post('api/v1/projects/' + projectId + '/media/', media);

        this.editMedia = {};
        this.mediaFile = {};

        return response.data;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    async updateMediaBinariesNote (projectId, noteId, mediaFiles) {
      if (mediaFiles.length === 0) {
        return false;
      }

      try {
        for (let idx = 0; idx < mediaFiles.length; idx++) {
          if (mediaFiles[idx].status === 'delete') {
            await HttpClient.delete('api/v1/projects/' + projectId + '/media/' + mediaFiles[idx].id);
          } else if (mediaFiles[idx].status === 'new') {
            const media = {
              name: mediaFiles[idx].name,
              description: '',
              binaryContent: mediaFiles[idx].base64,
              binaryContentType: mediaFiles[idx].type,
              binaryFilename: mediaFiles[idx].name,
              projectId: projectId,
              ProjectNoteId: noteId,
              issues: null,
              stakeholders: null
            };

            await HttpClient.post('api/v1/projects/' + projectId + '/media/', media);
          }
        }

        return true;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    }
  }
});
