<script setup>
import { ref } from 'vue';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiDownloadButtonsDropdown from '@/core/ui/UiDownloadButtonsDropdown';
import UiDownloadButton from '@/core/ui/UiDownloadButton';

// Store imports
import { storeToRefs } from 'pinia';
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';
import { useI18n } from 'vue-i18n';

// Lifecycle hook imports
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

onMounted(async () => {
  await fetchSingleProject(projectId);
});

// Variables
const { loadingProject, project } = storeToRefs(useProjectsStore());
const { fetchSingleProject } = useProjectsStore();
const route = useRoute();
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const downloadOptions = ref([
  {
    label: t('General.Frontend.DownloadPDF'),
    endpoint: 'contacts/pdf',
    type: 'pdf',
    filename: project.name + '_Contacts.pdf'
  },
  {
    label: t('General.Frontend.DownloadExcel'),
    endpoint: 'contacts/excel',
    type: 'xls',
    filename: project.name + '_Contacts.xls'
  }
]);
</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="$t('General.DownloadContacts')">
        <template v-slot:actions>
          <ui-download-buttons-dropdown
            :project-name="project.name"
            :downloadOptions="downloadOptions"
          ></ui-download-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <div class="mt-2">
        <ui-download-button
          label="Download Excel"
          endpoint="contacts/excel"
          :filename="project.name + '_Contacts.xls'"
          type="xls">
        </ui-download-button>
        <ui-download-button
          label="Download PDF"
          endpoint="contacts/pdf"
          :filename="project.name + '_Contacts.pdf'"
          type="pdf">
        </ui-download-button>
      </div>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
</template>

<style lang="scss">
.project-contacts-view {
}
</style>
