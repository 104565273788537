<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useConfirm } from 'primevue/useconfirm';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Components imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import IssueEdit from '@/modules/issueedit/IssueEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useIssuesStore } from '@/store/modules/issues';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Router import
import router from '@/router';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = t('Issue.AddIssue');
const { issueToAdd, loadingEditIssue } = storeToRefs(useIssuesStore());
const { fetchSingleIssueNew, addNewIssue, updateIssueGeoJson } = useIssuesStore();
const { fetchSingleProject, fetchSingleProjectIssues } = useProjectsStore();
const { project } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
// const store = useIssuesStore();
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const showError = ref(false);
const issueEditKey = ref(new Date());

const route = useRoute();
const projectId = route.params.projectId;
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
  ]
};

const cancel = () => {
  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.issueToAdd = {};
  // });
  // isDirtyPage.value = false;
  issueToAdd.value = {};
  router.push({ path: '/projects/' + projectId + '/issues/' });
};

const save = async () => {
  if (issueToAdd.value.name === undefined || issueToAdd.value.name === '') {
    showError.value = true;
    console.log('error: no name');
  } else {
    showError.value = false;
    await saveIssue(false);
  }
};

const saveNew = async () => {
  if (issueToAdd.value.name === undefined || issueToAdd.value.name === '') {
    showError.value = true;
    console.log('error: no name');
  } else {
    showError.value = false;
    await saveIssue(true);
  }
};

const saveIssue = async (newIssue) => {
  loadingEditIssue.value = true;
  const response = await addNewIssue(route.params.projectId, issueToAdd.value);
  await updateIssueGeoJson(projectId, response.id, issueToAdd.value.gis);

  await fetchSingleProjectIssues(projectId);
  isDirtyPage.value = false;

  // Object in store needs to be empty so new projects can be added
  // store.$patch((state) => {
  //   state.issueToAdd = {};
  // });

  if (newIssue) {
    issueEditKey.value = new Date();
    loadingEditIssue.value = false;
    await fetchSingleIssueNew(project.value);
    await router.push({ path: '/projects/' + route.params.projectId + '/issues/create/' });
  } else {
    await router.push({ path: '/projects/' + route.params.projectId + '/issues/' + response.id });
  }
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

onMounted(async () => {
  if (project.value.id !== projectId) {
    await fetchSingleProject(projectId);
  }
  await fetchSingleIssueNew(project.value);
  isDirtyPage.value = false;
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        loadingEditIssue.value = true;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    loadingEditIssue.value = true;
    next();
  }
});

</script>

<template>
  <base-layout class="project-issue-create-view position-relative">
    <template v-slot:header>
      <view-header
        :title="viewTitle">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <issue-edit
        :key="issueEditKey"
        v-if="!loadingEditIssue"
        :show-error="showError"
        :save-button="buttons.saveButton"
        :cancel-button="buttons.cancelButton"
        @emit-save="save()"
        @emit-cancel="cancel()"
        :buttons-dropdown-items="buttons.buttonsDropdownItems">
      </issue-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-issue-create-view__spinner position-absolute"
    v-if="loadingEditIssue">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-issue-create-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
