<script setup>
import { ref, onMounted } from 'vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
// https://github.com/geoman-io/leaflet-geoman
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-simple-map-screenshoter';

// Components imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import UiCardTitle from './UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  textLabelEdit: String,
  textLabelSave: String,
  gisDefaultCoordinatesLat: String,
  gisDefaultCoordinatesLng: String,
  gisDefaultZoomLevel: Number,
  geoJson: String,
  showSpinner: Boolean,
  screenshotName: String,
  readOnly: Boolean
});

// Define emits
const emit = defineEmits([
  'emitSave'
]);

// Variables
const editMode = ref(false);
const displayControl = ref(true);
const map = ref(null);
const osm = ref(null);
const osmUrl = ref(null);

// Functions
const buttonClickEdit = () => {
  editMode.value = true;
  map.value.pm.toggleControls();
};

const buttonClickSave = () => {
  map.value.pm.toggleControls();
  map.value.pm.disableDraw('Line');

  // Create an empty GeoJSON collection
  const geoCollection = {
    type: 'FeatureCollection',
    features: []
  };

  const allLayers = L.featureGroup();
  map.value.eachLayer((layer) => {
    if (layer instanceof L.Path || layer instanceof L.Marker || layer instanceof L.Circle || layer instanceof L.CircleMarker) {
      allLayers.addLayer(layer);
      // Create GeoJSON object from marker
      const geojson = layer.toGeoJSON();

      if (layer instanceof L.Circle) {
        geojson.properties.radius = layer.getRadius();
        geojson.properties.shape = 'Circle';
      } else if (layer instanceof L.CircleMarker) {
        geojson.properties.shape = 'CircleMarker';
      }

      // Push GeoJSON object to collection
      geoCollection.features.push(geojson);
    }
  });

  emit('emitSave', geoCollection);
  editMode.value = false;
};

const initMap = () => {
  // possible fix SOMSET-588
  // https://stackoverflow.com/a/20373342
  const mapContainer = document.getElementById('mapContainer');
  if (mapContainer) {
    mapContainer.innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
  }

  // create basemap
  osmUrl.value = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  map.value = L.map('map', {
    zoomControl: false,
    fullscreenControl: true,
    scrollWheelZoom: false,
    maxBounds: [[-90, -180], [90, 180]]
  });
  map.value.on('load move unload', (e) => console.log(e));
  map.value.setView(new L.LatLng(props.gisDefaultCoordinatesLat, props.gisDefaultCoordinatesLng), props.gisDefaultZoomLevel);

  const minZoomSetting = 2; // props.gisDefaultZoomLevel;
  const maxZoomSetting = 18;
  osm.value = L.tileLayer(osmUrl.value, {
    minZoom: minZoomSetting,
    maxZoom: maxZoomSetting,
    attribution: ''
  });
  map.value.addLayer(osm.value);

  L.Icon.Default.prototype.options.iconRetinaUrl = '/assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.iconUrl = '/assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.shadowUrl = '';

  L.control.zoom({
    zoomInTitle: 'Zoom in',
    zoomOutTitle: 'Zoom uit'
  }).addTo(map.value);

  if (props.geoJson && props.geoJson !== '') {
    L.geoJSON(JSON.parse(props.geoJson), {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.shape && feature.properties.shape === 'Circle' && feature.properties.radius) {
          return new L.Circle(latlng, feature.properties.radius);
        } if (feature.properties.shape && feature.properties.shape === 'CircleMarker') {
          return new L.CircleMarker(latlng);
        } else {
          return new L.Marker(latlng);
        }
      }
    }).addTo(map.value);
  }

  map.value.pm.addControls({
    position: 'topright',
    drawText: false,
    editMode: false
  });

  map.value.on('pm:create', ({ layer }) => {
    layer.on('pm:edit', e => {
      console.log(e);
    });
  });

  // Example: https://github.com/geoman-io/leaflet-geoman/blob/develop/src/assets/translations/en.json
  const customTranslation = {
    actions: {
      finish: 'Afronden' // Bewaren gaf de indruk dat het opgeslagen werd.
    }
  };
  map.value.pm.setLang('customName', customTranslation, 'nl');
  map.value.pm.toggleControls();

  const bounds = L.latLngBounds([]);
  map.value.eachLayer(function (layer) {
    // Note from JWB: If the layer contains a circle, the getBounds function crashes.
    // I have not found a ready solution on the web.
    // After hours of trying solutions I just put a try / catch around it.
    // There is a good chance that the map does not align properly.
    try {
      const layerBounds = layer.getBounds();
      // extend the bounds of the collection to fit the bounds of the new feature
      bounds.extend(layerBounds);
    } catch {
      //
    }
  });

  if (bounds._southWest !== undefined) {
    map.value.fitBounds(bounds);
  }

  // screenshot option
  const pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: false, // hide screen icon
    preventDownload: false, // prevent download on button click
    domtoimageOptions: {}, // see options for dom-to-image
    position: 'topleft', // position of take screen icon
    screenName: props.screenshotName ?? '', // string or function
    // iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
    hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
    mimeType: 'image/png', // used if format == image,
    caption: null, // string or function, added caption to bottom of screen
    captionFontSize: 15,
    captionFont: 'Arial',
    captionColor: 'black',
    captionBgColor: 'white',
    captionOffset: 5,
    // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
    // and screenshot not created
    onPixelDataFail: async function ({ node, plugin, error, mapPane, domtoimageOptions }) {
      // Solutions:
      // decrease size of map
      // or decrease zoom level
      // or remove elements with big distanses
      // and after that return image in Promise - plugin._getPixelDataOfNormalMap
      return plugin._getPixelDataOfNormalMap(domtoimageOptions);
    }
  };
  L.simpleMapScreenshoter(pluginOptions).addTo(map.value);
};

// Functions
onMounted(() => {
  if (!props.gisDefaultCoordinatesLat || !props.gisDefaultCoordinatesLng || !props.gisDefaultZoomLevel) {
    console.info('properties map not set correctly');
    displayControl.value = false;
    return;
  }
  initMap();
});

</script>

<template>
  <p-card class="ui-map-card" v-if="displayControl">
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <div class="ui-map-card__map position-relative w-100 overflow-hidden">
          <div class="position-absolute w-100 h-100" id="mapContainer"></div>
        </div>
        <p-button
          v-if="!props.readOnly && !editMode"
          class="mt-2 d-inline-block w-100 p-button-secondary"
          :label="props.textLabelEdit"
          icon="pi pi-pencil"
          iconPos="left"
          @click="buttonClickEdit()">
        </p-button>
        <p-button
          v-if="!props.readOnly && editMode"
          class="mt-2 d-inline-block w-100"
          :label="props.textLabelSave"
          @click="buttonClickSave()">
        </p-button>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-map-card {
  &__map {
    padding-bottom: 71.4479%;
    min-height: calc-rem(444);
    border-radius: calc-rem(5);
    border: calc-rem(1) solid $separatorLineColor;

    > div {
      top: 0;
      left: 0;
    }

    .leaflet-container {
      height: 100%;
    }
  }
}
</style>
