<script setup>
import { ref } from 'vue';
import useMoment from 'moment';

// Component imports
import PCard from 'primevue/card';
import PDropdown from 'primevue/dropdown';
import UiCardTitle from '@/core/ui/UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  items: Array,
  optionLabel: String,
  placeholder: String,
  selectedOption: Object,
  modelValue: Object,
  readOnly: Boolean,
  versionDetails: Object
});

// Define emits
const emit = defineEmits(['emitValue']);

// Variables
const emitItem = (val) => {
  emit('emitValue', val);
};
const selectedOption = ref(props.selectedOption);

// functions
const findParticipationName = (value) => {
  const participation = props.items.find(x => x.value === value);
  return participation.name;
};

</script>

<template>
  <p-card>
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <p-dropdown
          v-if="!props.readOnly"
          class="w-100"
          :options="props.items"
          :option-label="props.optionLabel"
          :placeholder="placeholder"
          scrollHeight="400px"
          v-model="selectedOption"
          @change="emitItem($event.value)">
        </p-dropdown>
        <p
          v-if="props.readOnly"
          class="w-100">
          {{ selectedOption.name }}
        </p>
        <div v-if="versionDetails">
          <p class="ui-review-card__version"><em>Op {{useMoment(versionDetails.date).format('DD-MM-YYYY')}} was de waarde:</em>
            {{findParticipationName(versionDetails.value)}}
          </p>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-participation-card {}
</style>
