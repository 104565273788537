<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useResizeEvent } from '@vueblocks/vue-use-core';
import { useI18n } from 'vue-i18n';

// Component imports
import PCard from 'primevue/card';
import PButton from 'primevue/button';
import PEditor from 'primevue/editor';
import POverlayPanel from 'primevue/overlaypanel';
import PRadioButton from 'primevue/radiobutton';
import PProgressBar from 'primevue/progressbar';
import UiScrollButtons from '@/core/ui/UiScrollButtons';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useStakeholderIssueStore } from '@/store/modules/stakeholderissue';
import { useStakeholdersStore } from '@/store/modules/stakeholders';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Define props
const props = defineProps({
  projectId: String,
  stakeholders: Object,
  issues: Object,
  projectConcernMatrix: Object
});

// Define emits

// Variables
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const { project } = storeToRefs(useProjectsStore());
const {
  updateStakeholderIssueConcernFromMatrix,
  updateStakeholderIssueConcernDescriptionFromMatrix,
  addStakeholderIssueConcern,
  removeStakeholderIssue
} = useStakeholderIssueStore();
const {
  updateStakeholderConcernFromMatrix,
  updateStakeholderParticipationFromMatrix
} = useStakeholdersStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
// const opColor = ref();
const showOverlayPanelButtons = ref(false);
const focusOverlayPanelInput = ref(false);
const onFocusOverlayPanelInput = () => {
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const onFocusOverlayPanelInputEditor = (stakeholderId, issueId) => {
  const concernText = crossPoint(stakeholderId, issueId).concernText;
  const status = crossPoint(stakeholderId, issueId).issueStakeholderConcernNr;

  if (!concernText && !status) {
    updateCrossPointConcern(5, stakeholderId, issueId);
  }
  focusOverlayPanelInput.value = true;
  showOverlayPanelButtons.value = true;
};
const onBlurOverlayPanelInput = () => {
  focusOverlayPanelInput.value = false;
};
const hideOverlayPanelButtons = () => {
  showOverlayPanelButtons.value = false;
};
// const cancelSelectColor = () => {
//   // opColor.value.hide();
//   focusOverlayPanelInput.value = false;
//   showOverlayPanelButtons.value = false;
// };
const getConcern = (concernNr) => {
  switch (concernNr) {
    case 5:
      return { className: '--unclear', name: configMatrix.tooltip.unclear };
    case 4:
      return { className: '--hardlyimportance', name: configMatrix.tooltip.hardlyimportance };
    case 3:
      return { className: '--reasonablyimportance', name: configMatrix.tooltip.reasonablyimportance };
    case 2:
      return { className: '--greatimportance', name: configMatrix.tooltip.greatimportance };
    case 1:
      return { className: '--extremlyhighimportance', name: configMatrix.tooltip.extremlyhighimportance };
    default:
      return { className: null, name: null };
  }
};
const matrix = ref();
const scrollWindow = ref();
const showScrollButtons = ref(false);
const disableRightButton = ref(false);
const disableLeftButton = ref(true);
const addShadowClass = ref(false);
const scrollValue = ref(0);
const countClicks = ref(0);
const scrollDistance = 40;
const onResize = () => {
  const matrixWidth = (matrix.value.offsetWidth);
  const windowWidth = (scrollWindow.value.offsetWidth);
  if (matrixWidth > windowWidth && matrixWidth - windowWidth > scrollDistance) {
    showScrollButtons.value = true;
  } else {
    showScrollButtons.value = false;
  }

  if (matrixWidth <= windowWidth) {
    scrollValue.value = 0;
    countClicks.value = 0;
    disableRightButton.value = false;
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }
};
const scrollRight = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value += 1;
  scrollValue.value -= scrollDistance;

  // Add class to 1st column and enable left button
  if (countClicks.value === 1) {
    disableLeftButton.value = false;
    addShadowClass.value = true;
  }
  // Disable right button on max clicks
  if (countClicks.value === maxClicks) {
    disableRightButton.value = true;
  }
};
const scrollLeft = () => {
  const currentWindowWidth = scrollWindow.value.offsetWidth;
  const currentMatrixWidth = matrix.value.offsetWidth;
  const maxClicks = Math.floor((currentMatrixWidth - currentWindowWidth) / scrollDistance);
  countClicks.value -= 1;
  scrollValue.value += scrollDistance;

  // Disable left button and remove class from 1st column
  if (countClicks.value === 0) {
    disableLeftButton.value = true;
    addShadowClass.value = false;
  }

  // Enable right button
  if (countClicks.value < maxClicks) {
    disableRightButton.value = false;
  }
};
const { dispatchResize } = useResizeEvent(onResize);
const configMatrix = {
  overlayPanel: {
    placeholder: t('General.Explanation'),
    saveButton: t('General.Save'),
    cancelButton: t('General.Camcel')
  },
  tooltip: {
    reset: t('General.Importance.No'),
    extremlyHighImportance: t('General.Importance.Extreme'),
    greatImportance: t('General.Importance.Great'),
    reasonablyImportance: t('General.Importance.Reasonable'),
    hardlyImportance: t('General.Importance.Hardly'),
    unclear: t('Status.Onduidelijk')
  }
};

// Functions
onMounted(() => {
  // TODO resize function should be triggered after loading data matrix
  // dispatchResize();
});

const strategyOption = ref([
  {
    name: '',
    value: null
  },
  {
    name: t('Participation.Informeren'),
    value: 1
  },
  {
    name: t('Participation.Communiceren'),
    value: 2
  },
  {
    name: t('Participation.Ad.hoc.betrokken'),
    value: 3
  },
  {
    name: t('Participation.Participeren'),
    value: 4
  },
  {
    name: t('Participation.Consensus'),
    value: 5
  },
  {
    name: t('Participation.Empowerment'),
    value: 6
  }
]);

const concernClassName = (concernNr) => {
  switch (concernNr) {
    case 5:
      return '--unclear';
    case 4:
      return '--hardlyimportance';
    case 3:
      return '--reasonablyimportance';
    case 2:
      return '--greatimportance';
    case 1:
      return '--extremlyhighimportance';
  }
  return '';
};
const concernText = (concernNr) => {
  switch (concernNr) {
    case 5:
      return t('Status.Onduidelijk');
    case 4:
      return t('Status.Nauwelijks.belang');
    case 3:
      return t('Status.Redelijk.belang');
    case 2:
      return t('Status.Groot.belang');
    case 1:
      return t('Status.Extreem.groot.belang');
  }
  return '';
};

const crossPoint = (stakeholderId, issueId) => {
  if (projectConcernMatrix.value && projectConcernMatrix.value.jsonModel) {
    const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderConcernName = concernText(crossPoint.issueStakeholderConcernNr);
      crossPoint.concernClassName = concernClassName(crossPoint.issueStakeholderConcernNr);
      if (!crossPoint.orgValue) {
        crossPoint.orgValue = crossPoint.concernText;
      }
      return crossPoint;
    }
  }
  return {
    issueStakeholderConcernNr: null,
    issueStakeholderConcernName: null,
    issueStakeholderImportanceNr: null,
    issueStakeholderImportanceName: null,
    concernText: null,
    concernClassName: null,
    orgValue: null
  };
};

watch(() => props.projectConcernMatrix, (first, second) => {
  // console.info('projectConcernMatrix', first);
  projectConcernMatrix.value = first;
});
watch(() => props.stakeholders, (first, second) => {
  // console.info('stakeholders', first);
  stakeholders.value = first;
});
watch(() => matrix, (first, second) => {
  dispatchResize();
});

// This collects dynamic refs for all crosspoints
const projectConcernMatrix = ref(null);
const stakeholders = ref(null);
const concern = ref([]);
const crossPoints = ref([]);
const crosspointTextareas = ref([]);
const stakeholderStrategy = ref([]);
const stakeholderStrategyLabel = ref([]);
const toggleConcern = (event, concernId) => {
  // console.info(concern, concernId);
  // console.info('concernId', crossPoints.value[concernId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectconcernmatrix.edit', currentUser.value, props.projectId)) {
    concern.value[concernId].toggle(event);
  }
};
const toggleCrossPoint = (event, crossPointId) => {
  // console.info(crossPoints, crossPointId);
  // console.info('crossPointId', crossPoints.value[crossPointId]);
  // This will toggle the crosspoint
  if (IsActionAllowedInRole('projectconcernmatrix.edit', currentUser.value, props.projectId)) {
    crossPoints.value[crossPointId].toggle(event);
  }
};
const updateStakeholderConcernValue = (value, categoryIndex, stakeholderId) => {
  if (value === -1 || value === null) {
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    // removeStakeholderIssue(props.projectId, stakeholderId);
  } else {
    // const stakeholder = stakeholders.value.panels[categoryIndex].panel.find(function (x) {
    //   return x.id === stakeholderId;
    // });
    // if (stakeholder) {
    //   stakeholder.concernNr = value;
    document.querySelector('#shconcernmain-' + stakeholderId + '').setAttribute('class', 'p-button p-component matrix__button p-0 ' + getConcern(value).className);
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderConcernFromMatrix(project.value, stakeholderId, value);
    // }
  }
};
const updateCrossPointConcern = (value, stakeholderId, issueId) => {
  if (value === -1 || value === null) {
    const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (!crossPoint || crossPoint === undefined) {
      return;
    }
    // console.info('DELETE VIA API FOR', stakeholderId, issueId);
    if (confirm(t('Product.ConcernMatrix.AreYouSure'))) {
      crossPoint.issueStakeholderConcernNr = null;
      crossPoint.concernClassName = null;
      removeStakeholderIssue(props.projectId, stakeholderId, issueId);
    }
  } else {
    const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
      return x.stakeholderId === stakeholderId && x.issueId === issueId;
    });
    if (crossPoint) {
      crossPoint.issueStakeholderConcernNr = value;
      crossPoint.concernClassName = value;
      // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      updateStakeholderIssueConcernFromMatrix(props.projectId, stakeholderId, issueId, value);
    } else {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints.push({
        stakeholderId: stakeholderId,
        issueId: issueId,
        issueStakeholderConcernNr: value
      });
      // console.info('SOFT CREATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
      addStakeholderIssueConcern(props.projectId, stakeholderId, issueId, value);
    }
  }
};
const updateCrossPointConcernDescription = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPoint = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPoint) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    updateStakeholderIssueConcernDescriptionFromMatrix(props.projectId, stakeholderId, issueId, crosspointTextareas.value[crossPointId].value);

    const indexOfObject = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].orgValue = crosspointTextareas.value[crossPointId].value;
    }
  }
  showOverlayPanelButtons.value = false;
};
const cancelCrossPointConcernDescription = (value, crossPointId, stakeholderId, issueId) => {
  // console.info(crosspointTextareas.value[crossPointId].value);
  const crossPointValue = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.find(function (x) {
    return x.stakeholderId === stakeholderId && x.issueId === issueId;
  });
  if (crossPointValue) {
    // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, issueId, value);
    const indexOfObject = projectConcernMatrix.value.jsonModel.MatrixCrossPoints.findIndex(object => {
      return object.stakeholderId === stakeholderId && object.issueId === issueId;
    });
    if (indexOfObject >= 0) {
      projectConcernMatrix.value.jsonModel.MatrixCrossPoints[indexOfObject].concernText = crossPointValue.orgValue;
    }
  }
  focusOverlayPanelInput.value = false;
  showOverlayPanelButtons.value = false;
  crossPoints.value[crossPointId].toggle();
};
const toggleStakeholderStrategy = (event, stakeholderId) => {
  stakeholderStrategy.value[stakeholderId].toggle(event);
};
const updateStakeholderStrategy = (value, valueText, stakeholderId, currentObject) => {
  document.querySelector('#strategyOptionLabel' + stakeholderId + ' .p-button-label').innerText = valueText;
  updateStakeholderParticipationFromMatrix(project.value, stakeholderId, value);
  // console.info('SOFT UPDATE WITHOUT RELOAD VIA API WITH', stakeholderId, value);
  stakeholderStrategy.value[stakeholderId].toggle(event);
};
</script>

<template>
  <main>
    <p-card
      class="concern-matrix position-relative"
      v-if="stakeholders && props.issues && projectConcernMatrix">
      <template #content>
        <div class="matrix__grid">
          <div
            class="matrix__grid-item"
            :class="{ '--shade': addShadowClass }">
            <!-- RENDER STAKEHOLDERS -->
            <div
              v-for="stakeholderCategory in stakeholders.panels"
              :key="'stakeholderCategory' + stakeholderCategory.id">
              <h3 class="matrix__stakeholder-cat mt-0 mb-0 pe-3 ps-3 d-flex align-items-center">
                <span>
                  {{ stakeholderCategory.panelTitle }}
                </span>
              </h3>
              <ul class="matrix__stakeholders mt-0 mb-0 ps-0">
                <li
                  v-for="stakeholder in stakeholderCategory.panel"
                  :key="'stakeholder' + stakeholder.id"
                  class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center"
                  id="shale_drillo"
                  role="rowheader">
                  <router-link
                    :to="'/projects/' + projectId + '/stakeholders/' + stakeholder.id"
                    :title="stakeholder.title">
                    {{ stakeholder.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="matrix__grid-item overflow-hidden">
            <div
              class="matrix__scroll-window w-100 h-100 position-relative"
              ref="scrollWindow">
              <div
                class="matrix__matrix position-absolute h-100"
                ref="matrix"
                :style="'transform: translateX( ' + scrollValue + 'px)'">
                <div
                  :class="{ '--border-right': !showScrollButtons }"
                  role="table"
                  aria-label="Belangenmatrix">
                  <div
                    class="d-flex"
                    role="row">
                    <!-- RENDER ISSUES TOP ROW -->
                    <template
                      v-for="issueCategory in props.issues.panels"
                      :key="'issueCategory' + issueCategory.id">
                      <div
                        class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category"
                        role="columnheader">
                        <span>
                          {{ issueCategory.panelTitle }}
                        </span>
                      </div>
                      <div
                        v-for="issue in issueCategory.panel"
                        class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end"
                        :key="'issue' + issue.id" role="columnheader">
                        <span>
                          <router-link
                            :to="'/projects/' + projectId + '/issues/' + issue.id"
                            :title="issue.title">
                            {{ issue.title }}
                          </router-link>
                        </span>
                      </div>
                    </template>
                    <div
                      class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category"
                      role="columnheader">
                      <span>
                        {{ $t('Stakeholder.Concern.Excel') }}
                      </span>
                    </div>
                    <!-- RENDER LAST COLUMN TOP ROW -->
                    <div class="matrix__th pt-3 pb-3 d-flex align-items-center justify-content-end --category --text-only" role="columnheader">
                      <span class="mt-auto">
                        {{ $t('Issue.Strategy') }}
                      </span>
                    </div>
                  </div>
                  <!-- RENDER SECOND ROW - FIRST STAKEHOLDERCATEGORY AND SO ON -->
                  <template
                    v-for="crosspointStakeholderCategoryPanel in props.stakeholders.panels"
                    :key="'crosspointStakeholderCategoryPanel' + crosspointStakeholderCategoryPanel.id">
                    <div
                      class="d-flex"
                      role="row">
                      <div
                        class="matrix__td --category"
                        role="cell"
                        v-for="index in props.issues.panels.length + props.issues.itemCount + 1"
                        :key="'crosspointStakeholderCategoryPanel' + index">
                      </div>
                      <div
                        class="matrix__td --strategy"
                        role="cell">
                      </div>
                    </div>
                    <div
                      v-for="(crosspointStakeholderPanel, index) in crosspointStakeholderCategoryPanel.panel" :key="'crosspointStakeholderPanel' + crosspointStakeholderPanel.id"
                      class="d-flex"
                      role="row">
                      <template
                        v-for="crosspointIssueCategoryPanel in props.issues.panels" :key="'crosspointIssueCategoryPanel' + crosspointIssueCategoryPanel.id">
                        <div
                          :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                          class="matrix__td d-flex justify-content-center align-items-center"
                          role="cell">
                        </div>
                        <div
                          v-for="crosspointIssuePanel in crosspointIssueCategoryPanel.panel"
                          :key="'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id"
                          :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                          class="matrix__td d-flex justify-content-center align-items-center"
                          role="cell"
                          :debugme-title="'crosspoint: ' + crosspointStakeholderPanel.title + '-' + crosspointIssuePanel.title + ' value: ' + crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).issueStakeholderConcernNr + ' - ' + crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).issueStakeholderConcernName + ' - ' + crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernText">
                          <p-button
                            class="matrix__button p-0"
                            :class="crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName"
                            :label="crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).issueStakeholderConcernName"
                            aria-haspopup="true"
                            :aria-controls="'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id"
                            v-tooltip.top="crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).issueStakeholderConcernName"
                            @click="toggleCrossPoint($event, 'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id)">
                          </p-button>
                          <p-overlay-panel
                            class="matrix__overlay-panel p-2"
                            :class="{ '--focused': focusOverlayPanelInput }" :ref="(el) => { crossPoints['crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id] = el; }
                            " :id="'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id"
                            @hide="hideOverlayPanelButtons()">
                            <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                              <p-button
                                class="p-button-rounded p-0 text-center --reset"
                                :class="{ '--active': crossPoint( crosspointStakeholderPanel.id, crosspointIssuePanel.id ).concernClassName == null, }"
                                label="Geen belang"
                                icon="pi pi-times"
                                v-tooltip.top="configMatrix.tooltip.reset"
                                @click="updateCrossPointConcern(null, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                              <p-button
                                class="p-button-rounded p-0 --unclear"
                                :class="{ '--active': crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName == '--unclear',}"
                                :label="configMatrix.tooltip.unclear"
                                v-tooltip.top="configMatrix.tooltip.unclear"
                                @click="updateCrossPointConcern(5, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                              <p-button
                                class="p-button-rounded p-0 --hardlyimportance"
                                :class="{ '--active': crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName == '--hardlyimportance', }" :label="configMatrix.tooltip.hardlyImportance"
                                v-tooltip.top="configMatrix.tooltip.hardlyImportance"
                                @click="updateCrossPointConcern(4, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                              <p-button
                                class="p-button-rounded p-0 --reasonablyimportance"
                                :class="{ '--active': crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName == '--reasonablyimportance', }"
                                :label="configMatrix.tooltip.reasonablyImportance"
                                v-tooltip.top="configMatrix.tooltip.reasonablyImportance"
                                @click="updateCrossPointConcern(3, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                              <p-button
                                class="p-button-rounded p-0 --greatimportance"
                                :class="{ '--active': crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName == '--greatimportance', }"
                                :label="configMatrix.tooltip.greatImportance"
                                v-tooltip.top="configMatrix.tooltip.greatImportance"
                                @click="updateCrossPointConcern(2, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                              <p-button
                                class="p-button-rounded p-0 --extremlyhighimportance"
                                :class="{ '--active': crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernClassName == '--extremlyhighimportance', }"
                                :label="configMatrix.tooltip.extremlyHighImportance"
                                v-tooltip.top="configMatrix.tooltip.extremlyHighImportance"
                                @click="updateCrossPointConcern(1, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                              </p-button>
                            </div>
                            <label
                              class="ui-form-label d-flex align-items-center my-2"
                              for="description">
                              <span class="d-inline-block">
                                Belang
                              </span>
                            </label>
                            <p-editor
                              class="mt-2"
                              :ref="(el) => { crosspointTextareas['crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id] = el; }"
                              @click="onFocusOverlayPanelInputEditor(crosspointStakeholderPanel.id, crosspointIssuePanel.id)"
                              v-model="crossPoint(crosspointStakeholderPanel.id,crosspointIssuePanel.id).concernText">
                              <template v-slot:toolbar>
                                <span class="ql-formats">
                                  <button
                                    class="ql-bold"
                                    v-tooltip.bottom="'Bold'">
                                  </button>
                                  <button
                                    class="ql-italic"
                                    v-tooltip.bottom="'Italic'">
                                  </button>
                                  <button
                                    class="ql-underline"
                                    v-tooltip.bottom="'Underline'">
                                  </button>
                                </span>
                              </template>
                            </p-editor>
                            <textarea
                              class="p-hidden"
                              :placeholder="$t('General.Explanation')"
                              :ref="(el) => { crosspointTextareas[ 'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id ] = el; }"
                              @focus="onFocusOverlayPanelInput()"
                              @blur="onBlurOverlayPanelInput()"
                              v-model="crossPoint(crosspointStakeholderPanel.id, crosspointIssuePanel.id).concernText">
                            </textarea>
                            <Transition name="fade">
                              <div
                                class="d-flex justify-content-end"
                                v-if="showOverlayPanelButtons">
                                <p-button
                                  class="matrix__overlay-panel-button p-button-text me-1"
                                  :label="configMatrix.overlayPanel.cancelButton"
                                  @mousedown="cancelCrossPointConcernDescription(null, 'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                                </p-button>
                                <p-button
                                  class="matrix__overlay-panel-button"
                                  :label="configMatrix.overlayPanel.saveButton"
                                  @mousedown="updateCrossPointConcernDescription(null, 'crosspoint-' + crosspointStakeholderPanel.id + '-' + crosspointIssuePanel.id, crosspointStakeholderPanel.id, crosspointIssuePanel.id)">
                                </p-button>
                              </div>
                            </Transition>
                          </p-overlay-panel>
                        </div>
                      </template>
                      <div
                        :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                        class="matrix__td d-flex justify-content-center align-items-center"
                        role="cell">
                        <p-button
                          class="matrix__button p-0"
                          :class="concernClassName(crosspointStakeholderPanel.concernNr)"
                          :label="$t(concernText(crosspointStakeholderPanel.concernNr))"
                          aria-haspopup="true"
                          :aria-controls="'shconcern-' + crosspointStakeholderPanel.id"
                          :id="'shconcernmain-' + crosspointStakeholderPanel.id"
                          v-tooltip.top="$t(concernText(crosspointStakeholderPanel.statusNr))"
                          @click="toggleConcern($event, 'shconcern-' + crosspointStakeholderPanel.id)">
                        </p-button>
                        <p-overlay-panel
                          class="matrix__overlay-panel p-2"
                          :class="{ '--focused': focusOverlayPanelInput }"
                          :ref="(el) => { concern['shconcern-' + crosspointStakeholderPanel.id] = el; }"
                          :id="'shconcern-' + crosspointStakeholderPanel.id"
                          @hide="hideOverlayPanelButtons()">
                          <div class="matrix__overlay-panel-buttons d-flex align-items-center w-100">
                            <p-button
                              class="p-button-rounded p-0 --unclear"
                              :class="{ '--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--unclear', }"
                              :label="configMatrix.tooltip.unclear"
                              v-tooltip.top="configMatrix.tooltip.unclear"
                              @click="updateStakeholderConcernValue(5, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --hardlyimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--hardlyimportance',}"
                              :label="configMatrix.tooltip.hardlyImportance"
                              v-tooltip.top="configMatrix.tooltip.hardlyImportance"
                              @click="updateStakeholderConcernValue(4, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --reasonablyimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--reasonablyimportance',}"
                              :label="configMatrix.tooltip.reasonablyImportance"
                              v-tooltip.top="configMatrix.tooltip.reasonablyImportance"
                              @click="updateStakeholderConcernValue(3, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --greatimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--greatimportance', }"
                              :label="configMatrix.tooltip.greatImportance"
                              v-tooltip.top="configMatrix.tooltip.greatImportance"
                              @click="updateStakeholderConcernValue(2, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                            <p-button
                              class="p-button-rounded p-0 --extremlyhighimportance"
                              :class="{'--active': getConcern(crosspointStakeholderPanel.concernNr).className == '--extremlyhighimportance',}"
                              :label="configMatrix.tooltip.extremlyHighImportance"
                              v-tooltip.top="configMatrix.tooltip.extremlyHighImportance"
                              @click="updateStakeholderConcernValue(1, crosspointStakeholderCategoryPanelIndex, crosspointStakeholderPanel.id)">
                            </p-button>
                          </div>
                        </p-overlay-panel>
                      </div>
                      <div
                        :class="index === (crosspointStakeholderCategoryPanel.panel.length - 1) ? '--last-in-cat' : ''"
                        class="matrix__td d-flex justify-content-center align-items-center"
                        role="cell">
                        <div>
                          <p-button
                            class="matrix__strategy-button p-button-text"
                            :label="crosspointStakeholderPanel.participation ? crosspointStakeholderPanel.participation.name : 'Onbekend'"
                            :ref="(el) => { stakeholderStrategyLabel[crosspointStakeholderPanel.id] = el;
  }
" :id="
  'strategyOptionLabel' +
  crosspointStakeholderPanel.id
" @click="
  toggleStakeholderStrategy(
    $event,
    crosspointStakeholderPanel.id
  )
">
                          </p-button>
                          <p-overlay-panel class="p-3" :ref="
                            (el) => {
                              stakeholderStrategy[
                                crosspointStakeholderPanel.id
                              ] = el;
                            }
                          ">
                            <ul class="matrix__strategy-list mt-0 mb-0 ps-0">
                              <li v-for="option in strategyOption" :key="
                                'strategyOption' +
                                crosspointStakeholderPanel.id +
                                option.value
                              " class="d-flex align-items-center">
                                <p-radio-button :name="
                                  'strategyOption' +
                                  crosspointStakeholderPanel.id
                                " :id="
  'strategyOption' +
  crosspointStakeholderPanel.id +
  option.value
" :value="option.value" @click="
  crosspointStakeholderPanel.participation = {
    nr: option.value,
    name: option.name,
  };
updateStakeholderStrategy(
  option.value,
  option.name,
  crosspointStakeholderPanel.id,
  crosspointStakeholderPanel.participation
);
">
                                </p-radio-button>
                                <label :for="
                                  'strategyOption' +
                                  crosspointStakeholderPanel.id +
                                  option.value
                                " class="matrix__strategy-label d-inline-block">
                                  {{ option.name }}
                                </label>
                              </li>
                            </ul>
                          </p-overlay-panel>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- RENDER LAST ROW - LAST ISSUES AND SO ON -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="matrix__grid" title="Legend">
          <div
            class="matrix__stakeholder-cat pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
            {{ $t('General.LegendImportance') }}
          </div>
          <div
            class="matrix__td d-flex justify-content-center align-items-center --legend-item --category" role="cell">
          </div>
          <template v-for="item in 5" :key="'legend-concern-' + item">
            <div
              class="matrix__stakeholders-item pe-3 ps-3 d-flex align-items-center --legend-item" role="cell">
              {{ $t(concernText(item)) }}
            </div>
            <div
              class="matrix__td d-flex justify-content-center align-items-center --legend-item" role="cell" :title="$t(concernText(item))">
              <p-button class="matrix__button p-0" :class="concernClassName(item)" :label="$t(concernText(item))"></p-button>
            </div>
          </template>
        </div>
        <Transition name="fade">
          <ui-scroll-buttons class="concern-matrix__buttons position-fixed" :right-button="disableRightButton"
            :left-button="disableLeftButton" v-if="showScrollButtons" @submit-right="scrollRight()"
            @submit-left="scrollLeft()">
          </ui-scroll-buttons>
        </Transition>
      </template>
    </p-card>
    <p-progress-bar class="concern-matrix__progressbar" :value="50" v-if="false">
    </p-progress-bar>
  </main>
</template>

<style lang="scss">
.concern-matrix {
  &__buttons {
    right: calc-rem(40);
    bottom: calc-rem(20);
    z-index: 10;
  }

  &__progressbar {
    .p-progressbar-label {
      font-size: calc-rem(12);
    }
  }

  .p-card-content {
    padding: 0;
  }

  .matrix__th {
    position: relative;
    bottom: -4px;
    left: 60px;
    transform: rotate(30deg);
  }
  .matrix__th.--strategy > span {
    transform: rotate(-30deg);
  }
}
</style>
