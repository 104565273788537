<script setup>
import { ref, reactive } from 'vue';
import { storeToRefs } from 'pinia';

// Component imports
import PCard from 'primevue/card';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import UiLabelInput from '@/core/ui/UiLabelInput';
import UiFileUpload from '@/core/ui/UiFileUpload';
import UiInputList from '@/core/ui/UiInputList';
import UiCheckboxList from '@/core/ui/UiCheckboxList';
import UiLabelEditor from '@/core/ui/UiLabelEditor';
import UiFieldset from '@/core/ui/UiFieldset';
import UiMapCardProjectEdit from '@/core/ui/UiMapCardProjectEdit';

// Store imports
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Constants import
import { ProjectConfigConstants } from '@/config/projectsConfigConstants';

// Define props
const props = defineProps({
  showError: Boolean,
  refresh: Boolean
});

// Define emits
const emit = defineEmits([
  'emitSave',
  'emitCancel'
]);

const submitSave = () => {
  emit('emitSave');
};

const submitCancel = () => {
  emit('emitCancel');
};

// Variables
const { projectToAdd, projectPicture } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const isDirtyPage = ref(isDirty);

const configName = ProjectConfigConstants.configName;
const configFileUpload = ProjectConfigConstants.configFileUpload;
const configDescription = ProjectConfigConstants.configDescription;
const configStakeholder = ProjectConfigConstants.configStakeholder;
const configIssue = ProjectConfigConstants.configIssue;
const configGisDefaultCoordinatesLat = ProjectConfigConstants.configGisDefaultCoordinatesLat;
const configGisDefaultCoordinatesLng = ProjectConfigConstants.configGisDefaultCoordinatesLng;
const configGisDefaultZoomLevel = ProjectConfigConstants.configGisDefaultZoomLevel;
const configFieldsetGis = ProjectConfigConstants.configFieldsetGis;
const configFieldsetVisibleFields = ProjectConfigConstants.configFieldsetVisibleFields;
const configFieldsetCustomFields = ProjectConfigConstants.configFieldsetCustomFields;
const configButtons = ProjectConfigConstants.configButtons;
const configIssueFieldNames = ProjectConfigConstants.issueFieldNames;
const configStakeholderFieldNames = ProjectConfigConstants.stakeholderFieldNames;
const configIssueFieldNameWithTitle = ProjectConfigConstants.issueFieldNameWithTitle;
const configStakeholderFieldNameWithTitle = ProjectConfigConstants.configStakeholderFieldNameWithTitle;
const checkboxesStakeholders = reactive(ProjectConfigConstants.checkboxesStakeholders);
const checkboxesIssues = reactive(ProjectConfigConstants.checkboxesIssues);
// const gisLat = ref(projectToAdd.value.gisDefaultCoordinatesLat);
// const gisLng = ref(projectToAdd.value.gisDefaultCoordinatesLng);
// const gisZoom = ref(projectToAdd.value.gisDefaultZoomLevel);
const gisLat = ref(projectToAdd.value.gisDefaultCoordinatesLat);
const gisLng = ref(projectToAdd.value.gisDefaultCoordinatesLng);
const gisZoom = ref(projectToAdd.value.gisDefaultZoomLevel);
// const defaultLat = ref(51.98325542289134);
// const defaultLng = ref(5.5652480849364405);
// const defaultZoom = ref(7);

const configMap = {
  cardTitle: 'Locatie'
};

// Functions
const assignIssueFields = (titles) => {
  // Filter fieldnames by matching title and put them into an array
  const availableIssueFieldNames = configIssueFieldNameWithTitle.filter(f => titles.indexOf(f.title) >= 0).map(f => f.FieldName);

  // Get issueFieldNames and set Available to true if checkbox has been checked, otherwise: keep it false
  const issueFieldNamesToAssign = configIssueFieldNames.map(f => availableIssueFieldNames.indexOf(f.FieldName) >= 0 ? { ...f, Available: true } : f);

  const availableIssueFieldNamesJsonString = JSON.stringify(issueFieldNamesToAssign);
  projectToAdd.value.availableIssueFields = availableIssueFieldNamesJsonString;
};

const assignStakeholderFields = (titles) => {
  const availableStakeholderFieldNames = configStakeholderFieldNameWithTitle.filter(f => titles.indexOf(f.title) >= 0).map(f => f.FieldName);

  const stakeholderFieldNamesToAssign = configStakeholderFieldNames.map(f => availableStakeholderFieldNames.indexOf(f.FieldName) >= 0 ? { ...f, Available: true } : f);

  const availableStakeholderFieldNamesJsonString = JSON.stringify(stakeholderFieldNamesToAssign);
  projectToAdd.value.availableStakeholderFields = availableStakeholderFieldNamesJsonString;
};

const fieldChanged = () => {
  isDirtyPage.value = true;
};

const updateGisLat = async (value) => {
  projectToAdd.value.gisDefaultCoordinatesLat = value;
  gisLat.value = value;
};

const updateGisLng = async (value) => {
  projectToAdd.value.gisDefaultCoordinatesLng = value;
  gisLng.value = value;
};

const updateGisZoom = async (value) => {
  projectToAdd.value.gisDefaultZoomLevel = value;
  gisZoom.value = value;
};
</script>

<template>
  <div class="project-edit">
    <p-card class="pt-5 pb-9 pe-4 ps-4">
      <template #content>
        <form>
          <ui-label-input class="mb-5"
            :label-title="$t(configName.labelName)"
            :label-for="configName.labelFor"
            :showError="props.showError"
            @emit-dirty="fieldChanged"
            v-model="projectToAdd.name">
          </ui-label-input>
          <ui-file-upload class="mb-6"
            :label-title="$t(configFileUpload.labelTitle)"
            :label-for="configFileUpload.labelFor"
            :file-types="configFileUpload.fileTypes"
            :file-limit="configFileUpload.fileLimit"
            :file-size="configFileUpload.fileSize"
            :file-url="configFileUpload.fileUrl"
            :more-files="configFileUpload.moreFiles"
            :thumbnail-width="configFileUpload.thumbnailWidth"
            :choose-label="$t(configFileUpload.chooseLabel)"
            @emit-dirty="fieldChanged"
            v-model="projectPicture">
          </ui-file-upload>
          <ui-label-editor
            class="mb-5"
            :label-title="$t(configDescription.labelName)"
            :label-for="configDescription.labelFor"
            :value-editor="projectToAdd.description"
            @emit-dirty="fieldChanged"
            v-model="projectToAdd.description">
          </ui-label-editor>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetCustomFields.labelName)">
            <div class="row">
              <div class="col-6">
                <ui-input-list class="mb-5"
                               :label-title="$t(configStakeholder.labelName)"
                               :label-for="configStakeholder.labelFor"
                               :default-message="$t(configStakeholder.defaultMessage)"
                               :error-message="$t(configStakeholder.errorMessage)"
                               :refresh="props.refresh"
                               @emit-dirty="fieldChanged"
                               v-model="projectToAdd.customStakeholderFields">
                </ui-input-list>
              </div>
              <div class="col-6">
                <ui-input-list class="mb-5"
                               :label-title="$t(configIssue.labelName)"
                               :label-for="configIssue.labelFor"
                               :default-message="$t(configIssue.defaultMessage)"
                               :error-message="$t(configIssue.errorMessage)"
                               :refresh="props.refresh"
                               @emit-dirty="fieldChanged"
                               v-model="projectToAdd.customIssueFields">
                </ui-input-list>
              </div>
            </div>
          </ui-fieldset>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetGis.labelName)">
            <p>{{ $t('Project.ClickMap') }}</p>
            <ui-map-card-project-edit
              class="mb-2 w-50"
              :card-title="configMap.cardTitle"
              :gis-default-coordinates-lat="projectToAdd.gisDefaultCoordinatesLat"
              :gis-default-coordinates-lng="projectToAdd.gisDefaultCoordinatesLng"
              :gis-default-zoom-level="projectToAdd.gisDefaultZoomLevel"
              @emit-lat="updateGisLat"
              @emit-lng="updateGisLng"
              @emit-zoom="updateGisZoom"
              @emit-dirty="fieldChanged">
            </ui-map-card-project-edit>
            <ui-label-input class="mb-5 p-hidden"
                            :label-title="$t(configGisDefaultCoordinatesLat.labelName)"
                            :label-for="configGisDefaultCoordinatesLat.labelFor"
                            @emit-dirty="fieldChanged"
                            v-model="gisLat">
            </ui-label-input>
            <ui-label-input class="mb-5 p-hidden"
                            :label-title="$t(configGisDefaultCoordinatesLng.labelName)"
                            :label-for="configGisDefaultCoordinatesLng.labelFor"
                            @emit-dirty="fieldChanged"
                            v-model="gisLng">
            </ui-label-input>
            <ui-label-input class="mb-5 p-hidden"
                            :label-title="$t(configGisDefaultZoomLevel.labelName)"
                            :label-for="configGisDefaultZoomLevel.labelFor"
                            @emit-dirty="fieldChanged"
                            v-model="gisZoom">
            </ui-label-input>
          </ui-fieldset>
          <ui-fieldset
            class="mb-1"
            :title="$t(configFieldsetVisibleFields.labelName)">
            <div class="row">
              <div class="col-6">
                <ui-checkbox-list
                  @emit-checkbox-list="assignStakeholderFields"
                  :refresh="props.refresh"
                  @emit-dirty="fieldChanged"
                  :checkbox-list="checkboxesStakeholders">
                </ui-checkbox-list>
              </div>
              <div class="col-6">
                <ui-checkbox-list
                  @emit-checkbox-list="assignIssueFields"
                  :refresh="props.refresh"
                  @emit-dirty="fieldChanged"
                  :checkbox-list="checkboxesIssues">
                </ui-checkbox-list>
              </div>
            </div>
          </ui-fieldset>
        </form>
      </template>
    </p-card>
    <div class="d-flex justify-content-end pt-4">
      <ui-save-buttons @emit-save="submitSave()" @emit-cancel="submitCancel()" :saveButton="$t(configButtons.saveButton)"
        :cancelButton="$t(configButtons.cancelButton)">
      </ui-save-buttons>
    </div>
  </div>
</template>

<style lang="scss">
.project-add {}
</style>
