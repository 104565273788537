<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import MediaEdit from '@/modules/mediaedit/MediaEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useMediaStore } from '@/store/modules/media';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Variables
const { editMedia, mediaFile, loadingMedia } = storeToRefs(useMediaStore());
const { updateMedia, fetchSingleMedia, fetchMedia, deleteMedia } = useMediaStore();
const { fetchSingleProjectIssues, fetchSingleProjectStakeholders } = useProjectsStore();
const { loadingProjectIssues, loadingProjectStakeholders, projectIssues, projectStakeholders } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const route = useRoute();
const showError = ref(false);
const projectId = route.params.projectId;
const mediaId = route.params.mediaId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  deleteButton: t('General.Delete'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
    // {
    //   label: 'Verwijderen',
    //   command: () => {
    //     confirmDeleteMedia();
    //   }
    // }
  ]
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const cancel = () => {
  editMedia.value = {};
  router.push({ path: '/projects/' + projectId + '/media/' + mediaId });
};

const save = async () => {
  if (editMedia.value.name === '') {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveMedia(false);
};

const saveNew = async () => {
  if (editMedia.value.name === '') {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveMedia(true);
};

const saveMedia = async (newMedia) => {
  await updateMedia(projectId, mediaId, editMedia.value, mediaFile.value);
  loadingMedia.value = true;
  await fetchMedia(projectId);
  loadingMedia.value = false;
  isDirtyPage.value = false;

  if (newMedia) {
    await router.push({ path: '/projects/' + projectId + '/media/create' });
  } else {
    await router.push({ path: '/projects/' + projectId + '/media/' + mediaId });
  }
};

const confirmDeleteMedia = () => {
  confirm.require({
    message: t('ProjectMedia.ConfirmDelete'),
    header: t('General.Confirm'),
    acceptLabel: t('General.Yes'),
    rejectLabel: t('General.No'),
    accept: async () => {
      loadingMedia.value = true;
      await deleteMedia(projectId, mediaId);
      loadingMedia.value = true;
      await fetchMedia(projectId);
      await router.push({ path: '/projects/' + projectId + '/media/' });
    },
    reject: () => {
      console.log('reject');
    }
  });
};

onMounted(async () => {
  loadingMedia.value = true;
  await fetchSingleMedia(projectId, mediaId);
  if (projectIssues.value.length === 0) {
    await fetchSingleProjectIssues(projectId);
  }
  if (projectStakeholders.value.length === 0) {
    await fetchSingleProjectStakeholders(projectId);
  }
  loadingMedia.value = false;
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        loadingMedia.value = true;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    loadingMedia.value = true;
    next();
  }
});

</script>

<template>
  <base-layout class="project-media-edit-view position-relative">
    <template v-slot:header>
      <view-header
        :title="$t('ProjectMedia.Edit')">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <media-edit
        v-if="!loadingMedia && !loadingProjectIssues && !loadingProjectStakeholders"
        :show-error="showError"
        :delete-button="buttons.deleteButton"
        @emit-save="save()"
        @emit-cancel="cancel()"
        @emit-delete="confirmDeleteMedia()">
      </media-edit>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-media-edit-view__spinner position-absolute"
    v-if="loadingMedia || loadingProjectIssues || loadingProjectStakeholders">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-media-edit-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
