<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import MediaEdit from '@/modules/mediaedit/MediaEdit';
import UiSaveButtonsDropdown from '@/core/ui/UiSaveButtonsDropdown';
import PProgressSpinner from 'primevue/progressspinner';
import PConfirmDialog from 'primevue/confirmdialog';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useMediaStore } from '@/store/modules/media';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Variables
const { editMedia, mediaFile, loadingMedia } = storeToRefs(useMediaStore());
const { createNewMedia, fetchNewMedia, fetchMedia } = useMediaStore();
const { fetchSingleProjectIssues, fetchSingleProjectStakeholders } = useProjectsStore();
const { loadingProjectIssues, loadingProjectStakeholders, projectIssues, projectStakeholders } = storeToRefs(useProjectsStore());
const { isDirty } = storeToRefs(useGlobalStore());
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const route = useRoute();
const showError = ref(false);
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttons = {
  saveButton: t('General.Save'),
  cancelButton: t('General.Cancel'),
  buttonsDropdownItems: [
    {
      label: t('General.Save'),
      command: () => {
        save();
      }
    },
    {
      label: t('General.SaveAndNew'),
      command: () => {
        saveNew();
      }
    }
  ]
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true
});

const cancel = () => {
  editMedia.value = {};
  router.push({ path: '/projects/' + projectId + '/media/' });
};

const save = async () => {
  if (editMedia.value.name === '' || Object.keys(mediaFile.value).length === 0) {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveMedia(false);
};

const saveNew = async () => {
  if (editMedia.value.name === '') {
    showError.value = true;
    return;
  }
  showError.value = false;
  await saveMedia(true);
};

const saveMedia = async (newMedia) => {
  const response = await createNewMedia(projectId, editMedia.value, mediaFile.value);
  loadingMedia.value = true;
  await fetchMedia(projectId);
  isDirtyPage.value = false;
  loadingMedia.value = false;

  if (newMedia) {
    await router.push({ path: '/projects/' + projectId + '/media/create/' });
  } else {
    await router.push({ path: '/projects/' + projectId + '/media/' + response.id });
  }
};

onMounted(async () => {
  loadingMedia.value = true;
  loadingProjectStakeholders.value = true;
  await fetchNewMedia(projectId);
  if (projectIssues.value.length === 0) {
    await fetchSingleProjectIssues(projectId);
  }
  if (projectStakeholders.value.length === 0) {
    await fetchSingleProjectStakeholders(projectId);
  }
  loadingMedia.value = false;
  loadingProjectStakeholders.value = false;
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        loadingMedia.value = true;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    loadingMedia.value = true;
    next();
  }
});

</script>

<template>
  <base-layout>
    <template v-slot:header>
      <view-header
        :title="$t('ProjectMedia.Frontend.Add')">
        <template v-slot:actions>
          <ui-save-buttons-dropdown
            :items="buttons.buttonsDropdownItems"
            :save-button="buttons.saveButton"
            :cancel-button="buttons.cancelButton"
            @emit-save="save()"
            @emit-cancel="cancel()">
          </ui-save-buttons-dropdown>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <media-edit
        v-if="!loadingMedia && !loadingProjectIssues && !loadingProjectStakeholders"
        :show-error="showError"
        @emit-save="save()"
        @emit-cancel="cancel()">
      </media-edit>
      <p-progress-spinner
        class="mt-10 me-auto ms-auto"
        v-if="loadingMedia || loadingProjectIssues || loadingProjectStakeholders">
      </p-progress-spinner>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
</template>

<style lang="scss">
.project-media-create-view {
}
</style>
