<script setup>
// import { ref } from 'vue';

// Components imports
import PCard from 'primevue/card';
import UiCardTitle from './UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  notes: Array
});

// Define emits
// const emit = defineEmits([]);

// Variables

// Functions
</script>

<template>
  <p-card class="ui-document-history-card">
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <div v-for="note in notes" :key="note.id">
          <p class="ui-document-history-card__info mt-0">
            <router-link class="ui-document-history-card__link" :to="'/projects/' + note.projectId + '/notes/' + note.id">
              {{ note.name }}
            </router-link>
          </p>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-document-history-card {
  font-size: calc-rem(14);

  &__subtitle {
    margin-bottom: calc-rem(7);
    line-height: calc-rem(16);
    color: $secondaryTextColor;
  }

  &__info {
    line-height: calc-rem(20);
  }

  &__link {
    text-decoration: none;
    color: $primaryColor;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
