<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import PButton from 'primevue/button';
import PProgressSpinner from 'primevue/progressspinner';
import MediaDetail from '@/modules/mediadetail/MediaDetail';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useMediaStore } from '@/store/modules/media';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const buttonLabel = t('ProjectMedia.Edit');
const { editMedia, loadingMedia } = storeToRefs(useMediaStore());
const { fetchSingleMedia } = useMediaStore();
const { IsActionAllowedInRole } = useRolesStore();
const { currentUser } = storeToRefs(useUserStore());
const route = useRoute();
const projectId = route.params.projectId;
const mediaId = route.params.mediaId;

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: true,
  toggleSideBarClass: true
});

const goToEdit = () => {
  router.push({ path: '/projects/' + projectId + '/media/' + mediaId + '/edit' });
};

onMounted(async () => {
  await fetchSingleMedia(projectId, mediaId);
});
</script>

<template>
  <base-layout class="project-media-detail-view position-relative">
    <template v-slot:header>
      <view-header
        :title="editMedia.name">
        <template v-slot:actions>
          <p-button
            v-if="IsActionAllowedInRole('media.edit', currentUser, route.params.projectId)"
            :label="buttonLabel"
            @click="goToEdit()">
          </p-button>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <media-detail  v-if="!loadingMedia">
      </media-detail>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-progress-spinner
    class="project-media-detail-view__spinner position-absolute"
    v-if="loadingMedia">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-media-detail-view {
  min-height: calc-rem(144);

  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
