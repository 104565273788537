<script setup>
import { ref, onMounted } from 'vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

// Components imports
import PCard from 'primevue/card';
import UiCardTitle from './UiCardTitle';

// Define props
const props = defineProps({
  cardTitle: String,
  gisDefaultCoordinatesLat: String,
  gisDefaultCoordinatesLng: String,
  gisDefaultZoomLevel: Number,
  showSpinner: Boolean
});

// Define emits
const emit = defineEmits([
  'emitDirty',
  'emitLat',
  'emitLng',
  'emitZoom'
]);

// Variables
const map = ref(null);
const osm = ref(null);
const osmUrl = ref(null);
const coordLat = props.gisDefaultCoordinatesLat ?? 51.98325542289134;
const coordLng = props.gisDefaultCoordinatesLng ?? 5.5652480849364405;
const zoomLevel = props.gisDefaultZoomLevel ?? 7;
const gisLat = ref(coordLat);
const gisLng = ref(coordLng);
const gisZoom = ref(zoomLevel);

// Functions
// const delay = (time) => {
//   return new Promise(resolve => setTimeout(resolve, time));
// };

const initMap = () => {
  // possible fix SOMSET-588
  // https://stackoverflow.com/a/20373342
  const mapContainer = document.getElementById('mapContainer');
  if (mapContainer) {
    mapContainer.innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
  }

  // create basemap
  osmUrl.value = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  map.value = L.map('map', {
    zoomControl: false,
    fullscreenControl: true,
    scrollWheelZoom: false,
    maxBounds: [[-90, -180], [90, 180]]
  });
  map.value.on('load move unload', (e) => console.log(e));
  map.value.setView(new L.LatLng(coordLat, coordLng), zoomLevel);
  const minZoomSetting = 2;
  const maxZoomSetting = 18;
  osm.value = L.tileLayer(osmUrl.value, {
    minZoom: minZoomSetting,
    maxZoom: maxZoomSetting,
    attribution: ''
  });
  map.value.addLayer(osm.value);

  L.Icon.Default.prototype.options.iconRetinaUrl = '/assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.iconUrl = '/assets/images/map-marker-primary.svg';
  L.Icon.Default.prototype.options.shadowUrl = '';

  L.control.zoom({
    zoomInTitle: 'Zoom in',
    zoomOutTitle: 'Zoom uit'
  }).addTo(map.value);

  let marker = null;
  if (props.gisDefaultCoordinatesLat && props.gisDefaultCoordinatesLng && props.gisDefaultZoomLevel) {
    marker = L.marker([coordLat, coordLng]).addTo(map.value);
  }

  map.value.on('click', function (e) {
    if (marker) {
      map.value.removeLayer(marker);
    }
    marker = new L.Marker(e.latlng).addTo(map.value);

    gisLat.value = e.latlng.lat;
    gisLng.value = e.latlng.lng;
    gisZoom.value = map.value.getZoom();

    emit('emitLat', gisLat.value);
    emit('emitLng', gisLng.value);
    emit('emitZoom', gisZoom.value);
    emit('emitDirty');
  });

  map.value.on('zoomend', function (e) {
    gisZoom.value = map.value.getZoom();
    emit('emitZoom', gisZoom.value);
    emit('emitDirty');
  });

  const bounds = L.latLngBounds([]);
  map.value.eachLayer(function (layer) {
    // Note from JWB: If the layer contains a circle, the getBounds function crashes.
    // I have not found a ready solution on the web.
    // After hours of trying solutions I just put a try / catch around it.
    // There is a good chance that the map does not align properly.
    try {
      const layerBounds = layer.getBounds();
      // extend the bounds of the collection to fit the bounds of the new feature
      bounds.extend(layerBounds);
    } catch {
      //
    }
  });

  if (bounds._southWest !== undefined) {
    map.value.fitBounds(bounds);
  }
};

// Functions
onMounted(async () => {
  initMap();
});

</script>

<template>
  <p-card class="ui-map-card">
    <template #content>
      <div class="pt-2 pb-2 pe-1 ps-1">
        <ui-card-title>
          {{ props.cardTitle }}
        </ui-card-title>
        <div class="ui-map-card__map position-relative w-100 overflow-hidden">
          <div class="w-100 h-100" id="mapContainer"></div>
        </div>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss">
.ui-map-card {
  &__map {
    aspect-ratio: 739 / 528;
    border-radius: calc-rem(5);
    border: calc-rem(1) solid $separatorLineColor;

    .leaflet-container {
      height: 100%;
    }
  }
}
</style>
