import { defineStore } from 'pinia';
import HttpClient from '@/services/httpclient';
import useMoment from 'moment';
import i18n from '../../../translations';

const t = (input) => {
  return i18n.global.t(input);
};

export const useNotesStore = defineStore({
  id: 'notes',
  state: () => ({
    allNotes: [],
    allNotesStakeholder: [],
    allNotesIssue: [],
    loadingAllNotes: true,
    loadingAllNotesStakeholder: true,
    loadingAllNotesIssue: true,
    loadingNote: true,
    error: null,
    note: {},
    noteFile: {},
    editNote: {},
    configNotesOverview: {
      title: t('General.ProjectNotes'),
      descriptionTitle: t('ProjectNote.Description'),
      stakeholderTitle: t('ProjectNote.Frontend.Stakeholders'),
      issueTitle: t('ProjectNote.Frontend.Issues'),
      modTitle: t('ProjectNote.Frontend.PublicationNote'),
      publishedText: t('ProjectNote.Frontend.PublicationDate'),
      hourBy: t('ProjectNote.Frontend.HourBy'),
      notes: []
    }
  }),
  getters: {
  },
  actions: {
    async fetchNotes (projectId) {
      this.loadingAllNotes = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/notes');
        this.allNotes = response.data;
        this.configNotesOverview.notes = [];
        for (let idx = 0; idx < this.allNotes.length; idx++) {
          // const modUsername = this.allNotes[idx].modUser !== null ? this.allNotes[idx].modUser.firstname + ' ' + this.allNotes[idx].modUser.lastname : '-';
          const modUsername = (this.allNotes[idx].modUser.firstname || this.allNotes[idx].modUser.lastname
            ? (this.allNotes[idx].modUser.firstname ?? '') +
            ' ' +
            (this.allNotes[idx].modUser.lastname ?? '')
            : this.allNotes[idx].modUser.userName);
          this.configNotesOverview.notes.push({
            title: this.allNotes[idx].name,
            url: '/projects/' + projectId + '/notes/' + this.allNotes[idx].id,
            description: this.allNotes[idx].description,
            publishedAt: this.allNotes[idx].modDate,
            stakeholders: this.allNotes[idx].stakeholders,
            issues: this.allNotes[idx].issues,
            user: modUsername
          });
        }

        this.loadingAllNotes = false;
      } catch (err) {
        this.loadingAllNotes = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchNotesByStakeholder (projectId, stakeholderId) {
      this.loadingAllNotesStakeholder = true;
      this.allNotesStakeholder = [];
      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/notes');
        if (response.data) {
          this.allNotesStakeholder = response.data.filter(x => x.stakeholders.some(s => s.id === stakeholderId));
        }
        this.loadingAllNotesStakeholder = false;
      } catch (err) {
        this.loadingAllNotesStakeholder = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchNotesByIssue (projectId, issueId) {
      this.loadingAllNotesIssue = true;
      this.allNotesIssue = [];
      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/notes');
        if (response.data) {
          this.allNotesIssue = response.data.filter(x => x.issues.some(s => s.id === issueId));
        }
        this.loadingAllNotesIssue = false;
      } catch (err) {
        this.loadingAllNotesIssue = false;
        console.error(err);
        this.error = err;
      }
    },
    async fetchSingleNote (projectId, noteId) {
      this.loadingNote = true;

      try {
        const response = await HttpClient.get('api/v1/projects/' + projectId + '/notes/' + noteId);
        this.editNote = response.data;
        this.editNote.startDate = useMoment(this.editNote.startDate).format('YYYY-MM-DD');

        const issues = [];
        for (let i = 0; i < this.editNote.issues.length; i++) {
          let classStatus = '--unclear';
          if (this.editNote.issues[i].statusNr) {
            switch (this.editNote.issues[i].statusNr) {
              case 1:
                classStatus = '--criticism';
                break;
              case 2:
                classStatus = '--warning';
                break;
              case 3:
                classStatus = '--focus';
                break;
              case 4:
                classStatus = '--positive';
                break;
              case 5:
                classStatus = '--unclear';
                break;
            }
          } else {
            classStatus = '--unclear';
          }
          issues.push({
            id: this.editNote.issues[i].id,
            name: this.editNote.issues[i].name,
            className: classStatus
          });
        }
        this.editNote.issues = issues;

        const stakeholders = [];
        for (let i = 0; i < this.editNote.stakeholders.length; i++) {
          let classStatus = '--unclear';
          if (this.editNote.stakeholders[i].statusNr) {
            switch (this.editNote.stakeholders[i].statusNr) {
              case 1:
                classStatus = '--criticism';
                break;
              case 2:
                classStatus = '--warning';
                break;
              case 3:
                classStatus = '--focus';
                break;
              case 4:
                classStatus = '--positive';
                break;
              case 5:
                classStatus = '--unclear';
                break;
            }
          } else {
            classStatus = '--unclear';
          }
          stakeholders.push({
            id: this.editNote.stakeholders[i].id,
            name: this.editNote.stakeholders[i].name,
            className: classStatus
          });
        }
        this.editNote.stakeholders = stakeholders;

        this.loadingNote = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingNote = false;
      }
    },
    async fetchNewNote (projectId) {
      this.loadingNote = true;

      try {
        this.editNote = {
          name: '',
          startDate: new Date(),
          description: '',
          body: '',
          attachment: null,
          attachmentFilename: null,
          attachmentContentType: null,
          projectId: projectId,
          id: null,
          stakeholderId: null
        };

        this.noteFile = [];
        this.loadingNote = false;
      } catch (error) {
        this.loadingNote = false;
        this.error = error;
      }
    },
    async updateNote (projectId, noteId, note) {
      this.loadingNote = true;

      try {
        const submitNote = {
          name: note.name,
          startDate: useMoment(note.startDate).format('YYYY-MM-DD'),
          // startDate: note.startDate,
          description: note.description,
          body: note.body,
          attachment: null,
          attachmentFilename: null,
          attachmentContentType: null,
          projectId: projectId,
          id: noteId,
          issues: note.issues,
          stakeholders: note.stakeholders
        };

        const response = await HttpClient.put('api/v1/projects/' + projectId + '/notes/' + noteId, submitNote);

        this.editNote = {};
        this.loadingNote = false;

        return response.data;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingNote = false;
      }
    },
    async deleteNote (projectId, noteId) {
      this.loadingNote = true;

      try {
        await HttpClient.delete('api/v1/projects/' + projectId + '/notes/' + noteId);

        this.editNote = {};
        this.noteFile = [];

        this.loadingNote = false;
      } catch (err) {
        console.error(err);
        this.error = err;
        this.loadingNote = false;
      }
    },
    async createNewNote (projectId, note) {
      try {
        const submitNote = {
          name: note.name,
          // startDate: useMoment(note.startDate).format('YYYY-MM-DD'),
          startDate: note.startDate,
          description: note.description,
          body: note.body,
          attachment: null,
          attachmentFilename: null,
          attachmentContentType: null,
          projectId: projectId,
          issues: note.issues,
          stakeholders: note.stakeholders
        };

        const response = await HttpClient.post('api/v1/projects/' + projectId + '/notes/', submitNote);

        this.editNote = {};

        return response.data;
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    }
  }
});
