<script setup>
import { onMounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

// Component imports
import BaseLayout from '@/modules/baselayout/BaseLayout';
import ViewHeader from '@/modules/viewheader/ViewHeader';
import ProjectCreate from '@/modules/projectcreate/ProjectCreate';
import ViewFooter from '@/modules/viewfooter/ViewFooter';
import UiSaveButtons from '@/core/ui/UiSaveButtons';
import PConfirmDialog from 'primevue/confirmdialog';
import PProgressSpinner from 'primevue/progressspinner';

// Store imports
import { useMenuBarStore } from '@/store/modules/menubar';
import { useSideBarStore } from '@/store/modules/sidebar';
import { useProjectsStore } from '@/store/modules/projects';
import { useGlobalStore } from '@/store/modules/global';

// Constants import
import { ProjectConfigConstants } from '@/config/projectsConfigConstants';

// Router import
import router from '@/router';

// Variables
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const viewTitle = t('Project.Add');
const { projectToAdd, projectPicture, loadingProject, allProjects } = storeToRefs(useProjectsStore());
const { addNewProject, fetchNewProject, fetchProjects, fetchMultipleProjectsPictures } = useProjectsStore();
const { isDirty } = storeToRefs(useGlobalStore());
// const store = useProjectsStore();
const confirm = useConfirm();
const isDirtyPage = ref(isDirty);
const showError = ref(false);
const refresh = ref(false);

const configButtons = ProjectConfigConstants.configButtons;

const cancel = () => {
  projectToAdd.value = {};
  router.push({ path: '/projects/' });
};

const save = async () => {
  loadingProject.value = true;
  if (projectToAdd.value.name === undefined || projectToAdd.value.name === '') {
    showError.value = true;
    loadingProject.value = false;
  } else {
    refresh.value = true;

    const response = await addNewProject(projectToAdd.value, projectPicture.value);
    await fetchProjects().then(async () => {
      await allProjects.value.forEach(project => {
        fetchMultipleProjectsPictures(project.id);
      });
    });
    isDirtyPage.value = false;
    refresh.value = false;
    projectToAdd.value = {};
    // Object in store needs to be empty so new projects can be added
    await router.push({ path: '/projects/' + response.id });
  }
};

// Functions
useMenuBarStore().$patch({
  showLeftButtons: true,
  showAddButton: true
});

useSideBarStore().$patch({
  showSideBar: false
});

onBeforeRouteLeave((to, from, next) => {
  if (isDirtyPage.value) {
    confirm.require({
      message: t('General.ContinueNoSave'),
      header: t('General.Confirm'),
      acceptLabel: t('General.Yes'),
      rejectLabel: t('General.No'),
      accept: async () => {
        isDirtyPage.value = false;
        next();
      },
      reject: () => {
        console.log('reject');
      }
    });
  } else {
    next();
  }
});

onMounted(async () => {
  loadingProject.value = false;
  isDirtyPage.value = false;

  await fetchNewProject();
});

</script>

<template>
  <base-layout v-if="!loadingProject">
    <template v-slot:header>
      <view-header :title="viewTitle">
        <template v-slot:actions>
          <ui-save-buttons
            @emit-save="save()"
            @emit-cancel="cancel()"
            :saveButton="$t(configButtons.saveButton)"
            :cancelButton="$t(configButtons.cancelButton)">
          </ui-save-buttons>
        </template>
      </view-header>
    </template>
    <template v-slot:main>
      <project-create
        :showError="showError"
        :refresh="refresh"
        @emit-save="save"
        @emit-cancel="cancel">
      </project-create>
    </template>
    <template v-slot:footer>
      <view-footer></view-footer>
    </template>
  </base-layout>
  <p-confirm-dialog></p-confirm-dialog>
  <p-progress-spinner
    class="project-create-view__spinner position-absolute"
    v-if="loadingProject">
  </p-progress-spinner>
</template>

<style lang="scss">
.project-create-view {
  &__spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
