<script setup>
import { ref, onMounted } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';

// Component imports
import PProgressSpinner from 'primevue/progressspinner';
import PConfirmDialog from 'primevue/confirmdialog';
import UiProjectMemberCard from '@/core/ui/UiProjectMemberCard';
import UiAddMemberCard from '@/core/ui/UiAddMemberCard';

// Store imports
import { storeToRefs } from 'pinia';
import { useProjectsStore } from '@/store/modules/projects';
import { useUserStore } from '@/store/modules/user';
import { useRolesStore } from '@/store/modules/roles';

// Lifecycle hook imports
import { useRoute } from 'vue-router';

onMounted(async () => {
  await fetchSingleProject(projectId);
  await fetchSingleProjectMembers(projectId);
});

// Variables
const { loadingProjectMembers, loadingProjectMemberAdd, projectMembers } = storeToRefs(useProjectsStore());
const { fetchSingleProject, fetchSingleProjectMembers, inviteMember, deleteMember, updateMember } = useProjectsStore();
const { currentUser } = useUserStore();
const { IsActionAllowedInRole } = useRolesStore();
const route = useRoute();
const projectId = route.params.projectId;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
});
const roles = [
  {
    nr: 0,
    name: t('Maintenance.Admin')
  },
  {
    nr: 1,
    name: t('Members.MemberTypeManage')
  },
  {
    nr: 2,
    name: t('Members.MemberTypeEdit')
  },
  {
    nr: 3,
    name: t('Members.MemberTypeReadOnly')
  }
];

const members = ref(projectMembers);
const addMemberLoad = ref(loadingProjectMemberAdd);
const member = ref();
const memberUsername = ref('');
const memberRole = ref();
const confirm = useConfirm();

const sendInvite = (value) => {
  if (value.username !== '' && value.nr !== '') {
    addMemberLoad.value = true;
    try {
      inviteMember(projectId, value.username, value.nr).then((result) => {
        if (result) {
          members.value.push(result);
        } else {
          console.log('Invite member, something went wrong. Do nothing');
        }

        memberUsername.value = '';
        memberRole.value = null;
        addMemberLoad.value = false;
      });
    } catch (err) {
      console.error('invite member:' + err);
      addMemberLoad.value = true;
    }
  }
};

const deleteProjectMember = (value) => {
  const member = members.value.find(x => x.id === value);

  confirm.require({
    message: 'Mag projectlid worden verwijderd?',
    header: t('General.Confirm'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('General.Yes'),
    rejectLabel: t('General.No'),
    accept: async () => {
      await deleteMember(projectId, member.id);
    },
    reject: () => {
      console.log('reject');
    }
  });
};

const updateMemberRole = (value) => {
  const member = members.value.find(x => x.id === value.id);
  member.role = value.role;
  updateMember(projectId, member);
};
// Functions
</script>

<template>
  <main class="members">
    <template v-if="!loadingProjectMembers">
      <div class="members__cards-grid">
        <div v-for="item in projectMembers" :key="item.id">
          <ui-project-member-card
            :card-title="
            item.user.firstname || item.user.lastname
              ? (item.user.firstname ?? '') +
              ' ' +
              (item.user.lastname ?? '')
              : item.user.userName
          " :card-url="'/profile/' + item.user.id" :mod-date="item.user.lastLoginDate" :show-updates="false"
            :detailsHeader="$t('Members.Role')"  :showImage="false" :selectRoleOptions="roles"
            :member="item"
                                  :edit-mode="IsActionAllowedInRole('project.members.edit', currentUser, route.params.projectId)"
                                  :selectRoleOptionLabel="'name'"
            @delete-Member="deleteProjectMember" @update-Member-Role="updateMemberRole">
          </ui-project-member-card>
        </div>
      </div>
      <div class="members__cards-grid mt-2" v-if="IsActionAllowedInRole('project.members.add', currentUser, route.params.projectId)">
        <div class="member-add">
          <ui-add-member-card
            class=“stakeholder-detail__card” :card-title="$t('Project.Members.NewMembers.Header')"
            :label-title="$t('Profile.Username')" :label-for="'username'" :option-label="'name'" :select-options="roles.filter(x => x.nr)"
            :multiple-select="false" :model-value="member" :button-label="$t('ProjectMembers.SendInvitation')"
            @update-value="sendInvite">
          </ui-add-member-card>
        </div>
      </div>
    </template>
    <p-confirm-dialog></p-confirm-dialog>
    <p-progress-spinner class="mt-10 me-auto ms-auto" v-if="loadingProjectMembers || loadingProjectMemberAdd">
    </p-progress-spinner>
    <div v-show="error">
      <!-- TODO Remove and add production worthy solution -->
      {{ t('ProjectMembers.Error') }}
    </div>
  </main>
</template>

<style lang="scss">
.members {

  &__grid,
  &__cards-grid {
    display: grid;
  }

  &__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-columns: 1fr;
    }

    &-item {
      font-size: calc-rem(14);
      line-height: calc-rem(20);

      &:nth-child(1) {
        border-bottom: calc-rem(1) solid $separatorLineColor;

        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 2;
          border-left: calc-rem(1) solid $separatorLineColor;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-up(lg) {
          grid-column: 1 / span 2;
          min-height: calc-rem(139);
        }
      }

      h3,
      h4 {
        color: $secondaryTextColor;
      }

      h3 {
        margin-top: calc-rem(3);
        font-size: calc-rem(16);
        font-weight: 600;
        line-height: calc-rem(18);
      }

      h4 {
        margin-bottom: calc-rem(7);
        line-height: calc-rem(16);
      }

      a {
        text-decoration: none;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__cards-grid {
    row-gap: calc-rem(12);

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: calc-rem(12);
      grid-auto-columns: 1fr;
    }
  }

  &__img {
    aspect-ratio: 308 / 296;
    border-radius: $borderRadius;
    background-color: $mainBg;
    color: $secondaryTextColor;

    &--default {
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: calc-rem(150);
      opacity: 0.2;
      transform: translate(-50%, -50%);
    }

    img {
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .p-card-content {
    padding: 0;
  }
}

.member-add {}
</style>
